import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';

import { ViewViolationService } from 'src/app/core/services';
import { Lane, Lot, Reason, Regulation, Spot } from 'src/app/core/models';

@Component({
  selector: 'app-violations-view-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.sass']
})
export class ViolationsViewFilterComponent implements OnInit {
  public loading: boolean;
  public filterForm: FormGroup;
  public dataContractsSub: Subscription;
  public contractViewViolationsSub: Subscription;
  public filterViewViolationsSub: Subscription;
  public dataProduction: any;
  public spots: Spot[] = [];
  public lanes: Lane[] = [];
  public allLanes: Lane[] = [];
  public isValid: boolean;
  public regulations: Regulation[] = [];
  public situations: any[] = [];
  public reasons: Reason[] = [];
  public lots: Lot[] = [];
  public users: any[] = [];
  public violations: any[] = [];
  public collapse: boolean;
  public reasonArea: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private viewViolationService: ViewViolationService,
  ) { }

  ngOnInit() {
    this.filterForm = this.formBuilder.group({
      spotsIds: ['', null],
      laneIds: ['', null],
      regulationsIds: ['', null],
      situationsIds: ['', null],
      reasonsIds: ['', null],
      lotIds: ['', null],
      userIds: ['', null],
      numberSerie: ['', null],
      numberPlate: ['', null],
      numberId: ['', null]
    });

    this.contractViewViolationsSub = this.viewViolationService.getDataViewContractViolation()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((data: any) => {
        this.spots = data.spots;
        this.lanes = data.lanes.map( e => ({ ...e, prettyName: e.prettyNames?.[0] }));
        this.allLanes = data.lanes.map( e => ({ ...e, prettyName: e.prettyNames?.[0] }));
        this.regulations = data.regulations;
        this.situations = data.situations;
        this.reasons = data.reasons;
        this.lots = data.lots;
        this.users = data.users;
        this.loading = true;
      });


    this.filterForm.get('situationsIds').valueChanges.subscribe(res => {
      if (res.includes('invalid')) { this.reasonArea = true; } else { this.reasonArea = false; }
    });

    this.filterForm.valueChanges.subscribe(res => {
      this.isValid = this.filterForm.valid;
    });

    this.contractViewViolationsSub = this.viewViolationService.getFilterViewViolation()
    .pipe(filter(result => Object.keys(result).length > 0))
    .subscribe((data: any) => {
        if (data.situationsIds.includes('quarantine')) {
          const quarantine = data.situationsIds.indexOf('quarantine');
          if (quarantine !== -1) {
            data.situationsIds[quarantine] = 'inProcess';
          }
        }

        this.filterForm.get('spotsIds').setValue(data.spotsIds);
        this.filterForm.get('laneIds').setValue(data.laneIds);
        this.filterForm.get('regulationsIds').setValue(data.regulationsIds);
        this.filterForm.get('situationsIds').setValue(data.situationsIds);
        this.filterForm.get('reasonsIds').setValue(data.reasonsIds);
        this.filterForm.get('lotIds').setValue(data.lotIds);
        this.filterForm.get('numberSerie').setValue(data.numberSerie);
        this.filterForm.get('numberPlate').setValue(data.numberPlate);
        this.filterForm.get('numberId').setValue(data.numberId);
        this.filterForm.get('userIds').setValue(data.userIds);

      });

    this.filterForm.get('spotsIds').valueChanges.subscribe(res => {
      const spotIds = res ? res.map(Number) : 0;
      this.lanes = spotIds.length > 0 ? this.allLanes.filter(lane => spotIds.includes(lane.spotId)) : this.allLanes;
      this.filterForm.get('laneIds').setValue([]);
    });
  }

  submitFilter() {
    this.viewViolationService.emitFilterViewViolation(this.filterForm.value);
  }

  redefineFilter() {
    this.filterForm.get('spotsIds').setValue('');
    this.filterForm.get('laneIds').setValue('');
    this.filterForm.get('regulationsIds').setValue('');
    this.filterForm.get('situationsIds').setValue([]);
    this.filterForm.get('reasonsIds').setValue('');
    this.filterForm.get('lotIds').setValue([]);
    this.filterForm.get('numberSerie').setValue('');
    this.filterForm.get('numberPlate').setValue('');
    this.filterForm.get('numberId').setValue('');
    this.filterForm.get('userIds').setValue([]);

    this.lanes = this.allLanes;
    this.viewViolationService.emitFilterViewViolation(this.filterForm.value);
  }

  toggleCollapse() {
    this.collapse = !this.collapse;
  }
}
