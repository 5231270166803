import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import {ModalContent} from '../../../core/interface';


@Component({
  selector: 'app-video-view',
  templateUrl: './video-view.component.html',
  styleUrls: ['./video-view.component.sass']
})
export class VideoViewComponent extends BaseModal implements OnInit, ModalContent {
  @Input() initialState;
  public loading: boolean;

  constructor(
    modalService: ModalService
  ) {
    super(modalService);
  }

  ngOnInit(): void {
  }

  get isValid(): boolean {
    throw new Error('Method not implemented.');
  }
  get lastState(): any {
    throw new Error('Method not implemented.');
  }
  onAllSubmited(): Promise<any> {
    throw new Error('Method not implemented.');
  }
}

