import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ContractService,
  StorageKey,
  StorageService,
  AlertService,
  ContractGlobalService,
  PreProcessSettingsService
} from 'src/app/core/services';
import { permissionStep, searchContractsPermited } from 'src/app/core/utils/permission';
import { AlertItem, AlertType, Contract, Modules, PreProcessSettings } from 'src/app/core/models';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-available-contracts',
  templateUrl: './available-contracts.component.html',
  styleUrls: ['./available-contracts.component.sass']
})
export class AvailableContractsComponent implements OnInit {
  contracts: any = [];
  verifyContracts: any = [];
  step: string;
  module: string;
  submodule: string;
  stepPermissions: string;
  currentContracts;
  public loading: boolean;
  public permissions: Array<{ contractId: string; actionIds: string[] }> = [];
  public allowedContracts: any = [];
  public params = {};
  public searchValue = '';
  public contractGlobal = null;
  public contractGlobalArray = null;
  public preProccessSettingsById: { [key: string]: PreProcessSettings } = {};
  private searchChanged: Subject<string> = new Subject<string>();

  constructor(
    private storageService: StorageService,
    private contractService: ContractService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private contractsGlobalService: ContractGlobalService,
    private preProcessSettingsService: PreProcessSettingsService
  ) {
    this.searchChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });

    if (this.contractsGlobalService.contracts != null) {
      this.contractGlobal = this.contractsGlobalService.contracts.length === 1 ? this.contractsGlobalService.contracts[0] : null;
    }
    if (this.contractsGlobalService.contracts && this.contractsGlobalService.contracts.length > 1 ) {
      this.contractGlobalArray =  this.contractsGlobalService.contracts;
    }
  }

  async ngOnInit() {
    this.permissions = this.storageService.get(StorageKey.currentPermissions);
    this.step = this.activatedRoute.snapshot.params.step;
    this.module = this.activatedRoute.snapshot.params.module;
    this.submodule = this.activatedRoute.snapshot.params.submodule;
    this.stepPermissions = permissionStep(this.step);
    await this.loadContracts();
  }

  async loadContracts(params = {}, isVerifyContract = true) {
    this.loading = true;
    if (this.permissions != null) {
      if (this.contractGlobal != null) {
        this.selectedContract(this.contractGlobal);
      } else {
        await this.contractService.getAll(params).then(async res => {
          const user = this.storageService.get(StorageKey.currentUser);
          if (user.superUser)   {
            this.contracts = res;
          } else {
            this.allowedContracts = await searchContractsPermited(
              this.permissions,
              this.stepPermissions,
              this.preProcessSettingsService,
              res
            );
            this.contracts = res.filter(contract => this.allowedContracts.includes(contract.id));
            if (['triage', 'typing', 'verify', 'validate'].includes(this.step)) {
              this.contracts = res.filter(contract => {
                if (this.module === 'pre-process') {
                  if (!contract.modules.includes(Modules.preProcess)) {
                    return false;
                  }
                }
                return true;
              });
            }
          }
          if (this.contractGlobalArray) {
            this.contracts = await this.contracts.filter(c => this.contractGlobalArray.includes(c.id));
          }
          if (this.contracts.length === 1 && isVerifyContract) {
            this.selectedContract(this.contracts[0]?.id);
          }
        });
      }
    }
    setTimeout(() => {
      this.loading = false;
    }, 1000);

  }
  handleSearch() {
    this.contracts = [];
    const search = this.searchValue;
    const filterObj = {};
    if (search != null && search !== '') {
      filterObj['name[contains,or]'] = `${search}`;
      filterObj['code[contains,or]'] = `${search}`;
    }
    this.params = filterObj;
    this.loadContracts(this.params, false);
  }
  selectedContract(contractId) {
    if (localStorage.getItem('lastLayer') && localStorage.getItem('lastQuarantineId')) {
      localStorage.removeItem('lastLayer');
      localStorage.removeItem('lastQuarantineId');
    }
    if (this.submodule == null) {
      this.router.navigate([`/${this.module}/${this.step}/${contractId}`]);
    } else {
      this.router.navigate([`/${this.module}/${this.submodule}/${this.step}/${contractId}`]);
    }
  }

  hasPreProcessModule(contract) {
    if (contract == null ) {
      return false;
    }

    if (contract == null) {
      return false;
    }

    return contract.modules.includes(Modules.violations);
  }

  searchKey(text: string) {
    this.searchChanged.next(text);
  }
}
