<div class="loading-page" *ngIf="isLoading">
  <div class="loading-spinner">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</div>

<div class="pt-3" *ngIf="!isLoading">
  <div class="w-100" *ngIf="noRegister; else hasRegisterBlock">
    <p class="text-center text-secondary py-5" i18n>Não há dados encontrados.</p>
  </div>
  <ng-template #hasRegisterBlock class="mb-3">
    <div class="row no-gutters">
      <div class="col-md-9 pr-3">
        <div class="select-page p-2 bd-highlight">
          <ul class="pagination">
            <li [ngClass]="{'page-item': true, disabled: !hasPrevious}">
              <a class="page-link btn-icon-block" [routerLink]="" (click)="previous()"><i class="material-icons">navigate_before</i></a>
            </li>
            <li *ngFor="let i of getSurroundingElements(pages, currentPage)" [ngClass]="{'page-item': true, active: i === currentPage}">
              <a class="page-link" [routerLink]="" (click)="setPage(i)">{{i}}</a>
            </li>
            <li [ngClass]="{'page-item': true, disabled: !hasNext}">
              <a class="page-link btn-icon-block" [routerLink]="" (click)="next()"><i class="material-icons">navigate_next</i></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-3">
        <div class="per-page p-2 bd-highlight d-none d-sm-block">
          <form [formGroup]="form">
            <select formControlName="pageSize" class="form-control form-control-fade-secondary" [value]="pageSize || 5">
              <option value="5" i18n>Mostrando 5 resultados</option>
              <option value="10" i18n>Mostrando 10 resultados</option>
              <option value="25" i18n>Mostrando 25 resultados</option>
              <option value="50" i18n>Mostrando 50 resultados</option>
              <option value="100" i18n>Mostrando 100 resultados</option>
            </select>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
</div>
