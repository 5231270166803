import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TreeviewModule } from 'ngx-treeview';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/guards';
import { ErrorInterceptor, JwtInterceptor } from './core/helpers';
import { AuthenticationService } from './core/services';
import { AlertService } from './core/services/alert.service';
import { ModalService } from './core/services/modal.service';
import { LaneSelectWeekdaysComponent } from './modals/lane-select-weekdays/lane-select-weekdays.component';
import { LaneSelectComponent } from './modals/lane-select/lane-select.component';
import { SetFieldComponent } from './modals/set-field/set-field.component';
import { ContractsModule } from './pages/contracts/contracts.module';
import { EquipmentViewModalComponent } from './pages/equipments/equipment-view-modal/equipment-view-modal.component';
import { EquipmentsModule } from './pages/equipments/equipments.module';
// import { ExemptPeriodsModule } from './pages/exempt-periods/exempt-periods.module';
import { HomeComponent } from './pages/home/home.component';
import { ViolationConfigModalComponent } from './pages/lanes/violation-config-modal/violation-config-modal.component';
import { ForgotPasswordComponent } from './pages/login/forgot-password/forgot-password.component';
import { InvalidTokenComponent } from './pages/login/invalid-token/invalid-token.component';
import { LoginComponent } from './pages/login/login.component';
import { VerifyUserComponent } from './pages/login/verify-user/verify-user.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PreProcessModule } from './pages/pre-process/pre-process.module';
import { SpotMapModalComponent } from './pages/spots/spot-map-modal/spot-map-modal.component';
import { SpotsModule } from './pages/spots/spots.module';
import { UserGroupsModule } from './pages/user-groups/user-groups.module';
import { UsersModule } from './pages/users/users.module';
import { SharedModule } from './shared/shared.module';
import { ViewFileModalComponent } from './shared/view-file-modal/view-file-modal.component';
import { ViewVideoModalComponent } from './shared/view-video-modal/view-video-modal.component';
import { TriageConfigModalComponent } from './modals/triage-config-modal/triage-config-modal.component';
import { SelectValuesComponent } from './modals/select-values/select-values.component';
import { ShortcutKeysModalComponent } from './pages/shortcut-keys/shortcut-keys-modal/shortcut-keys-modal.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SelectRegulationModalComponent } from './modals/select-regulation-modal/select-regulation-modal.component';
import { DetailsViolationComponent } from './modals/details-violation/details.component';
import { CoreModule } from './core/core.module';
import { CalibrationsModule } from './pages/calibrations/calibrations.module';
import {
  MessageDuplicateViolationModalComponent
} from './modals/message-duplicate-violation-modal/message-duplicate-violation-modal.component';
import { QuaratineActionsMessageModalComponent } from './modals/quaratine-actions-message-modal/quaratine-actions-message-modal.component';
ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production });
import { SelectPeriodComponent } from './modals/select-period/select-period.component';
import { ReleaseNotesComponent } from './modals/release-notes-modal/release-notes.component';
import { ViolationsModule } from './pages/violations/violations.module';
import { ActivitiesModule } from './pages/activities/activities.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { LoteInfoComponent } from './modals/lote-info/lote-info.component';
import { LotDetailsComponent } from './modals/lot-details/lot-details.component';
import { LotCreateComponent } from './modals/lot-create/lot-create.component';
import {
  MessageVehicleWhiteListModalComponent
} from './modals/message-vehicle-white-list-modal/message-vehicle-white-list-modal.component';
import {
  VehicleWhiteListViewModalComponent
} from './pages/vehicle-white-list/vehicle-white-list-view-modal/vehicle-white-list-view-modal.component';
import { LoteAuditInfoComponent } from './modals/lote-audit-info/lote-audit-info.component';
import { LotAuditDetailsComponent } from './modals/lot-audit-details/lot-audit-details.component';
import { ReasonsModule } from './pages/reasons/reasons.module';
import { LotExtraDataComponent } from './modals/lot-extraData/lot-extraData.component';
import { VerifyUrlAssignedComponent } from './pages/login/verify-url-assigned/verify-url-assigned.component';
import { ViewSelectListComponent } from './modals/view-select-list/view-select-list.component';
import { VideoTutorialComponent } from './pages/video-tutorial/video-tutorial.component';
import { VideoViewComponent } from './pages/video-tutorial/video-view/video-view.component';
import { AdministrativeModule } from './pages/administrative/administrative.module';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ForgotPasswordComponent,
    PageNotFoundComponent,
    VerifyUserComponent,
    InvalidTokenComponent,
    LoginComponent,
    ViolationConfigModalComponent,
    LaneSelectComponent,
    LaneSelectWeekdaysComponent,
    SpotMapModalComponent,
    EquipmentViewModalComponent,
    ViewFileModalComponent,
    ViewVideoModalComponent,
    SetFieldComponent,
    ViewVideoModalComponent,
    TriageConfigModalComponent,
    SelectValuesComponent,
    ShortcutKeysModalComponent,
    SelectRegulationModalComponent,
    MessageDuplicateViolationModalComponent,
    QuaratineActionsMessageModalComponent,
    DetailsViolationComponent,
    SelectPeriodComponent,
    ReleaseNotesComponent,
    LoteInfoComponent,
    LotDetailsComponent,
    LotCreateComponent,
    LotExtraDataComponent,
    MessageVehicleWhiteListModalComponent,
    LoteAuditInfoComponent,
    VehicleWhiteListViewModalComponent,
    LotAuditDetailsComponent,
    VerifyUrlAssignedComponent,
    ViewSelectListComponent,
    VideoTutorialComponent,
    VideoViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ContractsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    SharedModule,
    SpotsModule,
    UserGroupsModule,
    UsersModule,
    EquipmentsModule,
    // ExemptPeriodsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatExpansionModule,
    MatDatepickerModule,
    TreeviewModule.forRoot(),
    PreProcessModule,
    ViolationsModule,
    CalibrationsModule,
    ReasonsModule,
    ActivitiesModule,
    ViolationsModule,
    AdministrativeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  entryComponents: [
    ViolationConfigModalComponent,
    LaneSelectComponent,
    LaneSelectWeekdaysComponent,
    SetFieldComponent,
    SpotMapModalComponent,
    EquipmentViewModalComponent,
    ViewFileModalComponent,
    ViewVideoModalComponent,
    TriageConfigModalComponent,
    SelectValuesComponent,
    SelectPeriodComponent,
    ShortcutKeysModalComponent,
    SelectRegulationModalComponent,
    MessageDuplicateViolationModalComponent,
    QuaratineActionsMessageModalComponent,
    ReleaseNotesComponent,
    DetailsViolationComponent,
    LoteInfoComponent,
    MessageVehicleWhiteListModalComponent,
    VehicleWhiteListViewModalComponent,
    LoteInfoComponent,
    LotExtraDataComponent,
    ViewSelectListComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true  },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true  },
    AuthGuard,
    AuthenticationService,
    ModalService,
    AlertService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
