<div class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <div class="row">
        <h5 class="modal-title container" i18n>Replicar o(s) Motivo(s) de
          Invalidação(ões)</h5>
      </div>
      <button type="button" (click)="onClose()"
        class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="reasonForm" (submit)="onSubmit()">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <app-input i18n-label="@@ContractLabel" type="select"
                formControlName="contractId"
                inputRequired=true
                label="Replicar o(s) motivo(s) de invalidação(ões) do contrato"
                [list]="contracts"
                i18n-placeholder
                placeholder="Selecione o contrato"
                selectValue="id"
                selectText="prettyName">
              </app-input>
            </div>
          </div>
        </div>
        <div class="loading-page" *ngIf="loadingReasons">
          <div class="loading-spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
        <div class="row" *ngIf="displayReasons && !loadingReasons">
          <div class="col-12">
            <div class="form-group">
              <app-input type="multiselect"
                formControlName="reasons"
                inputRequired=true
                label="Motivo de invalidação"
                [list]="reasons"
                i18n-placeholder
                placeholder="Selecione o(s) motivo(s) de invalidação(ões)"
                selectValue="id"
                selectText="prettyName">
              </app-input>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="displayReasons && !loadingReasons">
          <div class="col-12">
            <div class="form-group">
              <app-input type="select"
                [list]="contractsReplicated"
                i18n-placeholder
                formControlName="targetContractId"
                inputRequired=true
                label="Replicar o(s) motivo(s) de invalidação(ões) para o contrato"
                placeholder="Selecione o contrato"
                selectValue="id"
                selectText="prettyName">
              </app-input>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary" (click)="onCancel()">
        <app-loading [promise]="promise" text="Cancelar" i18n-text></app-loading>
      </button>
      <button class="btn btn-primary" (click)="onSubmit()"
        [ngClass]="{disabled: !isValid}">
        <app-loading [promise]="promise" text="Confirmar" i18n-text></app-loading>
      </button>
    </div>
  </div>
</div>
