import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AlertItem, AlertType, Contract, EquipmentModel, Lane, Reason, Regulation,
  SerialNumberConfig, SerialNumberFields, Spot, ViolationStatus
} from 'src/app/core/models';
import {
  AlertService, ContractService, EquipmentModelService, LaneService, ModalService, ReasonService, RegulationService,
  SerialNumberConfigService, SpotService
} from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-serial-number-config-create-modal',
  templateUrl: './create-modal.component.html',
  styleUrls: ['./create-modal.component.sass']
})
export class SerialNumberConfigCreateModalComponent extends BaseModal implements OnInit {
  public serialNumberConfig: SerialNumberConfig = new SerialNumberConfig();
  public form: FormGroup;
  public contract: Contract;
  public spots: Spot[];
  public regulations: Regulation[];
  public equipmentModels: EquipmentModel[];
  public promise = null;
  public view: string;
  public serieArrayExample = [];
  public serieArrayExampleI18n = [];
  public serialNumberFields = Object.values(SerialNumberFields);
  public serialNumberFieldsI18n = [];
  public lanes: Array<Lane> = [];
  public lanesBySpots: Array<Lane> = [];
  public reasons: Reason[] = [];
  public reasonsCopy: Reason[] = [];
  public violationStatus = Object.values(ViolationStatus);
  public violationStatusI18n = [];
  public disabled = true;

  private contractId;
  private _namePattern;

  constructor(
    private formBuilder: FormBuilder,
    private serialNumberConfigService: SerialNumberConfigService,
    private spotService: SpotService,
    private laneService: LaneService,
    private regulationService: RegulationService,
    private equipmentModelService: EquipmentModelService,
    private alertService: AlertService,
    public modalService: ModalService,
    private contractService: ContractService,
    private reasonService: ReasonService
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.contractId = this.initialState != null && this.initialState.contractId;
    this.view = this.initialState != null && this.initialState.view;
    this.serialNumberConfig = this.initialState != null && this.initialState.serialNumberConfig || new SerialNumberConfig();
    const params = {
      'contractIds[in]': `[${this.contractId}]`
    };
    this.contractService.getById(this.contractId).then(contract => {
      const paramsRegulation = {
        regionId: contract.regionId
      };
      if (contract.regulationIds) {
        paramsRegulation['id[in]'] = `[${contract.regulationIds}]`;
      }
      this.regulationService.getAll(paramsRegulation).then(res => {
        this.regulations = res;
      });
      this.spotService.getAll({ contractId: contract.id }).then(spots => {
        this.spots = spots;
        this.laneService.getAll({ contractId: contract.id }).then(lanes => {
          this.lanes = lanes;
          this.setLanes(this.serialNumberConfig.spotIds);
        });
      });
    });
    this.equipmentModelService.getAll(params).then(equipmentModels => {
      this.equipmentModels = equipmentModels;
      this.equipmentModels.map(model => {
        switch(model.type) {
          case 'fixed':
            model.type = 'Fixo';
          break;
        }
      });
    });

    const contractFilter = { contractId: this.contractId, 'enabled[bool]': 'true', 'screen[contains]' : 'serialNumberConfig' };
    this.reasonService.getAll(contractFilter).then(data => {
      this.reasons =  data.sort((a, b): any => Number(a.code) - Number(b.code));
      this.reasonsCopy = Object.assign([], this.reasons)
    });

    this.createForm(this.serialNumberConfig);
  }

  setLanes(spotIds = []) {
    this.lanesBySpots = [];
    spotIds.map(spotId => {
      this.lanes.filter(lane => {
        if (lane.spotId === spotId) {
          this.lanesBySpots.push(lane);
        }
      });
    });

    const formValues = this.form.value;
    this.disabled = formValues.spotIds && formValues.spotIds.length > 0 ?  false : true;
  }

  createForm(serialNumberConfig: SerialNumberConfig = new SerialNumberConfig()) {
    this._namePattern = serialNumberConfig.namePattern;
    this.form = this.formBuilder.group({
      contractId: [this.contractId, Validators.required],
      identifier: [serialNumberConfig.identifier],
      prefix: [serialNumberConfig.prefix],
      verifyingDigit: [serialNumberConfig.verifyingDigit],
      start: [serialNumberConfig.range && serialNumberConfig.range.start],
      end: [serialNumberConfig.range && serialNumberConfig.range.end],
      sizePattern: [serialNumberConfig.sizePattern],
      equipmentModelIds: [serialNumberConfig.equipmentModelIds],
      regulationIds: [serialNumberConfig.regulationIds],
      spotIds: [serialNumberConfig.spotIds],
      laneIds: [serialNumberConfig.laneIds],
      reasonIds: [serialNumberConfig.reasonIds],
      violationStatus: [serialNumberConfig.violationStatus],
      namePattern: [this.getPrettyNamePattern(serialNumberConfig.namePattern), Validators.required],
      enabled: [serialNumberConfig.enabled],
      currentNumber: [serialNumberConfig.currentNumber || 0],
      serieExample: ['']
    });

    this.form.get('violationStatus').valueChanges.subscribe(reason => {
      const values = this.form.get('violationStatus').value;
      if (!values.includes('invalid')) {
        this.form.get('reasonIds').disable();
        this.form.get('reasonIds').patchValue([]);
      }
    });

    const startControl = this.form.get('start');
    const endControl = this.form.get('end');
    startControl.setValidators([this.isBiggerOrLess(endControl, 'isLowerThan', false), Validators.required, Validators.min(0)]);
    endControl.setValidators([this.isBiggerOrLess(startControl, 'isBiggerThan', true), Validators.required, Validators.min(0)]);

    this.setSerieExample();
    this.form.get('namePattern').valueChanges.subscribe(value => {
      const namePattern = this.getPrettyNamePattern(this._namePattern);
      if (this.getPrettyNamePattern(value) !== namePattern) {
        this._namePattern = value;
        this.setSerieExample();
      }
    });
    this.form.patchValue({ laneIds: serialNumberConfig.laneIds });
  }

  getPrettyNamePattern(namePattern = null) {
    if (namePattern == null || Array.isArray(namePattern)) {
      return namePattern;
    }
    const fields = namePattern.replace(/\W/g, '|').split('|').filter(i => i !== '');
    return fields.filter(f => f !== 'serialNumberConfig');
  }

  isBiggerOrLess(control, errorType, isBigger = true) {
    let oldState = true;
    return (verifyControl) => {
      if (verifyControl.value == null ||
        verifyControl.value === '' ||
        control.value == null ||
        control.value === '') {
        return null;
      }
      const controlValue = parseInt(control.value, 10);
      const verifyControlValue = parseInt(verifyControl.value, 10);
      const isValid = isBigger ? verifyControlValue > controlValue : verifyControlValue < controlValue;
      const needUpdate = oldState !== isValid;
      oldState = isValid;
      if (needUpdate) {
        control.updateValueAndValidity();
      }
      if (!isValid) {
        let msg = '';
        switch (errorType) {
          case 'isBiggerThan':
            msg = `The field value is lower than ${control.value}`;
            break;
          case 'isLowerThan':
            msg = `The field value is bigger than ${control.value}`;
        }
        return {
          [errorType]: {
            errMsg: msg,
            value: control.value
          }
        };
      } else {
        return null;
      }
    };
  }

  generateNamePattern() {
    const newNamePattern = this.createNamePattern();
    const arrayNewNamePattern = this.getPrettyNamePattern(newNamePattern);
    this.serieArrayExampleI18n = this.serialNumberFieldsI18n.filter(item => arrayNewNamePattern.includes(item.id));
    this._namePattern = newNamePattern;
    this.form.get('namePattern').setValue(arrayNewNamePattern);
  }

  createNamePattern(fieldsToJoin = null) {
    let str = '';
    if (fieldsToJoin == null) {
      fieldsToJoin = this.serialNumberFields;
    }

    for (let field of fieldsToJoin) {
      const value = this.form.value[field];
      if (value === '' || value == null || value.length === 0) {
        continue;
      }
      switch (field) {
        case 'end':
        case 'start':
          field = 'range.' + field;
          break;
        case 'spotIds':
          field = 'spot.code';
          break;
        case 'regulationIds':
          field = 'regulation.code';
          break;
        case 'equipmentModelIds':
          field = 'equipmentModel.name';
          break;
      }
      field = 'serialNumberConfig.' + field;
      if (value != null && value !== '') {
        str += `{{${field}}}`;
      }
    }
    return str;
  }

  onAllSubmited() {
    const serialNumberConfig = new SerialNumberConfig();
    serialNumberConfig.id = this.serialNumberConfig.id;
    const formValues = this.form.value;
    this._namePattern = this.createNamePattern(this.form.get('namePattern').value);
    serialNumberConfig.contractId = Number(formValues.contractId);
    serialNumberConfig.identifier = formValues.identifier || '';
    serialNumberConfig.prefix = formValues.prefix || '';
    serialNumberConfig.sizePattern = Number(formValues.sizePattern) || 0;
    serialNumberConfig.verifyingDigit = formValues.verifyingDigit || '';
    serialNumberConfig.equipmentModelIds = formValues.equipmentModelIds || [];
    serialNumberConfig.regulationIds = formValues.regulationIds || [];
    serialNumberConfig.spotIds = formValues.spotIds || [];
    serialNumberConfig.laneIds = formValues.laneIds || [];
    serialNumberConfig.violationStatus = formValues.violationStatus || [];
    serialNumberConfig.reasonIds = formValues.reasonIds || [];
    serialNumberConfig.enabled = formValues.enabled || false;

    serialNumberConfig.range = {
      start: parseInt(formValues.start, 10),
      end: parseInt(formValues.end, 10)
    };
    serialNumberConfig.currentNumber = formValues.currentNumber || formValues.start;
    serialNumberConfig.namePattern = this._namePattern;
    if (serialNumberConfig.id == null) {
      this.promise = this.serialNumberConfigService.create(serialNumberConfig, false).then(data => {
        this.serialNumberConfig = data;
        this.alertService.show(new AlertItem('SerialNumberConfigSaveSuccess', AlertType.success));
      }).catch(err => {
        if (err.status === 409) {
          this.alertService.show(new AlertItem('SerialNumberConfigDuplicityError', AlertType.danger));
        } else {
          this.alertService.show(new AlertItem('SerialNumberConfigSaveError', AlertType.danger));
        }
        throw err;
      });
    } else {
      this.promise = this.serialNumberConfigService.update(serialNumberConfig, false).then(data => {
        this.serialNumberConfig = data;
        this.alertService.show(new AlertItem('SerialNumberConfigUpdateSuccess', AlertType.success));
      }).catch(err => {
        this.alertService.show(new AlertItem('SerialNumberConfigUpdateError', AlertType.danger));
        throw err;
      });
    }
    Object.keys(serialNumberConfig).forEach(key => {
      if (serialNumberConfig[key] == null || serialNumberConfig[key] === '') {
        delete serialNumberConfig[key];
      }
    });

    return this.promise;
  }

  get isValid(): boolean {
    return this.form && this.form.valid;
  }
  get lastState(): any {
    throw new Error('Method not implemented.');
  }

  setSerieExample() {
    const sizePattern = this.form.get('sizePattern').value;
    // const currentNumber = this.form.get('currentNumber').value;
    const currentNumber = this.form.get('start').value;
    const names = this.form.get('namePattern').value;
    this.serieArrayExampleI18n = this.serieArrayExampleI18n.filter(item => names.includes(item.id));

    const length = currentNumber?.length || 0;
    if(length > sizePattern){
      this.form.get('sizePattern').setValue(length);
      return this.setSerieExample();
    }

    if (names != null) {
      let pathFormat = '';
      for (const name of names) {
        const value = this.form.value[name];
        if (value === '' || value == null || value.length === 0) {
          continue;
        }
        if (name === 'currentNumber' && sizePattern > 0) {
          let padlCurrent = '' + (currentNumber > 0 ? currentNumber : 1);
          padlCurrent = padlCurrent.padStart(sizePattern, '0');
          pathFormat += padlCurrent;
          continue;
        }
        pathFormat += this.form.get(name).value;
      }
      this.form.patchValue({ serieExample: pathFormat });
      this.serieArrayExample = names;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    const names = this.form.get('namePattern').value;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const originArray = event.item && event.item.dropContainer && event.item.dropContainer.data;
      const item = originArray[event.previousIndex];
      const index = names.indexOf(item);
      if (index >= 0) {
        this.serieArrayExample.splice(index, 1);
      }
    }
    const newValues = [];
    for (const item of event.container.data) {
      const itemObj: any = item;
      newValues.push(itemObj.id);
    }
    this.form.patchValue({ namePattern: newValues });
  }

  deleteFields(item = null) {
    this.serieArrayExampleI18n = this.serieArrayExampleI18n.filter(serie => serie.id !== item.id);
    const newValues = [];
    for (const serie of this.serieArrayExampleI18n) {
      const itemObj: any = serie;
      newValues.push(itemObj.id);
    }
    this.form.patchValue({ namePattern: newValues });
    this.setSerieExample();
  }

  disableViolationStatus() {
    const value = this.form.get('violationStatus').value || [];
    return value.length === 1 && value[0] === 'valid';
  }

  getReasons(retulationIds) {
    this.reasons = [];
    for (const regulation of retulationIds) {
      for (const r of this.reasonsCopy) {
        if (r.regulationIds.includes(regulation)) {
          if (this.reasons.find((item) => item.id === r.id) === undefined) {
            this.reasons.push(r);
          }
        }
      }
    }
  }
}
