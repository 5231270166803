<div class="container mb-5">
  <div class="card card-header">
    <div class="page-title page-title--columns mb-3">
      <div class="page-title__left">
        <span i18n>Relatório</span>
        <h2 i18n>Aproveitamento OCR</h2>
      </div>
    </div>
  </div>
  <div [id]="'userIdHistoryUnfold'">
    <div class="table-responsive table-hover table-block mb-1">
      <div class="d-flex flex-row justify-content-between" style="cursor: pointer;">
        <div class="col" (click)="unfoldMoreOrLess()">
        </div>
        <div class="p-3">
          <button type="button" class="btn text-secondary btn-icon-block" (click)="unfoldMoreOrLess()">
            <i class="material-icons" *ngIf="typeUnfold === 'more'; else elseBlock">unfold_less</i>
            <ng-template #elseBlock>
              <i class="material-icons">unfold_more</i>
              <span>Filtros</span>
            </ng-template>
          </button>
        </div>
      </div>
      <div *ngIf="typeUnfold === 'more'" class="limited-height">
        <form [formGroup]="ocrReportForm">
          <div class="card card-body">
            <div class="d-flex">
              <h5 class="px-4 font-weight-bold">Relatórios:<small class="text-danger">*</small></h5>
              <hr class="my-auto">
              <hr class="my-auto flex-grow-1">
            </div>
            <div class="row mt-2 mb-2">
              <div class="col">
                <div class="form-group">
                  <app-input type="multiselect" [list]="typeReport" formControlName="reportTypeIds" selectText="value"
                    selectValue="id" selectShowLimit="1" placeholder="Selecione o relatório" i18n-placeholder>
                  </app-input>
                </div>
              </div>
            </div>
          </div>
          <div class="card card-body">
            <div class="d-flex">
              <h5 class="px-4 font-weight-bold">Local</h5>
              <hr class="my-auto">
              <hr class="my-auto flex-grow-1">
            </div>
            <div class="row mt-2 mb-2">
              <div class="col-8 col-sm-8">
                <div class="form-group">
                  <label class="">Pontos:</label>
                  <app-input i18n-label type="multiselect" [list]="spots" formControlName="spotIds"
                    selectText="prettyName" selectValue="id" selectShowLimit="1" placeholder="Selecione o Ponto"
                    i18n-placeholder (ngModelChange)="setLanes($event)">
                  </app-input>
                </div>
              </div>
              <div class="col-4 col-sm-4">
                <label class="">Faixas:</label>
                <div class="form-group">
                  <app-input class="no-margin" formControlName="laneIds" type="multiselect" [list]="lanesBySpots"
                    placeholder="Selecione faixas" selectText="prettyNames" selectValue="id" selectShowLimit="1"
                    i18n-placeholder i18n-label>
                  </app-input>
                </div>
              </div>
            </div>
          </div>
          <div class="card card-body">
            <div class="d-flex">
              <h5 class="px-4 font-weight-bold">Período</h5>
              <hr class="my-auto">
              <hr class="my-auto flex-grow-1">
            </div>

            <div class="row mb-2">
              <div class="col-4">
                <label class="">Inicial:<small class="text-danger">*</small></label>
                <div class="form-group">
                  <app-input type="date" class="no-margin small " formControlName="startDate" inputRequired=true
                    label="Data Inicial" hasLabel=false>
                  </app-input>
                </div>
              </div>
              <div class="col-4">
                <label class="">Final:<small class="text-danger">*</small></label>
                <div class="form-group">
                  <app-input type="date" label="Data Inicial" class="no-margin small " formControlName="endDate"
                    inputRequired=true hasLabel=false>
                  </app-input>
                </div>
              </div>
              <div class="col-2 col-sm-2">
                <label class="">Horário Inicial:<small class="text-danger">*</small></label>
                <div class="form-group">
                  <app-input class="no-margin small " format="dd:dd:dd" formControlName="startHour" inputRequired=true
                    hasLabel=false>
                  </app-input>
                </div>
              </div>
              <div class="col-2 col-sm-2 pl-0">
                <label class="">Final:<small class="text-danger">*</small></label>
                <div class="form-group">
                  <app-input class="no-margin small " format="dd:dd:dd" formControlName="endHour" inputRequired=true
                    hasLabel=false>
                  </app-input>
                </div>
              </div>
            </div>
          </div>
          <div class="card card-body">
            <div class="d-flex">
              <h5 class="px-4 font-weight-bold">Turno</h5>
              <hr class="my-auto">
              <hr class="my-auto flex-grow-1">
            </div>
            <div class="row mb-4">
              <div class="col-6">
                <div class="row">
                  <div class="col-4 col-sm-4">
                    <label class="">Diurno Inicial:<small class="text-danger">*</small></label>
                    <div class="form-group">
                      <app-input class="no-margin small " format="dd:dd:dd" formControlName="startHourDaytime"
                        hasLabel=false [inputDisabled]="true">
                      </app-input>
                    </div>
                  </div>
                  <div class="col-5 col-sm-5">
                    <label class="">Final:<small class="text-danger">*</small></label>
                    <div class="form-group">
                      <app-input class="no-margin small " format="dd:dd:dd" formControlName="endHourDayTime"
                        hasLabel=false [inputDisabled]="true">
                      </app-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6 col-sm-6">
                    <label class="">Noturno Inicial:<small class="text-danger">*</small></label>
                    <div class="form-group">
                      <app-input class="no-margin small " format="dd:dd:dd" formControlName="startHourNocturne"
                        hasLabel=false [inputDisabled]="true">
                      </app-input>
                    </div>
                  </div>
                  <div class="col-6 col-sm-6 ml-0 pl-0">
                    <label class="">Final:<small class="text-danger">*</small></label>
                    <div class="form-group">
                      <app-input class="no-margin small " format="dd:dd:dd" formControlName="endHourNocturne"
                        hasLabel=false [inputDisabled]="true">
                      </app-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card card-body">
            <div class="row">
              <div class="col-6" *ngIf="allowedViewViolationsOCRFailed">
                <mat-checkbox i18n color="primary" formControlName="checkOcrNull">
                  Considerar registros em que o equipamento não realizou a leitura da placa
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-4 mt-2">
                <mat-checkbox i18n color="primary" formControlName="checkConsiderReasons">
                  Considerar registros que foram invalidos com os motivos
                </mat-checkbox>
              </div>
              <div class="col-4 col-sm-4 ml-4">
                <div class="form-group">
                  <app-input class="input-multiselect" i18n-label type="multiselect" [list]="reasons"
                    selectText="prettyName" selectValue="code" formControlName="reasonCodes" selectShowLimit="1"
                    placeholder="Selecione" [inputDisabled]="!ocrReportForm.get('checkConsiderReasons').value">
                  </app-input>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button type="submit" class="btn float-right" [ngClass]="{ 'btn-primary': true, disabled: !isValid }"
                  i18n (click)="onAllSubmited()">
                  <app-loading [promise]="promise" text="Gerar Relatório" i18n-text></app-loading>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div [id]="'userIdHistoryUnfoldTable'">
    <div class="table-responsive table-hover table-block mb-1">
      <div class="d-flex flex-row justify-content-between" style="cursor: pointer;">
        <div class="col mt-3" style="font-size: 20px;" (click)="unfoldMoreOrLessTable()">
          <span>Relatórios Gerados(csv)</span>
        </div>
        <div class="p-3">
          <button type="button" class="btn text-secondary btn-icon-block" (click)="unfoldMoreOrLessTable()">
            <i class="material-icons" *ngIf="typeUnfoldTable === 'more'; else elseBlockTable">unfold_less</i>
            <ng-template #elseBlockTable>
              <i class="material-icons">unfold_more</i>
              <span>Listagem</span>
            </ng-template>
          </button>
        </div>
      </div>
      <div *ngIf="typeUnfoldTable === 'more'" class="limited-height">
        <div class="card card-body">
          <div class="table-responsive table-hover table-block">
            <table class="table bg-white">
              <thead class="bg-header-table">
                <tr>
                  <th i18n class="text-center">Filtro</th>
                  <th i18n class="text-center">Detalhado</th>
                  <th i18n class="text-center">Caracter de Placa</th>
                  <th i18n class="text-center">Período</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let date of datesByPeriods">
                  <td class="text-center">{{storageByDate[date]?.period}}</td>
                  <td class="text-center">
                    <div *ngIf="!storageByDate[date]?.reportOCrDetail?.statusStorageId">
                      <i class="material-icons">close</i>
                    </div>
                    <div *ngIf="storageByDate[date]?.reportOCrDetail?.statusStorageId === 4">
                      <i class="material-icons danger"
                        appTooltip="Falha na geração do relatório. Necessário acionar a área responsável.">close</i>
                    </div>
                    <div *ngIf="storageByDate[date]?.reportOCrDetail?.statusStorageId === 3">
                      <a href="{{storageByDate[date]?.reportOCrDetail?.key}}" target="_blank">
                        <i class="material-icons"
                          appTooltip="{{'Última Atualização: '}} {{storageByDate[date]?.reportOCrDetail?.modifiedAt | moment: 'DD-MM-YYYY HH:mm:ss'}}">
                          file_download
                        </i>
                      </a>
                    </div>
                    <div
                      *ngIf="storageByDate[date]?.reportOCrDetail?.statusStorageId === 1 || storageByDate[date]?.reportOCrDetail?.statusStorageId === 2">
                      <i class="material-icons"
                        appTooltip="{{'Última Atualização: '}} {{storageByDate[date]?.reportOCrDetail?.modifiedAt | moment: 'DD-MM-YYYY HH:mm:ss'}}">
                        timelapse
                      </i>
                    </div>
                  </td>
                  <td class="text-center">
                    <div *ngIf="!storageByDate[date]?.reportOcrChangePlate?.statusStorageId">
                      <i class="material-icons">close</i>
                    </div>
                    <div *ngIf="storageByDate[date]?.reportOcrChangePlate?.statusStorageId === 4">
                      <i class="material-icons danger"
                        appTooltip="Falha na geração do relatório. Necessário acionar a área responsável.">close</i>
                    </div>

                    <div *ngIf="storageByDate[date]?.reportOcrChangePlate?.statusStorageId === 3">
                      <a href="{{storageByDate[date]?.reportOcrChangePlate?.key}}" target="_blank">
                        <i class="material-icons"
                          appTooltip="{{'Última Atualização: '}} {{storageByDate[date]?.reportOcrChangePlate?.modifiedAt | moment: 'DD-MM-YYYY HH:mm:ss'}}">
                          file_download
                        </i>
                      </a>
                    </div>
                    <div
                      *ngIf="storageByDate[date]?.reportOcrChangePlate?.statusStorageId === 1 || storageByDate[date]?.reportOcrChangePlate?.statusStorageId === 2">
                      <i class="material-icons"
                        appTooltip="{{'Última Atualização: '}} {{storageByDate[date]?.reportOcrChangePlate?.modifiedAt | moment: 'DD-MM-YYYY HH:mm:ss'}}">
                        timelapse
                      </i>
                    </div>
                  </td>
                  <td class="text-center">
                    <div *ngIf="!storageByDate[date]?.reportOCrPeriod?.statusStorageId">
                      <i class="material-icons">close</i>
                    </div>
                    <div *ngIf="storageByDate[date]?.reportOCrPeriod?.statusStorageId === 4">
                      <i class="material-icons danger"
                        appTooltip="Falha na geração do relatório. Necessário acionar a área responsável.">close</i>
                    </div>
                    <div *ngIf="storageByDate[date]?.reportOCrPeriod?.statusStorageId === 3">
                      <a href="{{storageByDate[date]?.reportOCrPeriod?.key}}" target="_blank">
                        <i class="material-icons"
                          appTooltip="{{'Última Atualização: '}} {{storageByDate[date]?.reportOCrPeriod?.modifiedAt | moment: 'DD-MM-YYYY HH:mm:ss'}}">
                          file_download
                        </i>
                      </a>
                    </div>
                    <div
                      *ngIf="storageByDate[date]?.reportOCrPeriod?.statusStorageId === 1 || storageByDate[date]?.reportOCrPeriod?.statusStorageId === 2">
                      <i class="material-icons"
                        appTooltip="{{'Última Atualização: '}} {{storageByDate[date]?.reportOCrPeriod?.modifiedAt | moment: 'DD-MM-YYYY HH:mm:ss'}}">
                        timelapse
                      </i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-infinite-scroll (items)="setLocations($event)" (clear)="datesByPeriods=[]" [params]="params" [service]="storageLocationsService">
            </app-infinite-scroll>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showReportByPeriod" [id]="'userIdHistoryUnfoldReportByPeriod'">
    <div class="table-responsive table-hover table-block mb-1" style="background: rgb(3, 134, 133);">
      <div class="d-flex flex-row justify-content-between" style="cursor: pointer;">
        <div class="col pt-4" style="background: rgb(3, 134, 133); color: white"
          (click)="unfoldMoreOrLessReportByPeriod()">
          <h5>Relatório de Aproveitamento por Período <span class="text-small ml-5">{{periodFilter}}</span></h5>
        </div>
        <div class="filter-dropdown">
          <button class="mt-4 btn btn-fade-secondary btn-icon">
            <i class="material-icons">view_column</i>
            <span>Exibir Linhas</span>
          </button>
          <app-column-filter [items]="lineNames" (output)="linesToShow=$event"></app-column-filter>
        </div>
        <div class="p-3">
          <button type="button" class="btn text-secondary btn-icon-block" (click)="unfoldMoreOrLessReportByPeriod()">
            <i class="material-icons expand-color"
              *ngIf="typeUnfoldReportByPeriod === 'more'; else elseBlockReportByPeriod">unfold_less</i>
            <ng-template #elseBlockReportByPeriod>
              <i class="material-icons expand-color">unfold_more</i>
            </ng-template>
          </button>
        </div>
      </div>
      <div *ngIf="typeUnfoldReportByPeriod === 'more'" class="limited-height" style="background-color: white;">
        <div class="card card-body">
          <div class="col-12">
            <div class="row">
              <div class="col-3 ml-5">
              </div>
              <div class="col-3 text-center">
                <span>DIURNO</span><br>
                <span class="text-small">({{ocrReportForm.get('startHourDaytime').value}} às
                  {{ocrReportForm.get('endHourDayTime').value}})</span>
              </div>
              <div class="col-3 text-center">
                <span>NOTURNO</span><br>
                <span class="text-small">({{ocrReportForm.get('startHourNocturne').value}} às
                  {{ocrReportForm.get('endHourNocturne').value}})</span>
              </div>
              <div class="col-2 text-center ml-5 pr-0">
                <span>TOTAL</span>
              </div>
            </div>
            <div class="divide-label"></div>
          </div>
          <div [id]="'userIdHistoryUnfoldReportByContract-' + contract.id"
            class="table-responsive table-hover table-block mb-1">
            <div class="d-flex flex-row justify-content-between table-flex__row bg-gray--250" style="cursor: pointer;">
              <div class="col" (click)="unfoldMoreOrLessReportByContract(contract.id)">
                <h5>Contrato {{ contract.id }} - {{ contract.name }}</h5>
              </div>
              <div class="p-3">
                <button type="button" class="btn text-secondary btn-icon-block"
                  (click)="unfoldMoreOrLessReportByContract(contract.id)">
                  <i class="material-icons expand-color-spot"
                    *ngIf="typeUnfoldReportByContract === 'more'; else elseBlockReportByContract">unfold_less</i>
                  <ng-template #elseBlockReportByContract>
                    <i class="material-icons expand-color-spot">unfold_more</i>
                  </ng-template>
                </button>
              </div>
            </div>
            <div *ngIf="typeUnfoldReportByContract === 'more'">
              <div class="col-12">
                <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalCount')}">
                  <div class="col-4">
                    <p class="fs-5" i18n>
                      Total de registros processados
                    </p>
                  </div>
                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalCountDaytime || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalCountDaytime && getPercentage(reportOcrByContract.totalCountDaytime,
                      reportOcrByContract.totalCount) + '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalCountNocturnal || 0}}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalCountNocturnal &&
                      getPercentage(reportOcrByContract.totalCountNocturnal, reportOcrByContract.totalCount) + '%') || 0
                      + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalCount || 0 }}
                    </span>
                  </div>
                  <div class="col-1 mt-1 pl-0">
                    <span>
                      {{ (reportOcrByContract.totalCount && reportOcrByContract.totalCount > 0 &&
                      getPercentage(reportOcrByContract.totalCount, reportOcrByContract.totalCount) + '%') || 0 + '%' }}
                    </span>
                  </div>
                </div>
                <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalPlateTyping')}">
                  <div class="col-4">
                    <p class="fs-5" i18n>
                      Total de Placas Digitadas
                    </p>
                  </div>
                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalPlateTypingDaytime || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalPlateTypingDaytime &&
                      getPercentage(reportOcrByContract.totalPlateTypingDaytime, reportOcrByContract.totalCount) + '%')
                      || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalPlateTypingNocturnal || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalPlateTypingNocturnal &&
                      getPercentage(reportOcrByContract.totalPlateTypingNocturnal, reportOcrByContract.totalCount) +
                      '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalPlateTyping || 0 }}
                    </span>
                  </div>
                  <div class="col-1 mt-1 pl-0">
                    <span>
                      {{ (reportOcrByContract.totalPlateTypingPercentage || 0) + '%'}}
                    </span>
                  </div>
                </div>
                <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalTecnical')}">
                  <div class="col-4">
                    <p class="fs-5" i18n>
                      Total de Registros com Erro Técnico
                    </p>
                  </div>
                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalTecnicalDaytime || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalTecnicalDaytime && reportOcrByContract.totalTecnicalDaytime > 0 &&
                      getPercentage(reportOcrByContract.totalTecnicalDaytime, reportOcrByContract.totalCount) + '%') ||
                      0 + '%'}}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalTecnicalNocturnal || 0}}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalTecnicalNocturnal && reportOcrByContract.totalTecnicalNocturnal > 0
                      && getPercentage(reportOcrByContract.totalTecnicalNocturnal, reportOcrByContract.totalCount) +
                      '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalTecnical || 0 }}
                    </span>
                  </div>
                  <div class="col-1 mt-1 pl-0">
                    <span>
                      {{ (reportOcrByContract?.totalTecnicalPercentage || 0) + '%' }}
                    </span>
                  </div>
                </div>
                <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalNonTecnical')}">
                  <div class="col-4">
                    <p class="fs-5" i18n>
                      Total de Registros com Erro Não Técnico
                    </p>
                  </div>
                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalNonTecnicalDaytime || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalNonTecnicalDaytime && reportOcrByContract.totalNonTecnicalDaytime > 0
                      && getPercentage(reportOcrByContract.totalNonTecnicalDaytime, reportOcrByContract.totalCount) +
                      '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalNonTecnicalNocturnal || 0}}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalNonTecnicalNocturnal && reportOcrByContract.totalNonTecnicalNocturnal
                      > 0 && getPercentage(reportOcrByContract.totalNonTecnicalNocturnal,
                      reportOcrByContract.totalCount) + '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalNonTecnical || 0 }}
                    </span>
                  </div>
                  <div class="col-1 mt-1 pl-0">
                    <span>
                      {{ (reportOcrByContract.totalNonTecnicalPercentage + '%') || 0 + '%' }}
                    </span>
                  </div>
                </div>
                <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalPlateReadByOCR')}">
                  <div class="col-4">
                    <p class="fs-5" i18n>
                      Total de Placas Lidas pelo OCR
                    </p>
                  </div>
                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalPlateReadByOCRDaytime || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalPlateReadByOCRDaytime &&
                      reportOcrByContract.totalPlateReadByOCRDaytime > 0 &&
                      getPercentage(reportOcrByContract.totalPlateReadByOCRDaytime, reportOcrByContract.totalCount) +
                      '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalPlateReadByOCRNocturnal || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalPlateReadByOCRNocturnal &&
                      reportOcrByContract.totalPlateReadByOCRNocturnal > 0 &&
                      getPercentage(reportOcrByContract.totalPlateReadByOCRNocturnal, reportOcrByContract.totalCount) +
                      '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalPlateReadByOCR || 0 }}
                    </span>
                  </div>
                  <div class="col-1 mt-1 pl-0">
                    <span>
                      {{ (reportOcrByContract.totalPlateReadByOCRPercentage + '%') || 0 + '%' }}
                    </span>
                  </div>
                </div>
                <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalPlateNotReadByOCR')}">
                  <div class="col-4">
                    <p class="fs-5" i18n>
                      Total de Placas Não Lidas pelo OCR
                    </p>
                  </div>
                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalPlateNotReadByOCRDaytime || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalPlateNotReadByOCRDaytime &&
                      reportOcrByContract.totalPlateNotReadByOCRDaytime > 0 &&
                      getPercentage(reportOcrByContract.totalPlateNotReadByOCRDaytime, reportOcrByContract.totalCount) +
                      '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalPlateNotReadByOCRNocturnal || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalPlateNotReadByOCRNocturnal &&
                      reportOcrByContract.totalPlateNotReadByOCRNocturnal > 0 &&
                      getPercentage(reportOcrByContract.totalPlateNotReadByOCRNocturnal, reportOcrByContract.totalCount)
                      + '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalPlateNotReadByOCR || 0 }}
                    </span>
                  </div>
                  <div class="col-1 mt-1 pl-0">
                    <span>
                      {{ (reportOcrByContract.totalPlateNotReadByOCRPercentage + '%') || 0 + '%' }}
                    </span>
                  </div>
                </div>
                <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalPlateEqualsOCRTyping')}">
                  <div class="col-4">
                    <p class="fs-5" i18n>
                      Total de Placas Digitadas iguais ao OCR
                    </p>
                  </div>
                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalPlateEqualsOCRTypingDaytime || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalPlateEqualsOCRTypingDaytime &&
                      reportOcrByContract.totalPlateEqualsOCRTypingDaytime > 0 &&
                      getPercentage(reportOcrByContract.totalPlateEqualsOCRTypingDaytime,
                      reportOcrByContract.totalCount) + '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalPlateEqualsOCRTypingNocturnal || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalPlateEqualsOCRTypingNocturnal &&
                      reportOcrByContract.totalPlateEqualsOCRTypingNocturnal > 0 &&
                      getPercentage(reportOcrByContract.totalPlateEqualsOCRTypingNocturnal,
                      reportOcrByContract.totalCount) + '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalPlateEqualsOCRTyping || 0 }}
                    </span>
                  </div>
                  <div class="col-1 mt-1 pl-0">
                    <span>
                      {{ (reportOcrByContract.totalPlateEqualsOCRTypingPercentage + '%') || 0 + '%' }}
                    </span>
                  </div>
                </div>
                <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalPlateDifferentOCRTyping')}">
                  <div class="col-4">
                    <p class="fs-5" i18n>
                      Total de Placas Digitadas Diferente OCR
                    </p>
                  </div>
                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalPlateDifferentOCRTypingDaytime || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalPlateDifferentOCRTypingDaytime &&
                      reportOcrByContract.totalPlateDifferentOCRTypingDaytime > 0 &&
                      getPercentage(reportOcrByContract.totalPlateDifferentOCRTypingDaytime,
                      reportOcrByContract.totalCount) + '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalPlateDifferentOCRTypingNocturnal || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalPlateDifferentOCRTypingNocturnal &&
                      reportOcrByContract.totalPlateDifferentOCRTypingNocturnal > 0 &&
                      getPercentage(reportOcrByContract.totalPlateDifferentOCRTypingNocturnal,
                      reportOcrByContract.totalCount) + '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalPlateDifferentOCRTyping || 0 }}
                    </span>
                  </div>
                  <div class="col-1 mt-1 pl-0">
                    <span>
                      {{ (reportOcrByContract.totalPlateDifferentOCRTypingPercentage + '%') || 0 + '%' }}
                    </span>
                  </div>
                </div>
                <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalLettersEquals')}">
                  <div class="col-4">
                    <p class="fs-5" i18n>
                      Letras Iguais
                    </p>
                  </div>
                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalLettersEqualsDaytime || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalLettersEqualsDaytime && reportOcrByContract.totalLettersEqualsDaytime
                      > 0 && getPercentage(reportOcrByContract.totalLettersEqualsDaytime,
                      reportOcrByContract.totalCount) + '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalLettersEqualsNocturnal || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalLettersEqualsNocturnal &&
                      reportOcrByContract.totalLettersEqualsNocturnal > 0 &&
                      getPercentage(reportOcrByContract.totalLettersEqualsNocturnal, reportOcrByContract.totalCount) +
                      '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalLettersEquals || 0 }}
                    </span>
                  </div>
                  <div class="col-1 mt-1 pl-0">
                    <span>
                      {{ (reportOcrByContract.totalLettersEqualsPercentage + '%') || 0 + '%' }}
                    </span>
                  </div>
                </div>
                <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalLettersDifferent')}">
                  <div class="col-4">
                    <p class="fs-5" i18n>
                      Letras Diferentes
                    </p>
                  </div>
                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalLettersDifferentDaytime || 0}}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalLettersDifferentDaytime &&
                      reportOcrByContract.totalLettersDifferentDaytime > 0 &&
                      getPercentage(reportOcrByContract.totalLettersDifferentDaytime, reportOcrByContract.totalCount) +
                      '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalLettersDifferentNocturnal || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalLettersDifferentNocturnal &&
                      reportOcrByContract.totalLettersDifferentNocturnal > 0 &&
                      getPercentage(reportOcrByContract.totalLettersDifferentNocturnal, reportOcrByContract.totalCount)
                      + '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalLettersDifferent || 0 }}
                    </span>
                  </div>
                  <div class="col-1 mt-1 pl-0">
                    <span>
                      {{ (reportOcrByContract.totalLettersDifferentPercentage + '%') || 0 + '%' }}
                    </span>
                  </div>
                </div>
                <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalLettersNumbers')}">
                  <div class="col-4">
                    <p class="fs-5" i18n>
                      Números Iguais
                    </p>
                  </div>
                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalLettersNumbersDaytime || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalLettersNumbersDaytime &&
                      reportOcrByContract.totalLettersNumbersDaytime > 0 &&
                      getPercentage(reportOcrByContract.totalLettersNumbersDaytime, reportOcrByContract.totalCount) +
                      '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalLettersNumbersNocturnal || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalLettersNumbersNocturnal &&
                      reportOcrByContract.totalLettersNumbersNocturnal > 0 &&
                      getPercentage(reportOcrByContract.totalLettersNumbersNocturnal, reportOcrByContract.totalCount) +
                      '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalLettersNumbers || 0 }}
                    </span>
                  </div>
                  <div class="col-1 mt-1 pl-0">
                    <span>
                      {{ (reportOcrByContract.totalLettersNumbersPercentage + '%') || 0 + '%' }}
                    </span>
                  </div>
                </div>
                <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalLettersNumbersDifferent')}">
                  <div class="col-4">
                    <p class="fs-5" i18n>
                      Números Diferentes
                    </p>
                  </div>
                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalLettersNumbersDifferentDaytime || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalLettersNumbersDifferentDaytime &&
                      reportOcrByContract.totalLettersNumbersDifferentDaytime > 0 &&
                      getPercentage(reportOcrByContract.totalLettersNumbersDifferentDaytime,
                      reportOcrByContract.totalCount) + '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalLettersNumbersDifferentNocturnal || 0 }}
                    </span>
                  </div>
                  <div class="col-2 mt-1 pl-0">
                    <span class="mr-4">
                      {{ (reportOcrByContract.totalLettersNumbersDifferentNocturnal &&
                      reportOcrByContract.totalLettersNumbersDifferentNocturnal > 0 &&
                      getPercentage(reportOcrByContract.totalLettersNumbersDifferentNocturnal,
                      reportOcrByContract.totalCount) + '%') || 0 + '%' }}
                    </span>
                  </div>

                  <div class="col-1 mt-1">
                    <span class="ml-3">
                      {{ reportOcrByContract.totalLettersNumbersDifferent || 0 }}
                    </span>
                  </div>
                  <div class="col-1 mt-1 pl-0">
                    <span>
                      {{ (reportOcrByContract.totalLettersNumbersDifferentPercentage + '%') || 0 + '%' }}
                    </span>
                  </div>
                </div>
              </div>
              <div [id]="'userIdHistoryUnfoldReportBySpot-' + spot.id"
                class="table-responsive table-hover table-block mb-1" *ngFor="let spot of spotsSelected">
                <div class="d-flex flex-row justify-content-between table-flex__row bg-gray--250"
                  style="cursor: pointer;">
                  <div class="col" (click)="unfoldMoreOrLessReportBySpot(spot.id)">
                    <h5>Ponto {{spot.code}} - {{spot.description}}</h5>
                  </div>
                  <div class="p-3">
                    <button type="button" class="btn text-secondary btn-icon-block"
                      (click)="unfoldMoreOrLessReportBySpot(spot.id)">
                      <i class="material-icons expand-color-spot"
                        *ngIf="typeUnfoldReportBySpot[spot.id] === 'more'; else elseBlockReportBySpot">unfold_less</i>
                      <ng-template #elseBlockReportBySpot>
                        <i class="material-icons expand-color-spot">unfold_more</i>
                      </ng-template>
                    </button>
                  </div>
                </div>
                <div *ngIf="typeUnfoldReportBySpot[spot.id] === 'more'">
                  <div class="col-12">
                    <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalCount')}">
                      <div class="col-4">
                        <p class="fs-5" i18n>
                          Total de registros processados
                        </p>
                      </div>
                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalCountDaytime || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalCountDaytime &&
                          getPercentage(reportOcrBySpot[spot.id].totalCountDaytime, reportOcrBySpot[spot.id].totalCount)
                          + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalCountNocturnal || 0}}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalCountNocturnal &&
                          getPercentage(reportOcrBySpot[spot.id].totalCountNocturnal,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalCount || 0 }}
                        </span>
                      </div>
                      <div class="col-1 mt-1 pl-0">
                        <span>
                          {{ (reportOcrBySpot[spot.id].totalCount && reportOcrBySpot[spot.id].totalCount > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalCount, reportOcrBySpot[spot.id].totalCount) + '%')
                          || 0 + '%' }}
                        </span>
                      </div>
                    </div>
                    <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalPlateTyping')}">
                      <div class="col-4">
                        <p class="fs-5" i18n>
                          Total de Placas Digitadas
                        </p>
                      </div>
                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalPlateTypingDaytime || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalPlateTypingDaytime &&
                          getPercentage(reportOcrBySpot[spot.id].totalPlateTypingDaytime,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalPlateTypingNocturnal || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalPlateTypingNocturnal &&
                          getPercentage(reportOcrBySpot[spot.id].totalPlateTypingNocturnal,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalPlateTyping || 0 }}
                        </span>
                      </div>
                      <div class="col-1 mt-1 pl-0">
                        <span>
                          {{ (reportOcrBySpot[spot.id].totalPlateTypingPercentage || 0) + '%'}}
                        </span>
                      </div>
                    </div>
                    <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalTecnical')}">
                      <div class="col-4">
                        <p class="fs-5" i18n>
                          Total de Registros com Erro Técnico
                        </p>
                      </div>
                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalTecnicalDaytime || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalTecnicalDaytime &&
                          reportOcrBySpot[spot.id].totalTecnicalDaytime > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalTecnicalDaytime,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%'}}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalTecnicalNocturnal || 0}}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalTecnicalNocturnal &&
                          reportOcrBySpot[spot.id].totalTecnicalNocturnal > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalTecnicalNocturnal,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalTecnical || 0 }}
                        </span>
                      </div>
                      <div class="col-1 mt-1 pl-0">
                        <span>
                          {{ (reportOcrBySpot[spot.id]?.totalTecnicalPercentage || 0) + '%' }}
                        </span>
                      </div>
                    </div>
                    <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalNonTecnical')}">
                      <div class="col-4">
                        <p class="fs-5" i18n>
                          Total de Registros com Erro Não Técnico
                        </p>
                      </div>
                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalNonTecnicalDaytime || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalNonTecnicalDaytime &&
                          reportOcrBySpot[spot.id].totalNonTecnicalDaytime > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalNonTecnicalDaytime,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalNonTecnicalNocturnal || 0}}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalNonTecnicalNocturnal &&
                          reportOcrBySpot[spot.id].totalNonTecnicalNocturnal > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalNonTecnicalNocturnal,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalNonTecnical || 0 }}
                        </span>
                      </div>
                      <div class="col-1 mt-1 pl-0">
                        <span>
                          {{ (reportOcrBySpot[spot.id].totalNonTecnicalPercentage + '%') || 0 + '%' }}
                        </span>
                      </div>
                    </div>
                    <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalPlateReadByOCR')}">
                      <div class="col-4">
                        <p class="fs-5" i18n>
                          Total de Placas Lidas pelo OCR
                        </p>
                      </div>
                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalPlateReadByOCRDaytime || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalPlateReadByOCRDaytime &&
                          reportOcrBySpot[spot.id].totalPlateReadByOCRDaytime > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalPlateReadByOCRDaytime,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalPlateReadByOCRNocturnal || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalPlateReadByOCRNocturnal &&
                          reportOcrBySpot[spot.id].totalPlateReadByOCRNocturnal > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalPlateReadByOCRNocturnal,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalPlateReadByOCR || 0 }}
                        </span>
                      </div>
                      <div class="col-1 mt-1 pl-0">
                        <span>
                          {{ (reportOcrBySpot[spot.id].totalPlateReadByOCRPercentage + '%') || 0 + '%' }}
                        </span>
                      </div>
                    </div>
                    <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalPlateNotReadByOCR')}">
                      <div class="col-4">
                        <p class="fs-5" i18n>
                          Total de Placas Não Lidas pelo OCR
                        </p>
                      </div>
                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalPlateNotReadByOCRDaytime || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalPlateNotReadByOCRDaytime &&
                          reportOcrBySpot[spot.id].totalPlateNotReadByOCRDaytime > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalPlateNotReadByOCRDaytime,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalPlateNotReadByOCRNocturnal || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalPlateNotReadByOCRNocturnal &&
                          reportOcrBySpot[spot.id].totalPlateNotReadByOCRNocturnal > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalPlateNotReadByOCRNocturnal,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalPlateNotReadByOCR || 0 }}
                        </span>
                      </div>
                      <div class="col-1 mt-1 pl-0">
                        <span>
                          {{ (reportOcrBySpot[spot.id].totalPlateNotReadByOCRPercentage + '%') || 0 + '%' }}
                        </span>
                      </div>
                    </div>
                    <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalPlateEqualsOCRTyping')}">
                      <div class="col-4">
                        <p class="fs-5" i18n>
                          Total de Placas Digitadas iguais ao OCR
                        </p>
                      </div>
                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalPlateEqualsOCRTypingDaytime || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalPlateEqualsOCRTypingDaytime &&
                          reportOcrBySpot[spot.id].totalPlateEqualsOCRTypingDaytime > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalPlateEqualsOCRTypingDaytime,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalPlateEqualsOCRTypingNocturnal || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalPlateEqualsOCRTypingNocturnal &&
                          reportOcrBySpot[spot.id].totalPlateEqualsOCRTypingNocturnal > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalPlateEqualsOCRTypingNocturnal,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalPlateEqualsOCRTyping || 0 }}
                        </span>
                      </div>
                      <div class="col-1 mt-1 pl-0">
                        <span>
                          {{ (reportOcrBySpot[spot.id].totalPlateEqualsOCRTypingPercentage + '%') || 0 + '%' }}
                        </span>
                      </div>
                    </div>
                    <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalPlateDifferentOCRTyping')}">
                      <div class="col-4">
                        <p class="fs-5" i18n>
                          Total de Placas Digitadas Diferente OCR
                        </p>
                      </div>
                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalPlateDifferentOCRTypingDaytime || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalPlateDifferentOCRTypingDaytime &&
                          reportOcrBySpot[spot.id].totalPlateDifferentOCRTypingDaytime > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalPlateDifferentOCRTypingDaytime,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalPlateDifferentOCRTypingNocturnal || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalPlateDifferentOCRTypingNocturnal &&
                          reportOcrBySpot[spot.id].totalPlateDifferentOCRTypingNocturnal > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalPlateDifferentOCRTypingNocturnal,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalPlateDifferentOCRTyping || 0 }}
                        </span>
                      </div>
                      <div class="col-1 mt-1 pl-0">
                        <span>
                          {{ (reportOcrBySpot[spot.id].totalPlateDifferentOCRTypingPercentage + '%') || 0 + '%' }}
                        </span>
                      </div>
                    </div>
                    <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalLettersEquals')}">
                      <div class="col-4">
                        <p class="fs-5" i18n>
                          Letras Iguais
                        </p>
                      </div>
                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalLettersEqualsDaytime || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalLettersEqualsDaytime &&
                          reportOcrBySpot[spot.id].totalLettersEqualsDaytime > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalLettersEqualsDaytime,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalLettersEqualsNocturnal || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalLettersEqualsNocturnal &&
                          reportOcrBySpot[spot.id].totalLettersEqualsNocturnal > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalLettersEqualsNocturnal,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalLettersEquals || 0 }}
                        </span>
                      </div>
                      <div class="col-1 mt-1 pl-0">
                        <span>
                          {{ (reportOcrBySpot[spot.id].totalLettersEqualsPercentage + '%') || 0 + '%' }}
                        </span>
                      </div>
                    </div>
                    <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalLettersDifferent')}">
                      <div class="col-4">
                        <p class="fs-5" i18n>
                          Letras Diferentes
                        </p>
                      </div>
                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalLettersDifferentDaytime || 0}}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalLettersDifferentDaytime &&
                          reportOcrBySpot[spot.id].totalLettersDifferentDaytime > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalLettersDifferentDaytime,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalLettersDifferentNocturnal || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalLettersDifferentNocturnal &&
                          reportOcrBySpot[spot.id].totalLettersDifferentNocturnal > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalLettersDifferentNocturnal,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalLettersDifferent || 0 }}
                        </span>
                      </div>
                      <div class="col-1 mt-1 pl-0">
                        <span>
                          {{ (reportOcrBySpot[spot.id].totalLettersDifferentPercentage + '%') || 0 + '%' }}
                        </span>
                      </div>
                    </div>
                    <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalLettersNumbers')}">
                      <div class="col-4">
                        <p class="fs-5" i18n>
                          Números Iguais
                        </p>
                      </div>
                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalLettersNumbersDaytime || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalLettersNumbersDaytime &&
                          reportOcrBySpot[spot.id].totalLettersNumbersDaytime > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalLettersNumbersDaytime,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalLettersNumbersNocturnal || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalLettersNumbersNocturnal &&
                          reportOcrBySpot[spot.id].totalLettersNumbersNocturnal > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalLettersNumbersNocturnal,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalLettersNumbers || 0 }}
                        </span>
                      </div>
                      <div class="col-1 mt-1 pl-0">
                        <span>
                          {{ (reportOcrBySpot[spot.id].totalLettersNumbersPercentage + '%') || 0 + '%' }}
                        </span>
                      </div>
                    </div>
                    <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalLettersNumbersDifferent')}">
                      <div class="col-4">
                        <p class="fs-5" i18n>
                          Números Diferentes
                        </p>
                      </div>
                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalLettersNumbersDifferentDaytime || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalLettersNumbersDifferentDaytime &&
                          reportOcrBySpot[spot.id].totalLettersNumbersDifferentDaytime > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalLettersNumbersDifferentDaytime,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalLettersNumbersDifferentNocturnal || 0 }}
                        </span>
                      </div>
                      <div class="col-2 mt-1 pl-0">
                        <span class="mr-4">
                          {{ (reportOcrBySpot[spot.id].totalLettersNumbersDifferentNocturnal &&
                          reportOcrBySpot[spot.id].totalLettersNumbersDifferentNocturnal > 0 &&
                          getPercentage(reportOcrBySpot[spot.id].totalLettersNumbersDifferentNocturnal,
                          reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                        </span>
                      </div>

                      <div class="col-1 mt-1">
                        <span class="ml-4">
                          {{ reportOcrBySpot[spot.id].totalLettersNumbersDifferent || 0 }}
                        </span>
                      </div>
                      <div class="col-1 mt-1 pl-0">
                        <span>
                          {{ (reportOcrBySpot[spot.id].totalLettersNumbersDifferentPercentage + '%') || 0 + '%' }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div [id]="'userIdHistoryUnfoldReportByLane-' + laneId"
                    class="table-responsive table-hover table-block mb-1" *ngFor="let laneId of spot.laneIds">
                    <div class="d-flex flex-row justify-content-between  table-flex__row bg-gray--200"
                      style="cursor: pointer;">
                      <div class="col" (click)="unfoldMoreOrLessReportByLane(laneId)">
                        <h5>Faixa {{reportOcrByLane[laneId + '']?.laneCode}} - {{reportOcrByLane[laneId +
                          '']?.laneDescription}} (Ponto: {{spot.code}})</h5>
                      </div>
                      <div class="p-3">
                        <button type="button" class="btn text-secondary btn-icon-block"
                          (click)="unfoldMoreOrLessReportByLane(laneId)">
                          <i class="material-icons expand-color-spot"
                            *ngIf="typeUnfoldReportByLane[laneId] === 'more'; else elseBlockReportByLane">unfold_less</i>
                          <ng-template #elseBlockReportByLane>
                            <i class="material-icons expand-color-spot">unfold_more</i>
                          </ng-template>
                        </button>
                      </div>
                    </div>
                    <div *ngIf="typeUnfoldReportByLane[laneId] === 'more'">
                      <div class="col-12">
                        <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalCount')}">
                          <div class="col-4">
                            <p class="fs-5" i18n>
                              Total de registros processados
                            </p>
                          </div>
                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalCountDaytime || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalCountDaytime && getPercentage(reportOcrByLane[laneId
                              + ''].totalCountDaytime, reportOcrByLane[laneId + ''].totalCount) + '%') || 0 + '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalCountNocturnal || 0}}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalCountNocturnal &&
                              getPercentage(reportOcrByLane[laneId + ''].totalCountNocturnal, reportOcrByLane[laneId +
                              ''].totalCount) + '%') || 0 + '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalCount || 0 }}
                            </span>
                          </div>
                          <div class="col-1 mt-1 pl-0">
                            <span>
                              {{ (reportOcrByLane[laneId + ''].totalCount && reportOcrByLane[laneId + ''].totalCount > 0
                              && getPercentage(reportOcrByLane[laneId + ''].totalCount,
                              reportOcrBySpot[spot.id].totalCount) + '%') || 0 + '%' }}
                            </span>
                          </div>
                        </div>
                        <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totaltotalPlateTypingCount')}">
                          <div class="col-4">
                            <p class="fs-5" i18n>
                              Total de Placas Digitadas
                            </p>
                          </div>
                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalPlateTypingDaytime || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalPlateTypingDaytime &&
                              getPercentage(reportOcrByLane[laneId + ''].totalPlateTypingDaytime, reportOcrByLane[laneId
                              + ''].totalCount) + '%') || 0 + '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalPlateTypingNocturnal || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalPlateTypingNocturnal &&
                              getPercentage(reportOcrByLane[laneId + ''].totalPlateTypingNocturnal,
                              reportOcrByLane[laneId + ''].totalCount) + '%') || 0 + '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalPlateTyping || 0 }}
                            </span>
                          </div>
                          <div class="col-1 mt-1 pl-0">
                            <span>
                              {{ (reportOcrByLane[laneId + ''].totalPlateTypingPercentage + '%') || 0 + '%'}}
                            </span>
                          </div>
                        </div>
                        <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalTecnical')}">
                          <div class="col-4">
                            <p class="fs-5" i18n>
                              Total de Registros com Erro Técnico
                            </p>
                          </div>
                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalTecnicalDaytime || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalTecnicalDaytime && reportOcrByLane[laneId +
                              ''].totalTecnicalDaytime > 0 && getPercentage(reportOcrByLane[laneId +
                              ''].totalTecnicalDaytime, reportOcrByLane[laneId + ''].totalCount) + '%') || 0 + '%'}}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalTecnicalNocturnal || 0}}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalTecnicalNocturnal && reportOcrByLane[laneId +
                              ''].totalTecnicalNocturnal > 0 && getPercentage(reportOcrByLane[laneId +
                              ''].totalTecnicalNocturnal, reportOcrByLane[laneId + ''].totalCount) + '%') || 0 + '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalTecnical || 0 }}
                            </span>
                          </div>
                          <div class="col-1 mt-1 pl-0">
                            <span>
                              {{ (reportOcrByLane[laneId + ''].totalTecnicalPercentage + '%') || 0 + '%' }}
                            </span>
                          </div>
                        </div>
                        <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalNonTecnical')}">
                          <div class="col-4">
                            <p class="fs-5" i18n>
                              Total de Registros com Erro Não Técnico
                            </p>
                          </div>
                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalNonTecnicalDaytime || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalNonTecnicalDaytime && reportOcrByLane[laneId +
                              ''].totalNonTecnicalDaytime > 0 && getPercentage(reportOcrByLane[laneId +
                              ''].totalNonTecnicalDaytime, reportOcrByLane[laneId + ''].totalCount) + '%') || 0 + '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalNonTecnicalNocturnal || 0}}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalNonTecnicalNocturnal && reportOcrByLane[laneId +
                              ''].totalNonTecnicalNocturnal > 0 && getPercentage(reportOcrByLane[laneId +
                              ''].totalNonTecnicalNocturnal, reportOcrByLane[laneId + ''].totalCount) + '%') || 0 + '%'
                              }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalNonTecnical || 0 }}
                            </span>
                          </div>
                          <div class="col-1 mt-1 pl-0">
                            <span>
                              {{ (reportOcrByLane[laneId + ''].totalNonTecnicalPercentage + '%') || 0 + '%' }}
                            </span>
                          </div>
                        </div>
                        <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalPlateReadByOCR')}">
                          <div class="col-4">
                            <p class="fs-5" i18n>
                              Total de Placas Lidas pelo OCR
                            </p>
                          </div>
                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalPlateReadByOCRDaytime || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalPlateReadByOCRDaytime && reportOcrByLane[laneId +
                              ''].totalPlateReadByOCRDaytime > 0 && getPercentage(reportOcrByLane[laneId +
                              ''].totalPlateReadByOCRDaytime, reportOcrByLane[laneId + ''].totalCount) + '%') || 0 + '%'
                              }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalPlateReadByOCRNocturnal || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalPlateReadByOCRNocturnal && reportOcrByLane[laneId +
                              ''].totalPlateReadByOCRNocturnal > 0 && getPercentage(reportOcrByLane[laneId +
                              ''].totalPlateReadByOCRNocturnal, reportOcrByLane[laneId + ''].totalCount) + '%') || 0 +
                              '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalPlateReadByOCR || 0 }}
                            </span>
                          </div>
                          <div class="col-1 mt-1 pl-0">
                            <span>
                              {{ (reportOcrByLane[laneId + ''].totalPlateReadByOCRPercentage + '%') || 0 + '%' }}
                            </span>
                          </div>
                        </div>
                        <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalPlateNotReadByOCR')}">
                          <div class="col-4">
                            <p class="fs-5" i18n>
                              Total de Placas Não Lidas pelo OCR
                            </p>
                          </div>
                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalPlateNotReadByOCRDaytime || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalPlateNotReadByOCRDaytime && reportOcrByLane[laneId +
                              ''].totalPlateNotReadByOCRDaytime > 0 && getPercentage(reportOcrByLane[laneId +
                              ''].totalPlateNotReadByOCRDaytime, reportOcrByLane[laneId + ''].totalCount) + '%') || 0 +
                              '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalPlateNotReadByOCRNocturnal || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalPlateNotReadByOCRNocturnal && reportOcrByLane[laneId
                              + ''].totalPlateNotReadByOCRNocturnal > 0 && getPercentage(reportOcrByLane[laneId +
                              ''].totalPlateNotReadByOCRNocturnal, reportOcrByLane[laneId + ''].totalCount) + '%') || 0
                              + '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalPlateNotReadByOCR || 0 }}
                            </span>
                          </div>
                          <div class="col-1 mt-1 pl-0">
                            <span>
                              {{ (reportOcrByLane[laneId + ''].totalPlateNotReadByOCRPercentage + '%') || 0 + '%' }}
                            </span>
                          </div>
                        </div>
                        <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalPlateEqualsOCRTyping')}">
                          <div class="col-4">
                            <p class="fs-5" i18n>
                              Total de Placas Digitadas iguais ao OCR
                            </p>
                          </div>
                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalPlateEqualsOCRTypingDaytime || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalPlateEqualsOCRTypingDaytime &&
                              reportOcrByLane[laneId + ''].totalPlateEqualsOCRTypingDaytime > 0 &&
                              getPercentage(reportOcrByLane[laneId + ''].totalPlateEqualsOCRTypingDaytime,
                              reportOcrByLane[laneId + ''].totalCount) + '%') || 0 + '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalPlateEqualsOCRTypingNocturnal || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalPlateEqualsOCRTypingNocturnal &&
                              reportOcrByLane[laneId + ''].totalPlateEqualsOCRTypingNocturnal > 0 &&
                              getPercentage(reportOcrByLane[laneId + ''].totalPlateEqualsOCRTypingNocturnal,
                              reportOcrByLane[laneId + ''].totalCount) + '%') || 0 + '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalPlateEqualsOCRTyping || 0 }}
                            </span>
                          </div>
                          <div class="col-1 mt-1 pl-0">
                            <span>
                              {{ (reportOcrByLane[laneId + ''].totalPlateEqualsOCRTypingPercentage + '%') || 0 + '%' }}
                            </span>
                          </div>
                        </div>
                        <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalPlateDifferentOCRTyping')}">
                          <div class="col-4">
                            <p class="fs-5" i18n>
                              Total de Placas Digitadas Diferente OCR
                            </p>
                          </div>
                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalPlateDifferentOCRTypingDaytime || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalPlateDifferentOCRTypingDaytime &&
                              reportOcrByLane[laneId + ''].totalPlateDifferentOCRTypingDaytime > 0 &&
                              getPercentage(reportOcrByLane[laneId + ''].totalPlateDifferentOCRTypingDaytime,
                              reportOcrByLane[laneId + ''].totalCount) + '%') || 0 + '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalPlateDifferentOCRTypingNocturnal || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalPlateDifferentOCRTypingNocturnal &&
                              reportOcrByLane[laneId + ''].totalPlateDifferentOCRTypingNocturnal > 0 &&
                              getPercentage(reportOcrByLane[laneId + ''].totalPlateDifferentOCRTypingNocturnal,
                              reportOcrByLane[laneId + ''].totalCount) + '%') || 0 + '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalPlateDifferentOCRTyping || 0 }}
                            </span>
                          </div>
                          <div class="col-1 mt-1 pl-0">
                            <span>
                              {{ (reportOcrByLane[laneId + ''].totalPlateDifferentOCRTypingPercentage + '%') || 0 + '%'
                              }}
                            </span>
                          </div>
                        </div>
                        <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalLettersEquals')}">
                          <div class="col-4">
                            <p class="fs-5" i18n>
                              Letras Iguais
                            </p>
                          </div>
                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalLettersEqualsDaytime || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalLettersEqualsDaytime && reportOcrByLane[laneId +
                              ''].totalLettersEqualsDaytime > 0 && getPercentage(reportOcrByLane[laneId +
                              ''].totalLettersEqualsDaytime, reportOcrByLane[laneId + ''].totalCount) + '%') || 0 + '%'
                              }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalLettersEqualsNocturnal || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalLettersEqualsNocturnal && reportOcrByLane[laneId +
                              ''].totalLettersEqualsNocturnal > 0 && getPercentage(reportOcrByLane[laneId +
                              ''].totalLettersEqualsNocturnal, reportOcrByLane[laneId + ''].totalCount) + '%') || 0 +
                              '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalLettersEquals || 0 }}
                            </span>
                          </div>
                          <div class="col-1 mt-1 pl-0">
                            <span>
                              {{ (reportOcrByLane[laneId + ''].totalLettersEqualsPercentage + '%') || 0 + '%' }}
                            </span>
                          </div>
                        </div>
                        <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalLettersDifferent')}">
                          <div class="col-4">
                            <p class="fs-5" i18n>
                              Letras Diferentes
                            </p>
                          </div>
                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalLettersDifferentDaytime || 0}}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalLettersDifferentDaytime && reportOcrByLane[laneId +
                              ''].totalLettersDifferentDaytime > 0 && getPercentage(reportOcrByLane[laneId +
                              ''].totalLettersDifferentDaytime, reportOcrByLane[laneId + ''].totalCount) + '%') || 0 +
                              '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalLettersDifferentNocturnal || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalLettersDifferentNocturnal && reportOcrByLane[laneId
                              + ''].totalLettersDifferentNocturnal > 0 && getPercentage(reportOcrByLane[laneId +
                              ''].totalLettersDifferentNocturnal, reportOcrByLane[laneId + ''].totalCount) + '%') || 0 +
                              '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalLettersDifferent || 0 }}
                            </span>
                          </div>
                          <div class="col-1 mt-1 pl-0">
                            <span>
                              {{ (reportOcrByLane[laneId + ''].totalLettersDifferentPercentage + '%') || 0 + '%' }}
                            </span>
                          </div>
                        </div>
                        <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalLettersNumbers')}">
                          <div class="col-4">
                            <p class="fs-5" i18n>
                              Números Iguais
                            </p>
                          </div>
                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalLettersNumbersDaytime || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalLettersNumbersDaytime && reportOcrByLane[laneId +
                              ''].totalLettersNumbersDaytime > 0 && getPercentage(reportOcrByLane[laneId +
                              ''].totalLettersNumbersDaytime, reportOcrByLane[laneId + ''].totalCount) + '%') || 0 + '%'
                              }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalLettersNumbersNocturnal || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalLettersNumbersNocturnal && reportOcrByLane[laneId +
                              ''].totalLettersNumbersNocturnal > 0 && getPercentage(reportOcrByLane[laneId +
                              ''].totalLettersNumbersNocturnal, reportOcrByLane[laneId + ''].totalCount) + '%') || 0 +
                              '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalLettersNumbers || 0 }}
                            </span>
                          </div>
                          <div class="col-1 mt-1 pl-0">
                            <span>
                              {{ (reportOcrByLane[laneId + ''].totalLettersNumbersPercentage + '%') || 0 + '%' }}
                            </span>
                          </div>
                        </div>
                        <div class="row" [ngClass]="{'d-none': !linesToShow.includes('totalLettersNumbersDifferent')}">
                          <div class="col-4">
                            <p class="fs-5" i18n>
                              Números Diferentes
                            </p>
                          </div>
                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalLettersNumbersDifferentDaytime || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalLettersNumbersDifferentDaytime &&
                              reportOcrByLane[laneId + ''].totalLettersNumbersDifferentDaytime > 0 &&
                              getPercentage(reportOcrByLane[laneId + ''].totalLettersNumbersDifferentDaytime,
                              reportOcrByLane[laneId + ''].totalCount) + '%') || 0 + '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalLettersNumbersDifferentNocturnal || 0 }}
                            </span>
                          </div>
                          <div class="col-2 mt-1 pl-0">
                            <span class="mr-4">
                              {{ (reportOcrByLane[laneId + ''].totalLettersNumbersDifferentNocturnal &&
                              reportOcrByLane[laneId + ''].totalLettersNumbersDifferentNocturnal > 0 &&
                              getPercentage(reportOcrByLane[laneId + ''].totalLettersNumbersDifferentNocturnal,
                              reportOcrByLane[laneId + ''].totalCount) + '%') || 0 + '%' }}
                            </span>
                          </div>

                          <div class="col-1 mt-1">
                            <span class="ml-4">
                              {{ reportOcrByLane[laneId + ''].totalLettersNumbersDifferent || 0 }}
                            </span>
                          </div>
                          <div class="col-1 mt-1 pl-0">
                            <span>
                              {{ (reportOcrByLane[laneId + ''].totalLettersNumbersDifferentPercentage + '%') || 0 + '%'
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showReportByChangePlate" [id]="'userIdHistoryUnfoldReportByChangePlate'">
    <div class="table-responsive table-hover table-block mb-1" style="background: rgb(3, 134, 133);">
      <div class="d-flex flex-row justify-content-between" style="cursor: pointer;">
        <div class="col pt-4" style="background: rgb(3, 134, 133); color: white"
          (click)="unfoldMoreOrLessReportByChangePlate()">
          <h5>Relatório de Aproveitamento troca de Placa <span class="text-small ml-5">{{periodFilter}}</span></h5>
        </div>
        <div class="p-3">
          <button type="button" class="btn text-secondary btn-icon-block"
            (click)="unfoldMoreOrLessReportByChangePlate()">
            <i class="material-icons expand-color"
              *ngIf="typeUnfoldReportByChangePlate === 'more'; else elseBlockReportByChangePlate">unfold_less</i>
            <ng-template #elseBlockReportByChangePlate>
              <i class="material-icons expand-color">unfold_more</i>
            </ng-template>
          </button>
        </div>
      </div>
      <div *ngIf="typeUnfoldReportByChangePlate === 'more'" class="limited-height" style="background-color: white;">
        <div class="table-responsive table-hover table-block mb-1" style="background: rgb(3, 134, 133);">
          <table class="table bg-white">
            <thead class="bg-header-table">
              <tr>
                <th i18n rowspan="3" class="text-center">
                  Posições da Placa
                </th>
                <th i18n colspan="3" class="text-center">
                  Diurno
                </th>
                <th i18n colspan="3" class="text-center">
                  Noturno
                </th>
                <th i18n colspan="3" class="text-center">
                  Total
                </th>
              </tr>
              <tr>
                <th i18n class="text-center">
                  QTD.Digitada
                </th>
                <th i18n class="text-center">
                  QTD. OCR OK
                </th>
                <th i18n class="text-center">
                  %
                </th>
                <th i18n class="text-center">
                  QTD.Digitada
                </th>
                <th i18n class="text-center">
                  QTD. OCR OK
                </th>
                <th i18n class="text-center">
                  %
                </th>
                <th i18n class="text-center">
                  QTD.Digitada
                </th>
                <th i18n class="text-center">
                  QTD. OCR OK
                </th>
                <th i18n class="text-center">
                  %
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">{{'1º Letra'}}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingCountDaytime || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate1CountOkDaytime || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate1CountOkDaytime &&
                  getPercentage(reportOcrByContract?.positionPlate1CountOkDaytime, reportOcrByContract?.plateTypingCountDaytime) + '%') ||
                  0 + '%' }}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingCountNocturnal || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate1CountOkNocturnal || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate1CountOkNocturnal &&
                  getPercentage(reportOcrByContract?.positionPlate1CountOkNocturnal, reportOcrByContract?.plateTypingCountNocturnal) +
                  '%') || 0 + '%' }}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingTotalCount || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate1TotalCountOk || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate1TotalCountOkPercentage + '%') || 0 + '%' }}
                </td>
              </tr>
              <tr>
                <td class="text-center">{{'2º Letra'}}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingCountDaytime || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate2CountOkDaytime || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate2CountOkDaytime &&
                  getPercentage(reportOcrByContract?.positionPlate2CountOkDaytime || 0, reportOcrByContract?.plateTypingCountDaytime || 0)
                  + '%') || 0 + '%' }}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingCountNocturnal || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate2CountOkNocturnal || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate2CountOkNocturnal &&
                  getPercentage(reportOcrByContract?.positionPlate2CountOkNocturnal, reportOcrByContract?.plateTypingCountNocturnal) +
                  '%') || 0 + '%' }}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingTotalCount || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate2TotalCountOk || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate2TotalCountOkPercentage + '%') || 0 + '%' }}
                </td>
              </tr>
              <tr>
                <td class="text-center">{{'3º Letra'}}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingCountDaytime || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate3CountOkDaytime || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate3CountOkDaytime &&
                  getPercentage(reportOcrByContract?.positionPlate3CountOkDaytime, reportOcrByContract?.plateTypingCountDaytime) + '%') ||
                  0 + '%' }}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingCountNocturnal || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate3CountOkNocturnal || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate3CountOkNocturnal &&
                  getPercentage(reportOcrByContract?.positionPlate3CountOkNocturnal, reportOcrByContract?.plateTypingCountNocturnal) +
                  '%') || 0 + '%' }}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingTotalCount || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate3TotalCountOk || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate3TotalCountOkPercentage + '%') || 0 + '%' }}
                </td>
              </tr>
              <tr>
                <td class="text-center">{{'1º Número'}}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingCountDaytime || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate4CountOkDaytime || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate4CountOkDaytime &&
                  getPercentage(reportOcrByContract?.positionPlate4CountOkDaytime, reportOcrByContract?.plateTypingCountDaytime) + '%') ||
                  0 + '%' }}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingCountNocturnal || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate4CountOkNocturnal || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate4CountOkNocturnal &&
                  getPercentage(reportOcrByContract?.positionPlate4CountOkNocturnal, reportOcrByContract?.plateTypingCountNocturnal) +
                  '%') || 0 + '%' }}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingTotalCount || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate4TotalCountOk || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate4TotalCountOkPercentage + '%') || 0 + '%' }}
                </td>
              </tr>
              <tr>
                <td class="text-center">{{'2º Num/Letra'}}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingCountDaytime || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate5CountOkDaytime || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate5CountOkDaytime &&
                  getPercentage(reportOcrByContract?.positionPlate5CountOkDaytime, reportOcrByContract?.plateTypingCountDaytime) + '%') ||
                  0 + '%' }}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingCountNocturnal || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate5CountOkNocturnal || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate5CountOkNocturnal &&
                  getPercentage(reportOcrByContract?.positionPlate5CountOkNocturnal, reportOcrByContract?.plateTypingCountNocturnal) +
                  '%') || 0 + '%' }}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingTotalCount || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate5TotalCountOk || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate5TotalCountOkPercentage + '%') || 0 + '%' }}
                </td>
              </tr>
              <tr>
                <td class="text-center">{{'3º Número'}}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingCountDaytime || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate6CountOkDaytime || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate6CountOkDaytime &&
                  getPercentage(reportOcrByContract?.positionPlate6CountOkDaytime, reportOcrByContract?.plateTypingCountDaytime) + '%') ||
                  0 + '%' }}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingCountNocturnal || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate6CountOkNocturnal || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate6CountOkNocturnal &&
                  getPercentage(reportOcrByContract?.positionPlate6CountOkNocturnal, reportOcrByContract?.plateTypingCountNocturnal) +
                  '%') || 0 + '%' }}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingTotalCount || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate6TotalCountOk || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate6TotalCountOkPercentage + '%') || 0 + '%' }}
                </td>
              </tr>
              <tr>
                <td class="text-center">{{'4º Número'}}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingCountDaytime || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate7CountOkDaytime || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate7CountOkDaytime &&
                  getPercentage(reportOcrByContract?.positionPlate7CountOkDaytime, reportOcrByContract?.plateTypingCountDaytime) + '%') ||
                  0 + '%' }}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingCountNocturnal || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate7CountOkNocturnal || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate7CountOkNocturnal &&
                  getPercentage(reportOcrByContract?.positionPlate7CountOkNocturnal, reportOcrByContract?.plateTypingCountNocturnal) +
                  '%') || 0 + '%' }}</td>
                <td class="text-center">{{ reportOcrByContract?.plateTypingTotalCount || 0 }}</td>
                <td class="text-center">{{ reportOcrByContract?.positionPlate7TotalCountOk || 0 }}</td>
                <td class="text-center">{{ (reportOcrByContract?.positionPlate7TotalCountOkPercentage + '%') || 0 + '%' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
