/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QuarantineViolationsProcess } from '../models';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Gb } from './growthbook.service';

@Injectable({
  providedIn: 'root'
})
export class QuarantineProcessService {
  gb = new Gb();

  constructor(private http: HttpClient) {
    this.gb.init();
  }

  getUrl(operation: string): string {
    return `${environment.apiUrl}/quarantine/${operation}`;
  }


  quarantineProcess(type: string, quarantineReprocess: QuarantineViolationsProcess): Promise<QuarantineViolationsProcess> {
    const url = this.getUrl(type);
    const header = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json'
    });
    const data = JSON.stringify(quarantineReprocess);
    return this.http.post(url, data, {
      headers: header,
      params: {
        v2: this.gb.isOn('ECS-3107') ? 'enabled' : 'disabled'
      }
     })
      .pipe(
        catchError(this.handleError)
      ).toPromise();
  }

  protected handleError(error: any): Observable<any> {
    return throwError(error);
  }
}
