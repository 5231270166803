import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { AlertComponent } from './alert/alert.component';
import { ModalComponent } from './modal/modal.component';
import { WrapperComponent } from './wrapper/wrapper.component';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { PaginationComponent } from './pagination/pagination.component';
import { AvatarComponent } from './avatar/avatar.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpotPanelComponent } from './spot-panel/spot-panel.component';
import { LanePanelComponent } from './lane-panel/lane-panel.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { TreeviewModule } from 'ngx-treeview';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { MomentPipe } from './moment/moment.pipe';
import { SanitizerPipe } from './sanitizer/sanitizer.pipe';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { PermissionDirective } from './permission/permission.directive';
import { EnumI18nComponent } from './enum-i18n/enum-i18n.component';
import { DragResizeComponent } from './drag-resize/drag-resize.component';
import { ImgEditComponent } from './img-edit/img-edit.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ColumnFilterComponent } from './column-filter/column-filter.component';
import { ChartComponent } from './chart/chart.component';
import { LoadingComponent } from './loading/loading.component';
import { QuarantineHeaderComponent } from './quarantine-header/quarantine-header.component';
import { ThumbnailComponent } from './thumbnail/thumbnail.component';
import { StateDiagramComponent } from './state-diagram/state-diagram.component';
import { GoTopComponent } from './go-top/go-top.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { PaginationSimpleComponent } from './pagination-simple/pagination-simple.component';
import { ImgGlassComponent } from './img-glass/img-glass.component';
import { UcFirstPipe } from './uc-first/uc-first.pipe';
import { PaginationUrlComponent } from './pagination-url/pagination-url.component';

@NgModule({
  declarations: [
    InputComponent,
    AlertComponent,
    ModalComponent,
    WrapperComponent,
    InfiniteScrollComponent,
    PaginationComponent,
    AvatarComponent,
    SpotPanelComponent,
    LanePanelComponent,
    TooltipDirective,
    MomentPipe,
    SanitizerPipe,
    FileUploadComponent,
    PermissionDirective,
    EnumI18nComponent,
    DragResizeComponent,
    ImgEditComponent,
    ColumnFilterComponent,
    ChartComponent,
    LoadingComponent,
    QuarantineHeaderComponent,
    ThumbnailComponent,
    StateDiagramComponent,
    GoTopComponent,
    BreadcrumbsComponent,
    MultiselectComponent,
    PaginationSimpleComponent,
    ImgGlassComponent,
    UcFirstPipe,
    PaginationUrlComponent
  ],
  exports: [
    InputComponent,
    AlertComponent,
    ModalComponent,
    WrapperComponent,
    InfiniteScrollComponent,
    PaginationComponent,
    AvatarComponent,
    SpotPanelComponent,
    LanePanelComponent,
    TooltipDirective,
    MomentPipe,
    SanitizerPipe,
    FileUploadComponent,
    PermissionDirective,
    EnumI18nComponent,
    DragResizeComponent,
    ImgEditComponent,
    ColumnFilterComponent,
    ChartComponent,
    LoadingComponent,
    QuarantineHeaderComponent,
    ThumbnailComponent,
    StateDiagramComponent,
    GoTopComponent,
    BreadcrumbsComponent,
    MultiselectComponent,
    PaginationSimpleComponent,
    PaginationUrlComponent,
    ImgGlassComponent,
    UcFirstPipe
  ],
  imports: [
    MatCheckboxModule,
    AngularMultiSelectModule,
    TreeviewModule.forRoot(),
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    MatRadioModule
  ]
})
export class SharedModule { }
