import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertItem, AlertType, ComponentModal, Contract, Regulation } from 'src/app/core/models';
import { AlertService, ContractService, ModalService, RegulationService, StorageService } from 'src/app/core/services';
import { hasPermission } from 'src/app/core/utils/permission';
import { ViewSelectListComponent } from 'src/app/modals/view-select-list/view-select-list.component';

@Component({
  selector: 'app-config-general',
  templateUrl: './config-general.component.html',
  styleUrls: ['./config-general.component.sass']
})
export class ConfigGeneralComponent implements OnInit {

  public loading = false;
  public isLoading = false;
  public allowEdit: boolean;
  public contractId: number;
  public contract: Contract;
  public qrcodeForm: FormGroup;
  public regulationForm: FormGroup;
  public regulations: Array<Regulation>;
  public enabledRegulations: Array<Regulation>;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private contractService: ContractService,
    private regulationService: RegulationService,
    private modalService: ModalService,
    private alertService: AlertService
  ) { }

  async ngOnInit() {
    this.loading = true;
    this.contractId = this.route.snapshot.params.contractId;
    this.createForm();
    await this.getcontract();
    await this.getRegulations();
    this.allowEdit = hasPermission('UpdateContract', this.storageService, [this.contract.id]);
    this.setFormValues();
    this.loading = false;
  }

  async getcontract() {
    await this.contractService.getById(this.contractId).then((contract) => {
      this.contract = contract;
    });
  }

  async getRegulations() {
    let params = {};
    params = { regionId: this.contract.regionId };
    await this.regulationService.getAll(params).then(res => {
      res.map(item => {
        item.shortDescription = `${item.code} - ${item.shortDescription}`;
      });
      this.regulations = res;
      this.enabledRegulations = this.regulations.filter(regulation => this.contract.regulationIds.includes(Number(regulation.id))) || [];
    });
  }

  createForm() {
    this.regulationForm = this.formBuilder.group({
      regulationIds: new FormControl({ value: this.contract?.regulationIds || [], disabled: !this.allowEdit })
    });
    this.qrcodeForm = this.formBuilder.group({
      enabledQRCode: new FormControl({ value: this.contract?.QRCode?.enabledQRCode || false }),
      regulationIds: new FormControl({ value: this.contract?.QRCode?.regulationIds || [], disabled: !this.allowEdit })
    });
  }

  setFormValues() {
    this.regulationForm.get('regulationIds').patchValue(this.contract.regulationIds || []);
    this.qrcodeForm.get('enabledQRCode').patchValue(this.contract?.QRCode?.enabledQRCode || false);
    this.qrcodeForm.get('regulationIds').patchValue(this.contract?.QRCode?.regulationIds || []);
  }

  async confirmation() {
    this.isLoading = true;
    const data: any = {
      regulationIds: this.regulationForm.get('regulationIds').value,
      qrcode: {
        enabledQRCode: this.qrcodeForm.get('enabledQRCode').value,
        regulationIds: this.qrcodeForm.get('regulationIds').value
      }
    };
    this.contract.regulationIds = data.regulationIds;
    this.contract.QRCode.enabledQRCode = data.qrcode.enabledQRCode;
    this.contract.QRCode.regulationIds = data.qrcode.regulationIds;
    await this.contractService.update(this.contract).then(res => {
      this.alertService.show(new AlertItem('ContractConfiged', AlertType.success));
    }).catch(error => {
      this.alertService.show(new AlertItem('ContractConfigError', AlertType.danger));
      throw error;
    });
    this.isLoading = false;
  }

  openViewSelectListModal(inputValueIds, title, condition, list) {
    if (condition) {
      const data = { title, inputValueIds, list };
      this.modalService.show(new ComponentModal(ViewSelectListComponent, data));
    }
  }

}
