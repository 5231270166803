import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VehicleWhiteList, Pagination } from '../models';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VehicleWhiteListService extends BaseApiService<VehicleWhiteList> {
  get cacheKey(): StorageKey {
    return StorageKey.vehicleWhiteListServiceCache;
  }
  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/vehicleWhiteLists`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }
  removeImage(id, file): Promise<any> {
    const url = `${environment.apiUrl}/vehicleWhiteLists/${id}/deleteFiles`;
    const body = file;

    return this.http.put(url, body).pipe(
      catchError(this.handleError),
      map(this.unparse)
    ).toPromise();
  }

  protected unparse(obj) {
    return VehicleWhiteList.unparse(obj);
  }

  protected parse(obj) {
    return VehicleWhiteList.parse(obj);
  }
}
