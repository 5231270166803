/* eslint-disable @typescript-eslint/member-ordering */
/// <reference types="@types/jquery" />
/// <reference types="@types/slick-carousel" />
import { Component, OnInit, HostListener, AfterViewInit, ViewChild, ElementRef, OnDestroy, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  Color, BrandModel, Type, PreProcessSettings,
  Category, Region, Classification, Contract, MessageModal, Regulation, Vehicle, VehicleWhiteList
} from 'src/app/core/models';
import {
  ReasonService, AlertService, ModalService, ColorService, PreProcessSettingsService,
  TypeService, CategoryService, BrandModelService, ClassificationService, StorageService,
  StorageKey, ContractService, ProcessViolationService, RegulationService
} from 'src/app/core/services';
import { ClosedModalError } from 'src/app/core/errors';
import { CommentPanelComponent } from '../comment-panel/comment-panel.component';
import { SearchPlatePanelComponent } from '../search-plate-panel/search-plate-panel.component';
import { HistoryPanelComponent } from '../history-panel/history-panel.component';
import { PlateService } from 'src/app/core/services/plate.service';
import { City } from 'src/app/core/models/City';
import { CityService } from 'src/app/core/services/city.service';
import * as _ from 'lodash';
import { RegionService } from 'src/app/core/services/region.service';
import { StateService } from 'src/app/core/services/state.service';
import { State } from 'src/app/core/models/State';
import { SelectRegulationModalComponent } from 'src/app/modals/select-regulation-modal/select-regulation-modal.component';
import { Violation, AlertType, AlertItem, ComponentModal, } from 'src/app/core/models';
import { ViolationService } from 'src/app/core/services/violation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual, cloneDeep } from 'lodash';
import {
  MessageDuplicateViolationModalComponent
} from 'src/app/modals/message-duplicate-violation-modal/message-duplicate-violation-modal.component';
import { ViolationFilter } from 'src/app/core/interface/ViolationFilter';
import { BrandInternal } from 'src/app/core/models/BrandInternal';
import { BrandInternalService } from 'src/app/core/services/brandInternal.service';
import moment from 'moment';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.sass']
})
export class ValidateComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('reasonInput', { static: false }) reasonInput: ElementRef;

  get plateInputElement() {
    return this._plateInputElement;
  }
  @ViewChild('plateInputElement', { static: false }) set plateInputElement(content) {
    if (content == null) {
      this._plateInputElement = content;
      return;
    }
    if (this._plateInputElement != null) {
      return;
    }
    this._plateInputElement = content;
    this.setPlateFocus();
  }
  public _plateInputElement;
  public plateInput: HTMLInputElement;
  public formGroup: FormGroup;
  public violation: Violation = new Violation();
  public violations: Violation[] = [];
  public preProcessSettings: PreProcessSettings;
  public i = 0;
  public files = [];
  public secondFiles = [];
  public reasons = [];
  public reasonsAll = [];
  public types: Array<Type>;
  public colors: Array<Color>;
  public brandModels: Array<any>;
  public brandInternalModelsAll: Array<any>;
  public brandInternalModels: Array<BrandInternal>;
  public cities: Array<City>;
  public categories: Array<Category>;
  public regions: Array<Region>;
  public states: Array<State>;
  public classifications: Array<Classification>;
  public showingCities: Array<City>;
  public filters: ViolationFilter;
  public showingStates: Array<State>;
  public hasWhiteList = false;
  public requiredFields = [];
  public hasSearch = false;
  public validatorsDisabled = false;
  public imagesSettings: {
    [type: string]: {
      blur: { positions: [{ width: number; height: number; x: number; y: number }] };
      contrast: number; brightness: number; restore?: boolean;
    };
  };
  public isBlurEnabled = false;
  public isClipEnabled = false;
  public contractId = '';
  public refuseLoading = false;
  public acceptLoading = false;
  public violationsSteps = null;
  public regionId = null;
  public stateTextCities;
  public sessionId = null;
  public violationIdsProcessed = [];
  public isLoadListViolation = false;
  public violationLoadError = false;
  public changesPermited = false;
  public violationProcessed = false;
  public isChangePlate = false;
  public formEdited = false;
  public regulationByIds: { [params: string]: Regulation } = {};
  public isSearchPlate = false;
  public cetModels = false;
  public vehicleData: Vehicle;
  public vehicleDataSearch = false;
  public plateNotFoundReasonCode = null;
  public whitelistReasonCode = null;
  public vehiclesWhiteList: Array<VehicleWhiteList>;
  public viewSearchBrandModels = false;
  public currentBrandModelCode: any;
  public hideButton = false;
  public plateValid: boolean;

  private currentId: string;
  private loadPromise = null;
  private limit = '5';
  private nextLocked = null;
  private next = null;
  private isDouble = false;
  private isSingleView;
  private isDoubleView;
  private modalState;
  private urlSegment = '';
  private isModified = false;
  private stepGroup;
  // eslint-disable-next-line @typescript-eslint/ban-types
  private plateSearched: { vechicle: {} };
  private plateRegex = null;
  private contract: Contract = null;
  private timeoutId = null;
  private expired = false;
  private placeDebouncer = null;
  private plateSearchPromise = null;
  private plateSearchFoundFields = [];
  private plateFound = true;
  private disabledRequiredFields: { [params: string]: any } = {};
  public searchVehicle: any;

  @Output() violationStatus: string;

  constructor(
    private formBuilder: FormBuilder,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private violationService: ViolationService,
    private reasonService: ReasonService,
    private modalService: ModalService,
    private preProcessSettingsService: PreProcessSettingsService,
    private alertService: AlertService,
    private plateService: PlateService,
    private colorService: ColorService,
    private typeService: TypeService,
    private categoryService: CategoryService,
    private brandModelService: BrandModelService,
    private classificationService: ClassificationService,
    private cityService: CityService,
    private regionService: RegionService,
    private storageService: StorageService,
    private stateService: StateService,
    private contractService: ContractService,
    private processViolationService: ProcessViolationService,
    private regulationService: RegulationService,
    private brandInternalService: BrandInternalService,
  ) { }

  ngOnInit() {
    this.processViolationService.getFilterProcessViolation()
      .pipe()
      .subscribe((result: any) => this.filters = result);
    if (this.filters === null) {
      this.router.navigate([`../../../pre-process`], { relativeTo: this.activatedRoute });
    }
    this.contractId = this.activatedRoute.snapshot.params.contractId;
    this.setInactivity();
    const promises = [];
    promises.push(this.contractService.getById(this.contractId));
    this.preProcessSettingsService.getById(this.contractId).then(preProcessSettings => {
      this.preProcessSettings = preProcessSettings;
      this.requiredFields = preProcessSettings.requiredFields || [];
      this.requiredFields.forEach(field => {
        this.disabledRequiredFields[field] = true;
      });
      this.hasSearch = preProcessSettings.vehicleDataSearch !== 'disabled';
      this.hasWhiteList = preProcessSettings.enabledWhiteList;
      this.isBlurEnabled = this.preProcessSettings.imageEdit.blur;
      this.isClipEnabled = this.preProcessSettings.imageEdit.clip;
      this.violationsSteps = this.preProcessSettings.violationSteps;
      this.hideButton = true;
      this.setRequiredFields();
      if (this.preProcessSettings?.vehicleDataSearch !== 'disabled') {
        this.vehicleDataSearch = true;
      }
      this.getViolations(true).catch(err => { });
    });

    promises.push(this.regionService.getAll().then(regions => {
      this.regions = regions;
      this.stateService.getAll({ regionId: regions[0].id }).then(states => {
        this.states = states;
        this.showingStates = states;
      }).catch(error => {
        console.error(error);
      });
      this.regulationService.getAll({ regionId: this.regions[0].id }).then(regulations => {
        regulations.map(regulation => {
          if (this.regulationByIds[regulation.id] == null) {
            this.regulationByIds[regulation.id] = regulation;
          }
        });
      });
      this.cities = this.storageService.get(StorageKey.cities) || [];
      if (this.cities.length === 0) {
        this.cityService.getAll({ regionId: this.regions[0].id }).then(res => {
          this.cities = _.sortBy(res, 'name');
        }).catch(error => {
          console.error(error);
        });
      } else {
        let cities = [];
        Object.keys(this.cities).map(stateId => {
          cities = cities.concat(this.cities[stateId]);
        });
        this.cities = _.sortBy(cities, 'name');
        return regions;
      }
      return regions;
    }).catch(error => {
      console.error(error);
    }));
    const routeCurrent = this.activatedRoute.snapshot.url[0] || { path: '' };
    this.urlSegment = 'validate';
    this.createForm();
    this.reasonService.getAll({
      contractId: this.contractId,
      order: 'code',
      'enabled[bool]': 'true'
    }).then(data => {
      this.reasons =  data.sort((a, b): any => Number(a.code) - Number(b.code));
      this.reasonsAll = this.reasons;
      if (this.preProcessSettings?.plateNotFoundReasonId != null) {
        const reason = this.reasons.find(r => r.id === this.preProcessSettings.plateNotFoundReasonId);
        this.plateNotFoundReasonCode = reason?.code;
      }
      if (this.preProcessSettings?.whitelistReasonId != null) {
        const reason = this.reasons.find(r => r.id === this.preProcessSettings.whitelistReasonId);
        this.whitelistReasonCode = reason?.code;
      }
    });
    this.stepGroup = routeCurrent.path;
    Promise.all(promises).then(results => {
      const contract = results[0];
      this.regionId = contract.regionId;
      const regions = results[1];
      const region = regions.find(r => r.id === contract.regionId);
      this.colorService.getAll({ regionId: region.id }).then(colors => {
        this.colors = colors;
      });
      this.typeService.getAll({ regionId: region.id }).then(types => {
        this.types = types;
      });
      this.categoryService.getAll({ regionId: region.id }).then(categories => {
        this.categories = categories;
      });
      this.brandModelService.list({ regionId: region.id, limit: '500' }).then(res => {
        this.brandModels = res.result;
      });
      this.classificationService.getAll({ regionId: region.id }).then(classifications => {
        this.classifications = classifications;
      });
      if (region == null) {
        return;
      }
      this.plateRegex = region.plateRegex;
      if (this.formGroup != null) {
        const plateControl = this.formGroup.get('plate');
        plateControl.setValidators([plateControl.validator, Validators.pattern(this.plateRegex)]);
      }
    });
  }

  ngOnDestroy(): void {
    const user = this.storageService.get(StorageKey.currentUser);
    if (user == null || user.id == null) {
      return;
    }
    const userId = user.id;
    const violationIds = this.violations.map(v => v.id);
    this.violationService.unlock(userId, violationIds);
    clearTimeout(this.timeoutId);
  }

  unlock() {
    const user = this.storageService.get(StorageKey.currentUser);
    const violationIds = this.violations.map(v => v.id);
    this.violationService.unlock(user.id, violationIds);
  }

  ngAfterViewInit(): void {
    this.setPlateFocus();
  }

  getQueryParams() {
    const queryParams: any = {
      contractId: this.contractId,
      lock: 'true',
      stepGroup: this.stepGroup,
      limit: this.limit
    };
    if (this.filters.hasOwnProperty('equipmentsIds') && this.filters.equipmentsIds  ) {
      queryParams['equipment.id[in]'] = `[${this.filters.equipmentsIds}]`;
    }
    if (this.filters.hasOwnProperty('laneIds') && this.filters.laneIds  ) {
      queryParams['laneCode[in]'] = `[${this.filters.laneIds.join()}]`;
    }
    if (this.filters.hasOwnProperty('regulationsIds') && this.filters.regulationsIds  ) {
      queryParams['regulationId[in]'] = `[${this.filters.regulationsIds.join()}]`;
    }
    if (this.filters.hasOwnProperty('startDate') && this.filters.startDate  ) {
      queryParams['date[gte]'] = this.filters.startDate;
    }
    if (this.filters.hasOwnProperty('endDate') && this.filters.endDate  ) {
      queryParams['date[lte]'] = this.filters.endDate;
    }
    if (this.filters.hasOwnProperty('situationsIds') && this.filters.situationsIds  ) {
      queryParams[`status[in]`] = `[${this.filters.situationsIds.join()}]`;
    }
    if (this.filters.hasOwnProperty('reasonsCodes') && this.filters.reasonsCodes  ) {
      queryParams['reason.code[in]'] = `[${this.filters.reasonsCodes}]`;
    }
    if (this.filters.hasOwnProperty('numberId') && this.filters.numberId  ) {
      queryParams.passId = this.filters.numberId;
      delete queryParams.limit;
    }
    if (this.filters.hasOwnProperty('numberPlate') && this.filters.numberPlate  ) {
      queryParams['vehicle.plate'] = this.filters.numberPlate.toUpperCase();
      delete queryParams.limit;
    }
    if (this.filters.hasOwnProperty('number_serie') && this.filters.numberSerie  ) {
      queryParams.serialNumber = this.filters.numberSerie;
      delete queryParams.limit;
    }
    if (this.filters.hasOwnProperty('authorIds') && this.filters.authorIds  ) {
      queryParams['typingAuthorId[in]'] = `[${this.filters.authorIds}]`;
    }

    if (this.preProcessSettings.enabledViolationStandBy && this.preProcessSettings.maxPeriodViolationStandBy > 0) {
      const now = new Date();
      const dateStandBy = new Date(now.setMinutes(now.getMinutes() - this.preProcessSettings.maxPeriodViolationStandBy)).toISOString();
      queryParams['updatedAt[lt]'] = dateStandBy;
    }

    queryParams.order = 'date';
    return queryParams;
  }

  backToFilter() {
    this.processViolationService.emitFilterProcessViolation(null);
    this.router.navigate([`../../${this.stepGroup}`], { relativeTo: this.activatedRoute, queryParams: { contractId: this.contractId } });
  }

  getViolations(isFirst = false, isRetry = false) {
    this.violationLoadError = false;
    if (this.loadPromise  ) {
      return this.loadPromise;
    }
    const queryParams = this.getQueryParams();
    if (isFirst && this.violations.length === 0) {
      this.loadPromise = this.violationService.getLockedViolationsByMe(null, queryParams).then(async data => {
        let next = data.next;
        if (next == null && data.result.length === 0) {
          this.isLoadListViolation = true;
          return this.loadPromise = this.violationService.list(queryParams).then(item => {
            this.next = item.next;
            this.isLoadListViolation = false;
            return item;
          });
        } else {
          while (next != null) {
            const nextData = await this.violationService.getLockedViolationsByMe(next, queryParams);
            if (nextData && nextData.result != null) {
              data.result = data.result.concat(nextData.result);
            }
            next = nextData && nextData.next;
          }
        }
        return data;
      }).catch(async err => {
        if (err.status === 404) {
          this.isLoadListViolation = true;
          this.loadPromise = this.violationService.list(queryParams).then(data => {
            this.next = data.next;
            this.isLoadListViolation = false;
            return data;
          });
        }
      });
    } else {
      this.isLoadListViolation = true;
      this.loadPromise = this.violationService.list(queryParams).then(data => {
        this.next = data.next;
        this.isLoadListViolation = false;
        return data;
      });
    }
    return this.loadPromise.then(async data => {
      this.loadPromise = null;
      this.violations = this.violations.concat(data.result);
      if (isFirst) {
        const violation = this.violations[0];
        if (violation == null) {
          await this.getViolations().catch(err => {});
        }
        if (violation != null) {
          this.setForm(violation);
        }
      }
      this.preloadFiles(data.result);
      if (this.i >= this.violations.length - 1) {
        this.alertService.show(new AlertItem('NoViolationsTyping', AlertType.danger));
      }
    }).catch(async err => {
      this.loadPromise = null;
      if (isRetry === false) {
        await this.getViolations(false, true).then(() => {
          // this.i++;
          this.setForm(this.violations[this.i]);
        });
      } else {
        this.alertService.show(new AlertItem('ViolationListError', AlertType.danger));
        this.violationLoadError = true;
        console.error(err);
        throw err;
      }
    });
  }

  getImageEdit(event) {
    if (event == null) {
      return;
    }
    this.imagesSettings = event;
  }

  get typingValid() {
    if (this.formGroup == null) {
      return false;
    }
    if (this.preProcessSettings != null &&
      this.preProcessSettings.allowInputNotFoundVehicle !== true &&
      this.plateFound === false) {
      return false;
    }
    const reasonControl = this.formGroup.get('reasonCode');
    if (reasonControl && reasonControl.value != null && reasonControl.value !== '') {
      return false;
    } else {
      return this.formGroup.valid;
    }
  }

  get verifyValidation() {
    if (this.violation == null) {
      return false;
    }
    const newViolation = this.getViolation();
    const oldReasonCode = this.violation && this.violation.reason && this.violation.reason.code;
    const newReasonCode = this.formGroup && this.formGroup.get('reasonCode').value || undefined;
    return isEqual(newViolation, this.violation) && oldReasonCode === newReasonCode;
  }

  get validationPlate() {
    const plate = this.formGroup && this.formGroup.get('plate');
    const reason = this.formGroup && this.formGroup.get('reasonCode');
    if ((plate.value == null || plate.value === '') && reason.value !== '') {
      return true;
    }
    plate.setValidators([plate.validator, Validators.pattern(this.plateRegex)]);

    return !plate.invalid;
  }

  setPlateFocus() {
    if (this.plateInputElement == null) {
      return;
    }
    if (this.plateInput == null) {
      const element = this.plateInputElement.nativeElement;
      this.plateInput = element.querySelector('input') as HTMLInputElement;
    }
    if (this.plateInput != null) {
      this.plateInput.focus();
    }
  }

  loadMoreViolations() {
    if (this.loadPromise != null) {
      return this.loadPromise;
    }
    const queryParams = this.getQueryParams();
    this.loadPromise = this.violationService.list(queryParams).then(data => {
      this.loadPromise = null;
      this.violations = this.violations.concat(data.result);
      this.preloadFiles(data.result);
      if (this.i >= this.violations.length - 1) {
        this.alertService.show(new AlertItem('NoViolationsTyping', AlertType.danger));
      }
    }).catch(err => {
      this.loadPromise = null;
      throw err;
    });
    return this.loadPromise;
  }

  preloadFiles(violations) {
    setTimeout(() => {
      const violation = violations.shift();
      if (violation && violation.files != null) {
        violation.files.forEach(f => {
          const img = new Image();
          img.crossOrigin = 'anonymous';
          img.src = f.urlCurrent || f.urlDecrypted;
        });
      }
      if (violations.length > 0) {
        this.preloadFiles(violations);
      }
    }, 50);
  }

  get form() {
    return this.formGroup.controls;
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      plate: ['', [Validators.pattern(this.plateRegex)]],
      colorCode: [''],
      year: [''],
      brandModelCode: [''],
      displayBrandModelCode: [''],
      categoryCode: [''],
      classificationCode: [''],
      typeCode: [''],
      cityCode: [''],
      stateId: [''],
      regionCode: [''],
      regionId: ['Brasil'],
      reasonCode: ['']
    });
    this.formGroup.valueChanges.subscribe(value => {
      this.isModified = true;
      this.editEvent('plate');
    });

    this.formGroup.get('plate').valueChanges.subscribe(value => {
      this.plateValid = this.formGroup.get('plate').valid;
    });

    this.formGroup.get('reasonCode').valueChanges.subscribe(value => {
      const textRequired = value !== '' ? '' : 'Este campo é requerido.';
      if (value == null || value === '') {
        this.setRequiredFields();
      } else {
        this.disableValidators();
      }
      if (value) {
        const element = document.getElementById('plateInputElement') as HTMLElement;
        if (element) {
          if (element.querySelector('.form-group .text-danger span')) {
            element.querySelector('.form-group .text-danger span').textContent = textRequired;
            if (value !== '') {
              element.querySelector('.form-group input')?.classList.remove('is-invalid');
            }
          }
        }
      }
    });
  }

  setForm(violation) {
    this.violation = violation;
    this.violationProcessed = false;
    this.processStatus();
    this.isChangesPermited();
    this.setFiles(violation);
    this.formGroup.controls.cityCode.setValue(null);
    this.formGroup.controls.stateId.setValue(violation.vehicle.uf || null);
    this.formGroup.controls.regionId.setValue(this.regionId);
    this.setFormPlate(violation.vehicle);
    const plateControl = this.formGroup.get('plate');
    plateControl.setValue(violation.vehicle.plate);
    this.formGroup.get('reasonCode').setValue((violation.reason && violation.reason.code) || '');

    if (this.violationIdsProcessed.includes(violation.id)) {
      plateControl.setValue(violation.vehicle.plate);
    }
    if (plateControl.value === '') {
      this.resetForm();
    }
    if (!violation.vehicle.plate && this.brandModels.length < 2 && this.requiredFields.includes('brandModelCode')) {
      this.brandModelService.list({ regionId: this.regionId, limit: '500' }).then(res => {
        this.brandModels = res.result;
      });
    }

    this.setPlateFocus();
    for (const i in this.formGroup.controls) {
      if (this.formGroup.controls.hasOwnProperty(i)) {
        const control = this.formGroup.controls[i];
        control.markAsPristine();
      }
    }
    this.reasons = this.reasonsAll.filter(reason =>
      reason.regulationIds.includes(violation.regulationId) && reason.screen !== undefined
      && reason.screen.includes('validate'));
    this.isModified = false;

    if (plateControl.value !== '' && this.hasSearch) {
      this.loadVehicle(plateControl.value, this.violation.step);
    }

    if (violation.vehicle.colorCode) {
      this.formGroup.controls.colorCode.setValue(violation.vehicle.colorCode);
    }
    if (violation.vehicle.year) {
      this.formGroup.controls.year.setValue(violation.vehicle.year);
    }
    if (violation.vehicle.categoryCode) {
      this.formGroup.controls.categoryCode.setValue(violation.vehicle.categoryCode);
    }
    if (violation.vehicle.classificationCode) {
      this.formGroup.controls.classificationCode.setValue(violation.vehicle.classificationCode);
    }
    if (violation.vehicle.typeCode) {
      this.formGroup.controls.typeCode.setValue(violation.vehicle.typeCode);
    }
  }

  setRequiredFields() {
    const controls = this.formGroup.controls;
    if (this.requiredFields == null || this.requiredFields.length === 0) {
      return;
    }
    Object.keys(controls).forEach(key => {
      if (this.requiredFields.includes(key)) {
        if (this.plateRegex != null && key === 'plate') {
          controls[key].setValidators([Validators.required, Validators.pattern(this.plateRegex)]);
        } else {
          controls[key].setValidators([Validators.required]);
        }
        controls[key].markAsDirty();
      } else {
        controls[key].clearValidators();
      }
      controls[key].updateValueAndValidity({ emitEvent: false });
    });
    this.validatorsDisabled = false;
  }

  disableValidators() {
    if (!this.validatorsDisabled) {
      Object.keys(this.formGroup.controls).forEach(control => {
        if (control !== 'plate') {
          this.formGroup.controls[control].clearValidators();
          this.formGroup.controls[control].updateValueAndValidity({ emitEvent: false });
        }
      });
      this.validatorsDisabled = true;
    }
  }

  setFiles(violation) {
    if (this.isDouble) {
      if (violation.files != null) {
        this.files = [];
        this.secondFiles = [];
        violation.files.forEach(f => {
          const obj = {
            src: f.urlCurrent || f.urlDecrypted,
            type: f.type,
            urlCurrent: f.urlCurrent,
            urlDecrypted: f.urlDecrypted,
            targeHeight: f.targeHeight,
            targePosition: f.targePosition
          };
          if (/zoom/.test(f.type)) {
            this.files.push(obj);
          } else if (/pan2/.test(f.type)) {
            this.secondFiles.unshift(obj);
          } else {
            this.secondFiles.push(obj);
          }
        });
        if (this.files.length !== 0 || this.secondFiles.length !== 0) {
          if (this.secondFiles.length === 0 && this.files.length > 1) {
            this.secondFiles.push(this.files.pop());
          }
          if (this.files.length === 0) {
            this.files.push(this.secondFiles.shift());
          }
        }
      }
    } else {
      this.files = [];
      violation.files.forEach(f => {
        const file = {
          src: f.urlCurrent || f.urlDecrypted,
          type: f.type,
          urlCurrent: f.urlCurrent,
          urlDecrypted: f.urlDecrypted,
          targeHeight: f.targeHeight,
          targePosition: f.targePosition
        };
        if (f.type === 'zoom') {
          this.files.unshift(file);
        } else {
          this.files.push(file);
        }
      });
    }
  }

  getViolation() {
    let violation = cloneDeep(this.violation);
    const plateOld = violation.vehicle.plate;
    if (violation.vehicle == null) {
      violation = Object.assign(violation, { vehicle: {} });
    }
    violation.vehicle.plate = this.formGroup.get('plate').value &&
      this.formGroup.get('plate').value.toUpperCase();
    violation.vehicle.colorCode = this.formGroup.get('colorCode').value;
    violation.vehicle.year = this.formGroup.get('year') &&
      this.formGroup.get('year').value && parseInt(this.formGroup.get('year').value, 10);
    violation.vehicle.brandModelCode = this.formGroup.get('brandModelCode').value;
    violation.vehicle.categoryCode = this.formGroup.get('categoryCode').value;
    violation.vehicle.classificationCode = this.formGroup.get('classificationCode').value;
    violation.vehicle.typeCode = this.formGroup.get('typeCode').value;
    violation.vehicle.cityCode = this.formGroup.get('cityCode').value;
    violation.vehicle.abbreviation = this.showingStates.filter(state => state.id === this.formGroup.get('stateId').value)[0]?.abbreviation;
    violation.vehicle.chassis =
      this.searchVehicle?.vehicle?.chassis || plateOld === violation.vehicle.plate ? violation.vehicle.chassis : null;
    violation.vehicle.modelYear =
      this.searchVehicle?.vehicle?.modelYear || plateOld === violation.vehicle.plate ? violation.vehicle.modelYear : null;
    if (violation.vehicle.abbreviation == null && violation.vehicle.uf != null) {
      violation.vehicle.abbreviation = violation.vehicle.uf;
    }
    const valueReason = this.formGroup.get('reasonCode').value;
    if (valueReason !== '') {
      const reason = this.reasons.find(r => r.code === valueReason) || violation.reason;
      if (violation.reason == null) {
        violation = Object.assign(violation, { reason: {} });
      }
      if (this.changesPermited && reason) {
        violation.reason = {
          code: reason.code,
          name: reason.name,
          type: reason.type || ''
        };
      }
    } else {
      delete violation.reason;
    }
    return this.removeVehicleFields(violation, ['length', 'profile', 'size', 'plateOCR']);
  }

  removeVehicleFields(violation, immutableFields: string[] = ['length', 'profile', 'size', 'plateOCR']) {
    const discardFields = ['brandModel', 'category', 'city',
      'classification', 'color', 'registrationNumber',
      'type', 'uf', null, 'undefined', ''];
    for (const key of Object.keys(violation.vehicle)) {
      if (immutableFields.includes(key)) {
        continue;
      }
      if (discardFields.includes(violation.vehicle[key])) {
        delete violation.vehicle[key];
      }
    }
    return violation;
  }

  setDouble(flag) {
    if (this.isDouble !== flag) {
      this.isDouble = flag;
      this.setFiles(this.violation);
    }
  }

  async nextViolation() {
    this.currentBrandModelCode = undefined;
    this.isChangePlate = false;
    if (this.imagesSettings != null) {
      await this.updateImages();
    }
    if (this.i < this.violations.length - 1) {
      this.i++;
      this.setForm(this.violations[this.i]);
      if (this.i > this.violations.length - 5) {
        this.getViolations().catch(err => { });
      }
    } else {
      const current = this.i;
      this.getViolations().then(() => {
        if (current < this.violations.length - 1) {
          this.i = current + 1;
        }
      }).catch(err => { });
    }
  }

  async prevViolation() {
    this.currentBrandModelCode = undefined;
    this.isChangePlate = false;
    if (this.imagesSettings != null) {
      await this.updateImages();
    }
    if (this.i > 0) {
      this.i--;
      this.setForm(this.violations[this.i]);
    }
  }

  async validate() {
    if (this.acceptLoading === true) {
      return;
    }
    this.acceptLoading = true;
    const violation = this.getViolation();
    try {
      await this.violationService.accept(violation).then(violationProc => {
        this.violations[this.i] = violationProc;
      });
      this.violationIdsProcessed.push(violation.id);
      this.alertService.show(new AlertItem('ViolationSaved', AlertType.success));
    } catch (err) {
      if (err.error.type === 'laneDisabled') {
        this.alertService.show(new AlertItem('FramingNotEnabledForTrack', AlertType.danger));
        this.acceptLoading = false;
        return;
      }
      if (err.status === 409) {
        const duplicateViolation = err.error;
        this.openMessageDuplicateViolation(duplicateViolation);
      } else if (err.status === 500) {
        this.alertService.show(new AlertItem('ProcessedViolationAlreadyExists', AlertType.danger));
      } else {
        if (err.error && err.error.search('whiteList') > 0) {
          this.alertService.show(new AlertItem('ViolationSaveWhiteList', AlertType.danger));
        } else {
          this.alertService.show(new AlertItem('ViolationSaveError', AlertType.danger));
        }
        console.error(err);
      }
    }
    this.currentBrandModelCode = undefined;
    this.nextViolation();
    this.acceptLoading = false;
  }

  async updateImages() {
    const id = this.violation.id;
    for (const type of Object.keys(this.imagesSettings)) {
      const imageSettings = Object.assign({ type }, this.imagesSettings[type]);
      if (imageSettings.restore) {
        delete imageSettings.restore;
      }
      await this.violationService.updateImage(id, imageSettings)
        .then(data => {
          this.setFiles(data);
          this.alertService.show(new AlertItem('ViolationImageSaved', AlertType.success));
        })
        .catch((err) => {
          console.error(err);
          this.alertService.show(new AlertItem('ViolationImageSaveError', AlertType.danger));
        });
    }
    this.imagesSettings = null;
  }

  async restoreImage(type, imgSettings = null) {
    this.imagesSettings = imgSettings;
    await this.violationService.restoreImage(this.violation.id, { type })
      .then(data => {
        this.setFiles(data);
        this.alertService.show(new AlertItem('ViolationImageRestored', AlertType.success));
      })
      .catch((err) => {
        console.error(err);
        this.alertService.show(new AlertItem('ViolationImageRestoredError', AlertType.danger));
      });
  }

  async refused() {
    if (this.refuseLoading === true) {
      return;
    }

    this.refuseLoading = true;
    try {
      const violation = this.getViolation();
      if (this.formGroup.get('plate').value === '') {
        violation.vehicle.plate = '';
      }
      await this.violationService.refuse(violation).then(violationProc => {
        this.violations[this.i] = violationProc;
      });
      this.violationIdsProcessed.push(violation.id);
      this.alertService.show(new AlertItem('ViolationSaved', AlertType.success));
      this.nextViolation();
    } catch (err) {
      if (err.status === 409) {
        const duplicateViolation = err.error;
        this.openMessageDuplicateViolation(duplicateViolation);
      } else {
        this.alertService.show(new AlertItem('ViolationRefusedError', AlertType.danger));
        console.error(err);
      }
    }
    this.refuseLoading = false;
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event) {
    const isCtrl = event.ctrlKey;
    switch (event.key) {
      case 'i':
        if (isCtrl && this.urlSegment === 'typing') {
          const element = this.reasonInput.nativeElement as HTMLSelectElement;
          if (element != null) {
            element.focus();
          }
          event.preventDefault();
        }
        break;
      case 'k':
        if (isCtrl) {
          this.prevViolation();
          event.preventDefault();
        }
        break;
      case 'l':
        if (isCtrl) {
          this.nextViolation();
          event.preventDefault();
        }
        break;
      case 'm':
        if (isCtrl) {
          if (this.modalService.isOpen(CommentPanelComponent)) {
            this.modalService.close();
          } else {
            this.openCommentPanel();
          }
          event.preventDefault();
        }
        break;
      case 'x':
        if (isCtrl) {
          this.openSearchPlatePanel();
        }
        break;
      case 'Enter':
        const reasonCode = this.formGroup.get('reasonCode').value;
        if (this.plateNotFoundReasonCode === reasonCode) {
          this.refused();
        }
        break;
    }
    this.setInactivity();
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseup(event: MouseEvent) {
    this.setInactivity();
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(e) {
    this.setInactivity();
  }

  openCommentPanel() {
    this.modalService.show(new ComponentModal(CommentPanelComponent, this.violation))
      .catch(err => { });
  }

  openSearchPlatePanel() {
    let data = null;
    this.modalService.show(new ComponentModal(SearchPlatePanelComponent, this.violation))
      .then((res: SearchPlatePanelComponent) => {
        data = res;
        this.setPlateFocus();
        this.vehicleData = data.component.instance.vehicleData;
        this.formGroup.get('plate').setValue(this.vehicleData.plate);
        this.formGroup.get('colorCode').setValue(this.colors.find(e => e.code === this.vehicleData.colorCode)?.code);
        this.formGroup.get('year').setValue(this.vehicleData.year);
        this.formGroup.get('brandModelCode').setValue(this.vehicleData.brandModelCode);
        this.formGroup.get('displayBrandModelCode').setValue(this.vehicleData.brandModel);
        this.formGroup.get('categoryCode').setValue(this.vehicleData.categoryCode);
        this.formGroup.get('classificationCode').setValue(this.vehicleData.classificationCode);
        this.formGroup.get('typeCode').setValue(this.vehicleData.typeCode);
        const city: any = this.loadCitiesByCode(this.vehicleData.cityCode);
        if (city.__zone_symbol__value) {
          this.loadCities(city.stateId);
          this.formGroup.get('cityCode').setValue(city.code);
          this.formGroup.get('regionId').setValue(city.regionId);
          this.formGroup.get('stateId').setValue(city.stateId);
        } else {
          const params = {
            contractId: this.violation.contractId,
            plate: this.vehicleData.plate,
            regulationId: this.violation.regulationId,
            date: this.violation.date
          };
          this.findPlate(params);
        }
        this.currentBrandModelCode = this.brandModels.filter(brand => brand.code === this.vehicleData.brandModelCode);
      }).catch(err => {
        console.error(err);
      });
  }


  openHistoryPanel() {
    this.modalService.show(new ComponentModal(HistoryPanelComponent, this.violation))
      .catch(err => { });
  }

  async setFormPlate(vehicle, isSearch = false) {
    const items = ['color', 'classification', 'category', 'brandModel', 'type', 'city', 'year'];
    this.plateSearchFoundFields = [];
    const changeBrandModelForClientBrand = this.getValue(this.preProcessSettings, 'changeBrandModelForClientBrand');
    items.forEach(async item => {
      const field = item === 'year' ? item : `${item}Code`;
      const value = item === 'year' ? 0 : '0';
      if (item === 'brandModel' && !isSearch) {
        this.cetModels = false;
        this.brandModelService.list({ regionId: this.regionId, code: vehicle[`${item}Code`] }).then(async res => {
          this.brandModels = res.result;
          let data = this.brandModels[0];
          if (data != null) {
            this.formGroup.get(field).setValue(data.code);
            if (isSearch) {
              this.plateSearchFoundFields.push(field);
            }
          } else if (changeBrandModelForClientBrand) {
            this.cetModels = true;
            const brandModelCode = vehicle[`${item}Code`] || this.formGroup.get(field).value;
            if (!this.brandInternalModelsAll || this.brandInternalModelsAll.length === 0) {
              await this.brandInternalService.list({ contractId: this.contractId }).then(resBrand => {
                const result = resBrand.result;
                result.map(brand => {
                  brand.description = `${brand.code} - ${brand.description}`;
                });
                this.brandInternalModelsAll = result;
                this.brandInternalModels = this.brandInternalModelsAll;

              });
            } else {
              this.brandInternalModels = this.brandInternalModelsAll;
            }
            data = this.brandInternalModelsAll.find(b => b.code === brandModelCode);
            this.formGroup.get(field).setValue(data.code);
          }
        });
      }
      if (vehicle != null && vehicle[field]) {
        this.formGroup.get(field).setValue(vehicle[field]);
        if (vehicle[field] !== '' && vehicle[field] !== value && isSearch) {
          this.plateSearchFoundFields.push(field);
        } else if (this.violation.vehicle[field] != null &&
          (this.violation.vehicle[field] === '')) {
          delete this.violation.vehicle[field];
        }
      } else if (vehicle == null || vehicle[`${item}Id`] == null) {
        this.formGroup.get(field).setValue('');
      } else {
        const data = await this[`${item}Service`].getById(vehicle[`${item}Id`]);
        if (data != null) {
          this.formGroup.get(field).setValue(data.code);
          if (isSearch) {
            this.plateSearchFoundFields.push(field);
          }
        }
      }
    });
    if (vehicle != null) {
      let city;
      if (vehicle.cityId != null) {
        city = this.cities && this.cities.find(c => c.id === vehicle.cityId);
      } else {
        city = this.cities && this.cities.find(c =>
          c.regionId === this.regionId && c.code === vehicle.cityCode);
      }
      if (city == null) {
        if (vehicle.cityCode != null && vehicle.cityCode !== '') {
          await this.cityService.list({ regionId: this.regionId, code: vehicle.cityCode })
            .then(async res => {
              city = res.result[0];
              await this.loadCities(city.stateId);
              await this.setCoutry(city, isSearch);
            });
        }
      } else {
        await this.loadCities(city.stateId);
        await this.setCoutry(city, isSearch);
      }
    }
  }

  async setCoutry(city, isSearch) {
    this.formGroup.get('cityCode').setValue(city.code);
    this.formGroup.get('regionId').setValue(city.regionId);
    this.formGroup.get('stateId').setValue(city.stateId);
    if (isSearch) {
      this.plateSearchFoundFields.push('cityCode');
      this.plateSearchFoundFields.push('regionId');
      this.plateSearchFoundFields.push('stateId');
    }
  }

  async loadVehicle(plateTyping: string, step) {
    const stepValid = this.urlSegment;
    const isNotSepCurrent = this.urlSegment !== step;
    if (plateTyping == null || plateTyping === '' || isNotSepCurrent) {
      this.resetForm();
      return;
    }

    if ((this.hasSearch || this.hasWhiteList) &&
      plateTyping   &&
      plateTyping !== '' &&
      this.formGroup.get('plate').valid) {
      if (this.placeDebouncer != null) {
        clearTimeout(this.placeDebouncer);
      }

      this.placeDebouncer = setTimeout(async () => {
        this.plateSearchFoundFields = [];
        const params = {
          contractId: this.violation.contractId,
          plate: plateTyping,
          regulationId: this.violation.regulationId,
          date: this.violation.date
        };
        this.violation = this.removeVehicleFields(this.violation, ['length', 'profile', 'size', 'plateOCR']);
        const isSearchPlateVerifyStep = this.violation.vehicle.plate !== plateTyping;
        if (isSearchPlateVerifyStep) {
          this.plateSearchPromise = this.findPlate(params);
        }
      }, 300);
    }
  }

  async findPlate(params: { contractId: string; plate: string; regulationId: string; date: Date }) {
    this.isSearchPlate = true;
    return this.plateService.searchPlate(params)
      .then((searchVehicle) => {
        this.cetModels = false;
        this.searchVehicle = searchVehicle;
        if (searchVehicle?.vehicle?.plate == null && this.preProcessSettings.changeBrandModelForClientBrand) {
          this.brandModelInternal();
        }
        if (searchVehicle?.vehicle?.brandModelId) {
          this.brandModelService.getById(searchVehicle.vehicle.brandModelId).then(brandModel => {
            this.brandModels = [brandModel];
            this.currentBrandModelCode = brandModel;
          });
        } else if (!searchVehicle?.vehicle?.brandModelId && searchVehicle?.vehicle?.brandModel) {
          const brandModel = {
            id: searchVehicle.vehicle.brandModelCode,
            code: searchVehicle.vehicle.brandModelCode,
            description: searchVehicle.vehicle.brandModel
          };
          this.brandModels = [brandModel];
          this.currentBrandModelCode = brandModel;
        } else if (searchVehicle?.vehicle?.brandModel === undefined) {
          this.brandModels = [];
          this.currentBrandModelCode = {};
        }
        this.formGroup.get('reasonCode').patchValue('');
        if(searchVehicle?.vehicle?.plate === undefined && !this.getValue(this.preProcessSettings, 'allowInputNotFoundVehicle')) {
          const plateNotFoundReasonId = this.getValue(this.preProcessSettings, 'plateNotFoundReasonId');
          const plateNotFoundReasonCode = this.reasons.filter(a => a.id === plateNotFoundReasonId).shift();
          this.formGroup.get('reasonCode').patchValue(plateNotFoundReasonCode.code);
        }
        this.setFormPlate(searchVehicle?.vehicle, true);
        if (searchVehicle.vehicle == null) {
          this.violation = this.removeVehicleFields(this.violation);

          if (this.preProcessSettings != null && this.preProcessSettings.allowInputNotFoundVehicle !== true) {
            this.plateFound = false;
            this.alertService.show(new AlertItem(
              'PlateNotFoundNotAuthorized', AlertType.warning, false, 1000));
          } else {
            this.alertService.show(new AlertItem(
              'PlateNotFoundAuthorized', AlertType.warning, false, 1000));
          }
          this.resetForm();
          return;
        }
        this.plateFound = true;
        const now = moment(new Date());
        if (searchVehicle.vehicleWhiteList) {
          if (this.whitelistReasonCode != null) {
            this.formGroup.get('reasonCode').setValue(this.whitelistReasonCode || '');
          }
          this.alertService.show(new AlertItem(
            'PlateFoundWhitelist',
            AlertType.warning, false, 5000));
          return;
        }
      })
      .catch(err => {
        if (this.preProcessSettings.changeBrandModelForClientBrand) {
          this.brandModelInternal();
        }
        return;
      }).finally(() => {
        this.isSearchPlate = false;
      });
  }

  async getBrandInternal() {
    const changeBrandModelForClientBrand = this.getValue(this.preProcessSettings, 'changeBrandModelForClientBrand');
    if (changeBrandModelForClientBrand) {
      this.cetModels = true;
      if (!this.brandInternalModels || this.brandInternalModels.length === 0) {
        await this.brandInternalService.list({ contractId: this.contractId }).then(res => {
          const result = res.result;
          result.map(brand => {
            brand.description = `${brand.code} - ${brand.description}`;
          });
          this.brandInternalModels = result;
          this.brandInternalModelsAll = result;
        });
      }
    }
  }

  getValue(model, attr, value = '') {
    let arr = [];
    if (typeof attr === 'string') {
      arr = attr.split('.');
    } else {
      arr = attr;
    }
    if (arr.length > 0) {
      if (model[arr[0]] == null) {
        return value;
      } else {
        return this.getValue(model[arr[0]], arr.slice(1), value);
      }
    } else {
      return model;
    }
  }

  async loadCities(stateId) {
    if (stateId == null || stateId === '') {
      this.showingCities = [];
      return;
    }
    this.showingCities = this.cities.filter(c => c.stateId === stateId);

    if (this.showingCities != null && this.showingCities.length === 0) {
      this.showingCities = await this.cityService.getAll({ regionId: this.formGroup.get('regionId').value, stateId });
      this.cities = this.cities.concat(this.showingCities);
    }
    this.showingCities = _.sortBy(this.showingCities, 'name');
    this.stateTextCities = true;
  }

  async loadCitiesByCode(code) {
    if (code == null || code === '') {
      this.showingCities = [];
      return;
    }
    this.showingCities = this.cities.filter(c => c.code === code);

    if (this.showingCities != null && this.showingCities.length === 0) {
      this.showingCities = await this.cityService.getAll({ regionId: this.formGroup.get('regionId').value, code });
      this.cities = this.cities.concat(this.showingCities);
    }
    this.showingCities = _.sortBy(this.showingCities, 'name');
    this.stateTextCities = true;
    return this.showingCities.shift();
  }

  setInactivity() {
    if (this.timeoutId != null) {
      clearTimeout(this.timeoutId);
    }
    if (this.expired === true) {
      return;
    }
    this.timeoutId = setTimeout(() => {
      this.expired = true;
      this.modalService.show(new MessageModal('Sessão expirada',
        'Sua sessão foi encerrada automaticamente por inatividade. ' +
        'Você será redirecionado para a tela de Pré-Processamento. ' +
        'Para continuar processando as infrações é necessário acessar esta funcionalidade novamente', false))
        .finally(() => {
          this.unlock();
          setTimeout(() => {
            this.router.navigate(['/pre-process/']);
          }, 1000);
        });
    }, 900000);
  }

  openMessageDuplicateViolation(violation) {
    this.modalService.show(new ComponentModal(MessageDuplicateViolationModalComponent, violation))
      .then(data => {
      }).catch(err => { });
  }

  isChangesPermited() {
    if (this.violationProcessed) {
      this.changesPermited = false;
    } else {
      this.changesPermited = true;
    }
  }

  processStatus() {
    if (
      (this.stepGroup === 'validate' && !(this.violation.step === 'validate'))
    ) {
      this.formGroup.get('reasonCode').disable();
      this.violationProcessed = true;
    } else {
      this.formGroup.get('reasonCode').enable();
      this.violationProcessed = false;
    }
  }

  editEvent(key) {
  }

  resetForm() {
    this.formGroup.get('colorCode').setValue('');
    this.formGroup.get('year').setValue('');
    this.formGroup.get('brandModelCode').setValue('');
    this.formGroup.get('categoryCode').setValue('');
    this.formGroup.get('classificationCode').setValue('');
    this.formGroup.get('typeCode').setValue('');
    this.formGroup.get('cityCode').setValue('');
    this.formGroup.get('stateId').setValue('');
    this.formGroup.get('regionCode').setValue('');
    this.formGroup.get('regionId').setValue('');
    this.formGroup.get('displayBrandModelCode').setValue('');
  }

  checkPlate() {
    const plateControl = this.formGroup.get('plate').value;
    if (plateControl && plateControl.length === 7) {
      return this.formGroup.valid;
    }
    return false;
  }

  disabledReasonCodeButton(violation) {
    if (!this.checkPlate() && !this.formGroup.get('plate').value && this.formGroup.get('reasonCode').value) {
      return false;
    } else
    if (this.formGroup.get('plate').value !== '' && !this.checkPlate()) {
      return true;
    } else if (
      this.formGroup.get('plate').value !== '' && !this.checkPlate() ||
      !this.formGroup.get('reasonCode').value ||
      this.violationIdsProcessed.includes(violation.id) ||
      !this.checkPlate() && this.formGroup.get('plate').value !== '' && !this.formGroup.get('reasonCode').value
    ) {
      return true;
    }
  }

  openSearchBrandModels() {
    if (this.viewSearchBrandModels) {
      this.viewSearchBrandModels = false;
    } else {
      this.viewSearchBrandModels = true;
    }
  }

  searchBrandModel(event) {
    if (event.target.value !== '') {
      this.brandModelService.list({
        limit: '500',
        regionId: this.regionId,
        'description[like]': `[%${event.target.value.toUpperCase()}%]`
      }).then(res => {
        this.brandModels = res.result;
      });
    }
  }

  brandModelInternal() {
    if (this.preProcessSettings != null && this.preProcessSettings.allowInputNotFoundVehicle !== true) {
      this.plateFound = false;
      this.resetForm();
      if (this.preProcessSettings.plateNotFoundReasonId != null) {
        this.formGroup.get('reasonCode').setValue(this.plateNotFoundReasonCode || '');
      }
      this.alertService.show(new AlertItem(
        'PlateNotFoundNotAuthorized', AlertType.warning, false, 1000));
    } else {
      this.resetForm();
      this.alertService.show(new AlertItem('PlateNotFoundAuthorized', AlertType.warning, false, 1000));
      if (this.requiredFields.includes('brandModelCode')) {
        this.brandModelService.list({ regionId: this.regionId, limit: '500' }).then(res => {
          this.brandModels = res.result;
        });
      }
    }
    this.getBrandInternal();
  }
}
