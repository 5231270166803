import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageContractComponent } from './manage-contract/manage-contract.component';
import { DashboardComponent } from 'src/app/core/layouts/dashboard/dashboard.component';
import { AdministrativeDashboardComponent } from './dashboard/dashboard.component';
import { ManageAppraisalComponent } from './manage-appraisal/manage-appraisal.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: 'manage-contract',
        component: ManageContractComponent,
      },
      { path: '', component: AdministrativeDashboardComponent },
      { path: 'manage-contract', component: ManageContractComponent },
      { path: 'manage-appraisal', component: ManageAppraisalComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrativeRoutingModule { }
