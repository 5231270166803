import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministrativeRoutingModule } from './administrative-routing.module';
import { ManageContractComponent } from './manage-contract/manage-contract.component';
import { ManageAppraisalComponent } from './manage-appraisal/manage-appraisal.component';
import { AdministrativeDashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    ManageContractComponent,
    ManageAppraisalComponent,
    AdministrativeDashboardComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    MatCheckboxModule,
    MatRadioModule,
    FormsModule,
    DragDropModule,
    ReactiveFormsModule,
    AdministrativeRoutingModule
  ]
})
export class AdministrativeModule { }
