import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Company, User, UserGroup, MessageModal } from 'src/app/core/models';
import { AlertItem, AlertType } from 'src/app/core/models/AlertItem';
import {
  AlertService, CompanyService, ModalService,
  UserGroupService, UserService, StorageService, StorageKey, ContractService, ContractGlobalService
} from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { updateLocalStorage } from 'src/app/core/utils/updateLocalStorage';
import { AvatarService } from 'src/app/core/services/avatar.service';
import { validateCpf } from 'src/app/core/utils/validateCpf';
import { ModalContent } from 'src/app/core/interface';
import { ClosedModalError } from 'src/app/core/errors';


@Component({
  selector: 'app-users-edit-modal',
  templateUrl: './users-edit-modal.component.html',
  styleUrls: ['./users-edit-modal.component.sass']
})
export class UsersEditModalComponent extends BaseModal implements OnInit, ModalContent {
  @Input() initialState;
  public userEditForm: FormGroup;
  public userGroups: Array<UserGroup>;
  public userGroupPrettyName: { [params: string]: any } = {};
  public companies: Array<Company> = [];
  public userGroupsDropdownSettings = {};
  public companiesDropdownSettings = {};
  public isValid = false;
  public user: any = {};
  public currentUser: User;
  public promise: Promise<any>;
  public extraData: any;
  public documentFiles = [];
  public uploadFiles = [];
  public userCreator = null;

  constructor(
    private formBuilder: FormBuilder,
    private userGroupService: UserGroupService,
    private companyService: CompanyService,
    private userService: UserService,
    private alertService: AlertService,
    private storageService: StorageService,
    private contractService: ContractService,
    private contractsGlobalService: ContractGlobalService,
    modalService: ModalService,
    private avatarService: AvatarService
  ) {
    super(modalService);
  }

  ngOnInit() {
    if (this.initialState != null) {
      this.user = this.initialState;
      if (this.user.extraData) {
        this.extraData = this.user.extraData;
      }
      if (this.user.files) {
        this.user.files.forEach(item => {
          if (this.uploadFiles.length < 10) {
            this.uploadFiles.push(item);
          }
        });
      }
    }
    this.editForm();
    this.currentUser = this.storageService.get(StorageKey.currentUser);

    this.companyService.getAll().then(list => {
      this.companies = list;
      this.loadUserGroups(this.user.companyId);
    });
  }

  get lastState() {
    const state = {};
    const keys = Object.keys(this.userEditForm.controls);
    for (const key of keys) {
      state[key] = this.userEditForm.get(key).value;
    }
    return state;
  }

  editForm() {
    this.userEditForm = this.formBuilder.group({
      name: [this.user.name || ''],
      cpf: [this.user.cpf || ''],
      email: [this.user.email || '', [Validators.required, Validators.email]],
      companyId: [this.user.companyId || '', [Validators.required]],
      avatar: [this.user.avatar || ''],
      superUser: [this.user.superUser || false],
      role: [this.user.role || ''],
      registryNumber: [this.user.registryNumber || ''],
      groupIds: [this.user.groupIds || []],
      extraData: [this.extraData?.observation || ''],
      files: ['']
    });
    if (this.user.verified) {
      this.userEditForm.get('name').setValidators([Validators.required, Validators.pattern('^([A-Za-zÀ-ÿ.]+( )?)+$')]);
    }
    this.userEditForm.valueChanges.subscribe(() => {
      this.isValid = this.userEditForm.valid;
    });
  }

  public async onAllSubmited() {
    if (this.promise != null) {
      return this.promise;
    }
    const cpf = this.userEditForm.get('cpf').value;
    if(cpf && !validateCpf(cpf)){
      return this.alertService.show(new AlertItem('InvalidCpf', AlertType.danger));
    }
    if (this.extraData == null) {
      this.extraData = {};
    }
    this.extraData.observation = this.userEditForm.get('extraData').value;
    let files = this.documentFiles;
    if (this.uploadFiles.length < 10) {
      files = [...(this.userEditForm.get('files').value || []).map(f => f.isNew === true ? f.detail : f.data)];
    }
    const user = {
      cpf: cpf.replace(/\D+/g, ''),
      id: this.user.id,
      name: this.userEditForm.get('name').value,
      email: this.userEditForm.get('email').value,
      registryNumber: this.userEditForm.get('registryNumber').value,
      companyId: this.userEditForm.get('companyId').value,
      role: this.userEditForm.get('role').value,
      avatar: this.userEditForm.get('avatar').value,
      superUser: this.userEditForm.get('superUser').value,
      groupIds: this.userEditForm.get('groupIds').value,
      verified: this.user.verified ? true : false,
      modifiedAt: this.user.modifiedAt,
      enabled: this.user.enabled,
      extraData: this.extraData,
      files
    };

    if (user.name == null) {
      delete user.name;
    }
    this.promise = this.userService.update(User.create(user), false).then(res => {
      this.user = res;
      if (this.user.id === this.currentUser.id) {
        this.userGroupService.getAll().then(data => {
          updateLocalStorage(this.user, this.storageService, data);
          this.avatarService.updateMyProfile(res);
        });
      }
      this.alertService.show(new AlertItem('UserSaved', AlertType.success));
      return res;
    }).catch(error => {
      this.alertService.show(new AlertItem('UserSaveError', AlertType.danger));
      throw error;
    }).finally(() => {
      this.promise = null;
    });
    return this.promise;
  }

  onClose() {
    this.userEditForm.reset();
    this.modalService.close();
  }

  loadUserGroups(companyId: string) {
    if (this.currentUser.superUser) {
      const filterObj = this.getFilter(companyId);
      if (filterObj == null) {
        return;
      }
      this.userGroupService.getAll(filterObj).then(userGroups => {
        if(this.contractsGlobalService.contracts?.length > 0){
          userGroups = userGroups.filter(
              ug => this.contractsGlobalService.contracts.some(ct => ct === ug.contractId)
          );
        }
        this.userGroups = userGroups.filter(usrGrp => usrGrp.prettyNames);
        const userGroupsWithoutPrettyNames = userGroups.filter(usrGrp => usrGrp.prettyNames == null);

        userGroupsWithoutPrettyNames.forEach((userGroup: any) => {
          this.contractService.getById(userGroup.contractId).then(contract => {
            const prettyNames = userGroup.name + ` (${contract.code} - ${contract.name})`;
            userGroup.prettyNames = prettyNames;
            this.userGroups.push(userGroup);
          });
        });
      });
    } else {
      this.user.groupIds.forEach(groupId => {
        this.userGroupService.getById(groupId).then(async userGroup => {
          if (this.userGroupPrettyName[userGroup.id] == null) {
            const contract = await this.contractService.getById(userGroup.contractId);
            this.userGroupPrettyName[userGroup.id] = userGroup.name + ` (${contract.code} - ${contract.name})`;
          }
        });
      });
    }
  }

  getFilter(companyId: string) {
    if (companyId == null) {
      return null;
    }
    const company = this.companies.find(c => c.id === companyId);
    if (company == null) {
      return null;
    }
    const contractIds = company.contractIds;
    return {
      'contractId[in]': `[${contractIds.join(',')}]`
    };
  }

  loadFiles() {
    const files = [];
    this.documentFiles.map(file => {
      files.push(file.data);
    });
    return files;
  }

  getOutputFiles(event) {
    this.documentFiles = event;
  }

  loadFileDetails(url) {
    const name = this.getFileName(url);
    const type = this.getFileType(name);
    const data = {
      detail: {
        type,
        name
      },
      data: url
    };
    return data;
  }

  getFileName(src) {
    const decoded = decodeURIComponent(src || '');
    const result = decoded.match(/(metrologic#[0-9]+\/)([^\?]+)/) || '';
    return result !== '' ? result[2] : result;
  }

  getFileType(name) {
    let type = name.split('.').pop();
    switch (type) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        type = `image/${type}`;
        break;
      default:
        return type;
    }
    return type;
  }
  openFile(url) {
    const win = window.open(url, '_blank');
    win.focus();
  }
  async deleteFile(file, idx) {
    this.userService.removeImage(this.user.id, file)
    .then(data => {
      this.uploadFiles = this.uploadFiles.filter(a => a !== file);
      document.getElementById(`file_${idx}`).remove();
      this.alertService.show(new AlertItem('UserSaved', AlertType.success));
    })
    .catch((err) => {
      console.error(err);
      this.alertService.show(new AlertItem('UserSaveError', AlertType.danger));
    });
  }

}
