import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AlertItem, AlertType, ComponentModal, FileType, Violation } from 'src/app/core/models';
import {
  AlertService, ModalService, PreProcessSettingsService, ProcessViolationService,
  StorageKey, StorageService
} from 'src/app/core/services';
import { RegulationService } from 'src/app/core/services/regulation.service';
import { ViolationService } from 'src/app/core/services/violation.service';
import { ViewFileModalComponent } from 'src/app/shared/view-file-modal/view-file-modal.component';
import { ReasonModalComponent } from '../reason-modal/reason-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ViolationFilter } from 'src/app/core/interface';

@Component({
  selector: 'app-triage',
  templateUrl: './triage.component.html',
  styleUrls: ['./triage.component.sass']
})
export class TriageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('container', { static: true, read: ElementRef }) container: ElementRef;
  public counter = 0;
  public contractId: string;
  public preProcessSettings: any;
  public fileTypes = Object.values(FileType);
  public fileTypesI18n = [];
  public get fileType() {
    return this._fileTipe;
  }
  public set fileType(value) {
    this._fileTipe = value;
    this.setMatrix();
  }
  public violationIds = [];
  public regulations = [];
  public get regulationId() {
    return this._regulationId || null;
  }
  public set regulationId(value) {
    if (this._regulationId !== value) {
      this._regulationId = value;
      this.setViolations();
    }
  }
  public violations: Array<Violation> = [];
  public violationMatrix: Array<Array<{
    item: Violation;
    isChecked: boolean;
    isDisabled: boolean;
  }>> = [];
  public isLoading = false;
  public get hasSelected() {
    if (this._hasSelected != null) {
      return this._hasSelected;
    }
    for (const row of this.violationMatrix) {
      for (const violation of row) {
        if (violation.isChecked) {
          this._hasSelected = true;
          return true;
        }
      }
    }
    this._hasSelected = false;
    return false;
  }
  public regulationForm: FormGroup;
  public isStartNewQuery = false;
  public filters: ViolationFilter;
  public disabledPlateZoom: boolean;
  public loadingPromises = false;
  public violationSelectedIds = [];
  private _fileTipe = 'zoom';
  private _regulationId;
  private _hasSelected;
  private aspectRatio = [3, 3];
  private targetNumber = 35;
  private x = 0;
  private y = 0;
  private loadingPromise;

  constructor(
    private preProcessService: PreProcessSettingsService,
    private violationService: ViolationService,
    private regulationService: RegulationService,
    private modalService: ModalService,
    private alertService: AlertService,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private processViolationService: ProcessViolationService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    this.processViolationService.getFilterProcessViolation()
      .pipe()
      .subscribe((result: any) => this.filters = result);
    if (this.filters == null) {
      this.router.navigate([`../../../pre-process`], { relativeTo: this.activatedRoute });
    }
    this.createForm();
    this.storageService.set(StorageKey.filterTarget, 70);
    this.targetNumber = this.storageService.get(StorageKey.filterTarget);
    this.contractId = this.activatedRoute.snapshot.params.contractId;
    this.preProcessService.getById(this.contractId).then((preProcess) => {
      const preProcessRegulationsIds = preProcess?.violationSteps?.triage?.regulationIds;
      if (preProcessRegulationsIds) {
        for (const id of preProcessRegulationsIds) {
          this.regulationService.getById(id).then(result => {
            this.regulations.push(result);
            this.regulationForm.patchValue({
              regulationIds: preProcessRegulationsIds
            });
          });
        }
      }
    });
    if (localStorage.getItem('disabledPlateZoom')) {
      this.disabledPlateZoom = true;
    }
    window.addEventListener('keydown', event => this.keyEvent(event));
  }

  createForm(regulationIds = null) {
    this.regulationForm = this.formBuilder.group({
      regulationIds: [regulationIds || '']
    });

    this.regulationForm.get('regulationIds').valueChanges.subscribe(data => {
      this.isStartNewQuery = true;
      this.regulationId = data;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setContainerHeight();
    }, 0);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setContainerHeight();
  }

  setViolations() {
    this.violations = [];
    this.counter = 0;
    const size = this.setMatrixSize(this.targetNumber);
    this.targetNumber = size[0] * size[1];
    this.loadItemsLocked().then(pagination => {
      if (pagination != null && pagination.result.length > 0) {
        this.setMatrix();
      } else {
        this.loadItems().then(pag => {
          if (pag != null && pag.result.length > 0) {
            this.setMatrix();
          }
        }).catch(err => { console.log(err.status); });
      }
    }).catch(err => { console.log(err.status); });
  }

  loadItemsLocked() {
    let params: any = {};
    if (this.loadingPromise == null) {
      this.isLoading = true;
      params = {
        limit: '' + this.targetNumber,
        contractId: this.contractId,
        stepGroup: 'triage',
        lock: 'true',
        ...this.getQueryParams()
      };

      this.loadingPromise = this.violationService.getLockedViolationsByMe(null, params).then(pagination => {
        this.loadingPromise = null;
        this.isLoading = false;
        if (this.isStartNewQuery) {
          this.isStartNewQuery = false;
          this.violations = pagination.result;
          this.violationIds = [];
          this.violationMatrix = [];
        } else {
          for (const violation of pagination.result) {
            if (this.violations.every(v => v.id !== violation.id)) {
              this.violations.push(violation);
            }
          }
        }
        if (pagination.result.length > 0 && this.violations.length < this.targetNumber * 2) {
          this.loadItems();
        }
        const violationIds = this.violations.map(v => v.id);
        violationIds.forEach(v => {
          if (!this.violationIds.includes(v)) {
            this.violationIds.push(v);
          }
        });
        return pagination;
      }).catch(err => {
        if (err.status === 504) {
          this.loadingPromise = null;
          this.isLoading = false;
          this.loadItemsLocked();
        }
        this.isLoading = false;
        this.loadingPromise = null;
        throw err;
      });
    }
    return this.loadingPromise;
  }

  loadItems() {
    if (this.loadingPromise == null) {
      this.counter = 0;
      this.isLoading = true;
      const params = {
        limit: '' + this.targetNumber,
        contractId: this.contractId,
        stepGroup: 'triage',
        lock: 'true',
        ...this.getQueryParams()
      };
      this.loadingPromise = this.violationService.list(params).then(pagination => {
        this.loadingPromise = null;
        this.isLoading = false;
        for (const violation of pagination.result) {
          if (this.violations.every(v => v.id !== violation.id)) {
            this.violations.push(violation);
          }
        }
        if (pagination.result.length > 0 && this.violations.length < this.targetNumber * 2) {
          this.loadItems();
        }
        const violationIds = this.violations.map(v => v.id);
        violationIds.forEach(v => {
          if (!this.violationIds.includes(v)) {
            this.violationIds.push(v);
          }
        });
        return pagination;
      }).catch(err => {
        if (err.status === 504) {
          this.loadingPromise = null;
          this.isLoading = false;
          this.loadItems();
        }
        this.loadingPromise = null;
        this.isLoading = false;
        throw err;
      });
    }
    return this.loadingPromise;
  }

  setContainerHeight() {
    const div = this.container.nativeElement as HTMLDivElement;
    const height = window.innerHeight - div.offsetTop - 71;
    div.style.height = height + 'px';
    this.setMatrix();
  }

  getQueryParams() {
    const queryParams: any = {
      contractId: this.contractId
    };
    if (this.filters.hasOwnProperty('regulationsIds') && this.filters.regulationsIds) {
      queryParams['regulationId[in]'] = `[${this.filters.regulationsIds.join()}]`;
    }
    if (this.filters.hasOwnProperty('equipmentsIds') && this.filters.equipmentsIds) {
      queryParams['equipment.id[in]'] = `[${this.filters.equipmentsIds}]`;
    }
    if (this.filters.hasOwnProperty('laneIds') && this.filters.laneIds) {
      queryParams['laneCode[in]'] = `[${this.filters.laneIds.join()}]`;
    }
    if (this.filters.hasOwnProperty('startDate') && this.filters.startDate) {
      queryParams['date[gte]'] = this.filters.startDate;
    }
    if (this.filters.hasOwnProperty('endDate') && this.filters.endDate) {
      queryParams['date[lte]'] = this.filters.endDate;
    }

    return queryParams;
  }

  setMatrix() {
    this.counter = 0;
    const size = this.setMatrixSize(this.targetNumber);
    this.x = size[0];
    this.y = size[1];
    const n = this.x * this.y;
    this.targetNumber = n;
    const violations = this.violations.slice(0, n);
    if (violations.length < n && !this.isLoading) {
      this.loadItemsLocked().then(pagination => {
        if (pagination !== null && pagination.result.length > 0) {
          this.setMatrix();
        } else {
          this.loadItems().then(pag => {
            if (pag != null && pag.result.length > 0) {
              this.setMatrix();
            }
          }).catch(err => { console.log(err.status); });
        }
      }).catch(err => { console.log(err.status); });
    }

    this.violationMatrix = [];
    let row = [];
    for (const violation of violations) {
      row.push({
        item: violation,
        isChecked: false,
        file: (violation.files.find(f => f.type === this.fileType) || { urlCurrent: undefined }).urlCurrent
      });
      if (row.length === this.x) {
        this.violationMatrix.push(row);
        row = [];
      }
    }
    if (row.length > 0) {
      this.violationMatrix.push(row);
    }
    this._hasSelected = false;
  }

  setMatrixSize(target) {
    const div = this.container.nativeElement as HTMLDivElement;
    const height = window.innerHeight + div.offsetTop + 650;
    const width = div.clientWidth;
    let multi = width / height;
    multi = multi * this.aspectRatio[1] / this.aspectRatio[0];
    let y = Math.sqrt(target / multi);
    y = Math.max(1, Math.round(y));
    let x = target / y;
    x = Math.max(1, Math.round(x));
    while (x > 7) {
      x--;
    }
    return [x, y];
  }

  zoomIn() {
    let size = Math.max((this.x - 1) * this.y, this.x * (this.y - 1), 1);
    while (size > 1) {
      const newSize = this.setMatrixSize(size);
      if (newSize[0] !== this.x || newSize[1] !== this.y) {
        break;
      }
      size--;
    }
    this.targetNumber = size;
    this.storageService.set(StorageKey.filterTarget, size);
    this.setMatrix();
  }

  zoomOut() {
    let size = Math.min((this.x + 1) * this.y, this.x * (this.y + 1), 200);
    while (size < 200) {
      const newSize = this.setMatrixSize(size);
      if (newSize[0] !== this.x || newSize[1] !== this.y) {
        break;
      }
      size++;
    }
    this.targetNumber = size;
    this.storageService.set(StorageKey.filterTarget, size);
    this.setMatrix();
  }

  selectViolation(item) {
    if (!item.isDisabled) {
      if (!this.violationSelectedIds.includes(item.item.id)) {
        this.violationSelectedIds.push(item.item.id);
      } else {
        this.violationSelectedIds = this.violationSelectedIds.filter(v => v !== item.item.id);
      }
      item.isChecked = !item.isChecked;
      this._hasSelected = item.isChecked === true ? true : undefined;

      this.counter = item.isChecked ? this.counter + 1 : this.counter - 1;
    }
  }

  openImgModal(violationItem, event = null) {
    if (event != null) {
      event.stopPropagation();
    }
    this.modalService.show(new ComponentModal(ViewFileModalComponent,
      { files: violationItem.item.files.map(f => f.urlCurrent), shownFile: violationItem.file })).catch(() => { });
  }

  openReasonModal() {
    if (this._hasSelected === false) {
      return;
    }
    const paramsReason = {
      contractId: this.contractId,
    };
    if (this.regulationId != null) {
      paramsReason['regulationIds[or,contains]'] = this.regulationId;
      paramsReason['regulationIds[or,null]'] = 'null';
    }
    paramsReason['screen[contains]'] = 'triage';

    this.modalService.show(new ComponentModal(ReasonModalComponent, paramsReason)).then(m => {
      const modal = m as ComponentModal;
      const reasonModal = modal.component.instance as ReasonModalComponent;
      const reason = reasonModal.reason;
      const violationsSelected = this.violationMatrix.reduce((acc, cur) => {
        for (const v of cur) {
          if (v.isChecked && !v.isDisabled) {
            acc.push(v);
          }
        }
        return acc;
      }, []);

      const promises = [];
      for (const violationSelected of violationsSelected) {
        const violation = violationSelected.item;
        violation.reason = {
          code: reason.code,
          name: reason.name,
          type: reason.type
        };
        promises.push(this.violationService.refuse(violation).then(() => {
          const index = this.violations.findIndex(v => v.id === violation.id);
          if (index >= 0) {
            this.violations.splice(index, 1);
          }
          violationSelected.isDisabled = true;
          violationSelected.isChecked = false;
        }));
      }
      this.loadingPromises = true;
      Promise.all(promises)
        .then(() => {
          this.loadingPromises = false;
          this.onSubmit();
        })
        .catch(err => {
          this.alertService.show(new AlertItem('ViolationInvalidationError', AlertType.danger));
          this.loadingPromises = false;
        });
      this._hasSelected = false;
    }).catch(() => { });
  }

  onSubmit() {
    this.loadingPromises = true;
    const violations = this.violationMatrix.reduce((acc, cur) => {
      for (const v of cur) {
        if (!v.isChecked) {
          acc.push(v);
        }
      }
      return acc;
    }, []);
    const promises = [];
    for (const item of violations) {
      const violation = item.item;
      if (!this.violationSelectedIds.includes(violation.id)) {
        promises.push(this.violationService.accept(violation).then(() => {
          const index = this.violations.findIndex(v => v.id === violation.id);
          if (index >= 0) {
            this.violations.splice(index, 1);
          }
        }));
      }
    }
    Promise.all(promises)
      .then(() => {
        this.setMatrix();
        this.alertService.show(new AlertItem('ViolationSaved', AlertType.success));
        this.loadingPromises = false;
      })
      .catch(err => {
        this.alertService.show(new AlertItem('ViolationValidationError', AlertType.danger));
        this.loadingPromises = false;
      });
    this.violations.splice(0, this.targetNumber);
  }

  plateZoom() {
    if (localStorage.getItem('disabledPlateZoom')) {
      this.disabledPlateZoom = false;
      localStorage.removeItem('disabledPlateZoom');
      document.getElementById('img-magnifier-glass').style.display = 'block';
    } else {
      this.disabledPlateZoom = true;
      localStorage.setItem('disabledPlateZoom', 'true');
      document.getElementById('img-magnifier-glass').style.display = 'none';
    }
  }

  keyEvent(event) {
    const isCtrl = event.ctrlKey;
    switch (event.key) {
      case 'a':
        event.preventDefault();
        if (isCtrl) {
          this.selectAll();
        }
        break;
    }
  }

  selectAll() {
    this.violationSelectedIds = [];
    this.violationMatrix.map(violationMatrix => {
      violationMatrix.map(item => {
        if (item.isChecked) {
          item.isChecked = false;
          this.counter--;
          this.violationSelectedIds = this.violationSelectedIds.filter(x => x !== item.item.id);
        } else {
          item.isChecked = true;
          this.counter++;
          this.violationSelectedIds.push(item.item.id);
        }
      });
    });
    if (this.counter > 0) {
      this._hasSelected = true;
    } else {
      this._hasSelected = false;
    }
  }

  ngOnDestroy(): void {
    const user = this.storageService.get(StorageKey.currentUser);
    if (user == null || user.id == null) {
      return;
    }
    const userId = user.id;
    this.violationService.unlock(userId, this.violationIds);
    window.removeEventListener('keydown', event => this.keyEvent(event));
  }
}
