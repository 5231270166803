import { Component, OnInit } from '@angular/core';
import { ModalContent } from 'src/app/core/interface';
import { ModalService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-lot-step-view',
  templateUrl: './lot-step-view.component.html',
  styleUrls: ['./lot-step-view.component.sass']
})
export class LotStepViewComponent  extends BaseModal implements OnInit, ModalContent {
  public isValid = false;
  public lotSteps: any = {};
  public columns = [];
  public columnsI18n = [];
  public tableColumns = [];

  constructor(
    modalService: ModalService
  ) {
    super(modalService);
   }

  ngOnInit() {
    if (this.initialState != null) {
      this.lotSteps = this.initialState.lotSteps || [];
      this.columns = this.initialState.columnStep || [];
      this.loadLotStep(this.lotSteps);
    }
  }

  onClose() {
    this.modalService.close();
  }

  public async onAllSubmited() {}

  loadLotStep(lotStep) {
    const tableColumns = [];
    const keys = Object.entries(lotStep);
    this.columns.map((step, index) => {
      tableColumns[index] = {};
      tableColumns[index].step = step.step;
      tableColumns[index].stepName = step.stepName;
      tableColumns[index].dateOldest = null;
      tableColumns[index].dateNewest = null;

      keys.map(key => {
        if (key[0] === step.step) {
          const dates = [];
          const keydates = Object.keys(key[1])[0].split('#');

          tableColumns[index].dateOldest = keydates[0];
          tableColumns[index].dateNewest = keydates[1];
        }
      });
    });

    this.tableColumns = tableColumns;
  }

  get lastState() {
    return {};
  }
}
