<div class="modal-dialog modal-dialog-centered modal-md">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n><strong>{{ data?.title }}</strong></h5>
      <button type="button" (click)="onClose()"
        class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body" *ngIf="!loading; else loadingBlock" #container>
      <hr class="mt-0">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="content-list">
              <p i18n><strong>Registro(s) selecionado(s)</strong></p>
              <div class="list">
                <div class="item" *ngFor="let item of list">
                  <p i18n>{{ item?.prettyNames || item?.prettyName || item?.pretty || item?.value || item?.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #loadingBlock>
      <div class="loading-page">
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
