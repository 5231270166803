import { environment } from 'src/environments/environment';
import { GrowthBook } from '@growthbook/growthbook';

export class Gb {

  public growthbook: GrowthBook;

  constructor() {
    this.growthbook = new GrowthBook({
      apiHost: environment.growthbookHost,
      clientKey: environment.growthbookKey,
      trackingCallback: (experiment, result) => {
        console.log('Usuário foi exposto a um experimento:', experiment.key, result);
      },
    });
    this.init();
  }

  async init() {
    await this.growthbook.init({
      streaming: true,
      cacheSettings: {
        backgroundSync: true,
        cacheKey: 'growthbook-TTL',
        maxEntries: 100,
        idleStreamInterval: 30000,
        staleTTL: 30000,
        maxAge: 30000,
        disableIdleStreams: false,
        disableCache: false,
      }
    });
  }

  isOn(feature: string) {
    return this.growthbook.isOn(feature);
  }

}

/*
  import a classe Gb para o componente

  import { Gb } from 'src/app/core/services/growthbook.service';

  Crie uma variável para instanciar a classe Gb

  public gb: Gb;

  dentro do método ngOnInit instancie a classe Gb

  async ngOnInit() {
      this.gb = new Gb();
  }

  para verificar uma flag use o método isOn

  para verificar uma flag no HTML use o ngIf

  <div *ngIf="gb.isOn('my-feature')">
    <p>Hello World!
  </div>

  para verificar uma flag no typescript use o if

  if (this.gb.isOn('my-feature')) {
    console.log("Feature enabled!");
  }

*/
