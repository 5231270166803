import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PreProcessSettings, Reason, VehicleRequiredFields } from 'src/app/core/models';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ReasonService } from 'src/app/core/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-config-audit',
  templateUrl: './config-audit.component.html',
  styleUrls: ['./config-audit.component.sass']
})
export class ConfigAuditComponent implements OnInit, OnDestroy {
  @Input() preProcessSettings: PreProcessSettings = new PreProcessSettings();
  @Output() nextStepCommand = new EventEmitter();
  @Output() isValid = new EventEmitter();
  @Output() outputValue = new EventEmitter();
  public form: FormGroup;
  public vehicleRequiredFields = Object.values(VehicleRequiredFields);
  public vehicleRequiredFieldsI18n: Array<{ id: string; value: string }> = [];
  public isAllRequiredFieldsSelected = false;
  public qualityLevel = 2.5;
  public isQualityLevel = 0;
  public reasons: Array<Reason>;
  public reasonsById: { [id: string]: Reason } = {};
  public reasonIds: Array<number> = [];
  public fieldsToLotRefused: Array<{
    id: string;
    violationStatus: string;
    reasonsEnabled: boolean;
    reasonIds: Array<number>;
    vehicleFields: Array<string>;
    boxOpen: boolean;
    vehicleFieldsEnabled: boolean;
  }> = [];

  constructor(
    private formBuilder: FormBuilder,
    private reasonService: ReasonService,
    private route: ActivatedRoute
  ) { }
  ngOnDestroy(): void { }


  ngOnInit() {
    this.createForm();
    this.getReasons();
    if (this.getValue(this.preProcessSettings, 'audit.fieldsToLotRefused')) {
      this.fieldsToLotRefused = this.getValue(this.preProcessSettings, 'audit.fieldsToLotRefused');
      this.fieldsToLotRefused.map(item => {
        item.id = this.uid();
        if (item.reasonIds && item.reasonIds.length > 0) {
          item.reasonsEnabled = true;
        } else {
          item.reasonsEnabled = false;
        }
        if (item.vehicleFields && item.vehicleFields.length > 0) {
          item.vehicleFieldsEnabled = true;
        } else {
          item.vehicleFieldsEnabled = false;
        }
      });
    }
    if (this.getValue(this.preProcessSettings, 'audit.enabledLotGenerationAudit') &&
      !this.getValue(this.preProcessSettings, 'audit.lotGeneration')) {
        this.isValid.emit(false);
    }
    document.addEventListener('autoLotConfigAuditValid', event => {
      const valid = this.getValue(event, 'detail');
      if (valid === 'true') {
        this.isValid.emit(true);
      } else {
        this.isValid.emit(false);
      }
    });
    if (this.getValue(this.preProcessSettings, 'audit.lotGeneration') &&
      !this.getValue(this.preProcessSettings, 'audit.enabledLotGenerationAudit')) {
      this.preProcessSettings.audit.lotGeneration.autoGeneration = [];
    }
  }

  uid() {
    const a = new Uint32Array(3);
    window.crypto.getRandomValues(a);
    return (performance.now().toString(36)+Array.from(a).map(b => b.toString(36)).join('')).replace(/\./g,'');
   };

  async getReasons() {
    await this.reasonService.getAll({
      contractId: this.route.snapshot.params.contractId,
      order: 'code',
      'screen[contains]': 'preProcessConfig',
      'enabled[bool]': 'true'
    }).then(res => {
      this.reasons = res.sort((a, b): any => Number(a.code) - Number(b.code));
      res.map(item => {
        this.reasonsById[item.id] = item;
        this.reasonIds.push(Number(item.id));
      });
    });
  }

  createForm() {
    const requiredFields = this.getValue(this.preProcessSettings, 'audit.requiredFieldsAudit');
    this.isQualityLevel = this.getValue(this.preProcessSettings, 'audit.acceptableQualityLevel') || this.qualityLevel;

    this.form = this.formBuilder.group({
      allowAuditInvalidateLot: [this.getValue(this.preProcessSettings, 'audit.allowAuditInvalidateLot')],
      acceptableQualityLevel: [this.getValue(this.preProcessSettings, 'audit.acceptableQualityLevel') || this.qualityLevel],
      violationStatus: [],
      reasonIds: [],
      vehicleFields: []
    });
    this.form.addControl('allRequiredFields', new FormControl('', []));
    for (const field of this.vehicleRequiredFields) {
      this.form.addControl(field, new FormControl(requiredFields.includes(field), []));
      this.form.get(field).valueChanges.subscribe(() => {
        this.isAllRequiredFieldsSelected = this.vehicleRequiredFields.every(f => this.form.get(f).value);
        this.form.get('allRequiredFields').setValue(this.isAllRequiredFieldsSelected);
      });
    }

    if (requiredFields.length > 0) {
      this.form.get('allRequiredFields').setValue(this.vehicleRequiredFields.length === requiredFields.length);
    }

    this.form.get('allRequiredFields').valueChanges.subscribe(value => {
      if (this.isAllRequiredFieldsSelected === value) {
        return;
      }
      for (const field of this.vehicleRequiredFields) {
        this.form.controls[field].setValue(value);
      }
    });
    this.form.valueChanges.subscribe(() => {
      this.isValid.emit(this.form.valid);
      if (this.form.valid === true) {
        this.outputValue.emit(this.getOutputValue());
      }
    });
  }

  getOutputValue() {
    const fieldsRefused = [];
    this.fieldsToLotRefused.map(fields => {
      let violationStatus = fields.violationStatus;
      let reasonIds = fields.reasonIds ;
      let vehicleFields = fields.vehicleFields;

      if (fields.violationStatus != null) {
        violationStatus = fields.violationStatus;
      }
      if (fields.reasonIds && fields.reasonIds.length > 0) {
        reasonIds = fields.reasonIds;
      }
      if (fields.vehicleFields && fields.vehicleFields.length > 0) {
        vehicleFields = fields.vehicleFields;
      }

      fieldsRefused.push({
        violationStatus,
        reasonIds,
        vehicleFields
      });
    });
    const enabledSamplingPlan = this.getValue(this.preProcessSettings, 'audit.enabledSamplingPlan');
    const audit: any = {
      allowAuditInvalidateLot: this.form.get('allowAuditInvalidateLot').value,
      fieldsToLotRefused: fieldsRefused,
      enabledSamplingPlan
    };
    if (enabledSamplingPlan === true) {
      audit.acceptableQualityLevel = Number(this.form.get('acceptableQualityLevel').value);
    }

    const config = {
      audit
    };
    return config;
  }

  getValue(model, attr, value = '') {
    let arr = [];
    if (typeof attr === 'string') {
      arr = attr.split('.');
    } else {
      arr = attr;
    }
    if (arr.length > 0) {
      if (model[arr[0]] == null) {
        return value;
      } else {
        return this.getValue(model[arr[0]], arr.slice(1), value);
      }
    } else {
      return model;
    }
  }

  addFieldsToLotRefused() {
    const data = {
      id: this.uid(),
      violationStatus: '',
      reasonsEnabled: false,
      reasonIds: [],
      vehicleFields: [],
      boxOpen: false,
      vehicleFieldsEnabled: false
    };
    this.fieldsToLotRefused.push(data);
    this.isValid.emit(false);
  }

  removeFieldsToLotRefused(setting: any) {
    this.fieldsToLotRefused = this.fieldsToLotRefused.filter(a => a.id !== setting?.id);
    this.isValid.emit(true);
    this.outputValue.emit(this.getOutputValue());
  }

  setFieldsToLotRefused(id: any, value: any, checked: boolean) {
    const data = this.fieldsToLotRefused.find(a => a.id === id);
    const index = this.fieldsToLotRefused.findIndex(a => a.id === id);
    if (data.violationStatus != null && value.violationStatus != null) {
      delete data.violationStatus;
    }
    if (value.violationStatus === true) {
      data.violationStatus = 'valid';
    }
    let enabledViolationStatus;
    let enabledReasonIds;
    let enabledvehicleFields;
    this.isValid.emit(true);
    this.outputValue.emit(this.getOutputValue());
    // *---- Reason
    if (value.reasonsEnabled != null) {
      this.fieldsToLotRefused[index].reasonsEnabled = value.reasonsEnabled;
      if (!value.reasonsEnabled) {
        this.fieldsToLotRefused[index].reasonIds = [];
        this.outputValue.emit(this.getOutputValue());
      }
    }
    if (value.reasonId && value.reasonId !== 'all' && !data.reasonIds.includes(value.reasonId)) {
      data.reasonIds.push(value.reasonId);
      this.isValid.emit(true);
      this.outputValue.emit(this.getOutputValue());
    } else if (value.reasonId && data.reasonIds.includes(value.reasonId)) {
      data.reasonIds = data.reasonIds.filter(a => a !== value.reasonId);
      if (data.reasonIds.length === 0) {
        this.isValid.emit(false);
      }
    }
    if (value.reasonId === '!all') {
      data.reasonIds = [];
      this.isValid.emit(false);
    }
    if (value.reasonId === 'all' && data.reasonIds !== this.reasonIds) {
      data.reasonIds = this.reasonIds;
      this.isValid.emit(true);
    } else if (value.reasonId === 'all' && data.reasonIds === this.reasonIds) {
      data.reasonIds = [];
      this.isValid.emit(false);
    }

    if (value.vehicleFieldsEnabled != null) {
      this.fieldsToLotRefused[index].vehicleFieldsEnabled = value.vehicleFieldsEnabled;
      if (!value.vehicleFieldsEnabled) {
        this.fieldsToLotRefused[index].vehicleFields = [];
      }
    }
    if (value.vehicleFields === '!all') {
      data.vehicleFields = [];
      this.isValid.emit(false);
    }
    if (value.vehicleFields === 'all' && data.vehicleFields !== this.vehicleRequiredFields) {
      data.vehicleFields = this.vehicleRequiredFields;
      this.isValid.emit(true);
    } else if (value.vehicleFields === 'all' && data.vehicleFields === this.vehicleRequiredFields) {
      data.vehicleFields = [];
      this.isValid.emit(false);
    }
    if (value.vehicleFields != null && !['all', '!all'].includes(value.vehicleFields)) {
      if (!this.fieldsToLotRefused[index]?.vehicleFields?.includes(value.vehicleFields)) {
        if (this.fieldsToLotRefused[index].vehicleFields == null) {
          this.fieldsToLotRefused[index].vehicleFields = [];
        }
        this.fieldsToLotRefused[index].vehicleFields.push(value.vehicleFields);
      } else if (checked === false &&
        this.fieldsToLotRefused[index].vehicleFields && this.fieldsToLotRefused[index].vehicleFields.includes(value.vehicleFields)) {
        this.fieldsToLotRefused[index].vehicleFields = this.fieldsToLotRefused[index].vehicleFields.filter(v => v !== value.vehicleFields);
      }
    }

    if (this.fieldsToLotRefused.length > 1) {
      const dataLotRefused = this.fieldsToLotRefused.find(a => a.id === id);
      enabledViolationStatus =
        this.fieldsToLotRefused.find(a => a.id !== id &&
          dataLotRefused.violationStatus && dataLotRefused.violationStatus === a.violationStatus);

      enabledReasonIds =
        this.fieldsToLotRefused.find(f => f.id !== id &&
          dataLotRefused.reasonIds && dataLotRefused.reasonIds.some(s => f.reasonIds && f.reasonIds.includes(s)));

      enabledvehicleFields =
        this.fieldsToLotRefused.find(f => f.id !== id &&
           dataLotRefused.vehicleFields &&
           dataLotRefused.vehicleFields.some(s => f.vehicleFields && f.vehicleFields.includes(s)));

      if (enabledViolationStatus || enabledReasonIds || enabledvehicleFields) {
        this.isValid.emit(false);
      }
    }
    this.fieldsToLotRefused.map(dataFields => {
      if ((dataFields.reasonIds == null || dataFields.reasonIds.length === 0) &&
      (dataFields.vehicleFields == null || dataFields.vehicleFields.length === 0) &&
      (dataFields.violationStatus == null || dataFields.violationStatus === '')) {
        this.isValid.emit(false);
      }
    });

    if ((value.vehicleFieldsEnabled &&
      data.vehicleFields && data.vehicleFields.length === 0) ||
      (value.reasonsEnabled && data.reasonIds && data.reasonIds.length === 0)) {
      this.isValid.emit(false);
    }
    this.outputValue.emit(this.getOutputValue());
  }

  selectBox(target: any) {
    target.boxOpen = target.boxOpen ? false : true;
  }

  setEnabledLotGenerationAudit(value) {
    if (!this.preProcessSettings.audit) {
      const audit: any = {
        enabledLotGenerationAudit: value,
        lotGeneration: {
          autoGeneration: []
        }
      };
      this.preProcessSettings.audit = audit;
    } else {
      this.preProcessSettings.audit.enabledLotGenerationAudit = value;
    }
    if (this.preProcessSettings.audit.lotGeneration == null) {
      this.preProcessSettings.audit.lotGeneration = {
        autoGeneration: []
      };
    }
    if (this.getValue(this.preProcessSettings, 'audit.lotGeneration.autoGeneration').length <= 0) {
      this.isValid.emit(false);
    }
    if (!value) {
      this.preProcessSettings.audit.lotGeneration.autoGeneration = [];
      this.isValid.emit(true);
    }
  }

  setEnabledSamplingPlan(value) {
    this.preProcessSettings.audit.enabledSamplingPlan = value;
    if (!this.preProcessSettings.audit.enabledSamplingPlan) {
      delete this.preProcessSettings.audit.acceptableQualityLevel;
    }
    this.outputValue.emit(this.getOutputValue());
  }
}
