import { Route } from '@angular/compiler/src/core';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VerifyUrlAssigned } from 'src/app/core/models';
import { VerifyUrlAssignedService } from 'src/app/core/services/verifyUrlAssigned.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-url-assigned',
  templateUrl: './verify-url-assigned.component.html',
  styleUrls: ['./verify-url-assigned.component.sass']
})

export class VerifyUrlAssignedComponent implements OnInit {

  @ViewChild('downloadButton', { static: false, read: ElementRef }) downloadButton: ElementRef;

  public id: string;
  public loading = true;
  public data: VerifyUrlAssigned;
  public invalidKey = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private verifyUrlAssignedService: VerifyUrlAssignedService
  ) { }

  async ngOnInit() {
    this.id = this.route.snapshot.params.id;
    await this.verifyUrlAssigned();
  }

  async verifyUrlAssigned() {
    await this.verifyUrlAssignedService.getById(this.id)
    .then(res => {
      this.data = res;
      if (!this.data.expired && this.data.download !== null) {
        const link = document.createElement('a');
        link.href = this.data.download;
        link.download = this.data.download.substr(this.data.download.lastIndexOf('/') + 1);
        link.click();
      }
    }).catch(err => {
      this.invalidKey = true;
      console.error(err);
    });
    setTimeout(() => {
      this.loading = false;
    }, 1500);
  }

}
