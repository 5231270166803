<div class="panel" [ngClass]="{'panel--show': !isRendering}">
  <div class="panel__container">
    <div class="panel__header card card-secondary p-3 mb-1">
      <h6 class="card-label-secondary mb-1" i18n>Histórico de Processamento do Registro</h6>
      <p class="card-label" i18n>Identificação da Infração: {{ violation?.passId }}</p>
    </div>

    <div class="panel__body">
      <div class="card mb-1" *ngIf="violation?.history?.triage">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-1 d-flex align-items-center">
              <span class="material-icons">filter_list</span>
            </div>
            <div class="col-11">
              <p class="card-label mb-1" i18n>Realizado Triagem em {{ violation?.history?.triage[0]?.date | moment: 'L' }}</p>
              <p class="card-label-secondary" i18n>Matrícula: {{ violation?.history?.triage[0]?.registryNumber || '...' }} - {{ violation?.history?.triage[0]?.authorName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-1" *ngIf="violation?.history?.typing">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-1 d-flex align-items-center">
              <span class="material-icons">done</span>
            </div>
            <div class="col-11">
              <p class="card-label mb-1" i18n>Digitado em {{ violation?.history?.typing[0]?.date | moment: 'L' }}</p>
              <p class="card-label-secondary" i18n>Matrícula: {{ violation?.history?.typing[0]?.registryNumber || '...' }} - {{ violation?.history?.typing[0]?.authorName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-1" *ngIf="violation?.history?.doubleTyping">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-1 d-flex align-items-center">
              <span class="material-icons">done</span>
            </div>
            <div class="col-11">
              <p class="card-label mb-1" i18n>Digitado em {{ violation?.history?.doubleTyping[0]?.date | moment: 'L' }}</p>
              <p class="card-label-secondary" i18n>Matrícula: {{ violation?.history?.doubleTyping[0]?.registryNumber || '...' }} - {{ violation?.history?.doubleTyping[0]?.authorName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-1" *ngIf="violation?.history?.verifyValid">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-1 d-flex align-items-center">
              <span class="material-icons">done_all</span>
            </div>
            <div class="col-11">
              <p class="card-label mb-1" i18n>Verificado em {{ violation?.history?.verifyValid[0]?.date | moment: 'L' }}</p>
              <p class="card-label-secondary" i18n>Matrícula: {{ violation?.history?.verifyValid[0]?.registryNumber || '...' }} - {{ violation?.history?.verifyValid[0]?.authorName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-1" *ngIf="violation?.history?.verifyInvalid">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-1 d-flex align-items-center">
              <span class="material-icons">done_all</span>
            </div>
            <div class="col-11">
              <p class="card-label mb-1" i18n>Verificado em {{ violation?.history?.verifyInvalid[0]?.date | moment: 'L' }}</p>
              <p class="card-label-secondary" i18n>Matrícula: {{ violation?.history?.verifyInvalid[0]?.registryNumber || '...' }} - {{ violation?.history?.verifyInvalid[0]?.authorName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-1" *ngIf="violation?.history?.validate">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-1 d-flex align-items-center">
              <span class="material-icons">done_outline</span>
            </div>
            <div class="col-11">
              <p class="card-label mb-1" i18n>Validado em {{ violation?.history?.validate[0]?.date | moment: 'L' }}</p>
              <p class="card-label-secondary" i18n>Matrícula: {{ violation?.history?.validate[0]?.registryNumber || '...' }} - {{ violation?.history?.validate[0]?.authorName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-1" *ngIf="violation?.history?.audits">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-1 d-flex align-items-center">
              <span class="material-icons">playlist_add_check</span>
            </div>
            <div class="col-11">
              <p class="card-label mb-1" i18n>Auditado em {{ violation?.history?.audits[0]?.date | moment: 'L' }}</p>
              <p class="card-label-secondary" i18n>Matrícula: {{ violation?.history?.audits[0]?.registryNumber || '...' }} - {{ violation?.history?.audits[0]?.authorName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-1" *ngIf="violation?.history?.revisions">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-1 d-flex align-items-center">
              <span class="material-icons">playlist_add_check</span>
            </div>
            <div class="col-11">
              <p class="card-label mb-1" i18n>Auditado em {{ violation?.history?.revisions[0]?.date | moment: 'L' }}</p>
              <p class="card-label-secondary" i18n>Matrícula: {{ violation?.history?.revisions[0]?.registryNumber || '...' }} - {{ violation?.history?.revisions[0]?.authorName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-1" *ngIf="violation?.history?.quarantine">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-1 d-flex align-items-center">
              <span class="material-icons">gpp_maybe</span>
            </div>
            <div class="col-11">
              <p class="card-label mb-1" i18n><strong>Etapa</strong>: Quarentena</p>
              <p class="card-label-secondary" i18n>Matrícula: {{ violation?.history?.quarantine?.registryNumber || '...' }} - {{ violation?.history?.quarantine?.authorName }}</p>
              <p class="card-label mb-1" i18n><strong>Data</strong>: {{ violation?.history?.quarantine?.date | moment: 'L' }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-1" *ngIf="!violation?.history">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-1 d-flex align-items-center">
              <span class="material-icons">access_time</span>
            </div>
            <div class="col-11">
              <p class="card-label" i18n>Aguardando Avaliação</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-1" *ngIf="violation?.history?.CFTV?.monitoringCFTV">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-1 d-flex align-items-center">
              <span class="material-icons">playlist_add_check</span>
            </div>
            <div class="col-11">
              <p class="card-label mb-1" i18n>Monitoramento em {{ violation?.history?.CFTV?.monitoringCFTV[0]?.date | moment: 'L' }}</p>
              <p class="card-label-secondary" i18n>Matrícula: {{ violation?.history?.CFTV?.monitoringCFTV[0]?.registryNumber || '...' }} - {{ violation?.history?.CFTV?.monitoringCFTV[0]?.authorName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-1" *ngIf="violation?.history?.CFTV?.verifyCFTV">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-1 d-flex align-items-center">
              <span class="material-icons">playlist_add_check</span>
            </div>
            <div class="col-11">
              <p class="card-label mb-1" i18n>Verificação CFTV em {{ violation?.history?.CFTV?.verifyCFTV[0]?.date | moment: 'L' }}</p>
              <p class="card-label-secondary" i18n>Matrícula: {{ violation?.history?.CFTV?.verifyCFTV[0]?.registryNumber || '...' }} - {{ violation?.history?.CFTV?.verifyCFTV[0]?.authorName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-1" *ngIf="violation?.history?.CFTV?.validateCFTV">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-1 d-flex align-items-center">
              <span class="material-icons">playlist_add_check</span>
            </div>
            <div class="col-11">
              <p class="card-label mb-1" i18n>Validação CFTV em {{ violation?.history?.CFTV?.validateCFTV[0]?.date | moment: 'L' }}</p>
              <p class="card-label-secondary" i18n>Matrícula: {{ violation?.history?.CFTV?.validateCFTV[0]?.registryNumber || '...' }} - {{ violation?.history?.CFTV?.validateCFTV[0]?.authorName }}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="panel__bottom">
      <div class="d-flex w-100 justify-content-end">
        <button class="btn btn-outline-secondary mr-3" (click)="onCancel()" i18n>Voltar</button>
      </div>
    </div>
  </div>
</div>
