import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as moment from 'moment';

import { SpotService, LaneService, ReasonService, ViewViolationService,
  ContractGlobalService, UserGroupService,
  UserService,
  RegulationService,
  StorageService,
  StorageKey} from 'src/app/core/services';
import { Contract, Lane, Reason, Regulation, Spot, User } from 'src/app/core/models';
import { ModalService } from 'src/app/core/services';
import { ComponentModal } from 'src/app/core/models';
import { SelectPeriodComponent } from 'src/app/modals/select-period/select-period.component';

@Component({
  selector: 'app-violations-view-filter-page',
  templateUrl: './filter-page.component.html',
  styleUrls: ['./filter-page.component.sass']
})
export class ViolationsViewFilterPageComponent implements OnInit {
  public contracts: Contract[] = [];
  public lanes: Lane[] = [];
  public spots: Spot[] = [];
  public regulations: Regulation[] = [];
  public reasons: Reason[] = [];
  public situations: any[] = [];
  public users: User[] = [];
  public allLanes: any[] = [];
  public modalPeriodSelected: any = {};
  public isValid: boolean;
  public timezone: string;
  public startDate;
  public endDate;
  public minDate;

  public loading: boolean;
  public filterForm: FormGroup;
  public toggleClass: any = {};
  public showArea: any = {};
  public dataViewViolationsSub: Subscription;
  public user: any = {};
  private contractGlobal = null;

  constructor(
    private contractsGlobalService: ContractGlobalService,
    private formBuilder: FormBuilder,
    private laneService: LaneService,
    private modalService: ModalService,
    private reasonsService: ReasonService,
    private router: Router,
    private route: ActivatedRoute,
    private spotService: SpotService,
    private userGroupService: UserGroupService,
    private viewViolationService: ViewViolationService,
    private userService: UserService,
    private regulationService: RegulationService,
    private storageService: StorageService,
  ) {
    if (this.contractsGlobalService.contracts != null) {
      if (this.contractsGlobalService.contracts.length === 1) {
        this.contractGlobal = this.contractsGlobalService.contracts.shift();
      }
    }
  }

  ngOnInit() {
    const user = this.storageService.get(StorageKey.currentUser);
    const now = new Date().toISOString();
    this.startDate = moment(now)
      .set({hour: 0,minute: 0,second: 0,millisecond:59}).subtract(1, 'month').format();
    this.endDate = moment(now)
      .set({hour: 23,minute: 59,second: 59,millisecond:59}).format();
    this.minDate = moment(now)
      .set({hour: 23,minute: 59,second: 59,millisecond:59}).subtract(3, 'month').format();

    this.filterForm = this.formBuilder.group({
      contractId: ['', Validators.required],
      spotsIds: ['', null],
      laneIds: ['', null],
      regulationsIds: ['', null],
      situationsIds: ['', null],
      reasonsIds: ['', null],
      numberSerie: ['', null],
      numberPlate: ['', null],
      numberId: ['', null],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      userIds: ['', null],
    });

    this.filterForm.get('startDate').setValue(this.startDate);
    this.filterForm.get('endDate').setValue(this.endDate);

    this.dataViewViolationsSub = this.viewViolationService.getDataViewViolation()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((data: any) => {

        this.contracts = data.contracts;
        this.regulations = data.regulations;
        this.situations = data.situations;

        if (this.contractGlobal  ) {
          setTimeout(() => {
            this.filterForm.get('contractId').setValue(this.contractGlobal);
          }, 100);
        }

        this.filterForm.get('contractId').valueChanges.subscribe(async contractId => {
          this.toggleClass.contract = true;
          this.spots = await this.spotService.getAll({contractId});
          this.allLanes = (await this.laneService.getAll({contractId })).map( e => ({ ...e, prettyName: e.prettyNames?.[0] }));

          this.timezone = this.contracts.filter(c => c.id === contractId).map(item => item.timezone).shift();

          const spotsIds = this.spots.map(spot => spot.id);
          this.lanes = this.allLanes.filter(lane => spotsIds.includes(lane.spotId));
          this.reasons = await this.reasonsService.getAll({ contractId });
          this.reasons =  this.reasons.sort((a, b): any => Number(a.code) - Number(b.code));
          this.users = (await this.userService.getAll({order: 'name'})).filter(u =>  u.name && u.name !== '');

          const contract = this.contracts.find(c => c.id === contractId);
          let regulationParams = {};
          if (contract && contract.regulationIds) {
            regulationParams = { regionId: contract.regionId, 'id[in]': `[${contract.regulationIds}]` };
          } else {
            regulationParams = { regionId: contract.regionId };
          }
          this.regulations = await this.regulationService.getAll(regulationParams);

          this.showArea.spots = true;
          this.showArea.lanes = true;
          this.toggleClass.contract = false;
        });

        this.filterForm.get('situationsIds').valueChanges.subscribe(situationsIds => {
          this.showArea.reasons = true;
          const reasonsIds = this.reasons.map(reason => reason.id);
          this.filterForm.get('reasonsIds').setValue(reasonsIds);

          if (!situationsIds.includes('invalid')) {
            this.showArea.reasons = false;
            this.filterForm.get('reasonsIds').setValue([]);
          }
        });
        this.loading = true;
    });

    this.filterForm.valueChanges.subscribe(res => {
      this.isValid = this.filterForm.valid;
    });
  }

  toggleCollapse(item) {
    this.showArea[item] = !this.showArea[item];
  }

  async selectModalPeriod() {
    this.startDate = moment(this.startDate) .tz(this.timezone).format();
    this.endDate = moment(this.endDate).tz(this.timezone).format();
    await this.modalService.show(new ComponentModal(SelectPeriodComponent, {
      startDate: this.startDate,
      endDate: this.endDate,
      minDate: this.minDate,
      rangeDates: [[7, 'days'], [15, 'days'], [1, 'month'], [2, 'month'], [3, 'month']]
    }))
      .catch(() => { })
      .then(data => {
        if (data == null) {
          return;
        }
        const modal = data as any;
        this.modalPeriodSelected = modal && modal.component
          && modal.component.instance
          && modal.component.instance.periodSelected;

          this.startDate = moment(this.modalPeriodSelected.startDate).tz(this.timezone).format();
          this.endDate = moment(this.modalPeriodSelected.endDate).tz(this.timezone).format();
      });
  }

  submitFilter() {
    const { startDate, endDate, ...filterValues } = this.filterForm.value;
    this.viewViolationService.emitFilterViewViolation(filterValues);
    this.viewViolationService.emitPeriodViewViolation({ startDate: this.startDate, endDate: this.endDate });
    this.router.navigate([this.filterForm.value.contractId], { relativeTo: this.route });
  }
}
