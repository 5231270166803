<div class="main-menu sticky-top" [ngClass]="{'main-menu--show': isOpen}">
  <div class="main-menu__scroll" [ngClass]="{'main-menu__scroll--scroller': isSubMenu}">
    <div class="main-menu__left">
      <ul>
        <li class="menu-active">
          <a routerLink="/" (click)="closeMenu()" class="main-menu__btn">
            <i class="material-icons">home</i>
            <span i18n>Dashboard</span>
          </a>
        </li>
        <li class="main-menu__divider d-md-none" i18n>Olá, {{user.name}}</li>
        <li class="d-md-none menu-link">
          <a routerLink="/users/myProfile" (click)="closeMenu()" class="main-menu__btn">
            <i class="material-icons">edit</i>
            <span i18n>Meu perfil</span>
          </a>
        </li>
        <li class="d-md-none pointer">
          <a (click)="logout()" class="main-menu__btn">
            <i class="material-icons">exit_to_app</i>
            <span i18n>Sair</span>
          </a>
        </li>
        <li class="main-menu__divider" i18n>Módulos</li>
        <li
          *appPermission="['OnlineMonitoring', 'ValidateCFTV', 'UpdateMonitoringSettings', 'ViewMonitoringSettings', 'VerifyCFTV', 'ViolationStepCFTV']">
          <a href="javascript:void(0)" (click)="menuNavigate($event, menuRegisterEcsSpy)" class="main-menu__btn">
            <i class="material-icons">video_camera_front</i>
            <span i18n>eCS Spy</span>
          </a>
        </li>
        <li *appPermission="['ConsultViolation', 'ViolationsReport']">
          <a href="javascript:void(0)" (click)="menuNavigate($event, menuRegisterViolations)" class="main-menu__btn">
            <i class="material-icons">error_outline</i>
            <span i18n>Infração</span>
          </a>
        </li>
        <li *appPermission="['ViewModelPreProcess']">
          <a href="javascript:void(0)" (click)="menuNavigate($event, menuRegisterPreProcess)" class="main-menu__btn">
            <i class="material-icons">burst_mode</i>
            <span i18n>Pré-Processamento</span>
          </a>
        </li>
        <li *appPermission="['ViewContract', 'CreateContract', 'DeleteContract', 'UpdateContract','ViewAppraisal', 'CreateAppraisal', 'DeleteAppraisal', 'UpdateAppraisal']">
          <a href="javascript:void(0)" (click)="menuNavigate($event, menuRegisterAdministrative)" class="main-menu__btn">
            <i class="material-icons">manage_accounts</i>
            <span i18n>Administrativo</span>
          </a>
        </li>
        <li class="main-menu__divider">Geral</li>

        <li *appPermission="[
            'ViewContract',
            'ViewCalibration',
            'ViewCompany',
            'ViewEquipment',
            'ViewEquipmentModel',
            'ViewLane',
            'ViewUserGroup',
            'ViewSpot',
            'ViewSpotGroup',
            'ViewVehicleWhiteList',
            'ViewUser',
            'ViewExemptPeriod',
            'ViewActivity',
            'ViewViolationSource',
            'ViewImportSettings',
            'ViewReason',
            'ViewMonitoringSettings'
          ]">
          <a href="javascript:void(0)" class="main-menu__btn" (click)="menuNavigate($event, menuRegister)">
            <i class="material-icons">playlist_add</i>
            <span i18n>Cadastros</span>
          </a>
        </li>
<!--        ###################################Vídeos Tutoriais#########################################-->
        <li class="main-menu__divider">Vídeos Tutoriais</li>
        <li class="main-menu__accordion" #tutorialEcsSpy>
          <p class="main-menu__accordion-header" (click)="toggleClass($event, tutorialEcsSpy)"
             *appPermission="['']">
            <span i18n>eCs Spy</span>
            <i class="material-icons closed">expand_more</i>
            <i class="material-icons opened">expand_less</i>
          </p>

          <ul>
            <li *appPermission="['']">
              <a routerLink="/video-tutorial/ecsspy/monitoring" class="main-menu__btn"
                 [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                <span i18n>Monitoramento</span>
              </a>
            </li>
          </ul>
          <ul>
            <li *appPermission="['']">
              <a routerLink="/video-tutorial/ecsspy/report" class="main-menu__btn"
                 [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                <span i18n>Relatório</span>
              </a>
            </li>
          </ul>
        </li>
        <li class="main-menu__accordion" #tutorialViolation>
          <p class="main-menu__accordion-header" (click)="toggleClass($event, tutorialViolation)"
             *appPermission="['']">
            <span i18n>Infração</span>
            <i class="material-icons closed">expand_more</i>
            <i class="material-icons opened">expand_less</i>
          </p>

          <ul>
            <li *appPermission="['']">
              <a routerLink="/video-tutorial/violation/consultation" class="main-menu__btn"
                 [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                <span i18n>Consulta</span>
              </a>
            </li>
          </ul>
          <ul>
            <li *appPermission="['']">
              <a routerLink="/video-tutorial/violation/report" class="main-menu__btn"
                 [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                <span i18n>Relatório</span>
              </a>
            </li>
          </ul>
        </li>
        <li class="main-menu__accordion" #tutorialProcess>
          <p class="main-menu__accordion-header" (click)="toggleClass($event, tutorialProcess)"
             *appPermission="['']">
            <span i18n>Pré-Processamento</span>
            <i class="material-icons closed">expand_more</i>
            <i class="material-icons opened">expand_less</i>
          </p>
          <ul>
            <li *appPermission="['']">
              <a routerLink="/video-tutorial/pre-process/process-violation" class="main-menu__btn"
                 [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                <span i18n>Processar Infrações</span>
              </a>
            </li>
          </ul>
          <ul>
            <li *appPermission="['']">
              <a routerLink="/video-tutorial/pre-process/operation" class="main-menu__btn"
                 [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                <span i18n>Operações</span>
              </a>
            </li>
          </ul>
          <ul>
            <li *appPermission="['']">
              <a routerLink="/video-tutorial/pre-process/report" class="main-menu__btn"
                 [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                <span i18n>Relatórios</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>

      <ul class="mt-auto">
        <li *appPermission="['ViewContract', 'UpdateContract']">
          <a routerLink="/contracts" class="main-menu__btn" (click)="closeMenu()">
            <i class="material-icons">settings_applications</i>
            <span i18n>Configuração</span>
          </a>
        </li>
        <li *ngIf="importFile">
          <span *appPermission="['UploadImportFile', 'ViewImportFile']">
            <a routerLink="/import-file" class="main-menu__btn" (click)="closeMenu()">
              <i class="material-icons">import_export</i>
              <span i18n>Importação de Arquivos</span>
            </a>
          </span>
        </li>
      </ul>

      <p class="main-menu__version">
        <small>
          <span i18n>Versão</span> {{appVersion}} |
          <span class="text-underline" (click)="clickModalRelease()" i18n>Ver ReleaseNotes</span>
        </small>
      </p>
    </div>
    <div #menuRegister class="main-menu__right">
      <ul>
        <li class="main-menu__back">
          <a href="javascript:void(0)" class="main-menu__btn" (click)="menuNavigateBack($event)">
            <i class="material-icons">subdirectory_arrow_left</i>
            <span i18n>Voltar</span>
          </a>
        </li>
        <li>
          <p href="javascript:void(0);" class="main-menu__head">
            <i class="material-icons">playlist_add</i>
            <span i18n>Cadastros</span>
          </p>
          <ul>
            <li *appPermission="'ViewCalibration'">
              <a routerLink="/calibrations" class="main-menu__btn" (click)="closeMenu()" i18n>Aferições
              </a>
            </li>
            <li *appPermission="'ViewEquipment'"><a routerLink="/equipments" class="main-menu__btn"
                (click)="closeMenu()" i18n>Equipamentos</a></li>
            <li *appPermission="'ViewSpotGroup'"><a routerLink="/spot-groups" class="main-menu__btn"
                (click)="closeMenu()" i18n>Grupo de Pontos</a></li>
            <li *appPermission="'ViewUserGroup'"><a routerLink="/user-groups" class="main-menu__btn"
                (click)="closeMenu()" i18n>Grupo de usuários</a></li>
            <li *appPermission="'ViewBrandInternal'">
              <a routerLink="/brandInternals" class="main-menu__btn" (click)="closeMenu()" i18n>Marca do Veículo</a>
            </li>
            <li *appPermission="'ViewReason'">
              <a routerLink="/reasons" class="main-menu__btn" (click)="closeMenu()" i18n>Motivo de Invalidação</a>
            </li>
            <li *appPermission="'ViewViolationSource'">
              <a routerLink="/violationSources" class="main-menu__btn" (click)="closeMenu()" i18n>Origem de Multas
              </a>
            </li>
            <li *appPermission="'ViewSpot'"><a routerLink="/spots" class="main-menu__btn" (click)="closeMenu()"
                i18n>Pontos e Faixas</a></li>
            <li *appPermission="'ViewUser'"><a routerLink="/users" class="main-menu__btn" (click)="closeMenu()"
                i18n>Usuários</a></li>
            <li *appPermission="'ViewVehicleWhiteList'">
              <a routerLink="/vehicleWhiteLists" class="main-menu__btn" (click)="closeMenu()" i18n>Veículo Isento</a>
            </li>
          </ul>
        </li>
      </ul>

      <p class="mt-auto">
        <a routerLink="/contracts" class="main-menu__btn" (click)="closeMenu()">
          <i class="material-icons">settings_applications</i>
          <span i18n>Configuração</span>
        </a>
      </p>

      <p class="main-menu__version">
        <small>
          <span i18n>Versão</span> {{appVersion}} |
          <span class="text-underline" (click)="clickModalRelease()" i18n>Ver ReleaseNotes</span>
        </small>
      </p>
    </div>
    <div #menuRegisterPreProcess class="main-menu__right">
      <ul class="mb-auto">
        <li class="main-menu__back">
          <a href="javascript:void(0)" class="main-menu__btn" (click)="menuNavigateBack($event)">
            <i class="material-icons">subdirectory_arrow_left</i>
            <span i18n>Voltar</span>
          </a>
        </li>
        <li>
          <p href="javascript:void(0);" class="main-menu__head">
            <i class="material-icons">burst_mode</i>
            <span i18n>Pré-Processamento</span>
          </p>
          <ul>
            <li class="main-menu__accordion" #accordionProcess>
              <p class="main-menu__accordion-header" (click)="toggleClass($event, accordionProcess)"
                *appPermission="['TriageViolation','TypingViolation','DoubleTypingViolation','VerifyViolation','ValidateViolation']">
                <span i18n>Processar infrações</span>
                <i class="material-icons closed">expand_more</i>
                <i class="material-icons opened">expand_less</i>
              </p>

              <ul>
                <li *appPermission="['TriageViolation']">
                  <a routerLink="/pre-process/triage/available-contracts" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">filter_list</i>
                    <span i18n>Triagem</span>
                  </a>
                </li>

                <li [hidden]="stepTyping" *appPermission="['TypingViolation', 'DoubleTypingViolation']">
                  <a routerLink="/pre-process//typing/available-contracts" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">done</i>
                    <span i18n>Digitação</span>
                  </a>
                </li>

                <li *appPermission="['VerifyViolation']">
                  <a routerLink="/pre-process/verify/available-contracts" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">done_all</i>
                    <span i18n>Verificação</span>
                  </a>
                </li>

                <li *appPermission="['ValidateViolation']">
                  <a routerLink="/pre-process/validate/available-contracts" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">done_outline</i>
                    <span i18n>Validação</span>
                  </a>
                </li>
              </ul>
            </li>
            <li class="main-menu__accordion" #accordionOperations>
              <p class="main-menu__accordion-header" (click)="toggleClass($event, accordionOperations)"
                *appPermission="['AuditViolation', 'ManageLot','ViewLot','QuarantineReport', 'ViewSerialNumberConfig','CreateSerialNumberConfig','UpdateSerialNumberConfig','DeleteSerialNumberConfig']">
                <span i18n>Operações</span>
                <i class="material-icons closed">expand_more</i>
                <i class="material-icons opened">expand_less</i>
              </p>

              <ul>
                <li *appPermission="['AuditViolation', 'ManageLot', 'ReviewLot']">
                  <a routerLink="/pre-process/internalAudit/available-contracts" class="main-menu__btn">
                    <i class="material-icons">playlist_add_check</i>
                    <span i18n>Auditoria</span>
                  </a>
                </li>

                <li *appPermission="['ViewLot']">
                  <a routerLink="/pre-process/lot-list/available-contracts" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">assignment</i>
                    <span i18n>Gerenciar Lotes</span>
                  </a>
                </li>
                <li
                  *appPermission="['CreateLot']">
                  <a routerLink="/pre-process/lot-generate/available-contracts" class="main-menu__btn"
                     [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">list_alt</i>
                    <span i18n>Gerar Lote</span>
                  </a>
                </li>

                <li *appPermission="['QuarantineReport']">
                  <a routerLink="/pre-process/quarantine/available-contracts" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">report</i>
                    <span i18n>Quarentena</span>
                  </a>
                </li>

                <li
                  *appPermission="['ViewSerialNumberConfig','CreateSerialNumberConfig','UpdateSerialNumberConfig','DeleteSerialNumberConfig']">
                  <a routerLink="/pre-process/serial-number-config/available-contracts" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">toc</i>
                    <span i18n>Gerenciar série</span>
                  </a>
                </li>
              </ul>
            </li>
            <li class="main-menu__accordion" #accordionReports>
              <p class="main-menu__accordion-header" (click)="toggleClass($event, accordionReports)"
                *appPermission="['PreProcessStepMap', 'PreProcessProductionReport']">
                <span i18n>Relatórios</span>
                <i class="material-icons closed">expand_more</i>
                <i class="material-icons opened">expand_less</i>
              </p>

              <ul>
                <li *appPermission="['PreProcessStepMap']">
                  <a routerLink="/pre-process/violation-step/available-contracts" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">assessment</i>
                    <span i18n>Mapa de etapas</span>
                  </a>
                </li>

                <li *appPermission="['PreProcessProductionReport']">
                  <a routerLink="/pre-process/production-report" class="main-menu__btn"
                    [queryParams]="{isProcessViolations: true}" (click)="closeMenu()">
                    <i class="material-icons">assignment_ind</i>
                    <span i18n>Produção</span>
                  </a>
                </li>

                <li *appPermission="['ReportOCR']">
                  <a routerLink="/pre-process/ocr-report/available-contracts" class="main-menu__btn"
                    (click)="closeMenu()">
                    <i class="material-icons">query_stats</i>
                    <span i18n>Aproveitamento OCR</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <p *appPermission="['UpdatePreProcessSettings','ViewPreProcessSettings']">
        <a (click)="navigatePreProcess('settings')" class="main-menu__btn">
          <i class="material-icons">settings_applications</i>
          <span i18n>Administrar módulo</span>
        </a>
      </p>

      <p class="main-menu__version">
        <small>
          <span i18n>Versão</span> {{appVersion}} |
          <span class="text-underline" (click)="clickModalRelease()" i18n>Ver ReleaseNotes</span>
        </small>
      </p>
    </div>
    <div #menuRegisterEcsSpy class="main-menu__right">
      <ul class="mb-auto">
        <li class="main-menu__back">
          <a href="javascript:void(0)" class="main-menu__btn" (click)="menuNavigateBack($event)">
            <i class="material-icons">subdirectory_arrow_left</i>
            <span i18n>Voltar</span>
          </a>
        </li>
        <li>
          <p href="javascript:void(0);" class="main-menu__head" *appPermission="['OnlineMonitoring','ValidateCFTV','VerifyCFTV']">
            <i class="material-icons">burst_mode</i>
            <span i18n>Processar infrações</span>
          </p>
          <ul>
            <li *appPermission="['OnlineMonitoring']">
              <a *ngIf="contractGlobal" routerLink="/ecsSpy/{{contractGlobal}}/monitoring" class="main-menu__btn"
                (click)="closeMenu()">
                <i class="material-icons">videocam</i>
                <span i18n>Monitoramento CFTV</span>
              </a>
              <a *ngIf="!contractGlobal" routerLink="/ecsSpy" class="main-menu__btn"
                [queryParams]="{step: 'monitoring', permission: ['OnlineMonitoring']}" (click)="closeMenu()">
                <i class="material-icons">videocam</i>
                <span i18n>Monitoramento CFTV</span>
              </a>
            </li>
            <li *appPermission="['ValidateCFTV']">
              <a *ngIf="contractGlobal" routerLink="/ecsSpy/{{contractGlobal}}/validate" class="main-menu__btn"
                (click)="closeMenu()">
                <i class="material-icons">video_file</i>
                <span i18n>Validar Infrações CFTV</span>
              </a>
              <a *ngIf="!contractGlobal" routerLink="/ecsSpy" class="main-menu__btn"
                [queryParams]="{step: 'validate', permission: ['ValidateCFTV']}" (click)="closeMenu()">
                <i class="material-icons">video_file</i>
                <span i18n>Validar Infrações CFTV</span>
              </a>
            </li>
            <li *appPermission="['VerifyCFTV']">
              <a *ngIf="contractGlobal" routerLink="/ecsSpy/{{contractGlobal}}/verify" class="main-menu__btn"
                (click)="closeMenu()">
                <i class="material-icons">video_camera_front</i>
                <span i18n>Verificar Infrações CFTV</span>
              </a>
              <a *ngIf="!contractGlobal" routerLink="/ecsSpy" class="main-menu__btn"
                [queryParams]="{step: 'verify', permission: ['VerifyCFTV']}" (click)="closeMenu()">
                <i class="material-icons">video_camera_front</i>
                <span i18n>Verificar Infrações CFTV</span>
              </a>
            </li>
          </ul>
          <p href="javascript:void(0);" class="main-menu__head" *appPermission="['ViolationStepCFTV']">
            <i class="material-icons">burst_mode</i>
            <span i18n>Relatórios</span>
          </p>
          <ul>
            <li *appPermission="['ViolationStepCFTV']">
              <a *ngIf="contractGlobal" routerLink="/ecsSpy/{{contractGlobal}}/violationStep" class="main-menu__btn"
                (click)="closeMenu()">
                <i class="material-icons">assessment</i>
                <span i18n>Mapa de Etapas</span>
              </a>
              <a *ngIf="!contractGlobal" routerLink="/ecsSpy" class="main-menu__btn"
                [queryParams]="{step: 'violationStep', permission: ['ViolationStepCFTV']}" (click)="closeMenu()">
                <i class="material-icons">assessment</i>
                <span i18n>Mapa de Etapas</span>
              </a>
            </li>
          </ul>
        </li>
        </ul>
        <p href="javascript:void(0);" class="main-menu__head"
          *appPermission="['UpdateMonitoringSettings', 'ViewMonitoringSettings']">
          <a routerLink="/ecsSpy" class="main-menu__btn" [queryParams]="{step: 'settings', contractId: contractGlobal}"
            (click)="closeMenu()">
            <i class="material-icons">settings_applications</i>
            <span i18n>Administrar Módulo</span>
          </a>
        </p>
    </div>
    <div #menuRegisterViolations class="main-menu__right">
      <ul class="mb-auto">
        <li class="main-menu__back">
          <a href="javascript:void(0)" class="main-menu__btn" (click)="menuNavigateBack($event)">
            <i class="material-icons">subdirectory_arrow_left</i>
            <span i18n>Voltar</span>
          </a>
        </li>
        <li>
          <p href="javascript:void(0);" class="main-menu__head">
            <i class="material-icons">error_outline</i>
            <span i18n>Infrações</span>
          </p>
          <ul>
            <li class="main-menu__accordion" #viewViolation>
              <p class="main-menu__accordion-header" (click)="toggleClass($event, viewViolation)"
                *appPermission="['ConsultViolation']">
                <span i18n>Consulta</span>
                <i class="material-icons closed">expand_more</i>
                <i class="material-icons opened">expand_less</i>
              </p>

              <ul>
                <li *appPermission="['ConsultViolation']">
                  <a routerLink="/violations/violations-view" class="main-menu__btn" (click)="closeMenu()">
                    <i class="material-icons">perm_scan_wifi</i>
                    <span i18n>Infrações</span>
                  </a>
                </li>
              </ul>
            </li>
            <li class="main-menu__accordion" #accordionReportViolation>
              <p class="main-menu__accordion-header" (click)="toggleClass($event, accordionReportViolation)"
                *appPermission="['ViolationsReport']">
                <span i18n>Relatórios</span>
                <i class="material-icons closed">expand_more</i>
                <i class="material-icons opened">expand_less</i>
              </p>

              <ul>
                <li *appPermission="['ViolationsReport']">
                  <a routerLink="/violations/violations-report" class="main-menu__btn" (click)="closeMenu()">
                    <i class="material-icons" i18n>assignment_late</i>
                    <span i18n>Infrações</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <p class="main-menu__version mt-auto">
        <small>
          <span i18n>Versão</span> {{appVersion}} |
          <span class="text-underline" (click)="clickModalRelease()" i18n>Ver ReleaseNotes</span>
        </small>
      </p>
    </div>
    <div #menuRegisterAdministrative class="main-menu__right">
      <ul class="mb-auto">
        <li class="main-menu__back">
          <a href="javascript:void(0)" class="main-menu__btn" (click)="menuNavigateBack($event)">
            <i class="material-icons">subdirectory_arrow_left</i>
            <span i18n>Voltar</span>
          </a>
        </li>
        <li>
          <p href="javascript:void(0);" class="main-menu__head">
            <i class="material-icons">manage_accounts</i>
            <span i18n>Administrativo</span>
          </p>
          <ul>
            <li class="main-menu__accordion" #accordionManageContract>
              <p class="main-menu__accordion-header" (click)="toggleClass($event, accordionManageContract)"
              *appPermission="['ViewContract', 'CreateContract', 'DeleteContract', 'UpdateContract']">
                <span i18n>Contrato</span>
                <i class="material-icons closed">expand_more</i>
                <i class="material-icons opened">expand_less</i>
              </p>

              <ul>
                <li>
                  <a routerLink="/administrative/manage-contract" class="main-menu__btn"
                    (click)="closeMenu()">
                    <i class="material-icons">receipt_long</i>
                    <span i18n>Gerenciar Contrato</span>
                  </a>
                </li>
              </ul>
            </li>
            <li class="main-menu__accordion" #accordionAppraisal>
              <p class="main-menu__accordion-header" (click)="toggleClass($event, accordionAppraisal)"
                *appPermission="['ViewAppraisal', 'CreateAppraisal', 'DeleteAppraisal', 'UpdateAppraisal']">
                <span i18n>Aferição</span>
                <i class="material-icons closed">expand_more</i>
                <i class="material-icons opened">expand_less</i>
              </p>
              <ul>
                <li>
                  <a routerLink="/administrative/manage-appraisal" class="main-menu__btn"
                    (click)="closeMenu()">
                    <i class="material-icons">task</i>
                    <span i18n>Gerenciar Certificado</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p *appPermission="['UpdatePreProcessSettings','ViewPreProcessSettings']">
        <a (click)="navigatePreProcess('settings')" class="main-menu__btn">
          <i class="material-icons">settings_applications</i>
          <span i18n>Administrar módulo</span>
        </a>
      </p>

      <p class="main-menu__version">
        <small>
          <span i18n>Versão</span> {{appVersion}} |
          <span class="text-underline" (click)="clickModalRelease()" i18n>Ver ReleaseNotes</span>
        </small>
      </p>
    </div>
  </div>
</div>
