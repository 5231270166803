import { BaseApiModel } from './BaseApiModel';

export class Reason extends BaseApiModel {
  public code: string;
  public name: string;
  public contractId: string;
  public regulationIds: string[];
  public enabled: boolean;
  public type: string;
  public modifiedAt: Date;
  public createdAt: Date;
  public deletedAt: Date;
  public requiredFields: string[];
  public screen: string[];
  public get prettyName() {
      return `${this.code} - ${this.name}`;
  }
}

// eslint-disable-next-line no-shadow
export enum ReasonTypes {
  technical = 'technical',
  nonTechnical = 'nonTechnical',
}
