import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { ModalService } from 'src/app/core/services';
import { ComponentModal } from 'src/app/core/models';
import { ViewViolationService } from 'src/app/core/services';
import { SelectPeriodComponent } from 'src/app/modals/select-period/select-period.component';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-violations-view-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.sass']
})
export class ViolationsViewPeriodComponent implements OnInit {
  public modalPeriodSelected: any = {};
  public periodViewViolationsSub: Subscription;
  public startDate;
  public endDate;

  constructor(
    private modalService: ModalService,
    private viewViolationService: ViewViolationService
  ) { }

  ngOnInit() {
    const now = new Date().toISOString();
    this.startDate = moment(now).subtract(1, 'month').utc().format();
    this.endDate = moment(now).utc().format();

    this.periodViewViolationsSub = this.viewViolationService.getPeriodViewViolation()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((result: any) => {
        this.startDate = result.startDate;
        this.endDate = result.endDate;
      });

  }

  async selectModalPeriod() {
    await this.modalService.show(new ComponentModal(SelectPeriodComponent, {
      startDate: this.startDate,
      endDate: this.endDate
    }))
      .catch(() => { })
      .then(data => {
        if (data == null) {
          return;
        }
        const modal = data as any;
        this.modalPeriodSelected = modal && modal.component
          && modal.component.instance
          && modal.component.instance.periodSelected;

        this.emitNewParams();
      });
  }

  emitNewParams() {
    this.startDate = moment(this.modalPeriodSelected.startDate).format();
    this.endDate = moment(this.modalPeriodSelected.endDate).format();

    const periodParams =  {
      startDate: this.startDate,
      endDate: this.endDate
    };

    this.viewViolationService.emitPeriodViewViolation(periodParams);
  }

}
