import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { VideoService, ModalService } from 'src/app/core/services';
import {ComponentModal, Video} from '../../core/models';
import {VideoViewComponent} from './video-view/video-view.component';

@Component({
  selector: 'app-video-tutorial',
  templateUrl: './video-tutorial.component.html',
  styleUrls: ['./video-tutorial.component.sass']
})
export class VideoTutorialComponent implements OnInit {
  public module = '';
  public function = '';
  public loading = true;
  public params: any = {};
  public videosList: Array<any> = [];
  constructor(private router: Router,
              private route: ActivatedRoute,
              private videoService: VideoService,
              private modalService: ModalService) { }

  ngOnInit(): void {
    this.module = this.route.snapshot.params.module;
    this.function = this.route.snapshot.params.function;
    if (this.module) {
      this.getVideos()
    } else {
      this.getVideosAll()
    }
  }
  async getVideos () {
    this.params.module = `${this.module}`;
    if(this.function) {
      this.params.function = `${this.function}`;
    }
    await this.videoService.list(this.params).then(res => {
        this.videosList = res.result;
    }).catch(err => {
      console.log('err', err)
    }).finally(() => {
      this.loading = false;
    });
  }

  async getVideosAll () {
    this.params.limit = 50;
    await this.videoService.getAll().then(res => {
      this.videosList = res;
    }).catch(err => {
      console.log('err', err)
    }).finally(() => {
      this.loading = false;
    });
  }

  getTitle(name) {
    switch (name) {
      case 'ecsspy':
        name = 'ecsSpy';
        break;
      case 'violation':
        name = 'Infrações';
        break;
      case 'pre-process':
        name = 'Pré-Processamento';
        break;
    }
    return name
  }
  openVideo(file) {
    this.modalService.show(new ComponentModal(VideoViewComponent, file));
  }
}
