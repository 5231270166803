import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalContent } from 'src/app/core/interface';
import { Contract, Reason, User, AlertItem, AlertType } from 'src/app/core/models';
import {
  AlertService,
  ContractGlobalService, ContractService, ModalService,
  ReasonService, StorageKey, StorageService
} from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-replicate-reason',
  templateUrl: './replicate-reason.component.html',
  styleUrls: ['./replicate-reason.component.sass']
})
export class ReplicateReasonComponent extends BaseModal implements OnInit, ModalContent {
  @Input() initialState;
  public contractsPromise: Promise<Contract[]>;
  public reasonForm: FormGroup;
  public contractGlobal = null;
  public isValid = false;
  public showArea = false;
  public promise: Promise<any>;
  public user: User;
  public currentPermissions = [];
  public contracts: Contract[] = [];
  public contractSelected: Contract;
  public contractsReplicated: Contract[] = [];
  public reasons: Array<Reason> = [];
  public displayReasons = false;
  public loadingReasons = false;

  constructor(
    private formBuilder: FormBuilder,
    private contractsGlobalService: ContractGlobalService,
    private storageService: StorageService,
    public modalService: ModalService,
    public contractService: ContractService,
    private alertService: AlertService,
    public reasonService: ReasonService
  ) {
    super(modalService);
    if (this.contractsGlobalService.contracts != null) {
      this.contractGlobal = this.contractsGlobalService.contracts.length === 1 ? this.contractsGlobalService.contracts[0] : null;
    }
  }

  get lastState(): any {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.getCurrentPermissions();
    this.getUser();
    this.getContracts();
    this.createForm();
    if (this.contractGlobal !== null) {
      this.reasonForm.get('contractId').patchValue(this.contractGlobal);
    }
  }

  getCurrentPermissions() {
    const currentPermissions = this.storageService.get(StorageKey.currentPermissions);
    this.currentPermissions = currentPermissions &&
      currentPermissions.filter(contractPermission =>
        contractPermission.actionIds.indexOf('CreateReason') >= 0);
  }

  getUser() {
    this.user = this.storageService.get(StorageKey.currentUser);
  }

  getContracts() {
    this.contractsPromise = this.contractService.getAll({ 'status[neq]': 'closed' }).then(res => {
      if (this.user.superUser) {
        this.contracts = res;
      } else {
        this.contracts = res.filter(contract => this.currentPermissions.find(contractPermissions =>
          contractPermissions.contractId === contract.id));
      }
      this.contractsReplicated = this.contracts;
      return this.contracts;
    });
  }

  createForm(): void {
    this.reasonForm = this.formBuilder.group({
      contractId: [this.contractGlobal, [Validators.required]],
      reasons: [[], [Validators.required]],
      targetContractId: [[], [Validators.required]]
    });
    this.reasonForm.valueChanges.subscribe(() => {
      this.isValid = this.reasonForm.valid;
    });
    this.reasonForm.get('contractId').valueChanges.subscribe(async contractId => {
      if (contractId !== undefined) {
        this.displayReasons = true;
        this.contractsReplicated = this.contracts.filter(c => c.id !== contractId);
        await this.getReasons(contractId);
      } else {
        this.contractsReplicated = this.contracts;
        this.displayReasons = false;
      }
    });
    this.reasonForm.get('targetContractId').valueChanges.subscribe(async toContractId => {
      const fromContractId = this.reasonForm.get('contractId').value;
      if (fromContractId !== undefined && toContractId !== undefined && fromContractId === toContractId) {
        this.reasonForm.get('targetContractId').patchValue('');
      }
    });
    if (this.reasonForm.get('contractId').value !== '') {
      this.contractsReplicated = this.contracts.filter(c => c.id !== this.reasonForm.get('contractId').value);
    }
  }

  async getReasons(contractId: number) {
    this.loadingReasons = true;
    await this.reasonService.list({
      'contractId[in]': `[${contractId}]`
    }).then(res => {
      this.reasons = res.result;
    });
    this.loadingReasons = false;
  }

  public async onAllSubmited() {
    const promises = [];
    const contractId = this.reasonForm.get('targetContractId').value;
    const reasonIds = this.reasonForm.get('reasons').value;
    const data = this.reasons.filter(reason => reasonIds.includes(reason.id));

    data.map(item => {
      delete item.id;
      item.contractId = contractId;
      promises.push(this.reasonService.create(item));
    });

    Promise.all(promises).then(res => {
      this.alertService.show(new AlertItem('ReasonSaved', AlertType.success));
    }).catch(err => {
      this.alertService.show(new AlertItem('ReasonSaveConflict', AlertType.danger));
    });

    return;
  }

}
