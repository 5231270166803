import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import {
  SpotService, LaneService, ReasonService, ProcessViolationService, StorageKey, StorageService,
  UserGroupService, RegulationService, ContractService, EquipmentService, ContractGlobalService, PreProcessSettingsService, UserService
} from 'src/app/core/services';
import { Contract, Equipment, Lane, Reason, Regulation, Spot, User, UserGroup } from 'src/app/core/models';
import { ModalService } from 'src/app/core/services';
import { ComponentModal } from 'src/app/core/models';
import { SelectPeriodComponent } from 'src/app/modals/select-period/select-period.component';
import { RegionService } from 'src/app/core/services/region.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-filter-process-violation',
  templateUrl: './filter-process-violation.component.html',
  styleUrls: ['./filter-process-violation.component.sass']
})
export class FilterProcessViolationComponent implements OnInit {
  public step = '';
  public stepName: string;
  public contracts: Contract[] = [];
  public equipments: Equipment[] = [];
  public lanes: Lane[] = [];
  public spots: Spot[] = [];
  public regulations: Regulation[] = [];
  public reasons: Reason[] = [];
  public users: User[] = [];
  public situations: any[] = [];
  public allLanes: any[] = [];
  public modalPeriodSelected: any = {};
  public isValid: boolean;
  public startDate;
  public endDate;
  public minDate;
  public selectedContractId: string;
  public loading: boolean;
  public filterForm: FormGroup;
  public toggleClass: any = {};
  public showArea: any = {};
  public dataProcessViolationsSub: Subscription;
  public contractId = '';
  public permission = '';
  public usersByContract = [];
  public userGroups: UserGroup[] = [];
  public contract = new Contract();
  public timezone = null;

  constructor(
    private formBuilder: FormBuilder,
    private laneService: LaneService,
    private modalService: ModalService,
    private reasonsService: ReasonService,
    private router: Router,
    private route: ActivatedRoute,
    private spotService: SpotService,
    private processViolationService: ProcessViolationService,
    private contractService: ContractService,
    private equipmentService: EquipmentService,
    private regulationService: RegulationService,
    private storageService: StorageService,
    private userGroupService: UserGroupService,
    private contractsGlobalService: ContractGlobalService,
    private regionService: RegionService,
    private preProcessSettingsService: PreProcessSettingsService,
    private userService: UserService,
  ) {
    if (this.contractsGlobalService.contracts != null) {
      this.selectedContractId = this.contractsGlobalService.contracts.length === 1 ? this.contractsGlobalService.contracts[0] : null;
    }
  }

  async ngOnInit(): Promise<void> {

    const currentUser = this.storageService.get(StorageKey.currentUser);
    const currentContracts = this.storageService.get(StorageKey.currentContracts);
    const currentPermissions = this.storageService.get(StorageKey.currentPermissions);
    const paramsContract: any = {};

    if (this.router.url.includes('typing')) {
      this.step = 'typing';
      this.stepName = 'digitação';
    }
    if (this.router.url.includes('verify')) {
      this.step = 'verify';
      this.stepName = 'verificação';
    }
    if (this.router.url.includes('validate')) {
      this.step = 'validate';
      this.stepName = 'validação';
    }
    if (this.router.url.includes('triage')) {
      this.step = 'triage';
      this.stepName = 'triagem';
    }
    this.permission = `${this.step.charAt(0).toUpperCase()}${this.step.substring(1)}Violation`;

    const selectedContractIdArray = this.contractsGlobalService.contracts;
    if (selectedContractIdArray && selectedContractIdArray.length === 1) {
      this.selectedContractId = selectedContractIdArray[0];
    }
    if (currentUser.superUser) {
      this.contracts = _.sortBy(await this.contractService.getAll({ order: 'code' }).catch(error => this.contracts));
    }
    this.userService.getAll({order: 'name'}).then(users => {
      this.users = users.filter(user => user.name != null);
      this.usersByContract = this.users;
      this.userGroupService.list({'id[in]': `[${currentUser.groupIds.join(',')}]`})
        .then(async res => {
          const userGroups = res.result;
          this.userGroups = userGroups;
          for (const group of userGroups) {
            await this.preProcessSettingsService.getById(group.contractId).then(async preProcessSettings => {
              const steps = Object.keys(preProcessSettings.violationSteps);
              const permissionByViolationStep = steps.includes(this.step) ||
                (this.step === 'verify' && steps.find(s => s.startsWith('verify')));

              if (currentUser.superUser || (permissionByViolationStep && group.permissions.includes(this.permission))) {
                if (this.contracts.find(c => c.id === group.contractId) == null) {
                  await this.contractService.getById(group.contractId).then(contract => {
                    this.contracts.push(contract);
                    if (this.selectedContractId === contract.id) {
                      this.filterForm.get('contractId').setValue(contract.id);
                    }
                    this.contracts = this.contracts.sort((a, b): any => Number(a.code) - Number(b.code));
                  });
                }
              }
            }).catch(() => {
              console.error('PreProcessSettings ', group.contractId, ' no found');
            });
          }
          if (this.contracts.length === 1) {
            this.filterForm.get('contractId').setValue(this.contracts[0].id);
          }
        });
    });
    this.regionService.getAll().then(regions => {
      const region = regions[0];
      this.regulationService.getAll({ regionId: region.id }).then(regulations => {
        this.regulations = regulations;
      });
    });

    this.situations = [
      {
        id: 'valid',
        name: 'Infrações válidas'
      },
      {
        id: 'invalid',
        name: 'Infrações inválidas'
      }
    ];

    const dataProcessViolation = {
      contracts: this.contracts,
      regulations: this.regulations,
      situations: this.situations
    };

    this.processViolationService.emitDataProcessViolation(dataProcessViolation);

    this.filterForm = this.formBuilder.group({
      contractId: [null, Validators.required],
      equipmentsIds: [null, null],
      spotsIds: [[], null],
      laneIds: [[], null],
      regulationsIds: [[], null],
      startDate: [null, null],
      endDate: [null, null]
    });

    if (this.step !== 'typing') {
      this.filterForm = this.formBuilder.group({
        contractId: [null, Validators.required],
        equipmentsIds: [null, null],
        spotsIds: [[], null],
        laneIds: [[], null],
        regulationsIds: [[], null],
        situationsIds: [[], null],
        reasonsIds: [null, null],
        numberSerie: [null, null],
        numberPlate: [null, null],
        numberId: [null, null],
        startDate: [null, null],
        endDate: [null, null],
        authorIds: [null, null]
      });
    }

    const now = new Date().toISOString();
    this.startDate = moment(now).subtract(30, 'days').utc().format();
    this.minDate = moment(now).subtract(3, 'month').utc().format();
    this.endDate = moment(now).utc().format();
    const startHour = { hour: 3, minutes: 0, seconds: 0 };
    const endHour = { hour: 23, minutes: 59, seconds: 59 };

    this.filterForm.get('startDate').setValue(this.startDate);
    this.filterForm.get('endDate').setValue(this.endDate);

    this.filterForm.get('contractId').valueChanges.subscribe(async contractId => {
      this.toggleClass.contract = true;
      if (this.contractId !== contractId) {
        this.contractId = contractId;
        this.contract = await this.contractService.getById(this.contractId);
        this.equipments = await this.equipmentService.getAll({ contractId, order: 'serialNumber' });
        this.spots = await this.spotService.getAll({ contractId, order: 'code' });
        this.allLanes = await this.laneService.getAll({ contractId, order: 'code' });

        let regulationParams = {};
        if (this.contract.regulationIds) {
          regulationParams = { regionId: this.contract.regionId, 'id[in]': `[${this.contract.regulationIds}]` };
        } else {
          regulationParams = { regionId: this.contract.regionId };
        }
        this.regulations = await this.regulationService.getAll(regulationParams);

        const spotsIds = this.spots.map(spot => spot.id);
        this.lanes = this.allLanes.filter(lane => spotsIds === lane.spotId);
        this.reasons = await this.reasonsService.getAll({
          contractId,
          order: 'code',
          'enabled[bool]': 'true'
        });
        if (this.step === 'verify') {
          this.reasons = this.reasons.filter(reason => reason.screen &&
            (reason.screen.includes('verifyValid') || reason.screen.includes('verifyInvalid')));
          this.showArea.reasons = this.reasons.length > 0 ? true : false;
        }
        this.reasons =  this.reasons.sort((a, b): any => Number(a.code) - Number(b.code));
        this.timezone = this.contracts.filter(contract => contract.id === contractId).map(item => item.timezone).shift();
        const userGroups = this.userGroups.filter(userGroup => userGroup.contractId === contractId);
        if (userGroups.length > 0) {
          this.usersByContract = this.users.filter(user => userGroups.find(group => group.userIds.find(u => u === user.id) != null));
        }
      }

      this.showArea.spots = true;
      this.showArea.lanes = false;
      this.toggleClass.contract = false;
    });

    this.dataProcessViolationsSub = await this.processViolationService.getDataProcessViolation()
      .pipe(filter(result => Object.keys(result).length > 0))
      .subscribe((data: any) => {
        this.contracts = data.contracts;
        this.equipments = data.equipments;
        this.regulations = data.regulations;
        this.situations = data.situations;
        if (this.contracts.length === 1) {
          this.filterForm.get('contractId').setValue(this.contracts[0].id);
        }
        this.filterForm.get('spotsIds').valueChanges.subscribe(spotsIds => {
          this.toggleClass.spot = true;
          this.showArea.lanes = false;

          this.lanes = this.allLanes.filter(lane => spotsIds.includes(lane.spotId));

          this.showArea.lanes = true;
          this.toggleClass.spot = false;
        });

        if (this.step !== 'typing') {
          this.filterForm.get('situationsIds').valueChanges.subscribe(situationsIds => {
            this.showArea.reasons = true;
            this.filterForm.get('reasonsIds').setValue(null);

            if (!situationsIds.includes('invalid')) {
              this.showArea.reasons = false;
              this.filterForm.get('reasonsIds').setValue(null);
            }
          });
        }
        if (this.selectedContractId) {
          this.filterForm.get('contractId').setValue(this.selectedContractId);
        }

        this.loading = true;
      });

    this.filterForm.valueChanges.subscribe(res => {
      this.isValid = this.filterForm.valid;
    });
  }

  toggleCollapse(item) {
    this.showArea[item] = !this.showArea[item];
  }

  async selectModalPeriod() {
    await this.modalService.show(new ComponentModal(SelectPeriodComponent, {
      startDate: this.startDate,
      endDate: this.endDate,
      minDate: this.minDate,
      rangeDates: [[7, 'days'], [15, 'days'], [1, 'month'], [3, 'month']]
    }))
      .catch(() => { })
      .then(data => {
        if (data == null) {
          return;
        }
        const modal = data as any;
        this.modalPeriodSelected = modal && modal.component
          && modal.component.instance
          && modal.component.instance.periodSelected;

        this.startDate = moment(this.modalPeriodSelected.startDate).tz(this.timezone).format();
        this.endDate = moment(this.modalPeriodSelected.endDate).tz(this.timezone).format();

        this.filterForm.get('startDate').setValue(this.startDate);
        this.filterForm.get('endDate').setValue(this.endDate);
      });
  }

  clearEmptiesParams(params) {
    for (const k in params) {
      if (params.hasOwnProperty(k)) {
        if (!params[k] || typeof params[k] === 'string') {
          if (params[k] === undefined || params[k] === '') {
            delete params[k];
          }
        }
        if (!params[k] || typeof params[k] !== 'object') {
          continue;
        }
        if (Object.keys(params[k]).length === 0) {
          delete params[k];
        }
      }
    }
  }

  clearAllSelecteds(params) {
    if (params.hasOwnProperty('spotsIds') && params.spotsIds) {
      if (params.spotsIds.length === this.spots.length) {
        delete params.spotsIds;
      }
    }
    if (params.hasOwnProperty('laneIds') && params.laneIds) {
      if (params.laneIds.length === this.allLanes.length) {
        delete params.laneIds;
      }
    }
    if (params.hasOwnProperty('regulationsIds') && params.regulationsIds) {
      if (params.regulationsIds.length === this.regulations.length) {
        delete params.regulationsIds;
      }
    }
    if (params.hasOwnProperty('situationsIds') && params.situationsIds) {
      if (params.situationsIds.length === this.situations.length) {
        delete params.situationsIds;
      }
    }
    return params;
  }

  submitFilter() {
    let filterParams: any = {};
    filterParams = this.filterForm.value;
    this.clearEmptiesParams(filterParams);
    this.clearAllSelecteds(filterParams);
    if (filterParams.hasOwnProperty('reasonsIds') && filterParams.reasonsIds) {
      filterParams.reasonsCodes = this.reasons.filter(reason => filterParams.reasonsIds.includes(reason.id)).map(res => res.code);
    }
    if (filterParams.hasOwnProperty('spotsIds') && !filterParams.hasOwnProperty('laneIds')) {
      for (const spot of filterParams.spotsIds) {
        const lanesOfSpot = this.lanes.filter(lane => spot === lane.spotId);
        filterParams.laneIds = [];
        for (const lane of lanesOfSpot) {
          filterParams.laneIds.push(lane.code);
        }
      }
    }
    this.processViolationService.emitFilterProcessViolation(filterParams);
    this.router.navigate([`../${this.step}/${this.filterForm.value.contractId}`], { relativeTo: this.route });
  }

  backToPreProcess() {
    this.processViolationService.emitFilterProcessViolation(null);
    this.router.navigate([`../../pre-process`], { relativeTo: this.route });
  }
}
