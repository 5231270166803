<app-enum-i18n [input]="statusLot"
  (output)="statusLotI18n=$event"></app-enum-i18n>
<app-enum-i18n [input]="typeLot" (output)="typeLotI18n=$event"></app-enum-i18n>
<app-enum-i18n [input]="situation"
  (output)="situationI18n=$event"></app-enum-i18n>

<div class="container-fluid">

  <div class="page-title page-title--columns">
    <div class="page-title__left">
      <p class="page-title__link-back" i18n><a
          routerLink="/pre-process">Pré-processamento</a></p>
      <h2 i18n>Gerenciamento de Lotes</h2>
    </div>
  </div>

  <div class="row justify-content-between">
    <div class="col-10">
      <form [formGroup]="searchForm" (submit)="handleSearch()">
        <div class="input-group">
          <input type="text" class="form-control form-search"
            placeholder="Buscar registro"
            formControlName="search" (ngModelChange)="searchKey($event)"
            autocomplete="off">

          <div class="input-group-append">
            <div class="filter-dropdown">
              <button type="button"
                class="btn btn-outline-secondary btn-icon filter-dropdown__handle"
                i18n>
                <i class="material-icons">filter_alt</i> Filtrar</button>

              <div class="filter-dropdown__content">
                <div class="form-group">
                  <app-input type="multiselect" [list]="statusLotI18n"
                    formControlName="status"
                    selectText="value" selectValue="id" selectShowLimit="2"
                    placeholder="Status" i18n-placeholder>
                  </app-input>
                </div>

                <div class="form-group">
                  <app-input type="multiselect" [list]="typeLotI18n"
                    formControlName="type"
                    selectText="value" selectValue="id" selectShowLimit="2"
                    placeholder="Tipo da Infração" i18n-placeholder>
                  </app-input>
                </div>

                <div class="form-group">
                  <app-input type="multiselect" [list]="situationI18n"
                    formControlName="situation"
                    selectText="value" selectValue="id" selectShowLimit="2"
                    placeholder="Situação do Lote" i18n-placeholder>
                  </app-input>
                </div>

                <div class="form-group">
                  <app-input type="multiselect" [list]="sources"
                    formControlName="source"
                    selectText="value" selectValue="id" selectShowLimit="2"
                    placeholder="Origem do Lote" i18n-placeholder>
                  </app-input>
                </div>

                <div class="filter-dropdown__footer">
                  <button i18n class="btn btn-info btn-block"
                    (click)="handleSearch()"
                    data-dismiss="modal">Aplicar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-2">
      <div class="filter-dropdown">
        <button i18n class="btn btn-fade-secondary btn-icon">
          <i class="material-icons">view_column</i>
          Exibir Colunas
        </button>

        <app-column-filter [items]="columns"
          (output)="columnsToShow=$event"></app-column-filter>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading" class="mt-5 mb-3">
    <div class="table-responsive table-block table-block--align-middle">
      <table class="table table-bordeless table-striped table-hover data-table">
        <thead>
          <tr class="mt-3">
            <th class="text-center" i18n
              *ngIf="columnsToShow.includes('contract')">Contrato</th>
            <th class="text-center dropdown-item mt-5"
              [ngClass]="{'ownBgColumn': isOrderFieldSpecific && params?.order === 'name'}"
              i18n *ngIf="columnsToShow.includes('lot')"
              (click)="orderLotByField('name')">
              <i *ngIf="clickCountColunm === 0"
                class="material-icons">swap_vert</i>
              Lote
              <i *ngIf="params?.order === 'name' && clickCountColunm === 1"
                class="material-icons">vertical_align_bottom</i>
              <i *ngIf="params?.order === 'name' && clickCountColunm === 2"
                class="material-icons">vertical_align_top</i>
            </th>
            <th class="text-center" i18n
              *ngIf="columnsToShow.includes('lot-source')">Origem</th>
            <th class="text-center" i18n
              *ngIf="columnsToShow.includes('lot-number')">Número</th>
            <th class="text-center" i18n
              *ngIf="columnsToShow.includes('status')">Status</th>
            <th class="text-center" i18n
              *ngIf="columnsToShow.includes('violationType')">Tipo de
              Infrações</th>
            <th class="text-center" i18n
              *ngIf="columnsToShow.includes('step')">Etapa</th>
            <th class="text-center" i18n
              *ngIf="columnsToShow.includes('situation')">Situação do Lote</th>
            <th class="text-center" i18n
              *ngIf="columnsToShow.includes('violationsAmount')">Qtd de
              Infrações</th>
            <th class="text-center dropdown-item mt-5" i18n
              [ngClass]="{'ownBgColumn': isOrderFieldSpecific && params?.order === 'oldestViolationAt'}"
              *ngIf="columnsToShow.includes('violationPeriod')"
              (click)="orderLotByField('oldestViolationAt')">
              <i *ngIf="clickCountColunm === 0"
                class="material-icons">swap_vert</i>
              Período das Infrações
              <i
                *ngIf="params?.order === 'oldestViolationAt' && clickCountColunm === 1"
                class="material-icons">vertical_align_bottom</i>
              <i
                *ngIf="params?.order === 'oldestViolationAt' && clickCountColunm === 2"
                class="material-icons">vertical_align_top</i>
            </th>
            <th class="text-center" i18n
              *ngIf="columnsToShow.includes('modifiedAt')">Última
              Modificação</th>
            <th
              *appPermission="['UpdateLot, DownloadLot, RemoveLot']; contracts: contract && [contract.id]"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let lot of lots">
            <td class="text-center" i18n
              *ngIf="columnsToShow.includes('contract')"
              (click)="openLotDetail(lot)">{{ contract.prettyName }} </td>
            <td i18n *ngIf="columnsToShow.includes('lot')"
              class="text-center d-flex" (click)="openLotDetail(lot)">
              {{ lot.name }}
              <i i18n-appTooltip class="material-icons icon-sm text-secondary"
                data-placement="top" appTooltip='{{lot.path}}'>info</i>
            </td>
            <td class="text-center" i18n
              *ngIf="columnsToShow.includes('lot-source')"
              (click)="openLotDetail(lot)">{{ sourcesById[lot.source] }}</td>
            <td class="text-center" i18n
              *ngIf="columnsToShow.includes('lot-number')"
              (click)="openLotDetail(lot)">{{ lot.lotNumber }} </td>
            <td class="text-center" *ngIf="columnsToShow.includes('status')"
              (click)="openLotDetail(lot)">
              <div [ngSwitch]="status">
                <div i18n *ngSwitchCase="'process'">Processando</div>
                <div i18n *ngSwitchCase="'audit'">Em Auditoria</div>
                <div i18n *ngSwitchDefault class="nowrap">{{
                  getStatusLot(lot.status) }} <i *ngIf="lot.status==='error'"
                    i18n-appTooltip
                    class="material-icons icon-sm text-secondary"
                    data-placement="top"
                    [appTooltip]="getStatusError(lot)">info</i></div>
              </div>
            </td>
            <td class="text-center" i18n
              *ngIf="columnsToShow.includes('violationType')"
              (click)="openLotDetail(lot)">
              <strong>{{ (lot.type && getTypeLot(lot.type)) || ''
                }}</strong></td>
            <td class="text-center" i18n
              *ngIf="columnsToShow.includes('step') && lot.step==='export'"
              (click)="openLotDetail(lot)">Exportar</td>
            <td class="text-center" i18n
              *ngIf="columnsToShow.includes('step') && lot.step==='internalAudit'"
              (click)="openLotDetail(lot)">Auditoria interna</td>
            <td class="text-center" i18n
              *ngIf="columnsToShow.includes('step') && lot.step==='revision'"
              (click)="openLotDetail(lot)">Revisão</td>
            <td class="text-center" i18n
              *ngIf="columnsToShow.includes('step') && lot.step==='externalAudit'"
              (click)="openLotDetail(lot)">Auditoria externa</td>
            <td class="text-center" i18n
              *ngIf="columnsToShow.includes('step') && lot.step==='waitReturn'"
              (click)="openLotDetail(lot)">Aguardando Retorno</td>
            <td class="text-center" i18n
              *ngIf="columnsToShow.includes('step') && lot.step==='serialNumberGeneration'"
              (click)="openLotDetail(lot)">Geração de número de série</td>
            <td class="text-center" i18n
              *ngIf="columnsToShow.includes('step') && lot.step==='generationLot'"
              (click)="openLotDetail(lot)">Geração de Lote</td>
            <td class="text-center" i18n
              *ngIf="columnsToShow.includes('step') && lot.step==='end'"
              (click)="openLotDetail(lot)">Fim</td>
            <td class="text-center" i18n
              *ngIf="columnsToShow.includes('step') && lot.step==='done'"
              (click)="openLotDetail(lot)">Concluído</td>
            <td class="text-center" i18n
              *ngIf="columnsToShow.includes('step') && !lot.step"
              (click)="openLotDetail(lot)">-</td>
            <td class="text-center" i18n
              *ngIf="columnsToShow.includes('situation')"
              (click)="openLotDetail(lot)">
              <div *ngIf="lot.situation === 'externalAudit'">Auditoria
                Externa</div>
              <div *ngIf="lot.situation === 'export'">Exportação</div>
              <div *ngIf="lot.situation === 'waitEvaluation'">Aguardando
                Avaliação</div>
              <div *ngIf="!lot.situation">-</div>
            </td>
            <td class="text-center" i18n
              *ngIf="columnsToShow.includes('violationsAmount')"
              (click)="openLotDetail(lot)">
              {{ lot.violationsAmount || 0 }} </td>
            <td class="text-center"
              *ngIf="columnsToShow.includes('violationPeriod')"
              (click)="openLotDetail(lot)">
              {{ lot.oldestViolationAt && (lot.oldestViolationAt | moment: 'l')
              || '' }}
              <span i18n
                *ngIf="lot.oldestViolationAt && lot.newestViolationAt">a</span>
              {{ lot.newestViolationAt && (lot.newestViolationAt | moment: 'l')
              || '' }}
            </td>
            <td class="text-center" *ngIf="columnsToShow.includes('modifiedAt')"
              (click)="openLotDetail(lot)">
              {{ lot.modifiedAt && (lot.modifiedAt | moment:
              'DD-MM-YYYY HH:mm:ss') || '-' }}
            </td>
            <td>
              <div *ngIf="permissionsByContract.updateRegenerateLot ||
                permissionsByContract.updateLot ||
                permissionsByContract.updateLotExport ||
                permissionsByContract.downloadLot ||
                permissionsByContract.exportLot ||
                permissionsByContract.deleteLot ||
                permissionsByContract.closeLot">
                <ng-container
                  *appPermission="['UpdateLot', 'CloseLot']; contracts: contract && [contract.id]">
                  <div class="dropdown"
                    *ngIf="lot.status === 'open' && lot.violationsAmount > 0">
                    <button class="btn btn-icon-block" type="button"
                      id="dropdownMenuButton" data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"><i
                        class="material-icons">more_horiz</i></button>
                    <div class="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuButton">
                      <a i18n class="dropdown-item"
                        (click)="openCloseLotModal(lot)">Fechar lote</a>
                    </div>
                  </div>
                </ng-container>
                <ng-container
                  *appPermission="['UpdateRegenerateLot', 'UpdateLotExport', 'ExportLot', 'DeleteLot', 'DownloadLot']">
                  <div class="dropdown"
                    *ngIf="(lot.status === 'error' || lot.status === 'closed' || lot.status === 'done') &&
                    lot.violationsAmount > 0 && verifyRegenerateLotPeriod(lot.modifiedAt) && (lot.step === 'export' || lot.step === 'generationLot' || lot.step === 'done')">
                    <button class="btn btn-icon-block" type="button"
                      id="dropdownMenuButton" data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"><i
                      class="material-icons">more_horiz</i>
                    </button>
                    <div class="loading-spinner" *ngIf="loadindLotDownload[lot?.id] || loadindLotRemove[lot?.id]">
                      <div class="bounce1"></div>
                      <div class="bounce2"></div>
                      <div class="bounce3"></div>
                    </div>
                    <div class="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuButton">
                      <span
                        *ngIf="lot?.pathS3 && permissionsByContract.downloadLot && ['closed', 'done'].includes(lot?.status)">
                        <a i18n class="dropdown-item" (click)="downloadLot(lot)"
                          *appPermission="['DownloadLot']">Baixar lote</a>
                      </span>
                      <span *ngIf="permissionsByContract.updateLotExport">
                        <a i18n class="dropdown-item" (click)="cancelLot(lot)"
                          *appPermission="['UpdateLotExport']">Cancelar lote</a>
                      </span>
                      <span *ngIf="permissionsByContract.exportLot">
                        <a i18n class="dropdown-item"
                          (click)="exportLot(lot.id)"
                          *appPermission="['ExportLot']">Exportar lote</a>
                      </span>
                      <span *ngIf="permissionsByContract.updateRegenerateLot">
                        <a i18n class="dropdown-item"
                          (click)="regenerateLot(lot.id)"
                          *appPermission="['UpdateRegenerateLot']">Regerar lote</a>
                      </span>
                      <span
                        *ngIf="permissionsByContract.deleteLot && ['closed', 'done'].includes(lot?.status)">
                        <a i18n class="dropdown-item" (click)="openRemoveModal(lot)"
                          *appPermission="['DeleteLot']">Remover lote</a>
                      </span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <app-pagination (items)="setLots($event)" (loading)="loadingEvent($event)"
    [params]="params" [deleteEvent]="deleteEvent" [service]="lotService">
  </app-pagination>

</div>
