<app-enum-i18n [input]="situationIds" (output)="situationIdsI18n=$event"></app-enum-i18n>
<div class="page-title page-title--columns">
  <div class="page-title__left">
    <p class="page-title__link-back" i18n><a routerLink="/pre-process">Pré-processamento</a></p>
    <h2 i18n>Gerar Lotes</h2>
  </div>
</div>
<div class="filter mb-4">
  <div class="filter__header">
    <h5 i18n>Filtrar Infrações</h5>

    <!-- <button class="btn btn-sm btn-icon-block"
      [ngClass]="{ 'btn-outline-secondary': !collapse, 'btn-secondary': collapse }">
      <i class="material-icons" *ngIf="collapse">unfold_more</i>
      <i class="material-icons" *ngIf="!collapse">unfold_less</i>
    </button> -->
  </div>

  <div>
    <form class="form-horizontal" [formGroup]="filterForm">
      <div class="filter__area">
        <div class="filter__block">
          <label i18n>Grupo de Pontos:</label>
          <app-multiselect type="inline" [list]="spotGroups" formControlName="spotGroupIds" selectValue="id" selectText="name"
            itemsOnList="0" buttonLabel="Grupo de Pontos" charactersOnItem="10">
          </app-multiselect>
        </div>

        <div class="filter__block">
          <label i18n>Ponto:</label>
          <app-multiselect type="inline" [list]="spots" formControlName="spotIds" selectValue="id" selectText="prettyName"
            itemsOnList="0" buttonLabel="Pontos" charactersOnItem="10">
          </app-multiselect>
        </div>

        <div class="filter__block">
          <label i18n>Faixa:</label>
          <app-multiselect type="inline" [list]="lanes" formControlName="laneIds" selectValue="id" selectText="prettyName"
            itemsOnList="0" buttonLabel="Faixas" charactersOnItem="10">
          </app-multiselect>
        </div>

        <div class="filter__block">
          <label i18n>Enquadramentos:</label>
          <app-multiselect type="inline" [list]="regulations" formControlName="regulationIds" selectValue="id"
            selectText="prettyName" itemsOnList="0" buttonLabel="Enquadramentos" charactersOnItem="10">
          </app-multiselect>
        </div>

        <div class="filter__block">
          <label i18n>Status:</label>
          <app-multiselect type="inline" [list]="situationIdsI18n" formControlName="situationIds" selectValue="id" selectText="value"
            itemsOnList="0" buttonLabel="Status">
          </app-multiselect>
        </div>

        <div *ngIf="isViewReason" class="filter__block">
          <label i18n>Motivos da invalidação:</label>
          <app-multiselect type="inline" [list]="reasons" formControlName="reasonIds" selectValue="id" selectText="prettyName"
            itemsOnList="0" buttonLabel="Motivo da invalidação">
          </app-multiselect>
        </div>

        <div class="filter__block">
          <label i18n>Modelos de Equipamento:</label>
          <app-multiselect type="inline" [list]="equipmentModels" formControlName="equipmentModelIds" selectValue="id" selectText="prettyName"
            itemsOnList="0" buttonLabel="Modelos de Equipamento">
          </app-multiselect>
        </div>
      </div>
      <div class="row">
        <div class="col-10 input-group">
          <input type="text" class="form-control form-search mr-4 ml-3"
            placeholder="Buscar registro"
            formControlName="search" (ngModelChange)="searchKey($event)"
            autocomplete="off">
        </div>
      </div>
      <div class="filter__button">
        <button type="submit" class="btn btn-outline-secondary mr-3" (click)="redefineFilter()" i18n>Redefinir
          filtro</button>
        <button type="submit"
          class="btn" [ngClass]="{ 'btn-primary': true, disabled: !isValid }" i18n>
          <app-loading (click)="submitFilter()" text="Aplicar filtro" i18n-text></app-loading>
        </button>
      </div>
    </form>

    <ng-template #loadingBlock>
      <div class="loading-page">
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<div class="card mb-2">
  <div class="row">
    <div class="col-4">
      <div class="period-filter">
        <p i18n class="period-filter__label ml-4 mb-0">Período:</p>
        <div class="card-body d-f align-items-center">
          <p (click)="selectModalPeriod()" class="period-filter__area d-flex align-items-center mb-0 cursor-pointer">
            <i class="material-icons mr-2">date_range</i>
            <span i18n>De {{ startDate | moment: 'L' }} à {{ endDate | moment: 'L' }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="col-6"></div>
    <div class="col-2 mt-5">
      <div class="filter-dropdown ml-5">
        <button i18n class="btn btn-fade-secondary btn-icon">
          <i class="material-icons">view_column</i>
          Exibir Colunas
        </button>
        <app-column-filter [items]="columns"
        (output)="columnsToShow=$event"></app-column-filter>
      </div>
    </div>
  </div>
</div>
<div class="mt-5">
  <table class="table table-hover table-block">
    <thead>
      <tr>
        <th class="text-center">
          <mat-checkbox i18n color="primary" (change)="allClick($event.checked)" [checked]="isCheckedAll">
          </mat-checkbox>
        </th>
        <th class="text-center" *ngIf="columnsToShow.includes('passId')" i18n>ID do Registro</th>
        <th class="text-center" *ngIf="columnsToShow.includes('violationDate')" i18n>Data/Hora da Infração</th>
        <th class="text-center" *ngIf="columnsToShow.includes('spot')" i18n>Ponto</th>
        <th class="text-center" *ngIf="columnsToShow.includes('lane')" i18n>Faixa</th>
        <th class="text-center" *ngIf="columnsToShow.includes('regulation')" i18n>Enquadramento</th>
        <th class="text-center" *ngIf="columnsToShow.includes('equipmentModel')" i18n>Modelo do Equipamento</th>
        <th class="text-center" *ngIf="columnsToShow.includes('status')" i18n>Status</th>
        <th class="text-center" *ngIf="columnsToShow.includes('reason')" i18n>Motivo de Invalidação</th>
        <!-- visualizar infração -->
        <th class="text-center" *ngIf="gb.isOn('ecs-3286')"></th>
        <!--fim visualizar infração -->
      </tr>
    </thead>
    <tbody *ngIf="!isLoading">
      <tr *ngFor="let violation of violations" [ngClass]="{'bg-light':violationsSelected[violation.id]?.isChecked}">
        <td class="text-center">
          <mat-checkbox i18n color="primary" [disabled]=false
                        [checked]="violationsSelected[violation.id]?.isChecked"
                        (change)="selectViolation($event.checked, violation.id)">
          </mat-checkbox>
        </td>
        <td class="text-center" *ngIf="columnsToShow.includes('passId')" i18n>{{violation.passId}}</td>
        <td class="text-center" *ngIf="columnsToShow.includes('violationDate')" i18n>{{violation.date | moment: 'L LTS'}}</td>
        <td class="text-center" *ngIf="columnsToShow.includes('spot')" appTooltip="{{getSpotDescription(violation.spotCode)}}" i18n>{{violation.spotCode}}</td>
        <td class="text-center" *ngIf="columnsToShow.includes('lane')" i18n>{{violation.laneCode}}-Fx{{violation.laneNumber}}</td>
        <td class="text-center" *ngIf="columnsToShow.includes('regulation')" appTooltip="{{getRegulation(violation.regulationId)}}" i18n>{{violation.regulationId}}</td>
        <td class="text-center" *ngIf="columnsToShow.includes('equipmentModel')" i18n>{{violation.equipment?.modelName}}</td>
        <td class="text-center" *ngIf="columnsToShow.includes('status')" i18n>{{translate(violation.status)}}</td>
        <td class="text-center" *ngIf="columnsToShow.includes('reason')" i18n>{{violation.reason?.code}} - {{violation.reason?.name}} </td>
        <!-- Visualizar infração -->
        <td class="text-center" i18n *ngIf="gb.isOn('ecs-3286')">
          <button type="button" class="btn btn-sm btn-fade-secondary btn-icon-block" (click)="showDetails(violation)">
            <i class="material-icons">manage_search</i>
          </button>
        </td>
        <!-- fim Visualizar infração -->
      </tr>
    </tbody>
  </table>
  <app-pagination (items)="setViolations($event)" [params]="params" [deleteEvent]="deleteEvent"
      [service]="violationService" (loading)="loadingEvent($event)">
  </app-pagination>
  <hr>
  <div class="d-flex justify-content-end my-4" *ngIf="violations.length > 0">
    <div class="col-sm-10 col-md-10 mt-xs-10 mt-md-0 text-right">
      <span class="text-small text-right">{{ idChecked.length }} Infrações selecionadas</span>
    </div>
    <button class="btn btn-primary" [disabled]="!idChecked.length > 0"  i18n>
      <app-loading (click)="openLotCreate()" text="Gerar Lote" i18n-text></app-loading>
    </button>
  </div>
</div>
