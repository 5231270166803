import { Component, OnInit, EventEmitter } from '@angular/core';
import { SerialNumberConfigService, SpotService, EquipmentModelService, RegulationService,
  ModalService, AlertService, ContractService, LaneService } from 'src/app/core/services';
import { SerialNumberConfig, Spot, Regulation, EquipmentModel, ComponentModal,
  AlertType, AlertItem, MessageModal, Lane } from 'src/app/core/models';
import { ActivatedRoute } from '@angular/router';
import { SerialNumberConfigCreateModalComponent } from '../create-modal/create-modal.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ViewModalComponent } from '../view-modal/view-modal.component';
import { Console } from 'console';

@Component({
  selector: 'app-serial-number-config-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class SerialNumberConfigListComponent implements OnInit {
  public serialNumberConfigs: SerialNumberConfig[] = [];
  public spotsById: { [key: string]: Spot } = {};
  public laneById: { [key: string]: Lane } = {};
  public regulationsById: { [key: string]: Regulation } = {};
  public regulations: Regulation[] = [];
  public equipmentModelsById: { [key: string]: EquipmentModel } = {};
  public equipmentModels: EquipmentModel[] = [];
  public columns = [
    'enabled', 'identifier', 'initials', 'verifyingDigit', 'equipmentModel', 'regulation', 'lane', 'situation',
    'startNumber', 'endNumber', 'lastUsed', 'actions'
  ];
  public columnsToShow: string[] = [];
  public params: any = {};
  public deleteEvent: EventEmitter<any> = new EventEmitter();
  public promise = null;
  public searchForm: FormGroup;
  public modelChanged: Subject<string> = new Subject<string>();
  private contractId: string;

  constructor(
    public serialNumberConfigService: SerialNumberConfigService,
    private spotService: SpotService,
    private regulationService: RegulationService,
    private equipmentModelService: EquipmentModelService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private contractService: ContractService,
    private laneService: LaneService
  ) {
    this.modelChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
  }

  ngOnInit() {
    this.contractId = this.activatedRoute.snapshot.params.contractId;
    this.params = { contractId: this.contractId };
    this.contractService.getById(this.contractId).then(contract => {
      let regulationParams = {};
      if (contract.regulationIds) {
        regulationParams = { regionId: contract.regionId, 'id[in]': `[${contract.regulationIds}]` };
      } else {
        regulationParams = { regionId: contract.regionId };
      }
      this.regulationService.getAll(regulationParams, true).then(regulations => {
        this.regulations = regulations;
        regulations.forEach(regulation => {
          this.regulationsById[regulation.id] = regulation;
        });
      });
      this.spotService.getAll(this.params).then(spots => {
        spots.forEach(spot => {
          this.spotsById[spot.id] = spot;
        });
      });
      this.getLanes();
    });
    const paramsContract = {};
    paramsContract['contractIds[contains]'] = this.contractId;

    this.equipmentModelService.getAll(paramsContract).then(equipmentModels => {
      this.equipmentModels = equipmentModels;
      equipmentModels.forEach(equipmentModel => {
        this.equipmentModelsById[equipmentModel.id] = equipmentModel;
      });
    });

    this.createSearchForm();
    this.handleSearch();
  }

  async getLanes() {
    await this.laneService.getAll(this.params).then(lanes => {
      lanes.forEach(lane => {
        this.laneById[lane.id] = lane;
      });
    });
  }

  async openCreateSerialNumberConfigModal(index: number = null, view: string = null) {
    let serialNumberConfig = null;
    if (index != null) {
      serialNumberConfig = this.serialNumberConfigs[index];
    }
    await this.modalService.show(new ComponentModal(SerialNumberConfigCreateModalComponent,
      { contractId: this.contractId, view, serialNumberConfig }))
      .then(data => {
        this.handleSearch();
      });
  }

  async updateEnabled(serialNumberConfig: SerialNumberConfig) {
    if (this.promise != null) {
      serialNumberConfig.enabled = !serialNumberConfig.enabled;
      serialNumberConfig.currentNumber = Number(serialNumberConfig.currentNumber);
      return;
    }
    this.promise = this.serialNumberConfigService.update(serialNumberConfig, false).then(() => {
      this.handleSearch();
      this.alertService.show(new AlertItem('SerialNumberConfigUpdateSuccess', AlertType.success));
    }).catch(err => {
      if (err.error === 'Cannot update a SerialNumberConfig after generation started') {
        this.alertService.show(new AlertItem('SerialNumberConfigGenerationStarted', AlertType.danger));
        return;
      }
      this.alertService.show(new AlertItem('SerialNumberConfigUpdateError', AlertType.danger));
    });
    await this.promise;
    this.promise = null;
  }

  isStarted(serialNumberConfig) {
    return serialNumberConfig.currentNumber != null && serialNumberConfig.currentNumber > serialNumberConfig.range.start;
  }

  openDeleteModal(serialNumberConfig: SerialNumberConfig) {
    this.modalService.show(new MessageModal('Remover faixa de série', 'Deseja remover esta faixa de série?', true))
      .then(() => {
        this.deleteSerialNumberConfig(serialNumberConfig);
      }).catch(err => { });
  }

  async deleteSerialNumberConfig(serialNumberConfig: SerialNumberConfig) {
    this.promise = this.serialNumberConfigService.delete(serialNumberConfig.id).then(() => {
      this.alertService.show(new AlertItem('SerialNumberConfigDeleteSuccess', AlertType.success));
    }).catch(err => {
      this.alertService.show(new AlertItem('SerialNumberConfigDeleteError', AlertType.danger));
    });
    await this.promise;
    this.promise = null;
    this.handleSearch();
  }

  handleSearch(event = null) {
    if (event != null) {
      event.preventDefault();
    }
    this.serialNumberConfigs = [];
    const filterObj = {
      contractId: this.contractId
    };
    const search = this.searchForm.get('search').value;
    const equipmentModelIds = this.searchForm.get('equipmentModelIds').value;
    const regulationIds = this.searchForm.get('regulationIds').value;
    const status = this.searchForm.get('violationStatusSearch').value;

    if (search != null && search !== '') {
      filterObj['prefix[contains,or]'] = `${search}`;
      filterObj['verifyingDigit[contains,or]'] = `${search}`;
      filterObj['identifier[contains,or]'] = `${search}`;
    }

    if (equipmentModelIds != null && equipmentModelIds.length > 0) {
      filterObj['equipmentModelIds[contains,or]'] = `[${equipmentModelIds.join(',')}]`;
    }
    if (regulationIds != null && regulationIds.length > 0) {
      filterObj['regulationIds[contains,or]'] = `[${regulationIds.join(',')}]`;
    }
    if (status != null && status !== '') {
      if (status === 'valid') {
        filterObj['violationStatus[like]'] = `[${status}%]`;
      } else {
        filterObj['violationStatus[like]'] = `[%${status}%]`;
      }
    }
    this.params = filterObj;
  }
  searchKey(text: string) {
    this.modelChanged.next(text);
  }

  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      search: [''],
      equipmentModelIds: [''],
      regulationIds: [''],
      violationStatusSearch: ['']
    });
  }

  openModalInfo(data) {
    const modalData = {
      serialNumberConfig: data,
      equipmentModelsById: this.equipmentModelsById,
      regulationsById: this.regulationsById,
      contractId: this.contractId
    };
    this.modalService.show(new ComponentModal(ViewModalComponent, modalData));
  }

  setData(data) {
    this.serialNumberConfigs = data;
  }
}
