import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'src/app/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { ViolationsRoutingModule } from './violations-routing.module';
import { ViolationsDashboardComponent } from './dashboard/dashboard.component';
import { ViolationsReportComponent } from './reports/violations/violations.component';
import { ViolationsReportListComponent } from './reports/violations/list/list.component';
import { ViolationsReportContractComponent } from './reports/violations/contract/contract.component';
import { ViolationsReportViewComponent } from './reports/violations/contract/view/view.component';
import { ViolationsReportByDateComponent } from './reports/violations/contract/by-date/by-date.component';
import { ViolationsReportFilterComponent } from './reports/violations/contract/filter/filter.component';
import { ViolationsReportPeriodComponent } from './reports/violations/contract/period/period.component';
import { ViolationsReportDetailsComponent } from './reports/violations/contract/details/details.component';
import { ViolationsViewComponent } from './view/view.component';
import { ViolationsViewFilterPageComponent } from './view/filter-page/filter-page.component';
import { ViolationsViewContractComponent } from './view/contract/contract.component';
import { ViolationsViewFilterComponent } from './view/contract/filter/filter.component';
import { ViolationsViewPeriodComponent } from './view/contract/period/period.component';
import { MosaicComponent } from './view/mosaic/mosaic.component';

@NgModule({
  declarations: [
    ViolationsDashboardComponent,
    ViolationsReportComponent,
    ViolationsReportListComponent,
    ViolationsReportContractComponent,
    ViolationsReportViewComponent,
    ViolationsReportByDateComponent,
    ViolationsReportFilterComponent,
    ViolationsReportPeriodComponent,
    ViolationsReportDetailsComponent,
    ViolationsViewComponent,
    ViolationsViewFilterPageComponent,
    ViolationsViewContractComponent,
    ViolationsViewFilterComponent,
    ViolationsViewPeriodComponent,
    MosaicComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    ViolationsRoutingModule
  ]
})
export class ViolationsModule { }
