import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ClosedModalError } from 'src/app/core/errors';
import { AlertItem, AlertType, Contract, Lot, MessageModal,
  StatusLot, Situation, TypeLot, ComponentModal, User } from 'src/app/core/models';
import { AlertService, ContractService, LotService, ModalService, StorageKey, StorageService } from 'src/app/core/services';
import { LotDetailsComponent } from 'src/app/modals/lot-details/lot-details.component';

@Component({
  selector: 'app-lot-list',
  templateUrl: './lot-list.component.html',
  styleUrls: ['./lot-list.component.sass']
})
export class LotListComponent implements OnInit {
  public lots: Array<Lot> = [];
  public columns = [
    'contract',
    'lot',
    'lot-source',
    'lot-number',
    'extraData',
    'status',
    'violationType',
    'step',
    'situation',
    'violationsAmount',
    'violationPeriod',
    'modifiedAt'
  ];
  public columnsToShow = [];
  public params: any = {};
  public statusLot = Object.keys(StatusLot);
  public statusLotI18n = [];
  public situation = Object.keys(Situation);
  public situationI18n = [];
  public typeLot = Object.keys(TypeLot);
  public typeLotI18n = [];
  public deleteEvent: EventEmitter<any> = new EventEmitter();
  public contract: Contract = new Contract();
  public modelChanged: Subject<string> = new Subject<string>();
  public searchForm: FormGroup;
  public contractId: string;
  public isLoading: boolean;
  public isOrderFieldSpecific = false;
  public sources: Array<{ id: string; value: string }> = [
    { id: 'internalAudit', value: 'Auditoria Interna' },
    { id: 'externalAudit', value: 'Auditoria Externa' },
    { id: 'export', value: 'Exportação' }
  ];
  public sourcesById: { [param: string]: string } = {};
  public clickCountColunm = 0;
  public paramsOrderColumn: any = {};
  public loadindLotDownload: { [param: number]: boolean } = {};
  public loadindLotRemove: { [param: number]: boolean } = {};
  public user: User;
  public permissionsByContract = {};

  constructor(
    public lotService: LotService,
    public activateRoute: ActivatedRoute,
    private alertService: AlertService,
    private contractService: ContractService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private storageService: StorageService
  ) {
    this.modelChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
  }

  ngOnInit() {
    this.isLoading = true;
    this.contractId = this.activateRoute.snapshot.params.contractId;
    this.columnsToShow = this.columns.slice(0);
    this.params = {
      contractId: `${this.contractId}`,
      ['sortAsc']: 'false',
      order: 'oldestViolationAt,newestViolationAt'
    };
    this.user = this.storageService.get(StorageKey.currentUser);
    this.contractService.getById(this.contractId).then(contract => this.contract = contract);
    this.createSearchForm();
    this.sources.map(source => {
      this.sourcesById[source.id] = source.value;
    });
    this.loadPermissions();
  }

  loadingEvent(valueEmitted) {
    this.isLoading = valueEmitted;
  }

  async loadPermissions() {
    this.permissionsByContract = {
      exportLot: true,
      updateRegenerateLot: true,
      downloadLot: true,
      updateLotExport: true,
      closeLot: true,
      deleteLot: true
    };

    if (!this.user.superUser) {
      const contracts = this.storageService.get(StorageKey.currentPermissions);
      const contract = contracts.find(c => c.contractId === Number(this.contractId));
      const exportLot = contract.actionIds.includes('ExportLot');
      const updateRegenerateLot = contract.actionIds.includes('UpdateRegenerateLot');
      const downloadLot = contract.actionIds.includes('DownloadLot');
      const updateLotExport = contract.actionIds.includes('UpdateLotExport');
      const closeLot = contract.actionIds.includes('CloseLot');
      const deleteLot = contract.actionIds.includes('DeleteLot');

      this.permissionsByContract = {
        exportLot,
        updateRegenerateLot,
        downloadLot,
        updateLotExport,
        closeLot,
        deleteLot
      };
    }
  }

  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      search: [''],
      type: [''],
      status: [''],
      situation: [''],
      source: ['']
    });
  }

  getStatusLot(statusLot) {
    const status = this.statusLotI18n.find(s => s.id === statusLot);
    return (status && status.value) || '';
  }

  getTypeLot(typeLot) {
    const type = this.typeLotI18n.find(t => t.id === typeLot);
    return type && type.value || '';
  }

  getSituation(situationLot) {
    const situation = this.situationI18n.find(s => s.id === situationLot);
    return situation && situation.value || '';
  }

  openCloseLotModal(lot) {
    this.modalService.show(new MessageModal(`Fechar lote`, `Deseja fechar o lote ${lot.name}?`, true))
      .then(() => {
          lot.status = 'closed';
          this.lotService.closeLot(lot).then(() => {
          const lotCurrent = this.lots.find(item => item.id === lot.id);
          lotCurrent.status = 'closed';

          this.alertService.show(
            new AlertItem('LotClosed', AlertType.success)
          );
        }).catch(error => {
          this.alertService.show(new AlertItem('LotClosedError', AlertType.danger));
        });
      }).catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
        }
      });
  }

  searchKey(text: string) {
    this.modelChanged.next(text);
  }

  regenerateLot(id: string, name: string) {
    this.modalService.show(new MessageModal(`Regerar o lote`, `Tem certeza que deseja regerar o lote?`, true))
      .then(() => this.lotService.regenerateLot(id).then(res => {
          const lot = this.lots.find(item => item.id === id);
          this.handleSearch();
          this.alertService.show(
            new AlertItem('LotRegenerate', AlertType.success)
          );
        }).catch(error => {
          console.error(error.error);
          this.alertService.show(new AlertItem('LotRegenerateError', AlertType.danger));
        })).catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
        }
      });
  }

  exportLot(id: string, name: string) {
    this.modalService.show(new MessageModal(`Exportar o lote`, `Tem certeza que deseja exportar o lote?`, true))
      .then(() => this.lotService.exportLot(id).then(res => {
          const lot = this.lots.find(item => item.id === id);
          this.handleSearch();
          this.alertService.show(
            new AlertItem('LotExport', AlertType.success)
          );
        }).catch(error => {
          console.error(error.error);
          this.alertService.show(new AlertItem('LotExportError', AlertType.danger));
        })).catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
        }
      });
  }

  verifyRegenerateLotPeriod(modifiedAt: string) {
    return (modifiedAt > moment().utc().subtract(30, 'days').format());
  }

  handleSearch() {
    const filterObj: any = {};
    const search = this.searchForm.get('search').value;
    const type = this.searchForm.get('type').value;
    const situation = this.searchForm.get('situation').value;
    const status = this.searchForm.get('status').value;
    const source = this.searchForm.get('source').value;
    let isFilter = false;
    if (search != null && search !== '') {
      filterObj['name[contains,or]'] = `${search}`;
      isFilter = true;
    }
    if (situation !== '' && situation.length > 0) {
      filterObj['situation[contains,in]'] = `[${situation}]`;
      isFilter = true;
    }
    if (type !== '' && type.length > 0) {
      filterObj['type[contains,in]'] = `[${type}]`;
      isFilter = true;
    }
    if (status !== '' && status.length > 0) {
      filterObj['status[contains,in]'] = `[${status}]`;
      isFilter = true;
    }
    if (source !== '' && source.length > 0) {
      filterObj['source[contains,in]'] = `[${source}]`;
      isFilter = true;
    }
    if (isFilter) {
      delete this.paramsOrderColumn.name;
      delete this.paramsOrderColumn['source[contains,in]'];
      delete this.paramsOrderColumn['status[contains,in]'];
      delete this.paramsOrderColumn['type[contains,in]'];
      delete this.paramsOrderColumn['situation[contains,in]'];
    }

    this.params = {
      contractId: `${this.contractId}`,
      ...filterObj,
      ...this.paramsOrderColumn
    };
  }

  getStatusError(lot) {
    if (lot?.errorLogs) {
      return lot?.errorLogs?.preExportError;
    } else {
      return lot?.status;
    }
  }

  openLotDetail(lot: Lot) {
    this.modalService.show(new ComponentModal(LotDetailsComponent, lot));
  }

  cancelLot(lot: Lot) {
    this.modalService.show(new MessageModal('Cancelar Lote', `Deseja cancelar o lote ${lot.name}`, true))
      .then(() => {
        this.lotService.lotSerproCancel(lot.id).then(res => {
          this.alertService.show(new AlertItem('CanceledLotSuccess', AlertType.success, false, 5000));
        }).catch(err => {
          this.alertService.show(new AlertItem('CanceledLotError', AlertType.danger, false, 5000));
        });
      })
      .catch(err => {});
  }

  orderLotByField(field) {
    this.paramsOrderColumn = Object.assign({}, this.params);
    this.clickCountColunm += 1;
    this.isOrderFieldSpecific = true;
    if (this.clickCountColunm > 2) {
      this.isOrderFieldSpecific = !this.isOrderFieldSpecific;
    }
    // if (this.paramsOrderColumn.order == null || this.paramsOrderColumn.order === field) {
    // }
    delete this.paramsOrderColumn.sortAsc;
    delete this.paramsOrderColumn.order;
    if (this.isOrderFieldSpecific) {
      this.paramsOrderColumn.order = field;
      if (this.clickCountColunm === 1) {
        this.paramsOrderColumn.sortAsc = 'true';
      } else {
        this.paramsOrderColumn.sortAsc = 'false';
      }
    } else {
      this.clickCountColunm = 0;
      this.paramsOrderColumn.sortAsc = 'false';
    }
    console.log('----------- paramsOrderColumn', this.paramsOrderColumn)
    this.handleSearch();
  }

  async downloadLot(lot) {
    this.loadindLotDownload[lot.id] = true;
    await this.lotService.downloadLot(lot.id).then(res => {
      this.alertService.show(
        new AlertItem('DownloadLotSuccess', AlertType.success)
      );
      const url = res.url;
      const btn = document.createElement('a');
      btn.href = url;
      btn.download = lot.name;
      btn.click();
      btn.remove();
    });
    this.loadindLotDownload[lot.id] = false;
  }

  setLots(list) {
    this.lots = list.filter((item, index, self) =>
        index === self.findIndex(obj => obj.id === item.id)
    );
  }

  openRemoveModal(lot: Lot) {
    this.modalService.show(new MessageModal(
      'Remover Lote',
      `Tem certeza que deseja remover o lote (${lot.name})? As infrações pertencentes ao lote serão encaminhadas para atribuição de lote.`,
      true)
    ).then(() => {
        this.removeLot(lot);
      });
  }

  async removeLot(lot) {
    this.loadindLotRemove[lot.id] = true;
    await this.lotService.removeLot(lot.id).then(res => {
      this.handleSearch();
      this.alertService.show(
        new AlertItem('RemoveLotSuccess', AlertType.success)
      );
      this.loadindLotRemove[lot.id] = false;
    }).catch(err => {
      this.alertService.show(
        new AlertItem('RemoveLotError', AlertType.danger)
      );
      console.error(err);
      this.loadindLotRemove[lot.id] = false;
    });
  }
}
