<div class="modal-dialog modal-full modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n><strong>Criar Lote Manual</strong></h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body pt-0">
      <div class="loading-page" *ngIf="loading">
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
      <div class="mt-3" *ngIf="!loading">
        <form [formGroup]="form">
          <div class="row">
              <div class="row">
                <div class="mt-3 col-md-12"><p i18n>Foram selecionadas {{lotSize}} infrações</p></div>
              </div>
            <div class="mt-3 col-md-12">
              <app-input i18n-label inputRequired=true type="text" [ngModel]="name"
                label="Nome Do Lote" formControlName="name">
              </app-input>
            </div>
          </div>
        </form>

      </div>
    </div>
    <div class="modal-footer">
      <button (click)="onCancel()" class="btn btn-outline-secondary">Cancelar</button>
      <button (click)="onSubmit()" [ngClass]="{btn: true, 'btn-primary': true, disabled: !isValid}">Confirmar</button>
    </div>
  </div>
</div>
