import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import {AlertService, ModalService, PreProcessSettingsService, ViolationService, LotService} from 'src/app/core/services';
import {AlertItem, AlertType, ComponentModal, Lot, MessageModal, PreProcessSettings, Violation} from 'src/app/core/models';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as moment from 'moment-timezone';
import { LotExtraDataComponent } from '../lot-extraData/lot-extraData.component';
import {DetailsViolationComponent} from '../details-violation/details.component';

@Component({
  selector: 'app-lot-details',
  templateUrl: './lot-details.component.html',
  styleUrls: ['./lot-details.component.sass'],
  animations: [
    trigger('openPanel', [
      state('isOpen', style({
        height: 300,
        opacity: 1
      })),
      state('closed', style({
        height: 0,
        opacity: 0,
        paddingTop: 0,
        paddingBottom: 0
      })),
      transition('closed => isOpen', animate('500ms ease-out')),
      transition('isOpen => closed', animate('500ms ease-out'))
    ])
  ]
})
export class LotDetailsComponent extends BaseModal implements OnInit {
  @Input() initialState;
  isValid: boolean;
  lastState: any;
  public loading: boolean;
  public preProcessSettings: PreProcessSettings;
  public violations: Array<Violation> = [];
  public idChecked: any =  [];
  public violationsSelected: {
    [key: string]: {
      contractId: string;
      isChecked: boolean;
    };
  } = {};
  public isChecked = false;
  public isCheckedAll = false;
  public isAudit = false;
  public violationIds = [];
  public currentPage = 0;
  public violationsDataPaginated: any[];
  public params: any = {};
  public violationsCountByDate: any = [];
  public routeByDate = 'bylot';

  public itemParams: any = {};
  public checkBox: boolean;
  public lot: Lot;

  constructor(
    modalService: ModalService,
    public violationService: ViolationService,
    private preProcessSettingsService: PreProcessSettingsService,
    private alertService: AlertService,
    private lotService: LotService
  ) {
    super(modalService);
   }

  async ngOnInit() {
    this.checkBox= false;
    this.loading = true;
    localStorage.setItem('lotDetailsCopy', JSON.stringify(this.initialState));
    this.lot = this.initialState;
    this.setParams();
    await this.getPreProcessSettings();
    await this.getDates();
    this.loading = false;
  }

  setParams() {
    const property = ['externalAudit', 'internalAudit'].includes(this.initialState.source) ? 'lotIdAudit' : 'lotId';
    this.params[property] = this.initialState.id;
    this.params.order = 'date';
    this.params.contractId = this.initialState.contractId;
  }

  async getDates() {
    await this.violationService.byDate(this.params, this.routeByDate).then(res => {
      res.violationsCountByDate.map(item => {
        item.isOpen = false;
        item.params = this.params;
        item.params.startsAt = moment(item.date).set({hour:3,minute:0,second:0,millisecond:0})
          .format('YYYY-MM-DD[T]HH:mm:ss') + 'Z';
        item.params.endsAt = moment(item.date).set({hour:23,minute:59,second:59,millisecond:59})
          .format('YYYY-MM-DD[T]HH:mm:ss') + 'Z';
        item.violations = [];
      });
      this.violationsCountByDate = res.violationsCountByDate;
    });
  }

  async getPreProcessSettings() {
    await this.preProcessSettingsService.getById(this.initialState.contractId).then(res => {
      this.preProcessSettings = res;
      const lotSteps = Object.keys(this.preProcessSettings.lotSteps);
      this.isAudit = lotSteps.find(l => ['externalAudit', 'internalAudit'].includes(l)) != null ? true : false;
    });
  }

  async onAllSubmited(): Promise<any> {
  }

  async openLotDetail(item) {
    if (this.violationsCountByDate.filter(a => a === item).shift()?.isOpen) {
      this.itemParams = {};
      this.violationsCountByDate.filter(a => a === item).shift().isOpen = false;
    } else {
      this.violationsCountByDate.map(a => a.isOpen = false);
      this.itemParams = {};
      this.itemParams = this.violationsCountByDate.filter(a => a === item).shift().params;
      this.itemParams.startsAt = moment(item.date).set({hour:3,minute:0,second:0,millisecond:0}).format('YYYY-MM-DD[T]HH:mm:ss') + 'Z';
      this.itemParams.endsAt = moment(item.date).set({hour:23,minute:59,second:59,millisecond:59}).utc()
        .format('YYYY-MM-DD[T]HH:mm:ss') + 'Z';

      this.violationsCountByDate.filter(a => a === item).shift().isOpen = true;
    }
    for (const data of this.violationsCountByDate) {
      for (const violation of data.violations) {
        this.violationsSelected[violation.id] = {
          contractId: violation.contractId,
          isChecked: false
        };
      }
    }
  }

  getStatePanel(data: boolean) {
    return data ? 'isOpen' : 'closed';
  }

  convertDate(date, timezone) {
    return moment(date).tz(timezone).utc().format();
  }

  openExtraData(data) {
    this.modalService.show(new ComponentModal(LotExtraDataComponent, data));
  }

  async showDetails(violation) {
    await this.modalService.show(new ComponentModal(DetailsViolationComponent, { violation }))
      .catch((error) => console.log(error));
  }

  async removeViolation(violationId = false) {
    this.loading = !this.loading;
    let modalTitle = '';
    let modalMessage = '';
    this.lot = this.initialState;
    if(violationId) {
      modalTitle = 'Remover Infração';
      modalMessage = 'Tem certeza que deseja remover esta infração do lote? Após confirmação ela será encaminhada para a etapa de atribuição de lote.';
      const idx = this.lot.violationIds.indexOf(Number(violationId));
      if (idx > -1) {
        this.lot.violationIds.splice(idx, 1);
      }
    } else {
      modalTitle = 'Remover Infrações';
      modalMessage = 'Tem certeza que deseja remover infrações do lote? Após confirmação todas selecionadas serão encaminhadas para a etapa de atribuição de lote.';
    }
    await this.modalService.show(new MessageModal(modalTitle, modalMessage, true))
      .then(() => {
        if (!violationId) {
          this.lot.violationIds = this.lot.violationIds.filter(id => !this.idChecked.includes(id));
        }
        this.lotService.lotViolationRemove(this.lot).then(result =>{
          this.initialState = result.violationIds;
          this.getDates();
          this.alertService.show(new AlertItem('RemoveViolationLotSuccess', AlertType.success, false, 1000));
        });
      })
      .catch(err => {
        this.lot = JSON.parse(localStorage.getItem('lotDetailsCopy'));
        if (err.error) {
          this.alertService.show(new AlertItem('RemoveViolationLotError', AlertType.danger, false, 5000));
        }
      }).finally(() => {
        this.loading = !this.loading;
        this.allClick(false);
      });
  }

  allClick(checked) {
    this.isCheckedAll = checked;
    for (const i in this.violationsSelected) {
      if (this.violationsSelected[i] != null) {
        this.violationsSelected[i].isChecked = this.isCheckedAll;
        this.isChecked = checked;
        const id = Number(i);
        if (checked && !this.idChecked.find((value) => value === id)) {
          this.idChecked.push(id);
        } else if(!checked) {
          this.idChecked = this.idChecked.filter( value => value !== id);
        }
      }
    }
  }

  setViolations(list) {
    this.isCheckedAll = false;
    this.violationsSelected = {};
    this.violations = list;
    if (this.violations.length > 0) {
      this.violations.forEach(async violation => {
        const check = this.idChecked.length > 0 && this.idChecked.filter( value => value === violation.id).length > 0 ? true : false;
        this.violationsSelected[violation.id] = {
          contractId: violation.contractId,
          isChecked: check
        };
      });
    }
  }

  selectViolation(checked, id) {
    this.isChecked = checked;
    this.violationsSelected[id].isChecked = checked;
    this.isCheckedAll = Object.values(this.violationsSelected)
      .every(v => v.isChecked === true);

    this.isChecked = Object.values(this.violationsSelected)
      .some(v => v.isChecked === true);
    if(checked && !this.idChecked.find((value) => value === id)) {
      this.idChecked.push(id);
    } else if(!checked) {
      this.idChecked = this.idChecked.filter( value => value !== id);
    }
  }
}
