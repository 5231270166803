import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from 'src/app/core/layouts/dashboard/dashboard.component';
import { ViolationsDashboardComponent } from './dashboard/dashboard.component';
import { ViolationsReportComponent } from './reports/violations/violations.component';
import { ViolationsReportListComponent } from './reports/violations/list/list.component';
import { ViolationsReportContractComponent } from './reports/violations/contract/contract.component';
import { ViolationsReportViewComponent } from './reports/violations/contract/view/view.component';
import { ViolationsReportByDateComponent } from './reports/violations/contract/by-date/by-date.component';
import { ViolationsReportDetailsComponent } from './reports/violations/contract/details/details.component';
import { ViolationsViewComponent } from './view/view.component';
import { ViolationsViewFilterPageComponent } from './view/filter-page/filter-page.component';
import { ViolationsViewContractComponent } from './view/contract/contract.component';
import { MosaicComponent } from './view/mosaic/mosaic.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      { path: '', component: ViolationsDashboardComponent },
      {
        path: 'violations-report',
        component: ViolationsReportComponent,
        children: [
          { path: '', component: ViolationsReportListComponent },
          {
            path: ':contractId',
            component: ViolationsReportContractComponent,
            children: [
              { path: '', component: ViolationsReportViewComponent },
              { path: ':laneId', component: ViolationsReportByDateComponent },
              { path: ':laneId/:dateId', component: ViolationsReportDetailsComponent }
            ]
          }
        ]
      },
      {
        path: 'violations-view',
        component: ViolationsViewComponent,
        children: [
          { path: '', component: ViolationsViewFilterPageComponent },
          { path: ':contractId', component: ViolationsViewContractComponent },
          { path: ':contractId/mosaic', component: MosaicComponent },
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ViolationsRoutingModule { }
