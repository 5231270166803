import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TriageComponent } from './triage/triage.component';
import { PreProcessDashboardComponent } from './dashboard/dashboard.component';
import { DashboardComponent } from 'src/app/core/layouts/dashboard/dashboard.component';
import { LotManualComponent } from './lot-manual/lot-manual.component';
import { DashboardProcessComponent } from 'src/app/core/layouts/dashboard-process/dashboard-process.component';
import { ConfigWizardComponent } from './config-wizard/config-wizard.component';
import { LotListComponent } from './lots/lot-list/lot-list.component';
import { LotGenerateComponent } from './lots/lot-generate/lot-generate.component';
import { ViolationStepListComponent } from './reports/violation-step/violation-step-list/violation-step-list.component';
import {
  QuarantineDetailComponent
} from './quarantine/quarantine-detail/quarantine-detail.component';
import { QuarantineListComponent } from './quarantine/quarantine-list/quarantine-list.component';
import { LotStepListComponent } from './reports/lot-step/lot-step-list/lot-step-list.component';
import { SerialNumberConfigListComponent } from './serial-number-config/list/list.component';
import { ProductionReportComponent } from './reports/production/production.component';
import { ProductionReportListComponent } from './reports/production/list/list.component';
import { ProductionReportContractComponent } from './reports/production/contract/contract.component';
import { ProductionReportContractViewComponent } from './reports/production/contract/view/contract-view.component';
import { ProductionReportStepsComponent } from './reports/production/contract/steps/steps.component';
import { FilterProcessViolationComponent } from './filter-process-violation/filter-process-violation.component';
import { TypingComponent } from './typing/typing.component';
import { VerifyComponent } from './verify/verify.component';
import { ValidateComponent } from './validate/validate.component';
import { ProcessAuditComponent } from './process-audit/process-audit.component';
import { AuditLotsComponent } from './audit-lots/audit-lots.component';
import { OcrReportComponent} from './reports/ocr-report/ocr-report.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      { path: '', component: PreProcessDashboardComponent },
      { path: 'settings/:contractId', component: ConfigWizardComponent },
      { path: 'settings', redirectTo: '/pre-process/settings/available-contracts', pathMatch: 'full' },
      { path: 'quarantine', redirectTo: '/pre-process/quarantine/available-contracts', pathMatch: 'full' },
      { path: 'quarantine/:contractId', component: QuarantineListComponent },
      { path: 'quarantine/:contractId/invalidAppraisalDate/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/expiredAppraisal/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/missingAppraisalDocument/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/invalidAppraisalNumber/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/invalidWeekDay/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/invalidDayPeriod/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/disabledRegulation/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/waitingFiles/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/testingLane/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/disabledLane/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/spotMissing/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/invalidLaneNumber/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/invalidSpeed/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/oddSpeed/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/exemptPeriod/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/whiteList/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/invalidEquipmentSerialNumber/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/invalidRegulationPeriod/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/invalidVehicleClassification/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/violationLimit/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/missingTarge/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/doubleViolation/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/invalidIdViolation/:quarantineId', component: QuarantineDetailComponent },
      { path: 'quarantine/:contractId/disabledEquipment/:quarantineId', component: QuarantineDetailComponent },
      { path: 'violation-step/:contractId', component: ViolationStepListComponent },
      { path: 'violation-step', redirectTo: '/pre-process/violation-step/available-contracts', pathMatch: 'full' },
      { path: 'lot-step/:contractId', component: LotStepListComponent },
      { path: 'lot-step', redirectTo: '/pre-process/lot-step/available-contracts', pathMatch: 'full' },
      { path: 'lot-list/:contractId', component: LotListComponent },
      { path: 'lot-list', redirectTo: '/pre-process/lot-list/available-contracts', pathMatch: 'full' },
      { path: 'lot-generate/:contractId', component: LotGenerateComponent },
      { path: 'lot-generate', redirectTo: '/pre-process/lot-generate/available-contracts', pathMatch: 'full' },
      { path: 'serial-number-config/:contractId', component: SerialNumberConfigListComponent },
      { path: 'serial-number-config', redirectTo: '/pre-process/serial-number-config/available-contracts', pathMatch: 'full' },
      { path: 'internalAudit/:contractId', component: AuditLotsComponent },
      { path: 'ocr-report/:contractId', component: OcrReportComponent },
      { path: 'ocr-report', redirectTo: '/pre-process/ocr-report/available-contracts', pathMatch: 'full' },
      { path: 'internalAudit', redirectTo: '/pre-process/internalAudit/available-contracts', pathMatch: 'full' },
      {
        path: 'production-report',
        component: ProductionReportComponent,
        children: [
          { path: '', component: ProductionReportListComponent },
          {
            path: ':contractId',
            component: ProductionReportContractComponent,
            children: [
              { path: '', component: ProductionReportContractViewComponent },
              { path: ':stepId', component: ProductionReportStepsComponent }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: DashboardProcessComponent,
    children: [
      { path: 'triage', component: FilterProcessViolationComponent, pathMatch: 'full' },
      { path: 'triage/:contractId', component: TriageComponent },
      { path: 'typing', component: FilterProcessViolationComponent, pathMatch: 'full' },
      { path: 'typing/:contractId', component: TypingComponent },
      { path: 'validate', component: FilterProcessViolationComponent, pathMatch: 'full' },
      { path: 'validate/:contractId', component: ValidateComponent },
      { path: 'verify', component: FilterProcessViolationComponent, pathMatch: 'full' },
      { path: 'verify/:contractId', component: VerifyComponent },
      { path: 'manual-lot', component: LotManualComponent },
      { path: 'internalAudit/:contractId/:lotId', component: ProcessAuditComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PreProcessRoutingModule { }
