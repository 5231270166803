<div class="modal-dialog modal-full modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n><strong>Vídeo {{ initialState?.description }}</strong></h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body pt-0">
      <div class="loading-page" *ngIf="loading">
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
      <div class="mt-3" *ngIf="!loading">
        <div class="row">
          <div class="col-md-12">
            <video id="video" controls autoplay="true">
              <source src="{{initialState?.file?.urlCurrent}}" type="video/mp4">
              {{item?.description}}
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
