import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contract, Reason } from 'src/app/core/models';
import { ContractService, ModalService, RegulationService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';

@Component({
  selector: 'app-reason-view-modal',
  templateUrl: './reason-view-modal.component.html',
  styleUrls: ['./reason-view-modal.component.sass']
})
export class ReasonViewModalComponent extends BaseModal implements OnInit {
  isValid: boolean;
  lastState: any;
  public isLoading: boolean;
  public reason: Reason;
  public contract: Contract;
  public regulations = [];
  public showArea = true;
  public reasonForm: FormGroup;

  onAllSubmited(): Promise<any> {
    throw new Error('Method not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private formBuilder: FormBuilder,
    public modalService: ModalService,
    public contractService: ContractService,
    public regulationService: RegulationService
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.reason = this.initialState as Reason || new Reason();
    this.contractService.getById(this.reason.contractId).then(contract => {
      this.contract = contract;
    });
    if (this.reason.regulationIds != null) {
      this.reason.regulationIds.forEach(regulationId => {
        this.regulationService.getById(regulationId).then(res => {
          if (!this.regulations.find(regulation => regulation.id === res.id)) {
            this.regulations.push({
              id: res.code,
              shortDescription: res.shortDescription,
              description: res.description
            });
          }
        });
      });
    }
    this.createForm();
  }

  createForm() {
    const screenObj = {
      audit: this.reason.screen ? this.reason.screen.includes('audit') : false,
      typing: this.reason.screen ? this.reason.screen.includes('typing') : false,
      quarantine: this.reason.screen ? this.reason.screen.includes('quarantine') : false,
      validate: this.reason.screen ? this.reason.screen.includes('validate') : false,
      triage: this.reason.screen ? this.reason.screen.includes('triage') : false,
      verifyValid: this.reason.screen ? this.reason.screen.includes('verifyValid') : false,
      verifyInvalid: this.reason.screen ? this.reason.screen.includes('verifyInvalid') : false,
      exemptPeriod: this.reason.screen ? this.reason.screen.includes('exemptPeriod') : false,
      monitoring: this.reason.screen ? this.reason.screen.includes('monitoring') : false,
      validateCFTV: this.reason.screen ? this.reason.screen.includes('validateCFTV') : false,
      verifyCFTV: this.reason.screen ? this.reason.screen.includes('verifyCFTV') : false,
      serialNumberConfig: this.reason.screen ? this.reason.screen.includes('serialNumberConfig') : false,
      preProcessConfig: this.reason.screen ? this.reason.screen.includes('preProcessConfig') : false,
    };
    const enableReason = this.reason.enabled == null ? true : this.reason.enabled;
    this.reasonForm = this.formBuilder.group({
      contractId: [this.reason.contractId],
      code: [this.reason.code],
      name: [this.reason.name],
      regulationIds: [this.reason.regulationIds],
      type: [this.reason.type],
      enabled: [enableReason],
      audit: [screenObj.audit],
      typing: [screenObj.typing],
      quarantine: [screenObj.quarantine],
      validate: [screenObj.validate],
      triage: [screenObj.triage],
      verifyValid: [screenObj.verifyValid],
      verifyInvalid: [screenObj.verifyInvalid],
      exemptPeriod: [screenObj.exemptPeriod],
      monitoring: [screenObj.monitoring],
      validateCFTV: [screenObj.validateCFTV],
      verifyCFTV: [screenObj.verifyCFTV],
      serialNumberConfig: [screenObj.serialNumberConfig],
      preProcessConfig: [screenObj.preProcessConfig]
    });
    this.reasonForm.valueChanges.subscribe(() => {
      this.isValid = this.reasonForm.valid;
    });
  }

  toggleCollapse(show) {
    this.showArea = !show;
  }

}
