import { Component, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import {LotService, ModalService} from 'src/app/core/services';

@Component({
  selector: 'app-view-select-list',
  templateUrl: './view-select-list.component.html',
  styleUrls: ['./view-select-list.component.sass']
})
export class ViewSelectListComponent extends BaseModal  implements OnInit {
  public loading = false;
  public data: any = {};
  public list: any = [];

  constructor( public modalService: ModalService, public lotService: LotService ) {
    super(modalService);
  }
  get isValid(): boolean {
      throw new Error('Method not implemented.');
  }
  get lastState(): any {
      throw new Error('Method not implemented.');
  }
  onAllSubmited(): Promise<any> {
      throw new Error('Method not implemented.');
  }
  ngOnInit(): void {
    this.data = this.initialState;
    this.list = this.data?.list.filter(item => this.data?.inputValueIds.includes(item.id));
  }


}
