import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Video } from '../models';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class VideoService extends BaseApiService<any> {
  get cacheKey(): StorageKey {
    return StorageKey.videoServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/tutorialVideo`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }
  protected parse(obj) {
    return Video.parse(obj);
  }

  protected unparse(obj) {
    return Video.unparse(obj);
  }
}
