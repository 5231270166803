<div class="modal-dialog modal-dialog-centered modal-xl">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n>Detalhes do Motivo de Invalidação</h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body label">
      <div class="container">
        <div class="row">
          <div class="col-6 col-sm-4 col-md-3 col-lg-3">
            <app-input type="text" [isLabel]="true" label="Contrato"
              [textValue]="contract && contract.code + ' - '  + contract.name">
            </app-input>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-3">
            <label class="text-label" i18n>Habilitado?</label>
            <div class="custom-control custom-switch">
              <label class="custom-control-label" [ngClass]="{checked:reason.enabled}">
                <input type="checkbox" class="form-control custom-control-input">
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4 col-sm-4 col-md-3 col-lg-3">
            <app-input type="text" [isLabel]="true" label="Código"
              [textValue]="reason.code">
            </app-input>
          </div>
          <div class="col-4 col-sm-4 col-md-3 col-lg-3">
            <app-input type="text" [isLabel]="true" label="Nome"
              [textValue]="reason.name">
            </app-input>
          </div>
          <div class="col-4 col-sm-4 col-md-3 col-lg-3">
            <app-input type="text" [isLabel]="true" label="Tipo"
              [textValue]="reason.type">
            </app-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row mb-4">
              <div class="col-12">
                <label class="text-label" i18n>Enquadramentos</label>
              </div>
              <div class="col-12">
                <span class="badge badge-fade-info" *ngFor="let regulation of regulations">
                  {{regulation.id}} - {{regulation.shortDescription ? regulation.shortDescription : regulation.description}}
                </span>
              </div>
              <div class="col-12">
                <form [formGroup]="reasonForm">
                  <div id="accordion">
                    <div class="card pb-2">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <div class="col-md-12 btn btn-link">
                            <div class="row" data-toggle="collapse" data-target="#collapseScreen" aria-expanded="true" aria-controls="collapseScreen" (click)="toggleCollapse(showArea)">
                              <div class="col-md-11"><p class="text-left" i18n>Exibir este motivo de invalidação nas telas:</p></div>
                              <div class="col-md-1">
                                <p class="mb-1 text-right">
                                  <i class="material-icons" *ngIf="!showArea" data-toggle="collapse" data-target="#collapseScreen" aria-expanded="true" aria-controls="collapseScreen">expand_more</i>
                                  <i class="material-icons" *ngIf="showArea" data-toggle="collapse" data-target="#collapseScreen" aria-expanded="false" aria-controls="collapseScreen">expand_less</i>
                                </p>
                              </div>
                            </div>
                          </div>
                        </h5>
                      </div>

                      <div id="collapseScreen" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body pb-2">
                          <div class="row">
                            <div class="col">
                              <label i18n>Cadastro Básico:</label>
                              <div class="row mt-1">
                                <div class="col-auto">
                                  <mat-checkbox color="primary" formControlName="exemptPeriod" i18n (click)="false">Período Isento</mat-checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4">
                            <div class="col-md-12">
                              <label i18n>Pré-processamento:</label>
                              <div class="row mt-1">
                                <div class="col-md-4">
                                  <div class="row mt-1">
                                    <div class="col-auto">
                                      <mat-checkbox color="primary" (click)="false" formControlName="quarantine" i18n>Quarentena</mat-checkbox>
                                    </div>
                                  </div>
                                  <div class="row mt-1">
                                    <div class="col-auto">
                                      <mat-checkbox color="primary" (click)="false" formControlName="triage" i18n>Triagem</mat-checkbox>
                                    </div>
                                  </div>
                                  <div class="row mt-1">
                                    <div class="col-auto">
                                      <mat-checkbox color="primary" (click)="false" formControlName="typing" i18n>Digitação</mat-checkbox>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="row mt-1">
                                    <div class="col-auto">
                                      <mat-checkbox color="primary" (click)="false" formControlName="verifyValid" i18n>Verificação de Válidas</mat-checkbox>
                                    </div>
                                  </div>
                                  <div class="row mt-1">
                                    <div class="col-auto">
                                      <mat-checkbox color="primary" (click)="false" formControlName="verifyInvalid" i18n>Verificação de Inválidas</mat-checkbox>
                                    </div>
                                  </div>
                                  <div class="row mt-1">
                                    <div class="col-auto">
                                      <mat-checkbox color="primary" (click)="false" formControlName="validate" i18n>Validação</mat-checkbox>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="row mt-1">
                                    <div class="col-auto">
                                      <mat-checkbox color="primary" (click)="false" formControlName="audit" i18n>Auditoria</mat-checkbox>
                                    </div>
                                  </div>
                                  <div class="row mt-1">
                                    <div class="col-auto">
                                      <mat-checkbox color="primary" (click)="false" formControlName="serialNumberConfig" i18n>Gerenciar série</mat-checkbox>
                                    </div>
                                  </div>
                                  <div class="row mt-1">
                                    <div class="col-auto">
                                      <mat-checkbox color="primary" (click)="false" formControlName="preProcessConfig" i18n>Configuração de Pré-Processamento</mat-checkbox>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4">
                            <div class="col-md-12">
                              <label i18n>ECS Spy:</label>
                              <div class="row mt-1">
                                <div class="col-md-4">
                                  <div class="row mt-1">
                                    <div class="col-auto">
                                      <mat-checkbox color="primary" (click)="false" formControlName="verifyCFTV" i18n>Verificação Infrações CFTV</mat-checkbox>
                                    </div>
                                  </div>
                                  <div class="row mt-1">
                                    <div class="col-auto">
                                      <mat-checkbox color="primary" (click)="false" formControlName="validateCFTV" i18n>Validação Infrações CFTV</mat-checkbox>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
