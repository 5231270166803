<div class="container">
  <div class="page-title page-title--columns">
    <div class="page-title__left">
      <h2 i18n>Videos Tutoriais </h2>
      <span class="mb-0" i18n>{{getTitle(module)}}</span>
    </div>
    <div class="page-title__right"></div>
  </div>
  <div class="mb-4"></div>
  <div class="loading-page" *ngIf="loading">
    <div class="loading-spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
  <div class="row card expansive-card" *ngIf="!loading">
    <div class="col-md-12">
      <div class="text-center" *ngIf="videosList.length === 0">
        <p class="text-center text-secondary" i18n>Nenhum registro foi encontrado...</p>
        <button class="btn btn-primary" i18n (click)="getVideos()">Recarregar</button>
      </div>
      <div class="mb-5" *ngIf="videosList.length > 0">
        <div class="row mt-4">
          <div class="col-lg-4 mb-5 text-center" *ngFor="let item of videosList">
            <video  controls>
              <source src="{{item.file.urlCurrent}}" type="video/mp4">
              {{item.description}}
            </video>
            <p class="text">{{item.description}}</p>
            <button class="btn-sm btn-info center" (click)="openVideo(item)">Assistir Full</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
