import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { ModalService } from 'src/app/core/services';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-select-period',
  templateUrl: './select-period.component.html',
  styleUrls: ['./select-period.component.sass'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'}
  ],
})
export class SelectPeriodComponent extends BaseModal implements OnInit {
  lastState: any;
  public form: FormGroup;
  public periodSelected: any = {};
  public startDateValue;
  public endDateValue;
  public maxDate;
  public maxDateStart;
  public minDate;
  public rangeDates;
  private dateValues = {
    startDate: null,
    endDate: null,
    startHour: null,
    endHour: null
  };

  public get isValid() {
    return this.form.valid;
  }

  constructor(
    modalService: ModalService,
    private formBuilder: FormBuilder,
  ) {
    super(modalService);
  }

  ngOnInit() {
    const now = new Date().toISOString();
    const startDateDefault = moment(now).set(
      {
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }).utc().subtract(31, 'days').format();
    const endDateDefault = moment(now).set(
      {
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 0
      }).utc().format();
    const nowDate = moment();
    const earliestDate = moment().subtract(6, 'month');
    const days = nowDate.diff(earliestDate, 'days');
    const minDateDefault = moment(now).subtract(days, 'days');
    const rangeDates = [[7, 'days'], [15, 'days'], [1, 'month'], [2, 'month'], [3, 'month'], [4, 'month'], [5, 'month'], [6, 'month']];

    this.startDateValue = this.initialState && this.initialState.startDate || startDateDefault;
    this.endDateValue = this.initialState && this.initialState.endDate || endDateDefault;
    this.minDate = minDateDefault;
    this.rangeDates = rangeDates;
    this.maxDate = moment(now);
    this.maxDateStart = moment(now);
    const hourRegex = new RegExp(/^(([0-1][0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]$/);

    this.form = this.formBuilder.group({
      startDate: [null, [this.validateDate]],
      endDate: [null, [this.validateDate]],
      startHour: ['00:00:00', [Validators.pattern(hourRegex), Validators.required, this.validateDate]],
      endHour: ['23:59:59', [Validators.pattern(hourRegex), Validators.required, this.validateDate]]
    });

    this.form.get('startDate').setValue(this.startDateValue);
    this.form.get('endDate').setValue(this.endDateValue);

    const startHour = this.startDateValue.split('T')[1].slice(0, 8);
    const endHour = this.endDateValue.split('T')[1].slice(0, 8);
    this.form.get('startHour').setValue(startHour);
    this.form.get('endHour').setValue(endHour);
    if (startHour === endHour) {
      this.form.get('startHour').setValue('00:00:00');
      this.form.get('endHour').setValue('23:59:59');
    }
  }

  validateDate = () => {
    if (this.form == null) {
      return null;
    }
    const dateControls: any = {};
    const newDateValues = {};
    let hasChange = false;
    for (const key of Object.keys(this.dateValues)) {
      dateControls[key] = this.form.get(key);
      newDateValues[key] = dateControls[key].value;
      if (newDateValues[key] !== this.dateValues[key]) {
        hasChange = true;
      }
    }
    this.dateValues = Object.assign(newDateValues);
    if (hasChange) {
      for (const key of Object.keys(dateControls)) {
        dateControls[key].updateValueAndValidity();
      }
    }
    const startHour = (this.form.get('startHour').value).split(':');
    const endHour = (this.form.get('endHour').value).split(':');
    const startDate = moment(this.form.get('startDate').value)
      .set({hour:startHour[0],minute:startHour[1],second:startHour[2],millisecond:59}).format();
    const endDate = moment(this.form.get('endDate').value)
      .set({hour:endHour[0],minute:endHour[1],second:endHour[2],millisecond:59}).format();

    if (endDate < startDate) {
      return {
        invalidPeriod: 'Fields startHour greater than endHour'
      };
    } else {
      return null;
    }
  };

  async onAllSubmited(): Promise<any> {
    const startHour = (this.form.get('startHour').value).split(':');
    const endHour = (this.form.get('endHour').value).split(':');
    const newDate = moment(this.form.get('startDate').value)
      .set({hour:startHour[0],minute:startHour[1],second:startHour[2],millisecond:59}).format();
    const nowDate = moment(this.form.get('endDate').value)
      .set({hour:endHour[0],minute:endHour[1],second:endHour[2],millisecond:59}).format();
    this.form.get('startDate').setValue(newDate);
    this.form.get('endDate').setValue(nowDate);
    this.periodSelected = this.form.getRawValue();
  }

  rangePeriod(day, period) {
    const now = new Date().toISOString();
    const newDate = moment(now).utc().subtract(day, period);
    this.form.get('startDate').setValue(newDate);
    this.form.get('endDate').setValue(now);
  }

}
