<div class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <div class="row">
        <h5 *ngIf="reason.id; else titleBlock" class="modal-title container"
          i18n>Editar Motivo de Invalidação</h5>
        <ng-template #titleBlock>
          <h5 class="modal-title container" i18n>Novo Motivo de Invalidação</h5>
        </ng-template>
      </div>
      <button type="button" (click)="onClose()"
        class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal"
        aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="reasonForm" (submit)="onSubmit()">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <app-input i18n-label="@@ContractLabel" type="select"
                formControlName="contractId" inputRequired=true
                label="Selecione o contrato" [inputDisabled]="reason.id"
                [list]="contracts" i18n-placeholder
                placeholder="Selecione o contrato" selectValue="id"
                selectText="prettyName">
              </app-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-4">
            <div class="form-group">
              <app-input i18n-label="@@Code" type="text" formControlName="code"
                inputRequired=true label="Código"
                [inputDisabled]="reason.id">
              </app-input>
            </div>
          </div>

          <div class="col col-md-8">
            <div class="form-group">
              <app-input i18n-label="@@Name" type="text"
                inputRequired=true formControlName="name" label="Nome">
              </app-input>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col col-md-5">
            <div class="form-group">
              <app-input i18n-label i18n-placeholder
                placeholder="Selecione os enquadramentos"
                label="Enquadramentos" inputRequired=true
                type="multiselect" selectText="shortDescription"
                selectValue="id" [list]="regulations"
                selectShowLimit=3
                formControlName="regulationIds">
              </app-input>
            </div>
            <div class="loading-spinner" *ngIf="loading">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          </div>
          <div class="col col-md-4">
            <div class="form-group">
              <app-enum-i18n [input]="reasonTypes"
                (output)="reasonTypesI18n=$event"></app-enum-i18n>
              <app-input i18n-placeholder="@@ReasonTypes"
                i18n-label="@@ReasonTypesLabel"
                type="select" [list]="reasonTypesI18n" label="Tipo"
                formControlName="type"
                selectText="value" selectValue="id" placeholder="{{reasonType}}"
                inputRequired=true>
              </app-input>
            </div>
          </div>
          <div class="col col-md-3">
            <label i18n>Habilitado?
              <div class="custom-control custom-switch">
                <div class="form-group mt-1">
                  <label class="custom-control-label"
                    [ngClass]="{checked: reasonForm.get('enabled').value}">
                    <input type="checkbox"
                      class="form-control custom-control-input"
                      formControlName="enabled">
                  </label>
                </div>
              </div>
            </label>
          </div>
        </div>
        <div id="accordion">
          <div class="card pb-2">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <div class="col-md-12 btn btn-link">
                  <div class="row" data-toggle="collapse"
                    data-target="#collapseScreen" aria-expanded="true"
                    aria-controls="collapseScreen"
                    (click)="toggleCollapse(showArea)">
                    <div class="col-md-11"><p class="text-left" i18n>Exibir este
                        motivo de invalidação nas telas:</p></div>
                    <div class="col-md-1">
                      <p class="mb-1 text-right">
                        <i class="material-icons" *ngIf="!showArea"
                          data-toggle="collapse" data-target="#collapseScreen"
                          aria-expanded="true"
                          aria-controls="collapseScreen">expand_more</i>
                        <i class="material-icons" *ngIf="showArea"
                          data-toggle="collapse" data-target="#collapseScreen"
                          aria-expanded="false"
                          aria-controls="collapseScreen">expand_less</i>
                      </p>
                    </div>
                  </div>
                </div>
              </h5>
            </div>

            <div id="collapseScreen" class="collapse"
              aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body pb-2">
                <div class="row">
                  <div class="col">
                    <label i18n>Cadastro Básico:</label>
                    <div class="row mt-1">
                      <div class="col-auto">
                        <mat-checkbox color="primary"
                          formControlName="exemptPeriod" i18n>Período
                          Isento</mat-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <label i18n>Pré-processamento:</label>
                    <div class="row mt-1">
                      <div class="col-md-4">
                        <div class="row mt-1">
                          <div class="col-auto">
                            <mat-checkbox color="primary"
                              formControlName="quarantine"
                              i18n>Quarentena</mat-checkbox>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-auto">
                            <mat-checkbox color="primary"
                              formControlName="triage"
                              i18n>Triagem</mat-checkbox>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-auto">
                            <mat-checkbox color="primary"
                              formControlName="typing"
                              i18n>Digitação</mat-checkbox>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="row mt-1">
                          <div class="col-auto">
                            <mat-checkbox color="primary"
                              formControlName="verifyValid" i18n>Verificação de
                              Válidas</mat-checkbox>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-auto">
                            <mat-checkbox color="primary"
                              formControlName="verifyInvalid" i18n>Verificação
                              de Inválidas</mat-checkbox>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-auto">
                            <mat-checkbox color="primary"
                              formControlName="validate"
                              i18n>Validação</mat-checkbox>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="row mt-1">
                          <div class="col-auto">
                            <mat-checkbox color="primary"
                              formControlName="audit"
                              i18n>Auditoria</mat-checkbox>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-auto">
                            <mat-checkbox color="primary"
                              formControlName="serialNumberConfig"
                              i18n>Gerenciar série</mat-checkbox>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-auto">
                            <mat-checkbox color="primary"
                              formControlName="preProcessConfig"
                              i18n>Configuração de
                              Pré-Processamento</mat-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <label i18n>ECS Spy:</label>
                    <div class="row mt-1">
                      <div class="col-md-4">
                        <div class="row mt-1">
                          <div class="col-auto">
                            <mat-checkbox color="primary"
                              formControlName="verifyCFTV" i18n>Verificação
                              Infrações CFTV</mat-checkbox>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-auto">
                            <mat-checkbox color="primary"
                              formControlName="validateCFTV" i18n>Validação
                              Infrações CFTV</mat-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary" (click)="onCancel()">
        <app-loading [promise]="promise" text="Cancelar"
          i18n-text></app-loading>
      </button>
      <button class="btn btn-primary" (click)="onSubmit()"
        [ngClass]="{disabled: !isValid}">
        <app-loading [promise]="promise" text="Confirmar"
          i18n-text></app-loading>
      </button>
    </div>
  </div>
</div>
