import { Component, Input, OnInit } from '@angular/core';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { ModalService, ViolationService, LotService, AlertService, PreProcessSettingsService } from 'src/app/core/services';
import {AlertItem, AlertType, Violation} from 'src/app/core/models';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as expEval from 'expression-eval';

@Component({
  selector: 'app-lot-details',
  templateUrl: './lot-create.component.html',
  styleUrls: ['./lot-create.component.sass'],
  animations: [
    trigger('openPanel', [
      state('isOpen', style({
        height: 300,
        opacity: 1
      })),
      state('closed', style({
        height: 0,
        opacity: 0,
        paddingTop: 0,
        paddingBottom: 0
      })),
      transition('closed => isOpen', animate('500ms ease-out')),
      transition('isOpen => closed', animate('500ms ease-out'))
    ])
  ]
})
export class LotCreateComponent extends BaseModal implements OnInit {
  @Input() initialState;
  btn: boolean;
  lastState: any;
  public loading: boolean;
  public name: string;
  public lotSize: number;
  public violations: Array<Violation> = [];
  public violationIds = [];
  public params: any = {};
  public violationsCountByDate: any = [];
  public form: FormGroup;

  constructor(
    modalService: ModalService,
    public violationService: ViolationService,
    public preProcessSettingsService: PreProcessSettingsService,
    private lotService: LotService,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {
    super(modalService);
   }

  async ngOnInit() {
    this.loading = true;
    this.createForm();
    this.lotSize = this.initialState.violationIds.length;
    this.loading = false;
    this.preProcessSettingsService.getById(this.initialState.contractId).then(preProcessSettings => {
      this.name = this.lotNameParse(preProcessSettings.lotGeneration.namePattern);
    });
  }
  async onAllSubmited(): Promise<any> {
    this.loading = true;
    this.initialState.name = this.form.get('name').value;
    this.lotService.create(this.initialState).then(res => {
      if (res.id) {
        this.alertService.show(
          new AlertItem('CreateLotSuccess', AlertType.success)
        );
      }

    }).catch(err => {
      this.alertService.show(
        new AlertItem('CreateLotError', AlertType.danger)
      );
    }).finally(() => {
      this.loading = false;
      this.onClose();
    });

  }
  createForm() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
    });
    return this.form
  }

  get isValid(): boolean {
    if (this.form != null) {
      return this.form.valid;
    } else {
      return false;
    }
  }
  lotNameParse (template) {
    if (typeof template === 'string' || template instanceof String) {
      return template.replace(/{{([^}\\]*(\\})?)*}}/g, (match, prop, n) => {
        const exp = match.slice(2, -2)
        const parsed = expEval.parse(exp)
        try {
          const value = expEval.eval(parsed, this.initialState || {})
          if (value == null) {
            return ''
          }
          return value
        } catch (err) {
          console.log(err)
          return ''
        }
      })
    } else {
      return template
    }
  }
}
