import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AlertItem, AlertType, Contract, Regulation } from 'src/app/core/models';
import { AlertService, ContractService, ModalService, RegulationService, StorageService } from 'src/app/core/services';
import { BaseModal } from 'src/app/core/utils/BaseModal';
import { hasPermission } from 'src/app/core/utils/permission';

@Component({
  selector: 'app-equipment-model-panel',
  templateUrl: './config-general-model-panel.component.html',
  styleUrls: ['./config-general-model-panel.component.sass']
})
export class ConfigGeneralModelPanelComponent extends BaseModal implements OnInit, OnDestroy {
  public contract: any;
  public allowEdit: boolean;
  public contractCodeDescription = '';
  get isValid() {
    if (!this.form.valid) {
      return false;
    }
    if (this.form.value.enabledQRCode && this.form.value.regulationIds.length === 0) {
      return false;
    }
    return true;
  }
  lastState: any;
  public form: FormGroup;
  public promise: Promise<any>;
  public loading = false;
  public regulations: Array<Regulation>;
  public displayRegulationsQRCode = false;
  public displayRegulations = false;

  constructor(
    modalService: ModalService,
    private contractService: ContractService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private regulationService: RegulationService,
    private storageService: StorageService
  ) {
    super(modalService);
  }

  async ngOnInit() {
    this.loading = true;
    this.contract = this.initialState;
    this.allowEdit = hasPermission('UpdateContract', this.storageService, [this.contract.id]);
    this.contractCodeDescription = (this.contract && this.contract.code + ' - ' + this.contract.name);
    this.createForm();
    await this.getRegulations();
    this.loading = false;
  }

  async getRegulations() {
    let params = {};
    if (this.contract.regulationIds && this.displayRegulationsQRCode) {
      params = { regionId: this.contract.regionId, 'id[in]': `[${this.contract.regulationIds}]` };
    } else {
      params = { regionId: this.contract.regionId };
    }
    await this.regulationService.getAll(params).then(res => {
      res.map(item => {
        item.shortDescription = `${item.code} - ${item.shortDescription}`;
      });
      this.regulations = res;
    });
  }

  public async onAllSubmited() {
    if (this.promise != null) {
      return this.promise;
    }
    const data: any = {
      regulationIds: this.form.get('regulationIds').value
    };
    if (this.displayRegulationsQRCode) {
      data.enabledQRCode = this.form.get('enabledQRCode').value;
      data.maxAmount = this.contract?.QRCode?.maxAmount || 3;
      this.contract.QRCode = data;
    } else {
      this.contract.regulationIds = data.regulationIds;
    }
    delete this.contract.isQrCode;
    this.promise = this.contractService.update(this.contract).then(res => {
      this.alertService.show(new AlertItem('ContractConfiged', AlertType.success));
      this.promise = null;
      return res;
    })
    .catch(error => {
      this.alertService.show(new AlertItem('ContractConfigError', AlertType.danger));
      throw error;
    }).finally(() => {
      this.promise = null;
    });
    return this.promise;
  }

  createForm() {
    if (this.contract.isQrCode && this.contract?.QRCode?.enabledQRCode) {
      this.displayRegulationsQRCode = true;
      this.form = this.formBuilder.group({
        enabledQRCode: new FormControl({ value: this.contract?.QRCode?.enabledQRCode || false, disabled: !this.allowEdit }),
        regulationIds: new FormControl({ value: this.contract?.QRCode?.regulationIds || [], disabled: !this.allowEdit })
      });
      this.form.get('enabledQRCode').valueChanges.subscribe(data => {
        if (data) {
          this.displayRegulationsQRCode = true;
          this.form.get('regulationIds').setValidators(Validators.required);
        } else {
          this.displayRegulationsQRCode = false;
          this.form.get('regulationIds').patchValue([]);
          this.form.get('regulationIds').clearValidators();
          this.form.get('regulationIds').updateValueAndValidity();;
        }
      });
    } else {
      this.displayRegulations = true;
      this.form = this.formBuilder.group({
        regulationIds: new FormControl({ value: this.contract?.regulationIds || [], disabled: !this.allowEdit })
      });
    }
  }

  ngOnDestroy() { }
}
