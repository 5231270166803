import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService, PreProcessSettingsService } from 'src/app/core/services';
import { PreProcessSettings, LotSteps, ComponentModal } from 'src/app/core/models';
import { LotStepReportService } from 'src/app/core/services/lotStepReport.service';
import { ChartItem } from 'src/app/core/models/ChartItem';
import { ChartType } from 'src/app/core/enums/ChartType';
import { ClosedModalError } from 'src/app/core/errors';
import { LotStepViewComponent } from '../lot-step-view/lot-step-view.component';

@Component({
  selector: 'app-lot-step-list',
  templateUrl: './lot-step-list.component.html',
  styleUrls: ['./lot-step-list.component.sass']
})
export class LotStepListComponent implements OnInit {
  public resourcesToBeTranslated = Object.values(LotSteps);
  public resources = [];
  public steps: string[] = [];
  public preProcessSettings: PreProcessSettings;
  public columns = [];
  public lotSteps: any = [];
  public tableColumns: any = [];
  public tableRows: any = [];
  public loading = null;
  public total: any = 0;
  public chartItems: ChartItem[] = [];
  public barType = ChartType.bar;
  public pieType = ChartType.pie;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private lotStepReportService: LotStepReportService,
    private preProcessSettingsService: PreProcessSettingsService,
    private modalService: ModalService
    ) { }

  ngOnInit() {
    const contractId = this.activatedRoute.snapshot.params.contractId;
    this.loading = this.preProcessSettingsService.getById(contractId)
      .then(async result => {
        this.preProcessSettings = result;
        if (this.preProcessSettings.lotAgeAlert == null) {
          this.preProcessSettings.lotAgeAlert = 10;
        }
        if (this.preProcessSettings.lotStepOrder != null) {
          this.columns = this.preProcessSettings.lotStepOrder;
        } else {
          const first = this.preProcessSettings.violationSteps.lotAttribution.lotStep;
          let last;
          const tempStep = [];
          let change;
          if (!this.columns.includes(first)) {
            this.columns.push(first);
          }
          Object.keys(this.preProcessSettings.lotSteps).forEach(step => {
            const nextStep = this.preProcessSettings.lotSteps[step].nextStep ||
              this.preProcessSettings.lotSteps[step].accepted ||
              this.preProcessSettings.lotSteps[step].refused;
            if(nextStep === 'done') {
              last = step;
            } else if (step !== first) {
              tempStep.push({step, next: nextStep});
            }  else if (step === first) {
              change = nextStep;
            }
          });
          let idx = 0;
          while (tempStep.length !== 0) {
            if ((tempStep[idx].step === change) || (change === 'next')) {
              if(this.columns.filter((s) => s === tempStep[idx].step).length === 0) {
                if (!this.columns.includes(tempStep[idx].step)) {
                  this.columns.push(
                    tempStep[idx].step
                  );
                  if(tempStep[idx].next !== last) {
                    change = tempStep[idx].next;
                  } else {
                    change = 'next';
                  }
                }

              }
              tempStep.splice(idx,1);
              if(idx !== 0) {
                idx = 0;
              }
            } else {
              idx ++;
            }
          }

          if (!this.columns.includes(last)) {
            this.columns.push(
              last
            );
          }
        }
        if (!this.columns.includes('export')) {
          this.columns.push('export');
        }
        await this.lotStepReportService.list({ contractId }).then(res => {
          this.lotSteps = res.result;
          setTimeout(() => {
            this.loadChart();
            this.loadColumnStep();
            this.loadRowViolations();
            this.showAllValues();
          }, 20);
        });
      }).finally(() => {
        this.loading = null;
      });
  }

  loadChart() {
    const reducer = (acc, curr) => acc + curr;
    const item = this.lotSteps[0];
    let values = {};
    const items: ChartItem[] = [];
    this.columns.forEach(id => {
      const label = this.resources.find(r => r.id === id).value;
      values = {};
      this.columns.forEach(step => {
        const key = this.resources.find(r => r.id === step);
        if (item && item[step] != null && step === id) {
          const value = Object.values(item[step]).reduce(reducer);
          values[key.value] = value;
        } else {
          values[key.value] = 0;
        }
      });
      items.push(new ChartItem(label, values));
    });
    this.chartItems = items;
  }

  loadColumnStep() {
    const reducer = (acc, curr) => acc + curr;
    const tableColumnsInsert = [];
    const columnStep = {};
    this.columns = this.columns.filter((v, i, a) => a.findIndex(step => step === v) === i);
    this.columns.map((step, index) => {
      columnStep[index] = {};
      columnStep[index].step = step;
      columnStep[index].stepName = (this.resources.find(r => r.id === step)).value;
      columnStep[index].lot = [];
      columnStep[index].id = index;
      this.total = 0;
      this.tableColumns = [];
      this.lotSteps.map((item, i) => {
        if (item[step] != null) {
          const value = Object.values(item[step]).reduce(reducer);
          columnStep[index].lot[i] = { value };
          this.total += value;
        } else {
          columnStep[index].lot[i] = { value: 0 };
        }
      });

      columnStep[index].lotTotal = this.total;
      tableColumnsInsert.push(columnStep[index]);
    });

    this.tableColumns = tableColumnsInsert;
  }

  loadRowViolations() {
    const reducer = (acc, curr) => acc + curr;
    const tableRows = [];
    let lotAgeAlert = false;

    this.lotSteps.map((lot, index) => {
      this.total = 0;

      this.columns.map(step => {
        if (lot[step] != null) {
          const values = Object.values(lot[step]).reduce(reducer);
          this.total += values;
          const date = Object.keys(lot[step]);

          if (this.dateExpired(date)) {
            lotAgeAlert = true;
          }
        }
      });

      tableRows.push({
        id: index,
        total: this.total,
        ageAlert: lotAgeAlert
      });
    });

    this.tableRows = tableRows;
  }

  dateExpired(lotDate) {
    if (this.preProcessSettings == null) {
      return false;
    }
    const olddestDate = new Date(lotDate);
    const dateNow = new Date();
    const diffc = dateNow.getTime() - olddestDate.getTime();
    const days = Math.round(Math.abs(diffc / (1000 * 60 * 60 * 24)));
    return this.preProcessSettings.lotAgeAlert != null && days > this.preProcessSettings.lotAgeAlert;
  }

  routerViolationStep() {
    const contractId = this.activatedRoute.snapshot.params.contractId;
    this.router.navigate([`/pre-process/violation-step/${contractId}`]);
  }

  showAllValues() {
    this.total = 0;
    this.tableColumns.map(item => {
      this.total += item.lotTotal;
    });

    return this.total;
  }

  setResources(value) {
    this.resources = value;
  }

  openLotStepView(i) {
    const lotSteps = this.lotSteps[i];

    const columnStep = [];

    this.columns.map((step, index) => {
      columnStep[index] = {};
      columnStep[index].step = step;
      columnStep[index].stepName = (this.resources.find(r => r.id === step)).value;
    });

    this.modalService.show(new ComponentModal(LotStepViewComponent, { lotSteps, i, columnStep }))
      .catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
        }
    });
  }

  onResize() {
    this.loadChart();
  }
}
