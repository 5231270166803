import { BaseApiModel } from './BaseApiModel';

export class UserGroup extends BaseApiModel {
  public name: string;
  public description: string;
  public contractId: string;
  public userIds: Array<string>;
  public permissions: Array<string>;
  public ipWhitelist: Array<any>;
  public enabled: boolean;
  public showArea1: boolean;
  public showArea2: boolean;
  public showArea3: boolean;
  public showArea4: boolean;
  public showArea5: boolean;
  public showArea6: boolean;
  public modifiedAt: Date;
  public createdAt: Date;
  public extraData: any;
  public users: Array<string>;
  public prettyNames: Array<string>;
  get prettyId() {
    return this.name;
  }
}
