import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pagination-url',
  templateUrl: './pagination-url.component.html',
  styleUrls: ['./pagination-url.component.sass']
})
export class PaginationUrlComponent implements OnInit {

  @Input() service: any;
  @Input() pageSize;
  @Input() currentRoute: any;
  @Output() items: EventEmitter<Array<any>> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() retPageSize = new EventEmitter();

  get params(): any {
    return this._params;
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() set params(params: {} | Promise<any>) {
    this.setParams(params);
  }

  public isLoading = false;
  public pages = [];
  public page = 1;
  public currentPage = 1;
  public totalPages = 1;
  public totalItems: number;
  public noRegister = false;

  public hasNext = false;
  public hasPrevious = false;
  public form: FormGroup;
  private _params = {};

  constructor() { }

  async ngOnInit() {
    this.isLoading = true;
    this.loading.emit(true);
    // this.setParams(this._params);
    this.createForm();
    this.isLoading = false;
    this.loading.emit(false);
  }

  async setParams(params) {
    // eslint-disable-next-line no-shadow
    this._params = params;
    if (params == null || params.contractId == null) {
      return;
    }
    this.retPageSize.emit(this.pageSize);
    this.params.page = this.page || 1;
    this.params.currentPage = this.currentPage;
    await this.getData();
  }

  createForm() {
    this.form = new FormGroup({
      pageSize: new FormControl([this.pageSize || 5])
    });
    this.retPageSize.emit(this.pageSize);
    this.form.get('pageSize').valueChanges.subscribe(value => {
      this.pageSize = value;
      this.params.pageSize = value;
      this.page = 1;
      this.params.page = this.page;
      this.getData();
    });
  }

  async getData() {
    this.isLoading = true;
    this.loading.emit(true);
    if (this?.params?.contractId) {
      await this.service?.paginate(this.params, this.currentRoute).then(res => {
        this.currentPage = res.meta.currentPage;
        this.pageSize = res.meta.pageSize;
        this.totalPages = res.meta.totalPages;
        this.totalItems = res.meta.totalItems;
        this.page = res.meta.page;
        this.pages = [];
        this.retPageSize.emit(this.pageSize);

        for(let i = 0; i < this.totalPages; i++) {
          this.pages.push(i + 1);
        }

        if (this.currentPage > 1) {
          this.hasPrevious = true;
        } else {
          this.hasPrevious = false;
        }

        if (this.currentPage < this.totalPages) {
          this.hasNext = true;
        } else {
          this.hasNext = false;
        }

        this.items.emit(res);
      });
    }
    this.loading.emit(false);
    this.isLoading = false;
  }

  setPage(page) {
    this.params.page = page;
    this.getData();
  }

  previous() {
    this.setPage(this.pages[0]);
  }

  next() {
    this.setPage(this.pages[this.pages.length -1]);
  }

  getSurroundingElements(array, activeIndex) {
    const start = Math.max(0, activeIndex - 3);
    const end = Math.min(array.length - 1, activeIndex + 3);
    return array.slice(start, end + 1);
  }

}
