import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {User, AlertItem, AlertType, PreProcessSettings} from 'src/app/core/models';
import {AlertService, ContractGlobalService, PreProcessSettingsService} from 'src/app/core/services';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-pre-process-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class PreProcessDashboardComponent implements OnInit {
  public currentUser: Observable<User>;
  public layer = 'root';
  public contractGlobal = [];
  public modelChanged: Subject<string> = new Subject<string>();
  public stepTyping = false;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private route: ActivatedRoute,
    public contractsGlobalService: ContractGlobalService,
    private preProcessSettingsService: PreProcessSettingsService
  ) {
    if (this.contractsGlobalService.contracts != null) {
      this.contractGlobal = this.contractsGlobalService.contracts || [];
    }
    this.modelChanged.pipe(
      debounceTime(50))
      .subscribe(() => {
        this.getPreProcessStep();
      });
    this.contractsGlobalService.contractEvent.pipe(
      debounceTime(100))
      .subscribe(() => {
        this.getPreProcessStep();
      });
  }

  ngOnInit() {
    const routeCurrent = this.route.snapshot.url[0];
    if (routeCurrent != null) {
      this.layer = routeCurrent.path;
    } else {
      this.layer = 'root';
    }
    this.getPreProcessStep();
  }

  async getPreProcessStep() {
    this.contractGlobal = this.contractsGlobalService.contracts || null;
    if (!this.contractGlobal) {
      this.stepTyping = false;
      return;
    }
    for (const contract of this.contractGlobal) {
      await this.preProcessSettingsService.getById(contract).then(preProcessSettings => {
        if(preProcessSettings.violationSteps.typing === undefined) {
          this.stepTyping = true;
        }
      });
    }
  }

  unavailable() {
    this.alertService.show(new AlertItem('ModuleUnavailable', AlertType.info));
  }
}
