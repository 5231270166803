<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="page-title page-title--columns">
            <div class="page-title__left">
              <p class="page-title__link-back" i18n *ngIf="!loading"><a routerLink="/contracts">Contrato {{ contract?.code }} - {{ contract?.name }}</a></p>
              <h2 i18n>Configurações Gerais</h2>
            </div>
          </div>
          <section *ngIf="!loading">
            <!-- Enquadramentos habilitados -->
            <h5 class="mb-3" i18n><strong>Habilitar no contrato os enquadramentos abaixo</strong>:</h5>
            <form [formGroup]="regulationForm">
              <div class="form-group">
                <app-input i18n-label i18n-placeholder placeholder="Selecione os enquadramentos" label="Enquadramentos"
                  type="multiselect" selectText="shortDescription" selectValue="id" [list]="regulations"
                  formControlName="regulationIds" [inputDisabled]="!allowEdit" selectShowLimit="5"
                  (click)="openViewSelectListModal(regulationForm.get('regulationIds').value, 'Enquadramentos', !allowEdit, regulations)">
                </app-input>
              </div>
            </form>
            <!-- fim Enquadramentos habilitados -->
            <hr>
            <!-- Gerar QRCode -->
            <h5 class="mb-3" i18n><strong>Gerar QRCode</strong>:</h5>
            <form [formGroup]="qrcodeForm">
              <div class="form-group pl-4">
                <input class="form-check-input" type="checkbox" formControlName="enabledQRCode" [ngClass]="{ 'disabled': !allowEdit }">
                <label for="enabledQRCode" [ngClass]="{'disabled': !allowEdit}" i18n>Habilitar geração do QRCode:</label>
                <i i18n-appTooltip class="material-icons icon-sm text-secondary"
                  appTooltip="Caso essa opção esteja habilitada, o sistema irá gerar um QRCode para acesso ao vídeo das infrações dos enquadramentos selecionados abaixo.">help</i>
              </div>
              <div class="form-group">
                <app-input i18n-label i18n-placeholder placeholder="Selecione os enquadramentos" label="Enquadramentos"
                  type="multiselect" selectText="shortDescription" selectValue="id" [list]="enabledRegulations"
                  formControlName="regulationIds" [inputDisabled]="!allowEdit" selectShowLimit="5"
                  (click)="openViewSelectListModal(qrcodeForm.get('regulationIds').value, 'Enquadramentos', !allowEdit, enabledRegulations)">
                </app-input>
              </div>
            </form>
            <!-- fim Gerar QRCode -->
             <hr>
          </section>
          <div class="loading-page" *ngIf="loading">
            <div class="loading-spinner">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-end mt-3">
          <a routerLink="/contracts"><button class="btn btn-outline-secondary mr-3">Cancelar</button></a>
          <button class="btn btn-primary" (click)="confirmation()" [disabled]="!this.allowEdit">
            <span *ngIf="!isLoading">Confirmar</span>
            <div class="loading-spinner" *ngIf="isLoading">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
