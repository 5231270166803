<div class="administrative-dashboard">
  <div class="container">
    <div class="page-title">
      <h2 i18n>Administrativo</h2>
      <p class="page-title__subtitle" i18n>Escolha a funcionalidade que deseja acessar</p>
    </div>

    <div class="mt-4" *appPermission="['ViewContract', 'CreateContract', 'DeleteContract', 'UpdateContract']">
      <h6 i18n>Contrato</h6>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-3" *appPermission="['ViewContract', 'CreateContract', 'DeleteContract', 'UpdateContract']">
          <a routerLink="manage-contract" class="administrative-dashboard__block">
            <i class="material-icons">receipt_long</i>
            <span i18n>Gerenciar Contrato</span>
          </a>
        </div>
      </div>
    </div>

    <div class="mt-4" *appPermission="['ViewAppraisal', 'CreateAppraisal', 'DeleteAppraisal', 'UpdateAppraisal']">
      <h6 i18n>Aferição</h6>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-3" *appPermission="['ViewAppraisal', 'CreateAppraisal', 'DeleteAppraisal', 'UpdateAppraisal']">
          <a routerLink="manage-appraisal"  class="administrative-dashboard__block">
            <i class="material-icons">task</i>
            <span i18n>Gerenciar Certificado</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
