<div class="card-header">
  <h5 i18n class="my-2 font-weight-bold">Gerais</h5>
</div>

<div class="card-body">
  <div class="row">
    <div class="col-md-12 mb-3">
      <h5 class="font-weight-bold" i18n>Configurações relacionadas às etapas de
        processamento</h5>
    </div>
  </div>
  <form [formGroup]="form">
    <div class="row mb-4">
      <div class="col-md-4">
        <app-enum-i18n [input]="vehicleDataSearchTypes"
          (output)="vehicleDataSearchTypesI18n=$event"></app-enum-i18n>
        <app-input i18n-label i18n-placeholder
          label="Selecione qual o método para pesquisa de dados veículares"
          inputRequired=true type="select" selectText="value" selectValue="id"
          [list]="vehicleDataSearchTypesI18n"
          formControlName="vehicleDataSearch"
          placeholder="Selecione qual o método de pesquisa veícular">
        </app-input>
      </div>
      <div class="col-md-4">
        <label i18n>Permitir inserção manual dos dados caso a placa não seja
          encontrada: <i i18n-appTooltip
            class="material-icons icon-sm text-secondary" data-placement="top"
            appTooltip="Caso este campo esteja preenchido com a opção “Sim“, após realizar a pesquisa de placa e não retornar os dados do veículo,
           o sistema irá permitir a inserção manual dos dados.">help</i></label>
        <mat-radio-group formControlName="allowInputNotFoundVehicle"
          color="primary" aria-label="Select an option">
          <mat-radio-button [value]=true class="mr-4"
            i18n>Sim</mat-radio-button>
          <mat-radio-button [value]=false [checked]="!allowInputNotFoundVehicle"
            i18n>
            Não</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="col-md-4" *ngIf="!allowInputNotFoundVehicle"
        [ngClass]="{ 'disabled': plateNotFoundReasonIdDisabled }">
        <app-input i18n-label i18n-placeholder
          placeholder="Selecione um motivo de invalidação" type="select"
          selectText="prettyName" selectValue="id" [list]="reasons"
          formControlName="plateNotFoundReasonId"
          i18n-toolTipMsg i18n-label="@@PlateNotFoundReasonId"
          label="Motivo de Invalidação"
          toolTipMsg="Selecione o motivo de invalidação que será
          preenchido de forma automática caso o veículo não seja encontrado na pesquisa de placa">
        </app-input>
      </div>
    </div>

    <div class="row mb-4"
      *ngIf="preProcessSettings?.vehicleDataSearch !== 'disabled'">
      <div class="col-12">
        <mat-checkbox i18n color="primary"
          formControlName="changeBrandModelForClientBrand"> Substituir o campo
          "Marca/Modelo" pelo Campo "Marca do Veículo" (base interna/cliente)
        </mat-checkbox>
        <i i18n-appTooltip class="material-icons icon-sm text-secondary"
          appTooltip="Caso esta opção esteja selecionada, o campo Marca/Modelo será substituído pelo campo 'Marca do Veículo'">help</i>
      </div>
    </div>

    <div class="mb-4">
      <label i18n>Selecione as informações relacionadas ao veículo que devem ser
        consideradas de preenchimento
        obrigatório: <i i18n-appTooltip
          class="material-icons icon-sm text-secondary" data-placement="top"
          appTooltip="Os itens que não estiverem selecionados como obrigatório, serão exibidos inativos nas telas de processamento de infração.">help</i></label>

      <div class="row">
        <div class="col-6 col-sm-4 col-lg-3">
          <mat-checkbox i18n color="primary"
            formControlName="allRequiredFields">Todos</mat-checkbox>
        </div>
        <app-enum-i18n [input]="vehicleRequiredFields"
          (output)="vehicleRequiredFieldsI18n=$event"></app-enum-i18n>
        <div class="col-6 col-sm-4 col-lg-3"
          *ngFor="let field of vehicleRequiredFieldsI18n">
          <mat-checkbox color="primary" [formControlName]="field.id"
            i18n>{{field.value}}</mat-checkbox>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col">
        <mat-checkbox color="primary" formControlName="enabledWhiteList">
          <span i18n>Habilitar a pesquisa de placa na lista branca
            <i i18n-appTooltip class="material-icons icon-sm text-secondary"
              data-placement="top"
              appTooltip="Caso esta opção esteja selecionada, sempre que o campo placa for preenchido, o sistema realizara uma verificação no cadastro de lista branca,
                realizando a invalidação do registro de forma automática com o motivo definido abaixo.">help
            </i>
          </span>
        </mat-checkbox>
      </div>
    </div>

    <div class="mb-4" *ngIf="form.value.enabledWhiteList">
      <app-input i18n-label i18n-placeholder
        placeholder="Selecione um motivo de invalidação"
        label="Selecione o motivo de invalidação que será aplicado para placas cadastradas na lista branca"
        inputRequired=true type="select" selectText="prettyName"
        selectValue="id" [list]="reasons"
        formControlName="whitelistReasonId"
        [inputDisabled]="!form.value.enabledWhiteList">
      </app-input>
    </div>

    <div class="row"
      *ngIf="isSkipTypingStep || isShowDataEditing || isAlertOddSpeed">
      <div class="col-12" *ngIf="isSkipTypingStep">
        <mat-checkbox i18n color="primary" formControlName="typingOcrSkip"> Caso
          tenha leitura por OCR pular etapa de
          Digitação </mat-checkbox>
        <i i18n-appTooltip class="material-icons icon-sm text-secondary"
          appTooltip="Caso esta opção esteja selecionada, os registros com a placa identificada pelo OCR do equipamento irão desconsiderar a etapa de Digitação e seguir para a próxima etapa configurada">help</i>
      </div>

      <div class="col-12 tooltip-div" *ngIf="isShowDataEditing">
        <mat-checkbox i18n color="primary" formControlName="changesPermited">
          Deverá permitir a edição dos dados do
          veículo nas etapas de Verificação </mat-checkbox>
        <i i18n-appTooltip class="material-icons icon-sm text-secondary"
          appTooltip="Caso esta opção esteja selecionada, os dados do veículos ficarão habilitados para edição nas etapas de Verificação">help</i>
      </div>

      <div class="col-12" *ngIf="isAlertOddSpeed">
        <mat-checkbox i18n color="primary"
          formControlName="enabledAlertOddSpeed"> Alertar velocidade discrepante
        </mat-checkbox>
        <i i18n-appTooltip class="material-icons icon-sm text-secondary"
          appTooltip="Caso este campo esteja ativo, as infrações com velocidade acima de 100% da regulamentada para via serão exibidas com um alerta visual nas telas de processamento.">help</i>
      </div>

      <div class="col-12">
        <mat-checkbox i18n color="primary" formControlName="addOcrPlate">
          Preencher automaticamente o campo “Placa“ a
          partir da leitura OCR
        </mat-checkbox>
        <i i18n-appTooltip class="material-icons icon-sm text-secondary"
          appTooltip="Caso o equipamento de rua tenha OCR e realize a leitura da placa, a informação será aplicada no campo “Placa” das telas de pré-processamento.">help</i>
      </div>

      <div class="col-12">
        <mat-checkbox i18n color="primary"
          formControlName="enabledViolationStandBy"> Manter as infrações já
          digitadas
          em stand-by por até
          <span><input type="number" formControlName="maxPeriodViolationStandBy"
              class="w-55"
              [ngClass]="{ 'input-disabled': !this.form.get('enabledViolationStandBy').value }" />
            minutos. </span>
        </mat-checkbox>
        <span> Durante este período, as infrações poderão ser alteradas.</span>
        <i i18n-appTooltip class="material-icons icon-sm text-secondary"
          appTooltip="Os registros ficarão disponíveis para alteração pelo tempo definido e enquanto o usuário estiver ativo na tela de digitação. Após expiração do tempo ou caso o usuário saia da tela de digitação, os registros seguirão o fluxo normal do sistema.">help</i>
      </div>

      <div class="col-12">
        <mat-checkbox i18n color="primary"
          formControlName="invalidateExpiredViolation"> Invalidar
          automaticamente
          infrações por decurso de prazo</mat-checkbox>
        <i i18n-appTooltip class="material-icons icon-sm text-secondary"
          appTooltip="As infrações que ultrapassarem o prazo estipulado serão invalidadas automaticamente pelo sistema com o motivo definido abaixo e encaminhadas para a etapa de atribuição ao lote.">help</i>
      </div>
      <div class="col-12" *ngIf="invalidateExpiredViolation">
        <div class="row py-2">
          <div class="col-md-8">
            <label class="required">Período máximo da infração na etapa de
              pré-processamento:</label>
            <span class="d-flex align-items-center">
              <input type="number" formControlName="violationExpiredMaxPeriod"
                [inputRequired]="true"
                class="form-control mr-2 w-100" /> Dias
            </span>
          </div>
          <div class="col-md-4">
            <app-input i18n-label label="Motivo de invalidação"
              type="native-select" selectText="prettyName"
              selectValue="id" [list]="reasons"
              formControlName="violationExpiredReasonId"
              placeholder="Selecione o motivo" [inputRequired]="true">
            </app-input>
          </div>
        </div>
      </div>
    </div>

    <label i18n> <br>A permanência máxima da infração nas etapas de
      processamento é de 180 dias.
      Após expiração deste período os registros serão deletados do
      sistema.</label>

    <div class="card-divider"></div>

    <h5 class="font-weight-bold" i18n>Quarentena
      <i i18n-appTooltip data-html="true"
        class="material-icons icon-sm text-secondary"
        appTooltip="Após expiração do período máximo da infração nesta etapa,
          os registros serão invalidados automaticamente com o motivo definido e encaminhados para  a etapa de atribuição de lote.">help
      </i>
    </h5>

    <div class="row">
      <div class="col-12 col-sm-3">
        <app-input i18n-label i18n-rightUnit label="Período máximo"
          type="number" min="1" max="60" rightUnit="dias"
          formControlName="quarantineMaxPeriod" inputRequired=true>
        </app-input>
      </div>
      <div class="col-12 col-sm-4">
        <app-input i18n-label label="Motivo de invalidação" type="select"
          selectText="prettyName" selectValue="id"
          [list]="reasons" formControlName="quarantineReasonId"
          placeholder="Selecione um motivo" inputRequired=true>
        </app-input>
      </div>
      <div class="col-12">
        <label i18n>Permitir validação de velocidade discrepante?
          <i i18n-appTooltip class="material-icons icon-sm text-secondary"
            appTooltip="Serão encaminhadas para Quarentena com o motivo de 'Velocidade Discrepante'
            as infrações em que a velocidade registrada seja superior a porcentagem definida em relação à velocidade regulamentada da via.">
            help
          </i><small class="text-danger">*</small>
        </label>
        <br />
        <mat-radio-group formControlName="quarantineValidateOddSpeed"
          required="true">
          <mat-radio-button i18n color="primary" style="margin-right: 3rem;"
            [value]="true">Sim</mat-radio-button>
          <mat-radio-button i18n color="primary" style="margin-right: 3rem;"
            [value]="false">Não</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-8" class="align-text-label">
            <label class="ml-3 mt-3">Considerar velocidade discrepante a partir
              de</label>
          </div>
          <div class="col-2 ml-3 mt-3" class="align-input">
            <input class="ml-3 mt-3" type="number" min="1" max="100"
              formControlName="setPercentageOddSpeed"
              required="true">
          </div>
          <div class="col-2" class="align-text-label1">
            <label class="ml-3 mt-3" i18n>% da velocidade regulamentada na
              via.<small
                class="text-danger">*</small></label>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3">
        <label i18n>Realizar validações de negócio nas infrações com aferição
          não metrológica?
          <i i18n-appTooltip class="material-icons icon-sm text-secondary"
            appTooltip="Serão realizadas as validações de negócio nas infrações com aferição não metrológica (data da aferição, laudo pendente ou inválido e vigência da aferição)">
            help
          </i>
        </label>
        <br />
        <mat-radio-group
          formControlName="verifyNoMetrologicalCalibrationValidity">
          <mat-radio-button i18n color="primary" style="margin-right: 3rem;"
            [value]="true">Sim</mat-radio-button>
          <mat-radio-button i18n color="primary" style="margin-right: 3rem;"
            [value]="false">Não</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="card-divider"></div>
    <h5 class="font-weight-bold" i18n>Relatórios</h5>

    <p class="text-secondary mb-4" i18n>Indicação de registros críticos no
      Relatório de Mapa de Etapas:</p>

    <div class="row">
      <div class="col">
        <app-input i18n-label i18n-rightUnit label="Infração" type="text"
          rightUnit="dias"
          formControlName="violationAgeAlert" inputRequired=true>
        </app-input>
      </div>
      <div class="col">
        <app-input i18n-label i18n-rightUnit label="Lote" type="text"
          rightUnit="dias" formControlName="lotAgeAlert"
          inputRequired=true>
        </app-input>
      </div>
    </div>
    <div class="card-divider"></div>
    <h5 class="font-weight-bold" i18n>Relatórios de Aproveitamento OCR</h5>

    <div class="row">
      <div class="col">
        <mat-checkbox i18n color="primary" formControlName="enabledReportOCR">
          Habilitar relatório de Aproveitamento do
          OCR </mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col mt-2" *ngIf="form.get('enabledReportOCR').value">
        <mat-checkbox i18n color="primary"
          formControlName="allowedViewViolationsOCRFailed"> Exibir opção para
          que seja considerado
          registros em que o equipamento não realizou a leitura da placa
        </mat-checkbox>
        <i i18n-appTooltip class="material-icons icon-sm text-secondary"
          appTooltip="Caso esta opção esteja selecionada, será possível visualizar na tela de Relatório de Aproveitamento a opção para que seja considerado ou não registros em que o equipamento não realizou a leitura da placa a partir do OCR.">help</i>
      </div>
    </div>
    <div class="row"
      *ngIf="form.get('allowedViewViolationsOCRFailed').value && form.get('allowedViewViolationsOCRFailed').value">
      <div class="col-8 ml-4 mt-2">
        <label i18n>Esta opção será exibida somente para os usuários
          pertencentes aos grupos</label>
      </div>
      <div class="col">
        <div class="form-group">
          <app-input class="input-multiselect" i18n-label type="multiselect"
            [list]="userGroups"
            selectText="name" selectValue="id" formControlName="userGroupIds"
            selectShowLimit="1"
            placeholder="Selecione"
            [inputDisabled]="!form.get('allowedViewViolationsOCRFailed').value">
          </app-input>
        </div>
      </div>
    </div>

    <div class="card-divider"></div>
    <h5 class="font-weight-bold" i18n>Configuração de lotes</h5>

    <div class="row mt-3 mb-1">
      <div class="col">
        <mat-checkbox color="primary" formControlName="exportOriginalFiles">
          <span i18n>Caso ocorra alteração nas imagens durante o processamento
            as originais também devem ser
            exportadas</span>
        </mat-checkbox>
      </div>
    </div>
    <!--    <div *ngIf="hasSomeStep(['internalAudit'], 'lotSteps')">-->
    <!--      <div class="card-divider"></div>-->
    <!--      <h5 class="font-weight-bold" i18n>Auditoria</h5>-->
    <!--      <div class="row">-->
    <!--        <div class="col-12">-->
    <!--          <mat-checkbox i18n color="primary" formControlName="failBatch">Permitir ao usuário reprovar um-->
    <!--            lote.</mat-checkbox>-->
    <!--          <i i18n-appTooltip class="material-icons icon-sm text-secondary"-->
    <!--            appTooltip="Caso esta opção esteja selecionada, o sistema exibirá uma mensagem na tela de auditoria e possibilitará-->
    <!--            ao usuário confirmar ou não a reprovação de um lote, caso a quantidade máxima de infrações recusadas for atingida.">help</i>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </form>
</div>
