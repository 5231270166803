import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AlertItem, AlertType, ComponentModal, MessageModal, Reason, Regulation } from 'src/app/core/models';
import { AlertService, ContractService, ModalService, ReasonService, RegulationService } from 'src/app/core/services';
import { ReasonCreateOrUpdateComponent } from '../reason-create-or-update/reason-create-or-update.component';
import { ClosedModalError } from 'src/app/core/errors';
import { ReasonViewModalComponent } from '../reason-view-modal/reason-view-modal.component';
import { ReplicateReasonComponent } from '../replicate-reason/replicate-reason.component';

@Component({
  selector: 'app-reason-list',
  templateUrl: './reason-list.component.html',
  styleUrls: ['./reason-list.component.sass']
})
export class ReasonListComponent implements OnInit {
  public modelChanged: Subject<string> = new Subject<string>();
  public searchForm: FormGroup;
  public contracts = [];
  public regulations = [];
  public reasons = [];
  public regulationById: { [params: string]: Regulation } = {};
  public params = {};
  public deleteEvent: EventEmitter<any> = new EventEmitter();
  public reason: Reason = new Reason();
  public modalState;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private alertService: AlertService,
    public contractService: ContractService,
    public regulationService: RegulationService,
    public reasonService: ReasonService
  ) {
    this.modelChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });
  }

  ngOnInit(): void {
    this.createSearchForm();
  }

  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      search: [''],
      isEnabled: ['']
    });
  };

  openCreateOrUpdateReason(reason = {}) {
    this.modalService.show(new ComponentModal(ReasonCreateOrUpdateComponent, reason))
      .then(data => {
        const modal = data as any;
        const savedReason = modal?.component?.instance?.reason;
        const i = this.reasons.findIndex(e => e.id === savedReason?.id);
        if (i > -1) {
          this.reasons[i] = savedReason;
          return this.setReasons(this.reasons);
        }
        this.handleSearch();
      }).catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
          this.modalState = (modalError.modal as ComponentModal).component.instance.lastState;
        } else {
          this.modalState = null;
        }
      });
  }

  openReasonView(reason: Reason) {
    this.modalService.show(new ComponentModal(ReasonViewModalComponent, reason))
      .catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
        }
      });
  }

  openReplicateReason() {
    this.modalService.show(new ComponentModal(ReplicateReasonComponent))
      .then(data => {
        this.params = {};
        this.handleSearch();
      }).catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
          this.modalState = (modalError.modal as ComponentModal).component.instance.lastState;
        } else {
          this.modalState = null;
        }
      });
  }

  handleSearch() {
    this.reasons = [];
    const filterObj = {};
    const search = this.searchForm.get('search').value;
    const isEnabled = this.searchForm.get('isEnabled').value;

    if (search != null && search !== '') {
      filterObj['code[contains,or]'] = `${search}`;
      filterObj['name[contains,or]'] = `${search}`;
      filterObj['contractId[contains,or]'] = `${search}`;
      filterObj['type[contains,or]'] = `${search}`;
    }

    if (isEnabled !== '') {
      filterObj[`enabled[bool]`] = isEnabled;
    }
    this.params = filterObj;
  }

  searchKey(text: string) {
    this.modelChanged.next(text);
  }

  setReasons(list) {
    this.reasons = [];
    list.forEach((reason: Reason) => {
      if (reason.regulationIds != null) {
        this.setRegulations(reason.regulationIds);
      }
      this.contractService.getById(reason.contractId).then(contract => {
        this.contracts[contract.id] = contract;
      });
      this.reasons.push(reason);
    });
  }

  setRegulations(regulationIds) {
    regulationIds.forEach(regulationId => {
      if (this.regulationById[regulationId] == null) {
        this.regulationService.getById(regulationId).then(regulation => {
          this.regulationById[regulationId] = regulation;
        });
      }
    });
  }

  updateReasonEdit(reason: Reason) {
    return this.reasonService.update(Reason.create(reason))
      .then(res => {
        this.alertService.show(new AlertItem('ReasonSaved', AlertType.success));
        return res;
      }).catch(error => {
        this.alertService.show(new AlertItem('ReasonSaveError', AlertType.danger));
      });
  }
  openDeleteModal(reason: Reason) {
    this.modalService.show(new MessageModal('Remover Motivo', 'Deseja remover este motivo?', true))
      .then(() => {
        this.delete(reason);
      })
      .catch(err => {
        if (err.error && err.error.type === 'reasonNotDelete') {
          this.alertService.show(new AlertItem('ModelNotDelete', AlertType.danger, false, 5000));
        } else {
          if (err.error !== undefined) {
            this.alertService.show(new AlertItem('ReasonDeleteError', AlertType.danger));
          }
        }
      });
  }

  delete(reason: Reason) {
    this.reasonService.delete(reason.id)
      .then(res => {
        this.deleteEvent.emit(reason.id);
        this.alertService.show(new AlertItem('ReasonDeleted', AlertType.success));
      })
      .catch(err => {
        if (err.error && err.error.type === 'reasonNotDelete') {
          this.alertService.show(new AlertItem('ModelNotDelete', AlertType.danger, false, 5000));
        } else {
          this.alertService.show(new AlertItem('ReasonDeleteError', AlertType.danger));
        }
      });
  }

  stepModesLabel(txt: string) {
    switch (txt) {
      case 'audit':
        return 'Auditoria';
      case 'typing':
        return 'Digitação';
      case 'quarantine':
        return 'Quarentena';
      case 'validate':
        return 'Validação';
      case 'triage':
        return 'Triagem';
      case 'verifyValid':
        return 'Verificação Válidas';
      case 'verifyInvalid':
        return 'Verificação Inválidas';
      case 'exemptPeriod':
        return 'Período Isento';
      case 'monitoring':
        return 'Monitoramento CFTV';
      case 'validateCFTV':
        return 'Validação CFTV';
      case 'verifyCFTV':
        return 'Verificação CFTV';
      case 'serialNumberConfig':
        return 'Gerenciar Série';
      case 'preProcessConfig':
        return 'Configuração Pré-Processamento';
      default:
        return txt;
    }
  }
}
