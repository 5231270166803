<div class="modal-dialog modal-full modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" i18n><strong>Detalhes do Lote {{ initialState?.name }}</strong></h5>
      <button type="button" (click)="onClose()" class="btn btn-fade-secondary btn-icon-block" data-dismiss="modal" aria-label="Close">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="modal-body pt-0">
      <div class="loading-page" *ngIf="loading">
        <div class="loading-spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
      <div class="mt-3" *ngIf="!loading">
        <div class="row">
          <div class="col-md-12">
            <div class="wrapper">
              <div class="title-content">
                <div class="row">
                  <div class="col mt-3"><p i18n>Data de cometimento</p></div>
                  <div class="col mt-3"><p i18n>Quantidade</p></div>
                  <div class="col mt-3"><p i18n>Válidas</p></div>
                  <div class="col mt-3"><p i18n>Inválidas</p></div>
                  <div class="col mt-3"><p i18n>Observações</p></div>
                  <div class="col" *appPermission="['UpdateRegenerateLot', 'ExportLot', 'UpdateLot']">
                    <div class="dropdown" *ngIf="idChecked.length > 0">
                      <button class="btn btn-icon-block" type="button" id="dropdownMenuButtonAll" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons">more_horiz</i>
                      </button>
                      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButtonAll">
                        <a i18n class="dropdown-item" (click)="removeViolation()">Remover Infrações</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-data">
                <div class="item" *ngFor="let data of violationsCountByDate">
                  <div class="item-button" (click)="openLotDetail(data)" [ngClass]="{ 'active': data?.isOpen }">
                    <div class="row">
                      <div class="col"><p i18n>{{ data?.date | moment: 'L' }}</p></div>
                      <div class="col"><p i18n>{{ (data?.valid + data?.invalid) }}</p></div>
                      <div class="col"><p i18n>{{ data?.valid || 0 }}</p></div>
                      <div class="col"><p i18n>{{ data?.invalid || 0 }}</p></div>
                      <div class="col pr-5">
                        <button class="btn btn-secondary" (click)="openExtraData(initialState)" *ngIf="initialState?.extraData" i18n>Visualizar</button>
                        <span *ngIf="!initialState?.extraData">-</span>
                      </div>
                    </div>
                  </div>
                  <div class="item-content" [@openPanel]="getStatePanel(data?.isOpen)">
                    <div class="row pb-1em">
                      <div class="col-md-1">
                        <mat-checkbox i18n color="primary" (change)="allClick($event.checked)" [checked]="isCheckedAll">
                        </mat-checkbox>
                      </div>
                      <div class="col passId"><p i18n>Id da infração</p></div>
                      <div class="col"><p i18n>Data de cometimento</p></div>
                      <div class="col"><p i18n>Data de recebimento</p></div>
                      <div class="col" *ngIf="preProcessSettings?.violationSteps?.triage"><p i18n>Triado em...</p></div>
                      <div class="col" *ngIf="preProcessSettings?.violationSteps?.typing"><p i18n>Digitado em...</p></div>
                      <div class="col" *ngIf="preProcessSettings?.violationSteps?.verifyValid"><p i18n>Verificado em...</p></div>
                      <div class="col" *ngIf="preProcessSettings?.violationSteps?.validate"><p i18n>Validado em...</p></div>
                      <div class="col" *ngIf="isAudit"><p i18n>Auditado em...</p></div>
                      <div class="col"><p i18n></p></div>
                      <div class="col"><p i18n></p></div>
                    </div>
                    <div class="row data" *ngFor="let violation of data?.violations">
                      <div class="col-md-1">
                        <mat-checkbox i18n color="primary" [disabled]=false
                           [checked]="violationsSelected[violation.id]?.isChecked"
                           (change)="selectViolation($event.checked, violation.id)">
                        </mat-checkbox>
                      </div>
                      <div class="col passId"><p i18n>{{ violation?.passId }}</p></div>
                      <div class="col"><p i18n>{{ violation?.date | moment: 'L' }} {{ violation?.date | moment: 'LTS' }}</p></div>
                      <div class="col"><p i18n>{{ violation?.createdAt | moment: 'L' }} {{ violation?.createdAt | moment: 'LTS' }}</p></div>
                      <div class="col" *ngIf="preProcessSettings?.violationSteps?.triage">
                        <p i18n *ngIf="violation?.history?.triage">{{ violation?.history?.triage[0]?.date | moment: 'L' }} {{ violation?.history?.triage[0]?.date | moment: 'LTS' }}</p>
                        <p *ngIf="!violation?.history?.triage">--/--/----</p>
                      </div> <!-- Triado em... -->
                      <div class="col" *ngIf="preProcessSettings?.violationSteps?.typing">
                        <p i18n *ngIf="violation?.history?.typing">{{ violation?.history?.typing[0]?.date | moment: 'L' }} {{ violation?.history?.typing[0]?.date | moment: 'LTS' }}</p>
                        <p *ngIf="!violation?.history?.typing">--/--/----</p>
                      </div> <!-- Digitado em... -->
                      <div class="col" *ngIf="preProcessSettings?.violationSteps?.verifyValid">
                        <p i18n *ngIf="violation?.history?.verifyValid">{{ violation?.history?.verifyValid[0]?.date | moment: 'L' }} {{ violation?.history?.verifyValid[0]?.date | moment: 'LTS' }}</p>
                        <p *ngIf="!violation?.history?.verifyValid">--/--/----</p>
                      </div> <!-- Verificado em... -->
                      <div class="col" *ngIf="preProcessSettings?.violationSteps?.validate">
                        <p i18n *ngIf="violation?.history?.validate">{{ violation?.history?.validate[0]?.date | moment: 'L' }} {{ violation?.history?.validate[0]?.date | moment: 'LTS' }}</p>
                        <p *ngIf="!violation?.history?.validate">--/--/----</p>
                      </div> <!-- Validado em... -->
                      <div class="col" *ngIf="isAudit">
                        <p i18n *ngIf="violation?.history?.audits">{{ violation?.history?.audits[0]?.date | moment: 'L' }} {{ violation?.history?.audits[0]?.date | moment: 'LTS' }}</p>
                        <p *ngIf="!violation?.history?.audits">--/--/----</p>
                      </div>  <!-- Auditado em... -->
                      <div class="col">
                        <button type="button" class="btn btn-sm btn-fade-secondary btn-icon-block" (click)="showDetails(violation)">
                          <i class="material-icons">manage_search</i>
                        </button>
                      </div>
                      <div class="col">
                        <td *appPermission="['UpdateRegenerateLot', 'ExportLot', 'UpdateLot']">
                          <div class="dropdown">
                            <button class="btn btn-icon-block" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">
                              <i class="material-icons">more_horiz</i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                              <a i18n class="dropdown-item" (click)="removeViolation(violation.id)">Remover Infração</a>
                            </div>
                          </div>
                        </td>
                      </div>
                    </div>
                    <div class="row" *ngIf="data?.isOpen">
                      <div class="col-md-12">
                        <app-pagination-url [service]="violationService" [currentRoute]="'bylot'" [params]="itemParams" (items)="data.violations=$event.data; setViolations(data.violations)"></app-pagination-url>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="violationsCountByDate.length === 0 && !this.loading">
                  <hr>
                  <p class="text-center text-secondary" i18n>Não há infrações registradas...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
