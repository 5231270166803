/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { isString } from 'util';
import { IApiService } from '../interface';
import { Pagination } from '../models';
import { BaseApiModel } from '../models/BaseApiModel';
import { StorageService, StorageKey } from './storage.service';
import { Gb } from './growthbook.service';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseApiService<T extends BaseApiModel> implements IApiService<T> {
  static instances = [];
  public cache = null;
  public cacheDate = {};
  public all = null;
  public allDate = null;
  public cachePeriod = 24 * 60 * 60 * 1000;
  public cacheErrorPeriod = 60 * 1000;
  public queryIdPromises = {};
  public getAllPromise = null;
  public savePromise = null;
  gb = new Gb();

  constructor(
    protected http: HttpClient,
    protected storageService: StorageService) {
    BaseApiService.instances.push(this);
    this.gb.init();
  }

  abstract get cacheKey(): StorageKey;

  static clearCaches() {
    for (const instance of this.instances) {
      instance.clearCache();
    }
  }

  clearCache() {
    this.cache = null;
    this.cacheDate = {};
    this.all = null;
    this.allDate = null;
    this.storageService.remove(this.cacheKey);
    this.storageService.remove(this.cacheKey + 'Date');
    this.storageService.remove(this.cacheKey + 'All');
    this.storageService.remove(this.cacheKey + 'DateAll');
  }

  abstract getUrl(operation: string, params, id?: string): string;

  // set cache and instances
  setCache(id, model: T) {
    if (this.cache == null) {
      this.initializeCache();
    }
    if (model != null) {
      if (this.cache[id] != null && !this.isCachedError(id)) {
        this.cache[id] = this.replaceProperties(this.cache[id], model);
        model = this.cache[id];
      } else {
        this.cache[id] = model;
      }
      this.cacheDate[id] = new Date().valueOf();
    } else {
      delete this.cache[id];
      delete this.cacheDate[id];
    }
    this.storageService.set(this.cacheKey, this.cache);
    this.storageService.set(this.cacheKey + 'Date', this.cacheDate);
    return model;
  }

  setCacheList(list) {
    if (this.cache == null) {
      this.initializeCache();
    }
    for (const item of list) {
      this.cache[item.id] = item;
      this.cacheDate[item.id] = new Date().valueOf();
    }
    this.storageService.set(this.cacheKey, this.cache);
    this.storageService.set(this.cacheKey + 'Date', this.cacheDate);
  }

  setCacheError(id, status: number) {
    if (this.cache == null) {
      this.cache = this.storageService.get(this.cacheKey) || {};
      this.cacheDate = this.storageService.get(this.cacheKey + 'Date') || {};
    }
    this.cache[id] = 'error:' + status;
    this.cacheDate[id] = new Date().valueOf();
    this.storageService.set(this.cacheKey, this.cache);
    this.storageService.set(this.cacheKey + 'Date', this.cacheDate);
  }

  getCache(id): T {
    if (this.cache == null) {
      this.initializeCache();
    }
    if (this.cache[id] != null && new Date().valueOf() < this.cacheDate[id] + this.cachePeriod) {
      return this.cache[id];
    }
  }

  isCachedError(id) {
    return typeof this.cache[id] === 'string' && this.cache[id].startsWith('error:');
  }

  setCacheAll(list: T[]) {
    this.all = list;
    this.allDate = new Date().valueOf();
    this.storageService.set(this.cacheKey + 'All', list);
    this.storageService.set(this.cacheKey + 'DateAll', new Date().valueOf());
  }

  getCacheAll(): T[] {
    if (this.all != null && new Date().valueOf() < this.allDate + this.cachePeriod) {
      return this.all;
    }
    const local = this.storageService.get(this.cacheKey + 'All');

    const date = this.storageService.get(this.cacheKey + 'DateAll');
    if (local != null && new Date().valueOf() < date + this.cachePeriod) {
      const result = [];
      if (this.cache == null) {
        this.cache = {};
      }
      for (const item of local) {
        const model = this.unparse(item);
        if (this.cache[model.id] == null) {
          this.cache[model.id] = model;
        } else {
          this.cache[model.id] = this.replaceProperties(this.cache[model.id], model);
        }
        result.push(this.cache[model.id]);
      }
      this.all = result;
      return this.all;
    }
    return null;
  }

  list(params: { [params: string]: string } | string): Promise<Pagination<T>> {
    let req: Observable<any>;

    if (typeof params === 'string') {
      req = this.http.get(environment.apiUrl + params);
    } else {
      const httpParams = new HttpParams({
        fromObject: params as { [params: string]: string }
      });
      req = this.http.get(this.getUrl('list', params), { params: httpParams });
    }

    return req.pipe(
      catchError((responseError) => this.handleError(responseError)),
      map(body => {
        if (body == null || body.result == null) {
          body = { result: [] };
        }
        body.result = body?.result.map(model => this.unparse(model));
        this.setCacheList(body.result);
        return body;
      })
    ).toPromise();
  }

  async getAll(params: { [params: string]: string } = null, force = false): Promise<Array<T>> {
    if (params == null && !force) {
      if (this.getAllPromise != null) {
        await this.getAllPromise;
      }
      const all = this.getCacheAll();
      if (all != null) {
        return all;
      }
    }
    let getAllResolve;
    let getAllReject;
    if (params == null) {
      this.getAllPromise = new Promise((resolve, reject) => {
        getAllResolve = resolve;
        getAllReject = reject;
      }).finally(() => this.getAllPromise = null);
    }
    let next: any = {
      limit: 1000
    };
    if (params != null) {
      next = Object.assign({
        limit: 1000
      }, params);
    }
    let list = [];
    try {
      do {
        const result = await this.list(next);
        list = list.concat(result.result);
        next = result.next;
      } while (next != null);
    } catch (error) {
      if (params == null) {
        getAllReject(error);
      }
      throw error;
    }
    if (params == null) {
      getAllResolve(list);
      this.setCacheAll(list);
    }
    return list;
  }

  async getById(id: any, force: boolean = false): Promise<T> {
    if (!force) {
      const model = this.getCache(id);
      if (model != null) {
        return model;
      }
    }
    if (this.queryIdPromises[id] != null) {
      return this.queryIdPromises[id];
    }
    const url = this.getUrl('get', null, id);
    const promise = this.http.get(url).pipe(
      catchError(this.handleError),
      map(model => {
        model = this.unparse(model);
        return this.setCache(model.id, model);
      })
    ).toPromise().catch(err => {
      if (err.status === 404 || err.status === 403) {
        this.setCacheError(id, err.status);
      }
      throw err;
    });
    this.queryIdPromises[id] = promise;
    promise.finally(() => {
      delete this.queryIdPromises[id];
    });
    return promise;
  }

  initializeCache() {
    const storage = this.storageService.get(this.cacheKey) || {};
    this.cache = {};
    Object.keys(storage).forEach(key => {
      if (typeof storage[key] === 'string' && storage[key].startsWith('error:')) {
        this.cache[key] = storage[key];
      } else {
        this.cache[key] = this.unparse(storage[key]);
      }
    });
    this.cacheDate = this.storageService.get(this.cacheKey + 'Date') || {};
  }

  async create(model: T, isformData: boolean = false): Promise<T> {
    let req;
    if (isformData) {
      req = this.formDataParser(model);
    } else {
      req = model;
    }
    const url = this.getUrl('create', model);
    if (this.savePromise != null) {
      this.savePromise = this.savePromise.catch(() => { }).then(v => this.post(url, req, model));
    } else {
      this.savePromise = this.post(url, req, model);
    }
    return this.savePromise;
  }

  private post(url, request, model) {
    return this.http.post(url, request).pipe(
      catchError(this.handleError),
      map(m => {
        m = this.replaceProperties(model, m);
        let local = this.storageService.get(this.cacheKey + 'All');
        if (local == null) {
          this.getAll();
        } else {
          local = local.map(item => this.unparse(item));
          if (!local.includes(m.id)) {
            local.push(model);
            this.setCacheAll(local);
          }
        }
        return this.setCache(m.id, m);
      })
    ).toPromise();
  }

  protected formDataParser(model: T, formData = new FormData(), prefix = '') {
    if (Array.isArray(model)) {
      model.forEach((value, index) => {
        this.formDataParser(value, formData, `${prefix}[${index}]`);
      });
    } else if (model instanceof File) {
      formData.append(prefix, model);
    } else if (typeof model === 'object') {
      for (const key of Object.keys(model)) {
        if (prefix === '') {
          this.formDataParser(model[key], formData, key);
        } else {
          this.formDataParser(model[key], formData, prefix + '[' + key + ']');
        }
      }
    } else {
      formData.append(prefix, model);
    }
    return formData;
  }

  update(model: T, isformData: boolean = false): Promise<T> {
    const oldId = model.id;
    let req;
    if (isformData) {
      req = this.formDataParser(model);
    } else {
      req = this.parse(model);
    }
    const url = this.getUrl('update', model, model.id);
    if (this.savePromise != null) {
      this.savePromise = this.savePromise.catch(() => { }).then(v => this.put(url, req, oldId));
    } else {
      this.savePromise = this.put(url, req, oldId);
    }
    return this.savePromise;
  }

  private put(url, request, oldId) {
    return this.http.put(url, request).pipe(
      catchError(this.handleError),
      map(m => {
        if (m.id !== oldId && this.cache[oldId] !== 'error:404') {
          const oldModel = this.cache[oldId];
          m = this.replaceProperties(oldModel, m);
          this.setCache(oldId, undefined);
        } else {
          m = this.unparse(m);
        }
        const local = this.storageService.get(this.cacheKey + 'All');
        if (local != null) {
          const updateCacheAll = local.map(item =>
            item.id === m.id ? m : item
          );
          this.setCacheAll(updateCacheAll);
        }
        return this.setCache(m.id, m);
      })
    ).toPromise();
  }

  delete(id: string): Promise<T> {
    const url = this.getUrl('delete', null, id);
    return this.http.delete(url).pipe(
      catchError(this.handleError)
    ).toPromise().then(r => {
      const local = this.storageService.get(this.cacheKey + 'All');
      if (local == null) {
        this.getAll();
      } else {
        const updateCacheAll = local.filter(item => item.id !== id);
        this.setCacheAll(updateCacheAll);
      }
      return this.setCache(id, undefined);
    });
  }


  protected abstract unparse(obj): T;

  protected abstract parse(obj): T;

  protected handleError(error: any): Observable<any> {
    return throwError(error);
  }

  replaceProperties(target, source) {
    if (target == null) {
      return source;
    }
    Object.assign(target, source);
    for (const i in target) {
      if (source[i] === undefined) {
        delete target[i];
      }
    }
    return target;
  }

  removeContractId(params: { [params: string]: string }) {
    const regex = /^(contractId)(?=\[|$)/;
    Object.keys(params).forEach(key => {
      const match = key.match(regex);
      if (match != null) {
        delete params[key];
      }
    });
    return params;
  }
}
