import {Component, EventEmitter, OnInit} from '@angular/core';
import {AlertItem, AlertType, ComponentModal,
  Contract,
  Lane, Lot, Reason, Regulation, Spot, SpotGroup, Violation} from '../../../../core/models';
import {ViolationService, ModalService,
  SpotGroupService, SpotService, LaneService,
  ReasonService, EquipmentModelService,
  ContractService, RegulationService,
  ManufacturerService} from '../../../../core/services';
import {ActivatedRoute} from '@angular/router';
import {LotCreateComponent} from '../../../../modals/lot-create/lot-create.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SelectPeriodComponent } from 'src/app/modals/select-period/select-period.component';
import * as moment from 'moment-timezone';
import { DetailsViolationComponent } from 'src/app/modals/details-violation/details.component';
import { Gb } from 'src/app/core/services/growthbook.service';

@Component({
  selector: 'app-lot-generete',
  templateUrl: './lot-generate.component.html',
  styleUrls: ['./lot-generate.component.sass']
})
export class LotGenerateComponent implements OnInit {
  public violations: Array<Violation> = [];
  public params: any = {};
  public contractId: string;
  public idChecked: any =  [];
  public loadPromise = false;
  public columns = [
    'passId',
    'violationDate',
    'spot',
    'lane',
    'regulation',
    'status',
    'reason'
  ];
  public columnsToShow = [];
  public isLoading: boolean;
  public isChecked = false;
  public isCheckedAll = false;
  public violationsSelected: {
    [key: string]: {
      contractId: string;
      isChecked: boolean;
    };
  } = {};
  public deleteEvent: EventEmitter<any> = new EventEmitter();
  public filterForm: FormGroup;
  public spotGroups: SpotGroup[] = [];
  public spots: Spot[] = [];
  public allSpots: Spot[] = [];
  public lanes: Lane[] = [];
  public allLanes = [];
  public isValid: boolean;
  public regulations: Regulation[] = [];
  public situations: any[] = [];
  public reasons: Reason[] = [];
  public equipmentModels: any = [];
  public equipmentModelsByContract: any = {};
  public situationIds: any[] = ['valid', 'invalid'];
  public situationIdsI18n = [];
  public isViewReason = false;
  public loading: boolean;
  public modelChanged: Subject<string> = new Subject<string>();
  public manufacturers = [];
  public startDate;
  public endDate;
  public contract: Contract;
  public gb: Gb;
  private targetNumber = 35;

  constructor(
    public violationService: ViolationService,
    public activateRoute: ActivatedRoute,
    public modalService: ModalService,
    private formBuilder: FormBuilder,
    private spotGroupService: SpotGroupService,
    private spotService: SpotService,
    private laneService: LaneService,
    private reasonService: ReasonService,
    private equipmentModelService: EquipmentModelService,
    private contractService: ContractService,
    private regulationService: RegulationService,
    private manufacturerService: ManufacturerService,
  ) {
    this.gb = new Gb();
    this.modelChanged.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.handleSearch();
      });

  }

  async ngOnInit() {
    this.isLoading = true;
    this.contractId = this.activateRoute.snapshot.params.contractId;
    this.manufacturerService.getAll().then(res => {
      this.manufacturers = res;
    });

    this.params = {
      limit: '' + this.targetNumber,
      contractId: `${this.contractId}`,
      step: 'lotAttribution',
      ['sortAsc']: 'false',
      order: 'date'
    };
    this.violationService.list(this.params).then(data => {
      this.violations = data.result;
      this.violations.forEach(async violation => {
        this.violationsSelected[violation.id] = {
          contractId: violation.contractId,
          isChecked: false
        };
      });
    }).catch(err => {
      throw err;
    }).finally(() => {
      this.isLoading = false;
    });
    this.spotGroupService.list({ contractId: this.contractId, order: 'name' }).then(res => {
      this.spotGroups = res.result;
    });
    this.spotService.list({ contractId: this.contractId, order: 'code' }).then(res => {
      this.spots = res.result;
      this.allSpots = res.result;
    });
    this.laneService.list({ contractId: this.contractId, order: 'code' }).then(res => {
      this.allLanes = res.result.filter(r => r?.prettyNames?.length > 0).map( e => ({ ...e, prettyName: e.prettyNames?.[0] }));
      this.lanes = this.allLanes;
    });
    this.contractService.getById(this.contractId).then((contract) => {
      this.contract = contract;
      const params = {
        regionId: contract.regionId
      };
      if (this.contract.regulationIds) {
        params['id[in]'] = `[${this.contract.regulationIds}]`;
      }
      this.regulationService.getAll(params).then(res => {
        this.regulations = res;
      });
      const equipmentModelIds = contract.equipmentModelIds.sort();
      for (const modelId of equipmentModelIds) {
        this.equipmentModelService.getById(modelId).then(e => {
          const equipmentModel: any = e;
          const manufacturer = this.manufacturers.find(m => m.id === e.manufacturerId);
          const prettyName= `${e.name} - ${manufacturer.name}`;
          equipmentModel.prettyName = prettyName;
          this.equipmentModels.push(e);
        });
      }
    });
    this.reasonService.list({ contractId: this.contractId, order: 'code', 'enabled[bool]': 'true' }).then(resReasons => {
      this.reasons = resReasons.result.sort((a, b): any => Number(a.code) - Number(b.code));
    });
    this.createForm();
    this.setDate();
    await this.gb.init();
  }

  createForm() {
    this.filterForm = this.formBuilder.group({
      spotGroupIds: [''],
      spotIds: [''],
      laneIds: [''],
      regulationIds: [''],
      situationIds: [''],
      reasonIds: [''],
      equipmentModelIds: [''],
      search: [''],
    });
    this.filterForm.get('situationIds').valueChanges.subscribe(res => {
      this.isViewReason = false;
      if (res.includes('invalid')) {
        this.isViewReason = true;
      }
    });
    this.filterForm.get('spotGroupIds').valueChanges.subscribe(res => {
      this.spots = this.allSpots;
      this.lanes = this.allLanes;
      if (res && res.length > 0) {
        const spotGroups: any = this.spotGroups.filter(sptGrp => res.includes(sptGrp.id + ''));
        const spotIds = spotGroups.map(m => m.spotIds).flat();
        this.spots = this.allSpots.filter(s => spotIds.includes(s.id));
        this.lanes = this.lanes.filter(s => spotIds.includes(s.spotId));
      }
    });
    this.filterForm.get('spotIds').valueChanges.subscribe(res => {
      this.lanes = this.allLanes;
      if (res && res.length > 0) {
        this.lanes = this.lanes.filter(lane => res.includes(lane.spotId + ''));
      }
    });
    this.filterForm.valueChanges.subscribe(res => {
      this.isValid = this.filterForm.valid;
    });
  }

  handleSearch() {
    const search = this.filterForm.get('search').value;
    delete this.params['passId[contains,or]'];
    this.violations = [];
    const params: any = Object.assign({}, this.params);
    if (search != null && search !== '') {
      params['passId[contains,or]'] = `${search}`;
    }
    this.params = {};
    this.loading = true;
    this.params = params;
  }

  selectViolation(checked, id) {
    this.isChecked = checked;
    this.violationsSelected[id].isChecked = checked;
    this.isCheckedAll = Object.values(this.violationsSelected)
      .every(v => v.isChecked === true);

    this.isChecked = Object.values(this.violationsSelected)
      .some(v => v.isChecked === true);
    if(checked && !this.idChecked.find((value) => value === id)) {
      this.idChecked.push(id);
    } else if(!checked) {
      this.idChecked = this.idChecked.filter( value => value !== id);
    }

  }
  allClick(checked) {
    this.isCheckedAll = checked;
    for (const i in this.violationsSelected) {
      if (this.violationsSelected[i] != null) {
        this.violationsSelected[i].isChecked = this.isCheckedAll;
        this.isChecked = checked;
        const id = Number(i);
        if (checked && !this.idChecked.find((value) => value === id)) {
          this.idChecked.push(id);
        } else if(!checked) {
          this.idChecked = this.idChecked.filter( value => value !== id);
        }
      }
    }
  }
  translate(status) {
    return status === 'valid'? 'Válido' : 'Inválido';
  }
  setViolations(list) {
    this.isCheckedAll = false;
    this.violationsSelected = {};
    this.violations = list;
    if (this.violations.length > 0) {
      this.violations.forEach(async violation => {
        const check = this.idChecked.length > 0 && this.idChecked.filter( value => value === violation.id).length > 0 ? true : false;
        this.violationsSelected[violation.id] = {
          contractId: violation.contractId,
          isChecked: check
        };
      });
    }
  }

  submitFilter() {
    this.violations = [];
    this.params = {};
    let spotIds = [];
    const spotGroupIds = this.filterForm.get('spotGroupIds').value;
    if (spotGroupIds?.length > 0) {
      const spotGroups: any = this.spotGroups.filter(sptGrp => spotGroupIds.includes(sptGrp.id + ''));
      spotIds = spotGroups.map(m => m.spotIds).flat();
    }
    const spotsIds = this.filterForm.get('spotIds').value;
    delete this.params['spotCode[in]'];
    delete this.params['laneCode[in]'];
    delete this.params['regulationId[in]'];
    delete this.params['status[in]'];
    delete this.params['reason.code[in]'];
    delete this.params['equipment.modelName[in]'];
    const params: any = {
      limit: '' + this.targetNumber,
      contractId: `${this.contractId}`,
      step: 'lotAttribution',
      ['sortAsc']: 'false',
      order: 'date'
    };
    if (spotIds?.length > 0) {
      const spotCodes = this.spots.filter(s => spotIds.includes(s.id)).map(s => s.code);
      params['spotCode[in]'] = `[${spotCodes.join(',')}]`;
    }

    if (spotsIds.length > 0) {
      const spotCodes = this.spots.filter(s => spotsIds.includes(s.id + '')).map(s => s.code);
      params['spotCode[in]'] = `[${spotCodes.join(',')}]`;
    }

    const laneIds = this.filterForm.get('laneIds').value;
    if (laneIds?.length > 0) {
      const laneCodes = this.lanes.filter(s => laneIds.includes(s.id + '')).map(s => s.code);
      params['laneCode[in]'] = `[${laneCodes}]`;
    }
    const regulationIds = this.filterForm.get('regulationIds').value;
    if (regulationIds?.length > 0) {
      params['regulationId[in]'] = `[${regulationIds}]`;
    }
    const situationIds = this.filterForm.get('situationIds').value;
    if (situationIds?.length > 0) {
      params['status[in]'] = `[${situationIds}]`;
    }
    const reasonIds = this.filterForm.get('reasonIds').value;
    if (reasonIds?.length > 0) {
      const reasonCodes = this.reasons.filter(s => reasonIds.includes(s.id + '')).map(s => s.code);
      params['reason.code[in]'] = `[${reasonCodes}]`;
    }
    const equipmentModelIds = this.filterForm.get('equipmentModelIds').value;
    if (equipmentModelIds?.length > 0) {
      const equipmentModelNames = this.equipmentModels.filter(s => equipmentModelIds.includes(s.id + '')).map(s => s.name);
      params['equipment.modelName[in]'] = `[${equipmentModelNames}]`;
    }
    params['date[gte]'] = moment(this.startDate).utc().format();
    params['date[lte]'] = moment(this.endDate).utc().format();
    this.loading = true;
    this.params = params;
  }

  loadingEvent(valueEmitted) {
    this.isLoading = valueEmitted;
  }

  redefineFilter() {
    this.filterForm.get('spotGroupIds').setValue([]);
    this.filterForm.get('spotIds').setValue([]);
    this.filterForm.get('laneIds').setValue([]);
    this.filterForm.get('regulationIds').setValue([]);
    this.filterForm.get('situationIds').setValue([]);
    this.filterForm.get('reasonIds').setValue([]);
    this.filterForm.get('equipmentModelIds').setValue([]);
    this.spots = this.allSpots;
    this.lanes = this.allLanes;
    this.submitFilter();
  }

  searchKey(text: string) {
    this.modelChanged.next(text);
  }

  async openLotCreate() {
    const lot: {
      violationIds: any;
      contractId: number;
      name: string; } = {
      contractId: Number(this.contractId),
      name: '',
      violationIds: this.idChecked
    };
    await this.modalService.show(new ComponentModal(LotCreateComponent, lot))
      .catch(() => { })
      .then(data => {
        if (data) {
          for(const vId of this.idChecked)  {
            this.violations = this.violations.filter((value) => value.id !== vId);
          }
          this.idChecked = [];
        }
      });
  }

  getRegulation(id) {
    return this.regulations.find((e) => e.id === id)?.description;
  }
  getSpotDescription(code) {
    return this.spots.find((e) => e.code === code)?.description;
  }

  setDate() {
    const now = new Date().toISOString();
    this.startDate = moment(now).subtract(30, 'days').set({hour:0,minute:0,second:0,millisecond:0})
    .format('YYYY-MM-DD[T]HH:mm:ss') +'Z';
    this.endDate = moment(now).set({hour:20,minute:59,second:59,millisecond:59}).utc().format('YYYY-MM-DD[T]HH:mm:ss') + 'Z';
  }

  async selectModalPeriod() {
    await this.modalService.show(new ComponentModal(SelectPeriodComponent, {
      startDate: this.startDate,
      endDate: this.endDate
    }))
      .catch(() => { })
      .then(data => {
        if (data == null) {
          return;
        }
        const modal = data as any;
        const values = modal && modal.component
          && modal.component.instance
          && modal.component.instance.periodSelected;

        this.startDate = values.startDate;
        this.endDate = values.endDate;
        this.submitFilter();
      });
  }

  // Visualizar infração
  async showDetails(violation) {
    await this.modalService.show(new ComponentModal(DetailsViolationComponent, { violation }))
      .catch((error) => console.log(error));
  }
  // fim Visualizar infração
}
