import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReasonsRoutingModule } from './reasons-routing.module';
import { ReasonListComponent } from './reason-list/reason-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CoreModule } from 'src/app/core/core.module';
import { ReasonCreateOrUpdateComponent } from './reason-create-or-update/reason-create-or-update.component';
import { ReasonViewModalComponent } from './reason-view-modal/reason-view-modal.component';
import { ReplicateReasonComponent } from './replicate-reason/replicate-reason.component';

@NgModule({
  declarations: [
    ReasonListComponent,
    ReasonCreateOrUpdateComponent,
    ReasonViewModalComponent,
    ReplicateReasonComponent
  ],
  imports: [
    CommonModule,
    ReasonsRoutingModule,
    CommonModule,
    SharedModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule
  ],
  entryComponents: [
    ReasonCreateOrUpdateComponent,
    ReplicateReasonComponent
  ]
})
export class ReasonsModule { }
