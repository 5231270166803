import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AlertItem, AlertType, ComponentModal } from 'src/app/core/models';
import {
  AuthenticationService,
  StorageKey, StorageService,
  AlertService, ModalService,
  ContractService,
  ContractGlobalService, PreProcessSettingsService
} from '../../services';
import { permissionStep } from 'src/app/core/utils/permission';
import pkg from '../../../../../package.json';
import { ReleaseNotesService } from '../../services/releaseNotes.service';
import { ClosedModalError } from '../../errors';
import { ReleaseNotesComponent } from 'src/app/modals/release-notes-modal/release-notes.component';
import { VisumVersionService } from '../../services/visumVersion.service';
import { VisumVersion } from '../../models/VisumVersion';
import { debounceTime } from 'rxjs/operators';
import {Subject} from 'rxjs';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass']
})
export class MenuComponent implements OnInit {
  @Output() isOpenChange = new EventEmitter();
  @Input() get isOpen() {
    return this._isOpen;
  }
  set isOpen(value) {
    this._isOpen = value;
    this.isOpenChange.emit(value);
  }

  public isSubMenu = false;
  public openSubMenu;
  public appVersion;
  public lastReleaseUpdated;
  public contracts: any = [];
  public contractGlobal = null;
  public user: any = {};
  public importFile: boolean;
  private _isOpen = false;
  public contractGlobalArray = [];
  public modelChanged: Subject<string> = new Subject<string>();
  public stepTyping = false;

  constructor(
    private authService: AuthenticationService,
    private storageService: StorageService,
    private router: Router,
    private alertService: AlertService,
    private releaseNotesService: ReleaseNotesService,
    private modalService: ModalService,
    private contractService: ContractService,
    private visumVersionService: VisumVersionService,
    private contractGlobalService: ContractGlobalService,
    private contractsGlobalService: ContractGlobalService,
    private preProcessSettingsService: PreProcessSettingsService
  ) {
    this.contractGlobalService.contractEvent.pipe(
      debounceTime(1000))
      .subscribe(() => {
        if (this.contractGlobalService.contracts != null) {
          this.contractGlobal = this.contractGlobalService.contracts.length === 1 ? this.contractGlobalService.contracts[0] : null;
        }
      });
    this.modelChanged.pipe(
      debounceTime(50))
      .subscribe(() => {
        this.getPreProcessStep();
      });
    this.contractsGlobalService.contractEvent.pipe(
      debounceTime(100))
      .subscribe(() => {
        this.getPreProcessStep();
      });
  }
  async ngOnInit() {
    this.importFile = false;
    const user = this.storageService.get(StorageKey.currentUser);
    if (user != null) {
      this.user = user;
    }
    this.contractGlobal = this.contractGlobal ||
      (this.contractGlobalService.contracts?.length > 0 ? this.contractGlobalService.contracts[0] : null);
    await this.getVersion();
    this.loadReleaseNotes();
    await this.contractService.list({ ['enabledImportFile[bool]']: 'true' }).then(res => {
      if (res.result.length > 0) {
        this.contracts = res.result;
        this.importFile = true;
      }
    });
    this.getPreProcessStep();
  }

  async getVersion() {
    await this.visumVersionService.get().subscribe(res => {
      this.appVersion = res?.version;
    });
  }

  logout() {
    this.authService.logout();
  }

  closeMenu() {
    this.isOpen = false;
  }

  menuNavigate(event, dest) {
    event.preventDefault();
    this.isSubMenu = true;
    this.openSubMenu = dest;
    $(dest).addClass('is-active');
  }

  toggleClass(event, dest) {
    event.preventDefault();
    $(dest).toggleClass('is-active');
  }

  navigatePreProcess(step = '') {
    this.closeMenu();
    const stepPermission = permissionStep(step);
    if (stepPermission === '') {
      this.alertService.show(new AlertItem('ModuleUnavailable', AlertType.info));
      this.router.navigate([`/`]);
      return;
    }
    this.router.navigate([`pre-process/${step}/available-contracts`]);
  }

  menuNavigateBack(event = null) {
    if (event != null) {
      event.preventDefault();
    }
    this.isSubMenu = false;
    $(this.openSubMenu).removeClass('is-active');
  }

  unavailable() {
    this.alertService.show(new AlertItem('ModuleUnavailable', AlertType.info));
  }

  async loadReleaseNotes() {
    this.releaseNotesService.getAll().then(notes => {
      for (const i in notes) {
        if (!notes[i].version || !notes[i].version.match(/([0-9]?)[0-9].[0-9].[0-9]/gm)) {
          delete notes[i];
        }
      }
      if (notes.length > 0) {
        this.lastReleaseUpdated = notes.slice(-1).pop().version;
      }
    }).catch(error => {
      console.log(error);
    });
  }

  clickModalRelease() {
    this.storageService.set(StorageKey.lastReleaseNote, this.lastReleaseUpdated);
    this.isOpen = false;
    this.modalService.show(new ComponentModal(ReleaseNotesComponent))
      .catch(err => {
        if (err instanceof ClosedModalError) {
          const modalError = err as ClosedModalError;
        }
      });
  }

  async getPreProcessStep() {
    this.contractGlobalArray = this.contractsGlobalService.contracts || null;
    if (!this.contractGlobalArray) {
      this.stepTyping = false;
      return;
    }
    for (const contract of this.contractGlobalArray) {
      await this.preProcessSettingsService.getById(contract).then(preProcessSettings => {
        if(preProcessSettings.violationSteps.typing === undefined) {
          this.stepTyping = true;
        }
      });
    }
  }
}
