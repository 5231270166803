import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Lane } from '../models';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './baseApiService';
import { StorageKey } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LaneService extends BaseApiService<Lane> {
  get cacheKey(): StorageKey {
    return StorageKey.laneServiceCache;
  }

  getUrl(operation: string, params, id: string = null): string {
    let url = `${environment.apiUrl}/lanes`;
    if (id != null) {
      url = url + '/' + encodeURIComponent(id);
    }
    return url;
  }

  getLaneSettings(id) {
    const url = `${environment.apiUrl}/lanes/${encodeURIComponent(id)}/settings`;
    return this.http.get(url).pipe().toPromise();
  }

  saveLaneSettings(id, body) {
    const url = `${environment.apiUrl}/lanes/${encodeURIComponent(id)}/settings`;
    return this.http.post(url, body).pipe().toPromise();
  }

  removeLaneSettings(id) {
    const url = `${environment.apiUrl}/lanes/${encodeURIComponent(id)}/settings`;
    return this.http.delete(url).pipe().toPromise();
  }

  protected parse(obj) {
    return Lane.parse(obj);
  }

  protected unparse(obj) {
    return Lane.unparse(obj);
  }
}
