import { BaseApiModel } from './BaseApiModel';

export class VehicleWhiteList extends BaseApiModel {
  public contractId: string;
  public regulationIds: Array<string>;
  public plate: string;
  public recordNumber: string;
  public startsAt: Date;
  public endsAt: any;
  public restrictionZoneId: string;
  public modalityId: string;
  public files: any[];

}
