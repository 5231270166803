<h3 i18n class="mb-5 text-center">
  <span *ngIf="loading">Verificando link</span>
  <div *ngIf="!invalidKey">
    <span *ngIf="!loading && !data.expired">Link verificado</span>
    <span *ngIf="!loading && data.expired">Link expirado</span>
  </div>
  <span *ngIf="!loading && invalidKey">Link inválido</span>
</h3>

<div class="loading-page" *ngIf="loading">
  <div class="loading-spinner">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</div>

<div *ngIf="!loading">
  <div *ngIf="!invalidKey">
    <div class="text-danger text-center" *ngIf="data.expired">
      <p><i class="material-icons">error</i></p>
      <p i18n>O link para download do relatório está expirado. Necessário realizar uma nova geração de relatório.</p>
    </div>
    <div class="text-success text-center" *ngIf="!data.expired">
      <p><i class="material-icons">check_circle</i></p>
      <p class="mb-0" i18n>O download está em andamento.</p>
      <p class="text-secondary f-875">(Caso o download não inicie automaticamente, clique no botão a baixo).</p>
    </div>
  </div>
  <div class="text-danger text-center" *ngIf="invalidKey">
    <p><i class="material-icons">error</i></p>
    <p i18n>O link para download do relatório está inválido. Necessário realizar uma nova geração de relatório.</p>
  </div>
</div>

<div class="mt-5 text-center" *ngIf="!loading">
  <button [routerLink]="['']" class="btn btn-fade-secondary btn-icon mr-2" i18n>
    <i class="material-icons">subdirectory_arrow_left</i> Voltar ao sistema
  </button>
  <a href="{{data.download}}" download="relatório.csv" *ngIf="!loading && !data.expired && !invalidKey" #downloadButton>
    <button type="button" class="btn btn-primary">Download</button>
  </a>
</div>
