import { BaseApiModel } from './BaseApiModel';

export class Video extends BaseApiModel {
  public module: string;
  public description: string;
  public function: string;
  public file: {
    url: string
    urlCurrent: string;
  };
  public createdAt: string;
  public modifiedAt: string;
  public deletedAt: string;
}
