import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { ModalService } from 'src/app/core/services';
import { ComponentModal } from 'src/app/core/models';
import { ProductionReportService } from 'src/app/core/services';
import { SelectPeriodComponent } from 'src/app/modals/select-period/select-period.component';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-production-report-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.sass']
})
export class ProductionReportPeriodComponent implements OnInit {
  public modalPeriodSelected: any = {};
  public contractSub: Subscription;
  public timezone: string;
  public startDate;
  public endDate;

  constructor(
    private modalService: ModalService,
    private productionReportService: ProductionReportService
  ) { }

  ngOnInit() {
    const now = new Date().toISOString();
    this.startDate = moment(now).subtract(7, 'days').utc().format();
    this.endDate = moment(now).utc().format();
  }

  async selectModalPeriod() {
    await this.modalService.show(new ComponentModal(SelectPeriodComponent, {
      startDate: this.startDate,
      endDate: this.endDate
    }))
      .catch(() => { })
      .then(data => {
        if (data == null) {
          return;
        }
        const modal = data as any;
        this.modalPeriodSelected = modal && modal.component
          && modal.component.instance
          && modal.component.instance.periodSelected;

        this.startDate = this.modalPeriodSelected.startDate;
        this.endDate = this.modalPeriodSelected.endDate;
        this.emitNewParams();
      });
  }

  emitNewParams() {
    const periodParams = {};
    periodParams['startsAt[gte]'] = moment(this.startDate).utc().format();
    periodParams['modifiedAt[lte, or]'] = moment(this.endDate).utc().format();
    this.productionReportService.emitPeriodProduction(periodParams);
  }

}
