import { Component, OnInit } from '@angular/core';

import {
  ContractService, UserGroupService, StorageKey, StorageService,
  RegulationService, ViewViolationService, UserService
} from 'src/app/core/services';
import { Contract, Regulation, User } from 'src/app/core/models';
import { RegionService } from 'src/app/core/services/region.service';

@Component({
  selector: 'app-violations-view',
  templateUrl: './view.component.html'
})
export class ViolationsViewComponent implements OnInit {
  public contracts: Contract[] = [];
  public regulations: Regulation[] = [];
  public users: User[] = [];
  public situations: any[] = [];
  public situationsList: any[] = ['inProcess', 'valid', 'invalid'];

  constructor(
    private contractService: ContractService,
    private regulationService: RegulationService,
    private storageService: StorageService,
    private userGroupService: UserGroupService,
    private userService: UserService,
    private viewViolationService: ViewViolationService,
    private regionService: RegionService
  ) {
  }

  async ngOnInit() {
    const currentUser = this.storageService.get(StorageKey.currentUser);
    const paramsContract: any = {};

    if (currentUser && !currentUser.superUser) {
      const contractsUser = [];
      await this.userGroupService.getAll()
        .then(userGroups => {
          userGroups.map(group => {
            if (currentUser.groupIds.includes(group.id) && group.permissions.includes('ViewViolation')) {
              contractsUser.push(group.contractId);
            }
          });
        });

      paramsContract['id[in]'] = `[${contractsUser.join()}]`;
    }

    this.contractService.getAll(paramsContract).then(contracts => {
      this.contracts = contracts;
      this.regionService.getAll({ name: 'Brasil' }).then(regions => {
        const region = regions[0];
        this.regulationService.getAll({ regionId: region.id }).then(regulations => {
          this.regulations = regulations;
          this.userService.getAll().then(users => {
            this.users = users;
            const dataViewViolation = {
              contracts: this.contracts,
              regulations: this.regulations,
              situations: this.situations,
              users: this.users
            };
            this.viewViolationService.emitDataViewViolation(dataViewViolation);
          });
        });
      });
    });


  }

  setResources(value) {
    this.situations = value;
  }

}
